import React from 'react';
import styles from './ComingSoon.module.css';
import { useNavigate } from 'react-router-dom';
import { EmptyLineIconStroke, StarIconStroke } from '../../assets/icons';

const ComingSoon = () => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        return navigate(-1);
    };

    return (
        <div className={styles["coming-soon-container"]}>
            <div className={styles["content"]}>
                <EmptyLineIconStroke style={styles["coming-soon_button_empty_icon"]} />
                <p className={styles["coming-soon-description"]}>We're crafting an amazing experience. Stay tuned!</p>
                <div className={styles["coming-soon_button"]} onClick={handleGoBack} >
                    <StarIconStroke style={styles["coming-soon_button_icon"]} />
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;