import React, { FC, useState } from "react";
import styles from "./agentRow.module.css";
import { MoreIcon } from "../../../../../assets/icons";
import Modal from "../../../../Modal/Modal";

interface user {
  agent: any;
  index: number;
  avatar: string;
  email: string;
  status?: string;
  deleteAgent: Function;
  handleEdit: Function;
  // handleAdminRemove?: (email: string) => void;
}

const AgentRow: FC<user> = (props) => {
  const [moreAction, setMoreAction] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const userData = JSON.parse(localStorage.getItem("user") as string);

  const confirmDeleteAction = () => {
    setConfirmDelete(false);
    props.deleteAgent(deleteIndex);
  };

  return (
    <>
      <Modal isOpen={confirmDelete}>
        <div className={styles["delete_modal"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]}>Delete Agent</div>
          </div>
          <div className={styles["modal-content"]}>
            Are you sure you want to remove this agent?
          </div>
          <div className={styles["modal-controls"]}>
            <input
              type="button"
              value="No"
              onClick={() => {
                setDeleteIndex(null);
                setConfirmDelete(false);
              }}
            />
            <input
              type="button"
              value="Remove"
              onClick={() => confirmDeleteAction()}
            />
          </div>
        </div>
      </Modal>
      <section className={styles.wrapper}>
        <div className={styles["user-profile"]}>
          <div className={styles["abv"]}>{props.agent.agentName[0]}</div>
          <div>
            <h4>{props.agent.agentName}</h4>
            {/* <p>{props.email}</p> */}
          </div>
        </div>
        <div className={styles["controls"]}>
          <div className={`${styles.dropdown} ${styles["div-dropdown"]}`}>
            Agent
          </div>
        { (['owner', 'super-admin'].includes(userData.role))? <div
            onMouseEnter={() => setMoreAction(true)}
            onMouseLeave={() => setMoreAction(false)}
            className={styles["edit_con"]}
          >
            <MoreIcon style={styles["edit_icon"]} />
            {moreAction && (
              <div className={styles["more_option_con"]}>
                <h5
                  onClick={() => {
                    props.handleEdit(props.agent.id, props.agent.agentName);
                  }}
                >
                  Edit Agent
                </h5>
                <h5
                  onClick={() => {
                    setConfirmDelete(true);
                    setDeleteIndex(props.agent.id);
                  }}
                >
                  Delete Agent
                </h5>
              </div>
            )}
          </div>:null}
        </div>
      </section>
    </>
  );
};

export default AgentRow;
