// import { useEffect, useState } from "react";
import styles from "./departments.module.css";
import { checkuser, getCookie, showToast } from "../../../../utilities/helpers";
import General from "../general";
import { InviteIcon, PlusCircleIcon } from "../../../../assets/icons";
import { useEffect, useState } from "react";
import Modal from "../../../Modal/Modal";
import instance from "../../../../api";
import { AxiosError } from "axios";
import DepartmentRow from "./DepartmentRow/DepartmentRow";

export default function Departments() {
  const [ openModal, setOpenModal ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(true);
  const [editId, setEditId] = useState<number | undefined>()
  const [departments, setDepartments] = useState<Array<any>>()
  const [errors, setErrors] = useState<any>({});
  const [formState, setFormState] = useState({
    departmentName: "",
    departmentDescription: "",
  });

  const {departmentName, departmentDescription} = formState
  checkuser()

  useEffect(() => {
    getDepartments()
  }, [])

  const addDepartment = async () => {
    let token = getCookie("Authorization")
    if(!validate()){
      return
    }
    const business = JSON.parse(localStorage.getItem("business") as string);
    try {
      let data = {
        "department": departmentName,
        "description": departmentDescription
      }

      if(business){
        const response = await instance.post(`api/department/${business.id}`, { ...data },
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        });
        console.log(response.data);
        setDepartments((prev) => ([...prev! , response.data.data]))
        setFormState({
          departmentName: "",
          departmentDescription: "",
        });
        showToast("primary", "Success!", "Department added successfully")
        setOpenModal(false)
        getDepartments()
      }

    } catch (error) {
      if (error instanceof AxiosError) {
        
      }
      console.log(error);
    }
  }

  const deleteDepartment = async (index: number) => {
    let token = getCookie("Authorization")
    const business = JSON.parse(localStorage.getItem("business") as string);
    try {

      if(business){
        const response = await instance.delete(`api/department/${business.id}/${index}`, 
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        });
        // const business: { businessId: string }[] | [] = response.data?.business;
        console.log(response.data);
        // setDepartments(response.data.departments)
        // setDepartmentName('')
        getDepartments()
        showToast("secondary", "Success!", "Department deleted successfully")
        setOpenModal(false)
      }

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
      console.log(error);
    }
  }

  const getDepartments = () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      // const userData = JSON.parse(localStorage.getItem("user") as string);
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(userData.businessId)
      if(business)
      instance.get(
        `api/department/${business.id}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res)
          setDepartments(res.data.data)
          setIsLoading(false)
      }).catch(e => {
        console.log(e.response.data)
        setIsLoading(false)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  const editDepartment = async () => {
    let token = getCookie("Authorization")
    if(departmentName.length <= 0){
      return
    }
    const business = JSON.parse(localStorage.getItem("business") as string);
    try {
      let data = {
        "department": departmentName,
        "description": departmentDescription
      }

      if(business){
        const response = await instance.patch(`api/department/${business.id}/${editId}`, { ...data },
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        });
        console.log(response.data);
        showToast("primary", "Success!", "Department updated successfully")
        setFormState({
          departmentName: "",
          departmentDescription: "",
        });
        setEditId(undefined)
        setOpenModal(false)
        getDepartments()
      }

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
      console.log(error);
    }
  }

  function onChange(e: any) {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    let err: any = errors
    if(e.target.value.length <= 0){
      err[e.target.name] = true;
      
      setErrors(err)
    }else{
      err[e.target.name] = false;
      setErrors(err)
    }
  }

  const validate = () => {
    let err: any = {}
    if(departmentName.length <= 0){
      err["departmentName"] = true;
      setErrors(err)
    }
    if(departmentDescription.length <= 0){
      err["departmentDescription"] = true;
      setErrors(err)
    }

    if(departmentName.length <= 0 || departmentDescription.length <= 0){
      return false
    }

    return true;
  }

  const handleEdit = (id: number, departmentName: string) => {
    setEditId(id)
    setFormState({ ...formState, ["departmentName"]: departmentName });
    setOpenModal(true)
  }

  return (
    <General actionText="Create Department" setOpenModal={setOpenModal}>
      <div className={styles.departments_container}>
        {!isLoading && 
        <>
          {departments && departments.length > 0 ?
          <div className={styles.usersList}>
            {departments.map((department, index) => (
              <DepartmentRow
                key={index}
                index={department.id}
                department={department.department}
                description={department.description}
                role={department.department}
                avatar={department.department}
                email={department.department}
                deleteDepartment={deleteDepartment}
                handleEdit={handleEdit}
              />
            ))}
          </div>
          :
          <div className={styles.no_departments}>
            <p>You have no active deparment</p>
            <div onClick={() => setOpenModal(true)} className={styles.create_department}>
              <h4>Create One</h4>
              <PlusCircleIcon style={styles.plusicon} />
            </div>
          </div>
          }
        </>
        }
        <Modal isOpen={openModal} onClose={() => {setOpenModal(false); setEditId(undefined);}}>
          <div className={styles.department_form}>
            <h2>{editId !== undefined ? "Edit department" : "Create a new deparment"}</h2>
            <div className={styles.form_group}>
              <label htmlFor="departmentName">Department Name</label>
              <input
                  style={errors["departmentName"] ? {border: "1px solid #fc3401"}: {}}
                id="departmentName"
                name="departmentName"
                type="text"
                value={departmentName}
                onChange={onChange}
              />
            </div>
            <div className={styles.form_group}>
              <label htmlFor="departmentDescription">Department Description</label>
              <input
                  style={errors["departmentDescription"] ? {border: "1px solid #fc3401"}: {}}
                id="departmentDescription"
                name="departmentDescription"
                type="text"
                value={departmentDescription}
                onChange={onChange}
              />
            </div>
            <div onClick={editId !== undefined ? () => editDepartment() : () => addDepartment()} className={styles.send_department}>
              <h4>{editId !== undefined ? "Update" : "Create"} Department</h4>
              <InviteIcon style={styles.sendicon} />
            </div>
          </div>
        </Modal>
      </div>
    </General>
  );
}
