import React, {
  ChangeEvent,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./tickets.module.css";
import instance from "../../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ArrowRightIcon2,
  CalenderIcon2,
  CancelIcon,
  CategoryStrokeIcon,
  CircuitIcon,
  CollapseIcon,
  EditIconBox,
  EyeStrokeIcon,
  FilterIcon,
  LiveChatIcon,
  LovelyIcon,
  MailStrokeIcon,
  MarkStrokeIcon,
  PhoneIcon,
  RefreshIconStroke,
  SearchIcon,
  SendIcon,
  TypeIcon,
  UrgencyIcon,
  UrgencyIcon2,
  UserStrokeIcon,
  UserStrokeIcon2,
  UsersStrokeIcon,
  VerifiedUserStrokeIcon,
} from "../../assets/icons";
import { checkuser, getCookie, showToast } from "../../utilities/helpers";
import { AxiosError } from "axios";
import TicketRow from "../../components/Tickets/TicketRow";
import Message from "../../components/Message/Message";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { EnifContext } from "../../utilities/context";
import MailCard from "../../components/Gmail/MailCard/MailCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";

interface MessageType {
  id: string;
  role: "assistance" | "user";
  content: string;
  createdAt: Date;
  status: string;
}

interface TicketsProps {
  socket: WebSocket;
}

interface MailType {
  id: string;
  ticketId: string;
  mailId: string;
  emailId: string;
  from: string;
  to: string;
  customerName: string;
  threadId: string;
  subject: string;
  content: string;
  status: string;
  businessId: string;
  originalMailDate: string;
  read: boolean;
  updatedAt: string;
  assistantResponse: string;
  assistantResponseDate: Date;
  role: "assistance" | "user";
  createdAt: Date;
}

const Tickets: FC<TicketsProps> = ({ socket }) => {
  checkuser();
  const navigation = useNavigate();
  const ref = useRef(null);
  const chatBoxRef = useRef<HTMLUListElement>(null);
  const router = useLocation();
  const [businessId, setBusinessId] = useState("");
  const [action, setAction] = useState("");
  const [search, setSearch] = useState("");
  const [hasTicket, setHasTicket] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<any>();
  const [selectedTicketIds, setSelectedTicketIds] = useState<Array<string>>();
  const [ticket, setTickets] = useState<Array<any>>([]);
  const [resolveId, setResolveId] = useState<string | null>();
  const [confirmResolve, setConfirmResolve] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<string | null>();
  const [confirmClose, setConfirmClose] = useState<boolean>(false);
  const [resolveMessage, setResolveMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [limit, setLimit] = useState<number>(100)
  const limit = 20;
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalTicket, setTotalTicket] = useState<number>(1);
  const [escalate, setEscalate] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Array<any>>();
  const [selectedDepartment, setSelectedDepartment] = useState<any | null>();
  const [selectedFilterDepartment, setSelectedFilterDepartment] = useState<
    any | null
  >();
  const [selectedFilterDate, setSelectedFilterDate] = useState<any | null>();
  const [searching, setSearching] = useState<boolean>(false);
  const [filterByDepartment, setFilterByDepartment] = useState<boolean>(false);
  const [filterByDate, setFilterByDate] = useState<boolean>(false);
  const [selectedMessages, setSelectedMessages] =
    useState<Array<MessageType> | null>(null);
  // const [team, setTeam] = useState<any>()
  const [message, setMessage] = useState("");
  const [draftMessage, setDraftMessage] = useState<any>();
  const [editMessage, setEditMessage] = useState<any>();
  const [draftBoxHeight, setDraftBoxHeight] = useState("0px");
  const [messageBoxHeight, setMessageBoxHeight] = useState("100px");
  const [restructuring, setRestructuring] = useState<boolean>(false);
  const [sidebar, setSidebar] = useState(false);
  const [closeMessage, setCloseMessage] = useState<string>();
  const [escalateMessage, setEscalateMessage] = useState<string>();
  const [comments, setComments] = useState<Array<any> | null>(null);
  const [selectedMails, setSelectedMails] = useState<Array<MailType> | null>(
    null
  );
  const [selectedChannel, setSelectedChannel] = useState<string>("");
  const { team } = useContext(EnifContext);
  const [resolutionOrder, setResolutionOrder] = useState("none");

  const [selectMode, setSelectMode] = useState(false);
  const [fetchingEscalatedTickets, setFetchingEscalatedTickets] =
    useState(false);
  const [escalatedFilter, setEscalatedFilter] = useState("desc");

  const confirmResolveAction = () => {
    setConfirmResolve(false);
    if (resolveId) resolve(resolveId);
  };
  useEffect(() => {
    let path = router.pathname;
    let escalated = path.includes("escalated");
    console.log("escalated tickets", { path, escalated });
    if (escalated) {
      setFetchingEscalatedTickets(true);
    } else {
      // setEscalatedFilter("desc");
      setFetchingEscalatedTickets(false);
    }
    getTicket();
  }, [router.pathname, resolutionOrder, escalatedFilter]);

  //   useEffect(() => {
  //     // Function to call when the mouse is clicked (with type definition)
  //     const handleMouseClick= (event: MouseEvent) => {
  //       console.log("this is selectMode",selectMode)
  //       if(selectMode===true){
  //         setSelectMode(false)
  //       }else if(selectedFilterDate){
  //         setSelectedFilterDate(false)
  //       }
  // };

  //     // Add event listener when the component is mounted
  //     document.addEventListener('click', handleMouseClick);

  //     return () => {
  //       document.removeEventListener('click', handleMouseClick);
  //     };
  //   }, []);

  const updateMode = async (resolutionOrder: string) => {
    let newResolutionOrder = "";
    if (resolutionOrder === "asc") {
      newResolutionOrder = "asc";
    } else if (resolutionOrder === "desc") {
      newResolutionOrder = "desc";
    } else if (resolutionOrder === "none") {
      newResolutionOrder = "none";
    }
    setResolutionOrder(newResolutionOrder);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35.71,
    height: 20,
    padding: 0,
    borderRadius: 17,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 16,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(15px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#3BDD99",
        },
        "& > .MuiSwitch-thumb": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#FFFFFF" : "#FFFFFF",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 14,
      height: 14,
      borderRadius: "50%",
      backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF" : "#FFFFFF",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      width: 35.71,
      height: 20,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor: "#D1D1E0",
      boxSizing: "border-box",
    },
  }));

  useEffect(() => {
    setIsLoading(true);
    console.log(router);
    console.log(team, businessId, router);
    console.log("team and id", team, businessId);
    if (team && businessId) {
      getTicket();
      // getChatss()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, router.search, businessId, team]);

  useEffect(() => {
    console.log(departments);
    if (departments === undefined) getDepartments();
  }, [departments]);

  useEffect(() => {
    console.log(team);

    const business = JSON.parse(localStorage.getItem("business") as string);
    setBusinessId(business.businessId);
    // getTeam()
  }, []);

  useEffect(() => {
    const handelClick = (evt: any) => {
      console.log(sidebar);
      let targetEl = evt.target; // clicked element

      let sidebarCon = document.getElementById("sidebar");
      let livechatCon = document.getElementsByClassName("livechat");
      let ignoreCon = document.getElementsByClassName("ignore");
      let muiSwitchthumb = document.getElementsByClassName("MuiSwitch-thumb");
      // let css1ikttudMuiSwitchroot = document.getElementsByClassName('css-1ikttud-MuiSwitch-root');
      // let muiSwitchswitchBase = document.getElementsByClassName('MuiSwitch-switchBase');
      let muiSwitchinput = document.getElementsByClassName("MuiSwitch-input");
      // console.log(livechatCon)
      // console.log(sidebarCon)
      if (targetEl === sidebarCon) {
        console.log("sdfs");
        return;
      } else {
        for (let i = 0; i < ignoreCon.length; i++) {
          if (targetEl === ignoreCon[i]) {
            console.log(ignoreCon[i]);
            // setSidebar((prev) => (true))
            return;
          }
        }

        for (let i = 0; i < livechatCon.length; i++) {
          if (targetEl === livechatCon[i]) {
            console.log(livechatCon[i]);
            setSidebar((prev) => true);
            return;
          }
        }
        for (let i = 0; i < muiSwitchthumb.length; i++) {
          if (targetEl === muiSwitchthumb[i]) {
            console.log(muiSwitchthumb[i]);
            setSidebar((prev) => true);
            return;
          }
        }
        for (let i = 0; i < muiSwitchinput.length; i++) {
          if (targetEl === muiSwitchinput[i]) {
            console.log(muiSwitchinput[i]);
            setSidebar((prev) => true);
            return;
          }
        }

        if (sidebar && targetEl !== sidebarCon) {
          console.log("afwf");
          setSidebar((prev) => false);
          setSelectedTicket((prev: any) => null);
          setSelectedMessages((prev: any) => null);
        }
      }
    };

    document
      .getElementById("ticket_con")!
      .addEventListener("click", handelClick, false);

    return () => {
      document
        ?.getElementById("ticket_con")
        ?.removeEventListener("click", handelClick);
    };
  }, [sidebar]);
  // console.log(selectedTicket)

  useEffect(() => {
    const handelClick = (evt: any) => {
      console.log(escalate);
      let targetEl = evt.target; // clicked element
      let ticketsBox = document.getElementById("tickets");

      let escalateBtn = document.getElementById("escalate_btn");
      let ignoreCon = document.getElementsByClassName("ignore");
      let searchCon = document.getElementById("search_con");
      let search = document.getElementById("search");
      let departmentCon = document.getElementsByClassName("department_con_c");
      console.log(departmentCon);
      let departmenth4 = document.getElementsByClassName("departmenth4");
      console.log(search);
      console.log(escalateBtn);
      if (targetEl === escalateBtn) {
        return;
      } else if (targetEl === ticketsBox) {
        setEscalate((prev) => true);
        return;
      } else if (targetEl === search) {
        // setEscalate((prev) => (true))
        return;
      } else {
        console.log("sfe");
        localStorage.removeItem("selectedChat");
        for (let i = 0; i < departmentCon.length; i++) {
          if (targetEl === departmentCon[i]) {
            console.log(departmentCon[i]);
            setEscalate((prev) => true);
            return;
          }
        }

        for (let i = 0; i < ignoreCon.length; i++) {
          if (targetEl === ignoreCon[i]) {
            console.log(ignoreCon[i]);
            // setSidebar((prev) => (true))
            return;
          }
        }

        for (let i = 0; i < departmenth4.length; i++) {
          if (targetEl === departmenth4[i]) {
            console.log(departmenth4[i]);
            setEscalate((prev) => true);
            return;
          }
        }

        if (!escalate && targetEl !== search && targetEl !== searchCon) {
          console.log("afwf");
          setEscalate((prev) => false);
        }
      }
    };

    document
      .getElementById("ticket_con")!
      .addEventListener("click", handelClick, false);

    return () => {
      document
        ?.getElementById("ticket_con")
        ?.removeEventListener("click", handelClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectTicket = (ticket: any) => {
    console.log(ticket);
    if (selectedTicket && selectedTicket.aiMode === "hybrid") {
      updateChatAiMode("auto", selectedTicket.id);
    }
    // setOpenModal(true)
    setSelectedTicket(ticket);
    if (ticket) {
      localStorage.setItem(
        "selectedChat",
        JSON.stringify({ email: ticket?.email, phoneNo: ticket?.phoneNo })
      );
      getComments(ticket.id);
    } else {
      localStorage.removeItem("selectedChat");
    }
  };

  const getComments = async (ticketId: string) => {
    console.log(businessId);
    let token = getCookie("Authorization");
    instance
      .get(`/api/ticket/comment-logs/${businessId}/${ticketId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        localStorage.setItem("comments", JSON.stringify(response.data?.data));
        setComments(response.data?.data);
        // setIsLoading(false);
        // setSelectedMessages(response.data?.data);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          // const errMessage = error.response?.data?.message;
        }
        setIsLoading(false);
      });
  };

  const selectTickets = (ticket: any) => {
    if (selectedTicketIds) {
      const index = selectedTicketIds.indexOf(ticket.id);
      console.log(index);
      if (index >= 0) {
        let ids = selectedTicketIds;
        ids.splice(index, 1);
        console.log(ids);
        setSelectedTicketIds((prev: any) => [...ids]);
      } else {
        setSelectedTicketIds((prev: any) => [...prev, ticket.id]);
      }
    } else {
      setSelectedTicketIds((prev: any) => [ticket.id]);
    }
  };

  const getDepartments = () => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(business.businessId)
      if (business)
        instance
          .get(`api/department/${business.id}`, {
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res);
            setDepartments(res.data.data);
          })
          .catch((e) => {
            console.log(e.response.data);
          });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const resolve = (ticketId: string) => {
    let token = getCookie("Authorization");
    instance
      .put(
        `/api/admin/resolve-ticket/business/${businessId}/ticket/${ticketId}`,
        {
          resolveMessage: resolveMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        nextPageTicket(currentPage);
        // nextPage2(currentPage)
        setSelectedTicket(res.data.data);
        setResolveMessage("");
        showToast("primary", "Success!", "Ticket resolved successfully");
        setOpenModal(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const message = err.response.data?.message;
            showToast("secondary", "Error!", message);
          }
        }
      });
  };

  const getTicket = (escalatedFilters?: string) => {
    setSelectedFilterDepartment(null);
    setSelectedFilterDate(null);
    let token = getCookie("Authorization");
    let path = router.pathname;
    let action = path.includes("all")
      ? "all"
      : path.includes("inactive")
      ? "in active"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("resolved")
      ? "resolved"
      : "closed";
    console.log(action);
    setAction(action);
    let department: string | null;
    if (router.search.length > 0) {
      let searchArray = router.search.split("=");
      if (searchArray[0].includes("department")) {
        department = searchArray[1].replaceAll("%20", " ");
        setSearch(department);
      } else {
        department = null;
      }
    } else {
      department = null;
      setSearch("");
    }
    setCurrentPage(1);
    if (localStorage.getItem("user")) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      let url = `/api/ticket/all-tickets/${
        userData.businessId
      }?page=${1}&active=true&limit=${limit}&closed=false`;
      if (action === "all") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}`;
      } else if (action === "in active") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}&closed=false`;
      } else if (action === "escalated") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}`;
      } else if (action === "closed") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&closed=true&limit=${limit}`;
      }

      if (action === "escalated") {
        url = url + `&escalated=true&escalatedFilter=${escalatedFilter}`;
      } else if (action === "resolved") {
        url = url + "&escalated=false";
      }

      console.log(team);

      if (team) {
        if (team.role === "member") {
          url = url + "&department=" + team?.departmentId?.department;
        } else {
          if (department) {
            url = url + "&department=" + department;
          }
        }
      } else {
        url = url + "&screen=ticket";
      }
      if (resolutionOrder !== "none") {
        url = url + `&resolutionOrder=${resolutionOrder}`;
      }
      instance
        .get(url, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data.length >= 0) {
            // if(department){
            //   const tickets = res.data.data.filter((chat:any) => chat.department === department);
            //   setTickets(tickets)
            // }else{
            setTickets(res.data.data);
            // }
            setHasTicket(true);
            console.log(hasTicket);
          }
          setTotalTicket(res.data.total);
          setTotalPage(res.data.pages);
          setCurrentPage(res.data.currentPage);
          setIsLoading(false);
          if (res.data?.resolutionOrder)
            setResolutionOrder(res.data.resolutionOrder);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e.response.data);
          if (
            e.response.data === "This business dosen't have a knowledge base"
          ) {
            setHasTicket(false);
          }
          setHasTicket(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  // const getChatss = (num:number = 1) => {
  //   let token = getCookie("Authorization")
  //   let ticketId: string = ""
  //   let department : string | null;
  //   if(router.search.length > 0){
  //     let searchArray = router.search.split("=")
  //     if(searchArray[0].includes("department")){
  //       department = searchArray[1].replaceAll("%20", " ")
  //       setSearch(department)
  //     }else{
  //       department = null
  //     }
  //   }else{
  //     department = null
  //     setSearch("")
  //   }

  //   if(router?.search?.length > 0){
  //     if(router.search.includes("&")){
  //       let searchArray = router.search.split("&")
  //       // if(searchArray[0].includes("ticketId")){
  //       //   let ticketId = searchArray[1]
  //       //   const selected = chats.filter((chat) => chat.id === ticketId);
  //       //   setSelectedConversation((prev) => selected[0]);
  //       //   getMessages(ticketId)
  //       // }
  //       if(searchArray[1].split("=")[0].includes("page")){
  //         setCurrentPage(parseInt(searchArray[1].split("=")[1]))
  //       }
  //     }
  //   }
  //   console.log(ticketId, "sdvds")
  //   setIsLoading(true)
  //   // setSelectedConversation(null)
  //   let path = router.pathname
  //   let action = path.includes("all")
  //   ? "all" : path.includes("escalated")
  //   ? "escalated": path.includes("unread")
  //   ? "unread": path.includes("resolved")
  //   ? "resolved": path.includes("closed")
  //   ? "closed": path.includes("read")
  //   ? "read": ""
  //   setAction(action);
  //   const userData = JSON.parse(localStorage.getItem("user") as string);

  //   let url = "";
  //   if(action === "all" || action === ""){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "in active"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "escalated"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&escalated=true&closed=false`
  //   }else if(action === "unread"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&read=false&closed=false`
  //   }else if(action === "read"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&read=true&closed=false`
  //   }else if(action === "resolved"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&escalated=false&closed=false`
  //   }else if(action === "closed"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&escalated=false&closed=true`
  //   }

  //   if(ticketId){
  //     url = url + `&ticketId=${ticketId}`
  //   }

  //   if(team){
  //     if(team.role === "member"){
  //       url = url+"&department="+team?.departmentId?.department
  //     }else{
  //       if(department){
  //         url = url+"&department="+department
  //       }else if(selectedFilterDepartment){
  //         url = url+"&department="+selectedFilterDepartment.department
  //       }
  //     }
  //   }else{
  //     url = url+"&screen=ticket"
  //   }

  //   setCurrentPage(1)

  //   // setFilter(action)

  //   instance
  //     .get(url,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //     .then((response) => {
  //       console.log(response)
  //       setTickets(response.data.data);
  //       setTotalPage(response.data.pages)
  //       setCurrentPage(response.data.currentPage)
  //       // console.log(ticketId)
  //       // if(ticketId){
  //       //   const selected = response.data.data.filter((chat:any) => chat.id === ticketId);
  //       //   setSelectedConversation((prev) => selected[0]);
  //       //   getMessages(ticketId)
  //       // }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       // if (error instanceof AxiosError) {
  //       //   const errMessage = error.response?.data?.message;
  //       //   // setError(errMessage);
  //       // }
  //       setIsLoading(false);
  //     });
  // }

  const nextPageTicket = (num: number) => {
    // setSelectedFilterDepartment(null)
    // setSelectedFilterDate(null)
    let token = getCookie("Authorization");
    setIsLoading(true);
    let path = router.pathname;
    let action = path.includes("all")
      ? "all"
      : path.includes("inactive")
      ? "in active"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("resolved")
      ? "resolved"
      : "closed";
    console.log(action);
    setAction(action);
    let department: string | null;
    if (router.search.length > 0) {
      let searchArray = router.search.split("=");
      if (searchArray[0].includes("department")) {
        department = searchArray[1].replaceAll("%20", " ");
        setSearch(department);
      } else {
        department = null;
      }
    } else {
      department = null;
      setSearch("");
    }
    if (localStorage.getItem("user")) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      let url = `/api/ticket/all-tickets/${userData.businessId}?page=${num}&active=true&limit=${limit}&closed=false`;
      if (action === "all") {
        url = `/api/ticket/all-tickets/${userData.businessId}?page=${num}&active=true&limit=${limit}`;
      } else if (action === "in active") {
        url = `/api/ticket/all-tickets/${userData.businessId}?page=${num}&active=true&limit=${limit}&closed=false`;
      } else if (action === "escalated") {
        url = `/api/ticket/all-tickets/${userData.businessId}?page=${num}&active=true&limit=${limit}`;
      } else if (action === "closed") {
        url = `/api/ticket/all-tickets/${userData.businessId}?page=${num}&active=true&limit=${limit}&closed=true`;
      }

      if (action === "escalated") {
        url = url + `&escalated=true&escalatedFilter=${escalatedFilter}`;
      } else if (action === "resolved") {
        url = url + "&escalated=false";
      }

      if (team) {
        if (team.role === "member") {
          url = url + "&department=" + team?.departmentId?.department;
        } else {
          if (department) {
            url = url + "&department=" + department;
          } else if (selectedFilterDepartment) {
            url = url + "&department=" + selectedFilterDepartment.department;
          }
        }
      } else {
        url = url + "&screen=ticket";
      }

      let maxD = new Date().toISOString().split("T")[0];
      if (selectedFilterDate) {
        url = url + "&minDate=" + selectedFilterDate;
        url = url + "&maxDate=" + maxD;
      }

      instance
        .get(url, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data.length >= 0) {
            // if(department){
            //   const tickets = res.data.data.filter((chat:any) => chat.department === department);
            //   setTickets(tickets)
            // }else{
            setTickets(res.data.data);
            // }
            setHasTicket(true);
          }
          setTotalTicket(res.data.total);
          setTotalPage(res.data.pages);
          setCurrentPage(res.data.currentPage);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e.response.data);
          if (
            e.response.data === "This business dosen't have a knowledge base"
          ) {
            setHasTicket(false);
          }
          setHasTicket(false);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  // const nextPage2 = (num:number) => {
  //   let token = getCookie("Authorization")
  //   setIsLoading(true)
  //   // setSelectedConversation(null)
  //   let department : string | null;
  //   if(router.search.length > 0){
  //     let searchArray = router.search.split("=")
  //     if(searchArray[0].includes("department")){
  //       department = searchArray[1].replaceAll("%20", " ")
  //       setSearch(department)
  //     }else{
  //       department = null
  //     }
  //   }else{
  //     department = null
  //     setSearch("")
  //   }

  //   let path = router.pathname
  //   let action = path.includes("all")
  //   ? "all" : path.includes("escalated")
  //   ? "escalated": path.includes("unread")
  //   ? "unread": path.includes("resolved")
  //   ? "resolved": path.includes("read")
  //   ? "read": ""
  //   const userData = JSON.parse(localStorage.getItem("user") as string);

  //   let url = "";
  //   if(action === "all" || action === ""){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "in active"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "escalated"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&escalated=true&closed=false`
  //   }else if(action === "unread"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&read=false&closed=false`
  //   }else if(action === "read"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&read=true&closed=false`
  //   }else if(action === "resolved"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&escalated=false&closed=false`
  //   }else if(action === "closed"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${num}&active=true&limit=${limit}&escalated=false&closed=true`
  //   }

  //   // if(action !== filter){
  //   //   console.log()
  //   //   setCurrentPage(1)
  //   // }

  //   if(team){
  //     if(team.role === "member"){
  //       url = url+"&department="+team?.departmentId?.department
  //     }else{
  //       if(department){
  //         url = url+"&department="+department
  //       }else if(selectedFilterDepartment){
  //         url = url+"&department="+selectedFilterDepartment.department
  //       }
  //     }
  //   }else{
  //     url = url+"&screen=ticket"
  //   }

  //   // setFilter(action)

  //   instance
  //     .get(url,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //     .then((response) => {
  //       console.log(response)
  //       setTickets(response.data.data);
  //       setTotalPage(response.data.pages)
  //       setCurrentPage(response.data.currentPage)
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       // if (error instanceof AxiosError) {
  //       //   const errMessage = error.response?.data?.message;
  //       //   setError(errMessage);
  //       // }
  //       setIsLoading(false);
  //     });
  // }

  const filterTicket = (filterDept?: any, filterDate?: any) => {
    setIsLoading(true);
    let token = getCookie("Authorization");
    let path = router.pathname;
    let action = path.includes("all")
      ? "all"
      : path.includes("inactive")
      ? "in active"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("resolved")
      ? "resolved"
      : "closed";
    console.log(action);
    setAction(action);
    let department: string | null;
    if (router.search.length > 0) {
      let searchArray = router.search.split("=");
      if (searchArray[0].includes("department")) {
        department = searchArray[1].replaceAll("%20", " ");
        setSearch(department);
      } else {
        department = null;
      }
    } else {
      department = null;
      setSearch("");
    }
    if (localStorage.getItem("user")) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      let url = `/api/ticket/all-tickets/${
        userData.businessId
      }?page=${1}&active=true&limit=${limit}&closed=false`;
      if (action === "all") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}`;
      } else if (action === "in active") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}&closed=false`;
      } else if (action === "escalated") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}&closed=false`;
      } else if (action === "closed") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}&closed=true`;
      }

      if (action === "escalated") {
        url = url + `&escalated=true&escalatedFilter=${escalatedFilter}`;
      } else if (action === "resolved") {
        url = url + "&escalated=false";
      }

      if (team) {
        if (team.role !== "owner") {
          url = url + "&screen=ticket";
        } else {
          if (department) {
            url = url + "&department=" + department;
          } else if (filterDept) {
            url = url + "&department=" + filterDept.department;
          }
        }
      } else {
        url = url + "&screen=ticket";
      }

      let maxD = new Date().toISOString().split("T")[0];
      if (filterDate) {
        url = url + "&minDate=" + filterDate;
        url = url + "&maxDate=" + maxD;
      }

      instance
        .get(url, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data.length >= 0) {
            // if(department){
            //   const tickets = res.data.data.filter((chat:any) => chat.department === department);
            //   setTickets(tickets)
            // }else{
            setTickets(res.data.data);
            // }
            setHasTicket(true);
          }
          setTotalTicket(res.data.total);
          setTotalPage(res.data.pages);
          setCurrentPage(res.data.currentPage);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e.response.data);
          if (
            e.response.data === "This business dosen't have a knowledge base"
          ) {
            setHasTicket(false);
          }
          setHasTicket(false);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  const reAssignTicket = () => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/ticket/reassign-escalated/${businessId}`,
        {
          departmentId: selectedDepartment?.id,
          ticketId: selectedTicket?.id,
          comment: escalateMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setSelectedTicket(res.data.data);
        nextPageTicket(currentPage);
        // nextPage2(currentPage)
        showToast("primary", "Success!", "Ticket reassigned successfully");
        setOpenModal(false);
        setEscalate(false);
        setEscalateMessage("");
        setSelectedDepartment(null);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const data = err.response.data;
            showToast("secondary", "Error!", data?.data[0].message);
          } else {
            showToast("secondary", "Error!", "Error reassigning");
          }
        } else {
          showToast("secondary", "Error!", "Error reassigning");
        }
      });
    setEscalateMessage("");
  };

  const searchDepartment = (e: any) => {
    let token = getCookie("Authorization");
    if (e.target.value !== "") {
      if (localStorage.getItem("user") && token) {
        const business = JSON.parse(localStorage.getItem("business") as string);
        // setBusinessId(business.businessId)
        if (business)
          instance
            .get(`api/department/${business.id}?name=${e.target.value}`, {
              headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              console.log(res);
              setDepartments(res.data.data);
            })
            .catch((e) => {
              console.log(e.response.data);
            });
      } else {
        localStorage.removeItem("user");
        window.location.assign("/login");
      }
    } else {
      getDepartments();
    }
  };

  // const getTeam = async () => {
  //   let token = getCookie("Authorization")
  //   if(localStorage.getItem("user") && token){
  //     const userData = JSON.parse(localStorage.getItem("user") as string);
  //     setBusinessId(userData.businessId)
  //     instance.get(
  //       `/api/team/${userData.businessId}/${userData.id}`,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       }).then(res => {
  //         console.log(res.data)
  //         setTeam(res.data.data)
  //     }).catch(e => {
  //       console.log(e.response.data)
  //     })
  //   }else{
  //     localStorage.removeItem("user")
  //     window.location.assign('/login')
  //   }
  // }

  const closeTicket = (ticketIds = ticketId) => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/ticket/close-escalated-ticket/${businessId}`,
        {
          ticketId: ticketIds,
          comment: closeMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        nextPageTicket(currentPage);
        // nextPage2(currentPage)
        setSelectedTicket(res.data.data);
        setSidebar(false);
        setCloseMessage("");
        showToast("primary", "Success!", "Ticket closed successfully");
        setOpenModal(false);
        setSelectedTicketIds(undefined);
        setConfirmClose(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const data = err.response.data;
            showToast("secondary", "Error!", data?.data[0].message);
          }
        }
      });
  };

  const closeManyTicket = (ticketIds: any) => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/ticket/close-escalated-tickets/${businessId}`,
        {
          tickets: ticketIds,
          comment: closeMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        nextPageTicket(currentPage);
        // nextPage2(currentPage)
        setSelectedTicket(res.data.data);
        setSidebar(false);
        setCloseMessage("");
        showToast("primary", "Success!", "Ticket closed successfully");
        setOpenModal(false);
        setSelectedTicketIds(undefined);
        setConfirmClose(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const data = err.response.data;
            showToast("secondary", "Error!", data?.data[0].message);
          }
        }
      });
  };

  const selectAll = (ask: boolean) => {
    let ids = [];
    if (ask)
      for (let i = 0; i < ticket.length; i++) {
        let ti: any = ticket[i];
        ids.push(ti.id);
      }

    setSelectedTicketIds(ids);
  };

  const searchTicket = (e: ChangeEvent<HTMLInputElement>) => {
    let token = getCookie("Authorization");
    // if(e.target.value.length <3 ){
    //   return
    // }
    // setSelectedFilterDepartment(null)
    // setSelectedFilterDate(null)
    setIsLoading(true);
    let path = router.pathname;
    let action = path.includes("all")
      ? "all"
      : path.includes("inactive")
      ? "in active"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("resolved")
      ? "resolved"
      : "closed";
    console.log(action);
    setAction(action);
    let department: string | null;
    if (router.search.length > 0) {
      let searchArray = router.search.split("=");
      if (searchArray[0].includes("department")) {
        department = searchArray[1].replaceAll("%20", " ");
        setSearch(department);
      } else {
        department = null;
      }
    } else {
      department = null;
      setSearch("");
    }
    if (localStorage.getItem("user")) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      let url = `/api/ticket/all-tickets/${
        userData.businessId
      }?page=${1}&active=true&limit=${limit}&closed=false`;
      if (action === "all") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}`;
      } else if (action === "in active") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}&closed=false`;
      } else if (action === "escalated") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}&closed=false`;
      } else if (action === "closed") {
        url = `/api/ticket/all-tickets/${
          userData.businessId
        }?page=${1}&active=true&limit=${limit}&closed=true`;
      }

      if (action === "escalated") {
        url = url + `&escalated=true&escalatedFilter=${escalatedFilter}`;
      } else if (action === "resolved") {
        url = url + "&escalated=false";
      }

      if (team) {
        if (team.role === "member") {
          url = url + "&department=" + team?.departmentId?.department;
        } else {
          if (department) {
            url = url + "&department=" + department;
          } else if (selectedFilterDepartment) {
            url = url + "&department=" + selectedFilterDepartment.department;
          }
        }
      } else {
        url = url + "&screen=ticket";
      }

      let maxD = new Date().toISOString().split("T")[0];
      if (selectedFilterDate) {
        url = url + "&minDate=" + selectedFilterDate;
        url = url + "&maxDate=" + maxD;
      }

      if (e.target.value) {
        url = `${url}&search=${e.target.value}`;
      }

      instance
        .get(url, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data.length >= 0) {
            // if(department){
            //   const tickets = res.data.data.filter((chat:any) => chat.department === department);
            //   setTickets(tickets)
            // }else{
            setTickets(res.data.data);
            // }
            setHasTicket(true);
          }
          setTotalTicket(res.data.total);
          setTotalPage(res.data.pages);
          setCurrentPage(res.data.currentPage);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e.response.data);
          if (
            e.response.data === "This business dosen't have a knowledge base"
          ) {
            setHasTicket(false);
          }
          setHasTicket(false);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  // const searchChats = (e: ChangeEvent<HTMLInputElement>) => {
  //   let token = getCookie("Authorization")
  //   let ticketId: string = ""
  //   let department : string | null;
  //   if(router.search.length > 0){
  //     let searchArray = router.search.split("=")
  //     if(searchArray[0].includes("department")){
  //       department = searchArray[1].replaceAll("%20", " ")
  //       setSearch(department)
  //     }else{
  //       department = null
  //     }
  //   }else{
  //     department = null
  //     setSearch("")
  //   }

  //   if(router?.search?.length > 0){
  //     if(router.search.includes("&")){
  //       let searchArray = router.search.split("&")
  //       // if(searchArray[0].includes("ticketId")){
  //       //   let ticketId = searchArray[1]
  //       //   const selected = chats.filter((chat) => chat.id === ticketId);
  //       //   setSelectedConversation((prev) => selected[0]);
  //       //   getMessages(ticketId)
  //       // }
  //       if(searchArray[1].split("=")[0].includes("page")){
  //         setCurrentPage(parseInt(searchArray[1].split("=")[1]))
  //       }
  //     }
  //   }
  //   console.log(ticketId, "sdvds")
  //   setIsLoading(true)
  //   // setSelectedConversation(null)
  //   let path = router.pathname
  //   let action = path.includes("all")
  //   ? "all" : path.includes("escalated")
  //   ? "escalated": path.includes("unread")
  //   ? "unread": path.includes("resolved")
  //   ? "resolved": path.includes("closed")
  //   ? "closed": path.includes("read")
  //   ? "read": ""
  //   setAction(action);
  //   const userData = JSON.parse(localStorage.getItem("user") as string);

  //   let url = "";
  //   if(action === "all" || action === ""){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${1}&active=true&limit=${limit}`
  //   }else if(action === "in active"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${1}&active=true&limit=${limit}`
  //   }else if(action === "escalated"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${1}&active=true&limit=${limit}&escalated=true&closed=false`
  //   }else if(action === "unread"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${1}&active=true&limit=${limit}&read=false&closed=false`
  //   }else if(action === "read"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${1}&active=true&limit=${limit}&read=true&closed=false`
  //   }else if(action === "resolved"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${1}&active=true&limit=${limit}&escalated=false&closed=false`
  //   }else if(action === "closed"){
  //     url = `/api/ticket/all-tickets-combined/${userData.businessId}?page=${1}&active=true&limit=${limit}&escalated=false&closed=true`
  //   }

  //   if(ticketId){
  //     url = url + `&ticketId=${ticketId}`
  //   }

  //     if(team){
  //       if(team.role === "member"){
  //         url = url+"&department="+team?.departmentId?.department
  //       }else{
  //         if(department){
  //           url = url+"&department="+department
  //         }else if(selectedFilterDepartment){
  //           url = url+"&department="+selectedFilterDepartment.department
  //         }
  //       }
  //     }else{
  //       url = url+"&screen=ticket"
  //     }

  //   if(e.target.value){
  //     url=`${url}&search=${e.target.value}`
  //   }

  //   // setFilter(action)

  //   instance
  //     .get(url,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //     .then((response) => {
  //       console.log(response)
  //       setTickets(response.data.data);
  //       setTotalPage(response.data.pages)
  //       setCurrentPage(response.data.currentPage)
  //       // console.log(ticketId)
  //       // if(ticketId){
  //       //   const selected = response.data.data.filter((chat:any) => chat.id === ticketId);
  //       //   setSelectedConversation((prev) => selected[0]);
  //       //   getMessages(ticketId)
  //       // }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       // if (error instanceof AxiosError) {
  //       //   const errMessage = error.response?.data?.message;
  //       //   setError(errMessage);
  //       // }
  //       setIsLoading(false);
  //     });
  // }

  const getMessages = async (ticketId: string) => {
    // setSelectedMessages(null)
    let token = getCookie("Authorization");
    instance
      .get(`/api/chat/messages/${ticketId}?read=true`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setSelectedMessages(response.data?.data);
        scrollToBottom();
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          console.log(errMessage);
        }
        setIsLoading(false);
      });

    instance
      .get(`/api/chat/draft/${ticketId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setDraftMessage(response.data?.data);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          console.log(errMessage);
        }
        setIsLoading(false);
      });
    console.log(draftMessage);
  };

  const getMails = async (ticketId: string) => {
    let token = getCookie("Authorization");
    instance
      .get(`/api/email-forwarder/${ticketId}?page=1&read=true`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setSelectedMails(response.data);
        scrollToBottom();
      })
      .catch((error) => {
        // if (error instanceof AxiosError) {
        //   const errMessage = error.response?.data?.message;
        //   setError(errMessage);
        // }
        setIsLoading(false);
      });
    console.log(draftMessage);
  };

  // const getMessagess = async (ticketId: string, email: string) => {
  //   let token = getCookie("Authorization")
  //   instance
  //   .get(`/api/chat/all/user/${email}/${businessId}?ticketId=${ticketId}`,
  //   {
  //     headers: {
  //       'Access-Control-Allow-Headers': 'X-Requested-With',
  //       'Authorization': `Bearer ${token}`
  //     }
  //   })
  //   .then((response) => {
  //     console.log(response.data);
  //     setIsLoading(false);
  //     setSelectedMessages(response.data?.data);
  //     scrollToBottom()
  //   })
  //   .catch((error) => {
  //     // if (error instanceof AxiosError) {
  //     //   const errMessage = error.response?.data?.message;
  //     //   setError(errMessage);
  //     // }
  //     setIsLoading(false);
  //   });

  //   console.log(draftMessage)
  // }

  const scrollToBottom = () => {
    setTimeout(() => {
      const element: any = document.querySelector(".chat-list");
      if (element) {
        element.behavior = "smooth";
        element.scrollTop = element.scrollHeight;
        console.log(element.scrollHeight);
      }
    }, 10);
  };

  const messages = selectedMessages?.map((message: any) => (
    <Message
      key={message.id}
      id={message.id}
      role={message.role}
      created_date={message.createdAt}
      content={message.content}
      status={message.status}
      name={selectedTicket?.customer}
    />
  ));

  const mails = selectedMails?.map((thread) => (
    <MailCard selectedEmail={selectedTicket} thread={thread} />
  ));

  function editDraftMessage(message: MessageType) {
    setDraftMessage(null);
    setMessage(message.content);
    setEditMessage(message);
  }

  function removeDraft(message: MessageType) {
    // setMessage(message.content)
    setDraftMessage(null);
    setEditMessage(message);
  }

  async function restructure(message: string) {
    if (message.length <= 0) {
      return;
    }
    setRestructuring(true);
    let token = getCookie("Authorization");
    try {
      let data: any = {
        message: message,
      };

      let url = "api/chat/restructure";
      if (selectedChannel === "email") {
        url = "api/email-forwarder/restructure";
        data = {
          mail: message,
        };
      }
      const response = await instance.post(
        url,
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showToast("primary", "Success!", "Corrected");
      // setSelectMode(false)
      console.log(response.data.data);
      setMessage(response.data.data);
      setRestructuring(false);
      const business: { businessId: string }[] | [] = response.data?.business;
      console.log(response.data, business);
    } catch (error) {
      if (error instanceof AxiosError) {
      }
      console.log(error);
      setRestructuring(false);
    }
  }

  const businessTyping = (event: any) => {
    const typingEvent = {
      event: "businessTyping",
      data: {
        businessId: businessId,
        ticketId: selectedTicket?.id,
        typing: event.target.value.length > 0 ? true : false,
      },
    };
    socket.send(JSON.stringify(typingEvent));
  };

  const handleMessage = (e: any) => {
    setMessage(e.target.value);
    businessTyping(e);
    let textareaMsg = document.getElementById("message");
    if (textareaMsg) {
      console.log(textareaMsg?.scrollHeight);
      let textareaRows = e.target.value.split("\n");
      console.log(textareaRows);

      if (
        textareaMsg?.scrollHeight >= 100 &&
        textareaMsg?.scrollHeight <= 280
      ) {
        textareaMsg.style.height = "auto";
        textareaMsg.style.height =
          (textareaMsg?.scrollHeight).toString() + "px";
        setMessageBoxHeight((textareaMsg?.scrollHeight).toString() + "px");
        // textareaMsg.setAttribute("rows", (textareaRows.length))
      } else {
        textareaMsg.style.height = "auto";
        textareaMsg.style.height = "280px";
        setMessageBoxHeight("280px");
      }
      scrollToBottom();
    }
  };

  async function sendMessage() {
    console.log(editMessage, selectedTicket);
    let token = getCookie("Authorization");
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let data = {
        ticketId: selectedTicket?.id,
        businessId: userData.businessId,
        reply: message,
        messageId: editMessage?.id,
      };
      const response = await instance.post(
        "api/chat/reply",
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      // let conv: any = selectedConversation?.messages.map((msg: any) => {
      //   if(msg.id === editMessage.id){
      //     return {
      //       id: editMessage.id,
      //       role: editMessage.role,
      //       content: message,
      //       created_date: editMessage.createdAt,
      //       status: "sent"
      //     }
      //   }
      //   return msg
      // })
      getMessages(selectedTicket ? selectedTicket?.id : "");
      // getMessagess(selectedTicket ? selectedTicket?.id : '', selectedTicket?.email ?? "")
      // setSelectedConversation((prevState: any) => {
      //   if (prevState) {
      //     return {
      //       ...prevState,
      //       messages: [...conv],
      //     };
      //   }
      // });
      setMessage("");
      setEditMessage(null);
      resetTextarea();
    } catch (error) {
      if (error instanceof AxiosError) {
      }
      console.log(error);
    }
  }

  async function sendMail() {
    // businessTyping(false)
    if (message.length < 1 && !selectedMails) {
      return;
    }
    // console.log(editMessage, selectedConversation)
    let token = getCookie("Authorization");
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let data = {
        ticketId: selectedTicket?.id,
        businessId: userData.businessId,
        reply: message,
        // "id": selectedMails ? selectedMails[selectedMails.length-1]?.id : ""
      };
      const response = await instance.post(
        "api/email-forwarder/reply",
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      // let conv: any = selectedConversation?.messages.map((msg: any) => {
      //   if(msg.id === editMessage.id){
      //     return {
      //       id: editMessage.id,
      //       role: editMessage.role,
      //       content: message,
      //       created_date: editMessage.createdAt,
      //       status: "sent"
      //     }
      //   }
      //   return msg
      // })
      // getMessages(selectedConversation ? selectedConversation?.id : '')
      // getMessagess(selectedConversation ? selectedConversation?.id : '', selectedConversation?.email ?? "")
      // setSelectedConversation((prevState: any) => {
      //   if (prevState) {
      //     return {
      //       ...prevState,
      //       messages: [...conv],
      //     };
      //   }
      // });
      setMessage("");
      // setEditMessage(null)
      resetTextarea();
      if (selectedTicket) getMails(selectedTicket.id);
    } catch (error) {
      if (error instanceof AxiosError) {
      }
      console.log(error);
    }
  }

  const resetTextarea = () => {
    let textareaMsg = document.getElementById("message");
    if (textareaMsg) {
      console.log(textareaMsg?.scrollHeight);
      textareaMsg.style.height = "100px";
      setMessageBoxHeight("100px");
      scrollToBottom();
    }
    scrollToBottom();
  };

  useLayoutEffect(() => {
    const element = document.getElementById("draft_message");
    if (element) {
      let height = element.getBoundingClientRect().height;
      console.log(height);
      setDraftBoxHeight(`${height}px`);
    } else {
      setDraftBoxHeight(`0px`);
    }
  }, [draftMessage]);

  const updateChatAiMode = (mode: any, id?: string) => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      console.log(selectedTicket);
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance
        .post(
          `api/chat/mode/business/${userData.businessId}/ticket/${
            id ? id : selectedTicket?.id
          }`,
          {
            aiMode: mode,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log(res.data, ticket)
          let index = ticket.findIndex(
            (tick: any) => tick.id === selectedTicket?.id
          );
          // console.log(index)
          let newTickets: Array<any> = ticket;
          let selectTicket: any = ticket[index];
          // console.log(selectTicket)
          selectTicket.aiMode = mode;

          newTickets[index] = selectTicket;
          // console.log(selectTicket)
          setTickets((prev: any) => [...newTickets]);

          showToast("primary", "Success!", "Mode changed successfully");
          if (!id) setSelectedTicket(selectTicket);
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const handleOpenConv = () => {
    setEscalate(true);
    const selected = ticket.filter((chat) => chat.id === selectedTicket?.id);
    if (selected.length >= 1) {
      // setSelectedConversation((prev) => selected[0]);
      // getMessagess(ticketId, selected[0].email)
      localStorage.setItem(
        "conversation",
        JSON.stringify({
          email: selected[0]?.email,
          phoneNo: selected[0]?.phoneNo,
          customer: selected[0]?.customer,
          message: selected[0]?.message,
          _id: selected[0]?.id,
          escalated: selected[0]?.escalated,
          department: selected[0]?.department,
          sentiment: selected[0]?.sentiment,
          createdAt: selected[0]?.createdAt,
        })
      );
    }
    if (selectedChannel === "email") {
      navigation(`/thread/${selectedTicket?.id}`);
    } else {
      navigation(`/live-chat/all?ticketId=${selectedTicket?.id}`);
    }
    // navigation(`thread/65f06a364fb2ffda9a9afc2b/conversations/${action}?ticketId=${selectedTicket?.id}&page=${currentPage}`)
  };

  const handleTicketClicked = (
    ticket: any,
    channel: string,
    slaExceeded: boolean
  ) => {
    console.log(slaExceeded);
    if (selectedTicket?.id === ticket.id) {
      setSelectedChannel("");
      selectTicket(null);
    } else {
      setSelectedChannel(channel);
      selectTicket(ticket);
      if (channel === "chat") {
        getMessages(ticket.id);
      }

      if (channel === "email") {
        getMails(ticket.id);
      }
    }
  };
  const closeFilters = () => {
    if (selectMode) setSelectMode(false);
    if (filterByDate) setFilterByDate(false);
    if (filterByDepartment) setFilterByDepartment(false);
    if (searching) setSearching(false);
  };
  return (
    <section
      id="ticket_con"
      className={styles.wrapper}
      onClick={() => closeFilters()}
    >
      {/* {selectedTicket && currentPage && */}
      <Modal
        isOpen={openModal}
        onClose={() => {
          setOpenModal(false);
          setSelectedDepartment(null);
          setEscalate(false);
        }}
      >
        <div className={styles["ticket_details_modal"]}>
          <div className={styles["ticket_details_modal_row_1"]}>
            <div className={styles["ticket_details_modal_info"]}>
              <h1>{selectedTicket?.customer}</h1>
            </div>
            <div className={styles["ticket_details_mores"]}>
              <div className={`${styles["ticket_details_more"]}`}>
                <MailStrokeIcon
                  style={styles["ticket_details_more_icon_stroke"]}
                />
                <h4>{selectedTicket?.email}</h4>
              </div>
              <div className={`${styles["ticket_details_more"]}`}>
                <PhoneIcon style={styles["ticket_details_more_icon"]} />
                <h4>{selectedTicket?.phoneNo}</h4>
              </div>
            </div>
            <div
              style={
                escalate || selectedDepartment
                  ? {
                      gridTemplateColumns:
                        "max-content max-content max-content max-content",
                    }
                  : {
                      gridTemplateColumns:
                        "max-content max-content max-content",
                    }
              }
              className={styles["ticket_details_modal_buttons"]}
            >
              <Link
                to={`/live-chat/${action}?ticketId=${selectedTicket?.id}&page=${currentPage}`}
              >
                <div
                  className={`${styles["ticket_details_modal_button"]} ${styles["view_conversation_button"]}`}
                >
                  <h4>View</h4>
                  <ArrowRightIcon style={styles["arrow_right_icon"]} />
                </div>
              </Link>
              <div
                id="escalate_btn"
                onClick={
                  selectedDepartment
                    ? () => reAssignTicket()
                    : () => setEscalate(true)
                }
                style={
                  selectedDepartment
                    ? { gridTemplateColumns: "max-content max-content" }
                    : {}
                }
                className={`${styles["ticket_details_modal_button"]} ${styles["escalate_button"]}`}
              >
                <h4>{selectedDepartment ? "Assign" : "Escalate"}</h4>
                {selectedDepartment && (
                  <VerifiedUserStrokeIcon
                    style={styles["mark_resolved_icon"]}
                  />
                )}
              </div>
              {selectedDepartment && (
                <div className={styles["selected_department"]}>
                  <h4>{selectedDepartment.department}</h4>
                  <div
                    onClick={() => setSelectedDepartment(null)}
                    className={styles["remove_department"]}
                  >
                    <CancelIcon style={styles["cancel_icon"]} />
                  </div>
                </div>
              )}
              {escalate && (
                <>
                  <div style={{ width: "154px" }}>
                    <div className={styles["escalate_department_con"]}>
                      <div className={styles["search_input"]}>
                        <div id="search_con" className={styles["search"]}>
                          <SearchIcon style={styles["search_icon"]} />
                          <input
                            onChange={searchDepartment}
                            id="search"
                            placeholder="Search for department"
                          />
                        </div>
                      </div>
                      <div
                        id="department_con"
                        className={`${styles["department_con"]}`}
                      >
                        {departments?.map((department) => {
                          return (
                            <div
                              onClick={() => {
                                setSelectedDepartment(department);
                                setEscalate(false);
                              }}
                              className={`${styles["department"]} department_con_c`}
                            >
                              <h4 className="departmenth4">
                                {department.department}
                              </h4>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>
              )}

              {!selectedTicket?.closed && (
                <div
                  onClick={() => {
                    setConfirmClose(true);
                    setTicketId(selectedTicket.id);
                  }}
                  className={`${styles["ticket_details_modal_button"]} ${styles["mark_resolved_button"]}`}
                >
                  <h4>Close Ticket</h4>
                  {/* <CheckedCircleStrokeIcon style={styles["mark_resolved_icon"]} /> */}
                </div>
              )}
            </div>
          </div>
          <div className={styles["ticket_details_modal_row_2"]}>
            <h2>Account Details</h2>
            <div className={styles["ticket_details_modal_more_info"]}>
              <div className={styles["ticket_details_modal_category"]}>
                <div>
                  <CategoryStrokeIcon style={styles["category_icon_stroke"]} />
                  <h3>Categorisation</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedTicket?.category}</h4>
                </div>
              </div>
              <div className={styles["ticket_details_modal_category"]}>
                <div>
                  <LovelyIcon style={styles["category_icon"]} />
                  <h3>Sentiment</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedTicket?.sentiment}</h4>
                </div>
              </div>
              <div className={styles["ticket_details_modal_category"]}>
                <div>
                  <UsersStrokeIcon style={styles["category_icon_stroke"]} />
                  <h3>Department</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedTicket?.department}</h4>
                </div>
              </div>
              <div className={styles["ticket_details_modal_category"]}>
                <div>
                  <UrgencyIcon2 style={styles["category_icon"]} />
                  <h3>Escalation</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedTicket?.escalated ? "Yes" : "No"}</h4>
                </div>
              </div>
              <div className={styles["ticket_details_modal_category"]}>
                <div>
                  <UserStrokeIcon2 style={styles["category_icon_stroke"]} />
                  <h3>Agent Name</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedTicket?.agentName}</h4>
                </div>
              </div>
              <div className={styles["ticket_details_modal_category"]}>
                <div>
                  <TypeIcon style={styles["category_icon"]} />
                  <h3>Type</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedTicket?.type}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {/* } */}
      <Modal isOpen={confirmResolve}>
        <div className={styles["confirmation_modal"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]}>Resolve Ticket</div>
          </div>
          <div className={styles["modal-content"]}>
            Are you sure you want to resolve this ticket?
          </div>

          <div className={styles["modal-controls"]}>
            <input
              type="button"
              value="No"
              onClick={() => {
                setResolveId(null);
                setConfirmResolve(false);
              }}
            />
            <input
              type="button"
              value="Resolve"
              onClick={() => confirmResolveAction()}
            />
          </div>
        </div>
      </Modal>
      {((selectedTicketIds && selectedTicketIds?.length === 1) ||
        !selectedTicketIds) && (
        <Modal isOpen={confirmClose}>
          <div className={`${styles["confirmation_modal"]} ignore`}>
            <div className={`${styles["modal-header"]} ignore`}>
              <div className={`${styles["header"]} ignore`}>Close Ticket</div>
            </div>
            <div className={`${styles["modal-content"]} ignore`}>
              Are you sure you want to close this ticket?
            </div>
            <div className={`${styles.form_group} ignore`}>
              <label className={`ignore`} htmlFor="closeMessage">
                Comment
              </label>
              <textarea
                id="closeMessage"
                value={closeMessage}
                className={`ignore`}
                onChange={(e) => setCloseMessage(e.target.value)}
              />
            </div>
            {/* <div className={styles.form_group}>
          <label htmlFor="resolveMessage">Message</label>
          <textarea
            id="resolveMessage"
            value={resolveMessage}
            onChange={(e) => setResolveMessage(e.target.value)}
          />
        </div> */}
            <div
              className={`${styles["modal-controls"]} ${
                selectedTicket && "ignore"
              }`}
            >
              <input
                className={`${selectedTicket && "ignore"}`}
                type="button"
                value="No"
                onClick={() => {
                  setTicketId(null);
                  setConfirmClose(false);
                }}
              />
              <input
                className={`${selectedTicket && "ignore"}`}
                type="button"
                value="Close"
                onClick={() => closeTicket()}
              />
            </div>
          </div>
        </Modal>
      )}
      {selectedTicketIds && selectedTicketIds?.length > 1 && (
        <Modal isOpen={confirmClose}>
          <div className={`${styles["confirmation_modal"]}`}>
            <div className={`${styles["modal-header"]}`}>
              <div className={`${styles["header"]}`}>Close Ticket</div>
            </div>
            <div className={`${styles["modal-content"]}`}>
              Are you sure you want to close this ticket?
            </div>
            {/* <div className={styles.form_group}>
          <label htmlFor="resolveMessage">Message</label>
          <textarea
            id="resolveMessage"
            value={resolveMessage}
            onChange={(e) => setResolveMessage(e.target.value)}
          />
        </div> */}
            <div className={`${styles["modal-controls"]}`}>
              <div
                style={{
                  flex: 1,
                  fontFamily: "Axiforma",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#313233",
                }}
              >
                {`${selectedTicketIds?.length} tickets selected`}
              </div>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setTicketId(null);
                  setConfirmClose(false);
                }}
              />
              <input
                type="button"
                value="Close"
                onClick={() => closeManyTicket(selectedTicketIds)}
              />
            </div>
          </div>
        </Modal>
      )}

      <Modal isOpen={escalate}>
        <div className={`${styles["confirmation_modal"]} ignore`}>
          <div className={`${styles["modal-header"]} ignore`}>
            <div
              className={`${styles["header"]} ignore`}
              style={{ marginBottom: ".5rem" }}
            >
              Confirm Ticket Reassignment
            </div>
          </div>
          <div
            className={`${styles["modal-content"]} ignore`}
            style={{ margin: "0vh 0 1rem 0" }}
          >
            Are you sure you want to reassign this ticket to another department?
          </div>
          {selectedDepartment ? (
            <div className={`${styles["selected_department"]} ignore`}>
              <h4 className={`ignore`}>{selectedDepartment.department}</h4>
              <div
                onClick={() => setSelectedDepartment(null)}
                className={`${styles["remove_department"]} ignore`}
              >
                <CancelIcon style={`${styles["cancel_icon"]} ignore`} />
              </div>
            </div>
          ) : (
            <div
              className={`ignore`}
              style={{ width: "144px", height: "58px" }}
            >
              <div
                style={{ height: searching ? "156px" : "max-content" }}
                className={`${styles["escalate_department_con"]} ignore`}
              >
                <div className={`${styles["search_input"]} ignore`}>
                  <div id="search_con" className={`${styles["search"]} ignore`}>
                    <SearchIcon style={`${styles["search_icon"]} ignore`} />
                    <input
                      onFocus={() => setSearching(true)}
                      className={`ignore`}
                      onChange={(e) => {
                        searchDepartment(e);
                        setSearching(true);
                      }}
                      id="search"
                      placeholder="Search for department"
                    />
                  </div>
                </div>
                {searching && (
                  <div className={`${styles["department_con"]} ignore`}>
                    {departments?.map((department) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedDepartment(department);
                          }}
                          className={`${styles["department"]} ignore`}
                        >
                          <h4 className={`ignore`}>{department.department}</h4>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
          {/* <div className={styles.form_group}>
          <label htmlFor="resolveMessage">Message</label>
          <textarea
            id="resolveMessage"
            value={resolveMessage}
            onChange={(e) => setResolveMessage(e.target.value)}
          />
        </div> */}
          <div className={`${styles.form_group} ignore`}>
            <label className={`ignore`} htmlFor="closeMessage">
              Comment
            </label>
            <textarea
              id="closeMessage"
              value={escalateMessage}
              className={`ignore`}
              onChange={(e) => setEscalateMessage(e.target.value)}
            />
          </div>
          <div className={`${styles["modal-controls"]} ignore`}>
            <input
              className={`ignore`}
              type="button"
              value="Cancel"
              onClick={() => {
                setEscalate(false);
                setSelectedDepartment(null);
              }}
            />
            <input
              type="button"
              value="Confirm"
              onClick={() => reAssignTicket()}
              className={`ignore`}
            />
          </div>
        </div>
      </Modal>
      <header
        style={{
          gridTemplateColumns:
            selectedTicketIds && selectedTicketIds.length > 1
              ? "max-content max-content 1fr"
              : "max-content 1fr",
        }}
      >
        <h1>{search ? search : action}</h1>

        <div className={styles.header_col2}>
          <div className={styles.filters}>
            {(selectedFilterDepartment || selectedFilterDate) && !isLoading && (
              <h4>{`${totalTicket}  Ticket(s)`}</h4>
            )}
            {/* <div className={styles["resolution-time-filter"]}>
              <p>Resolution Time:</p>

              <div className={styles.form_group2}>
                <div
                  onClick={() => {
                    setSelectMode(true);
                  }}
                >
                
                  <p
                   
                  >
                    {resolutionOrder == "none"
                      ? "Sort"
                      : resolutionOrder == "asc"
                      ? "Ascending"
                      : resolutionOrder == "desc"
                      ? "Descending"
                      : "Sort"}
                  </p>
                  <span
                    onClick={() => {               
                    }}
                  >{resolutionOrder == "none"?
                    <FilterIcon style={styles.header_btn_icon} />
                    :
                    <div className={styles["remove_department"]}onClick={()=>{setResolutionOrder("none") ;setSelectMode(false)}}>
                      <CancelIcon style={styles["filter_cancel_icon"]} />
                    </div>
                    

                  }
                  </span>
                  {selectMode && (
                    <div
                      id="more_option_con"
                      className={styles["more_option_con"]}
                    >
                      <div
                      >
                        <div
                        className={`${
                          resolutionOrder === "none" ? `${styles["selectedMode"]} ${styles["more_option"]}`:styles["more_option"]}`}
                        onClick={() => {
                            updateMode("none");
                          }}
                        >         
                          <h5>None</h5>                        
                        </div>
                        <div
                        
                        className={`${
                          resolutionOrder === "asc" ? `${styles["selectedMode"]} ${styles["more_option"]}`:styles["more_option"]}`}                          onClick={() => {
                            updateMode("asc");
                          }}
                        >
                        
                          <h5>Ascending</h5>
                     
                          
                        </div>
                        <div
                          className={`${
                            resolutionOrder === "desc" ? `${styles["selectedMode"]} ${styles["more_option"]}`:styles["more_option"]}`}
                      >
                        <div
                          className={styles["more_option"]}
                          onClick={() => {
                            updateMode("desc");
                          
                          }}
                        >
                          <h5>Descending</h5>
                         
                        </div>
                      </div>
                      </div>
                    
                    </div>
                  )}
                </div>
              </div>
            </div> */}

            <div style={{ position: "relative" }}>
              {selectedFilterDate ? (
                <div className={styles["filter_selected_department"]}>
                  <h4>{selectedFilterDate}</h4>
                  <div
                    onClick={() => {
                      setSelectedFilterDate(null);
                      filterTicket(selectedFilterDepartment, null);
                    }}
                    className={styles["remove_department"]}
                  >
                    <CancelIcon style={styles["filter_cancel_icon"]} />
                  </div>
                </div>
              ) : (
                <div className={styles["resolution-time-filter"]}>
                  <p>Date:</p>
                  <div
                    onClick={(e) => {
                      setFilterByDate(!filterByDate);
                    }}
                    className={styles.header_button}
                  >
                    <p>Sort</p>
                    <FilterIcon style={styles.header_btn_icon} />
                  </div>
                </div>
              )}
              {filterByDate && (
                <div
                  style={{
                    width: "113px",
                    height: "85px",
                    position: "absolute",
                    top: "42px",
                    left: "-4px",
                  }}
                >
                  <input
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      setSelectedFilterDate(e.target.value);
                      setFilterByDate(false);
                      filterTicket(selectedFilterDepartment, e.target.value);
                    }}
                    className={styles.filterDate}
                    type="date"
                  />
                </div>
              )}
            </div>
            <div style={{ position: "relative" }}>
              {selectedFilterDepartment ? (
                <div className={styles["filter_selected_department"]}>
                  <h4>{selectedFilterDepartment.department}</h4>
                  <div
                    onClick={() => {
                      setSelectedFilterDepartment(null);
                      filterTicket(null, selectedFilterDate);
                    }}
                    className={styles["remove_department"]}
                  >
                    <CancelIcon style={styles["filter_cancel_icon"]} />
                  </div>
                </div>
              ) : (
                <div className={styles["resolution-time-filter"]}>
                  <p>Department:</p>
                  <div
                    onClick={(e) => {
                      setFilterByDepartment(!filterByDepartment);
                    }}
                    className={styles.header_button}
                  >
                    <p>Sort</p>
                    <FilterIcon style={styles.header_btn_icon_stroke} />
                  </div>
                </div>
              )}
              {filterByDepartment && (
                <div
                  style={{
                    width: "144px",
                    height: "85px",
                    position: "absolute",
                    top: "42px",
                    left: "-13px",
                  }}
                >
                  <div className={styles["filter_escalate_department_con"]}>
                    <div className={styles["filter_search_input"]}>
                      <div id="search_con" className={styles["filter_search2"]}>
                        <SearchIcon style={styles["filter_search_icon"]} />
                        <input
                          onChange={(e) => {
                            searchDepartment(e);
                            setSearching(true);
                          }}
                          onClick={(e) => e.stopPropagation()}
                          id="search"
                          placeholder="Search for department"
                        />
                      </div>
                    </div>
                    {searching && (
                      <div className={styles["filter_department_con"]}>
                        {departments?.map((department) => {
                          return (
                            <div
                              onClick={() => {
                                setSelectedFilterDepartment(department);
                                setFilterByDepartment(false);
                                filterTicket(department, selectedFilterDate);
                              }}
                              className={styles["filter_department"]}
                            >
                              <h4>{department.department}</h4>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* promise */}
            {fetchingEscalatedTickets && (
              <div style={{ position: "relative" }}>
                <div className={styles["resolution-time-filter"]}>
                  <p>Resolution Time:</p>

                  <button
                    className={styles["escalatedFilter"]}
                    onClick={() =>
                      setEscalatedFilter((prev) => {
                        return prev === "desc" ? "asc" : "desc";
                      })
                    }
                  >
                    {escalatedFilter.toLowerCase() === "desc" ? "Desc" : "Asc"}{" "}
                    {escalatedFilter === "asc" ? (
                      <FontAwesomeIcon icon={faArrowUp} />
                    ) : (
                      <FontAwesomeIcon icon={faArrowDown} />
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
          {((selectedTicketIds && selectedTicketIds.length <= 1) ||
            !selectedTicketIds) && (
            <div className={styles["search2"]}>
              <SearchIcon style={styles["search_icon"]} />
              <input
                onChange={searchTicket}
                placeholder="Search for customer name,"
              />
            </div>
          )}
        </div>
        {selectedTicketIds && selectedTicketIds.length > 1 && (
          <div className={styles.header_col2}>
            <button
              onClick={() => setConfirmClose(true)}
              className={styles.resolve_btn}
            >
              Close Tickets
            </button>
          </div>
        )}
        {/* {fetchingEscalatedTickets && (
          <button
            className={styles["escalatedFilter"]}
            onClick={() =>
              setEscalatedFilter((prev) => {
                return prev === "desc" ? "asc" : "desc";
              })
            }
          >
            {escalatedFilter.toUpperCase()}{" "}
            {escalatedFilter === "asc" ? (
              <FontAwesomeIcon icon={faArrowUp} />
            ) : (
              <FontAwesomeIcon icon={faArrowDown} />
            )}
          </button>
        )} */}
      </header>
      <section id="tickets" className={styles.content}>
        <section className={styles["ticket_table_container"]}>
          <table className={styles["ticket_table"]}>
            <thead>
              <tr
                style={
                  action === "closed"
                    ? { gridTemplateColumns: "4% 17% 14% 12% 12% 12% 12% 12%" }
                    : { gridTemplateColumns: "4% 17% 14% 12% 12% 12% 12% 12%" }
                }
              >
                <th
                  style={{ textAlign: "center" }}
                  // onClick={() => {
                  //   selectedTicketIds?.length === ticket.length
                  //     ? selectAll(false)
                  //     : selectAll(true);
                  // }}
                >
                  {/* <input
                    onChange={() => {}}
                    checked={selectedTicketIds?.length === ticket.length}
                    type="checkbox"
                  /> */}
                </th>
                <th>Customer Name</th>
                <th>Conversation</th>
                <th>Department</th>
                <th>Status</th>
                <th>Sentiment</th>
                {/* <th>Issue categoristion</th> */}
                {/* <th>Type</th> */}
                {/* <th>Counter</th> */}
                <th>
                  {/* Resolution Time{" "} */}
                  {!fetchingEscalatedTickets ? (
                    "Resolution Time"
                  ) : (
                    <button
                      className={styles["escalatedFilter_btn"]}
                      onClick={() =>
                        setEscalatedFilter((prev) => {
                          return prev === "desc" ? "asc" : "desc";
                        })
                      }
                    >
                      {/* {escalatedFilter.toUpperCase()}{" "} */}
                      Resolution Time{" "}
                      {escalatedFilter === "asc" ? (
                        <FontAwesomeIcon icon={faArrowUp} />
                      ) : (
                        <FontAwesomeIcon icon={faArrowDown} />
                      )}
                    </button>
                  )}{" "}
                </th>
                {action !== "closed" && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr style={{ gridTemplateColumns: "1fr" }}>
                  <td>
                    <h5 style={{ textAlign: "center" }}>Loading...</h5>
                  </td>
                </tr>
              ) : (
                <>
                  {ticket.map((ticket: any, index) => {
                    return (
                      // <div>
                      <TicketRow
                        key={ticket.id}
                        ticket={ticket}
                        setEscalate={setEscalate}
                        selectedTicket={selectedTicket}
                        action={action}
                        selectTicket={selectTicket}
                        setConfirmClose={setConfirmClose}
                        setTicketId={setTicketId}
                        selectedTicketIds={selectedTicketIds}
                        selectTickets={selectTickets}
                        // getMessages={getMessages}
                        handleTicketClicked={handleTicketClicked}
                      />
                      // </div>
                    );
                  })}
                </>
              )}
            </tbody>
            <div className={styles.pagination}>
              <div
                onClick={() => {
                  setCurrentPage(currentPage <= 1 ? 1 : currentPage - 1);
                  nextPageTicket(currentPage <= 1 ? 1 : currentPage - 1);
                }}
                className={styles.pagination_btn_left}
              >
                <ArrowLeftIcon style={styles.pagination_icon} />
              </div>
              <div>
                <h5
                  className={styles.pagination_num}
                >{`${currentPage} of ${totalPage}`}</h5>
              </div>
              <div
                onClick={() => {
                  setCurrentPage(
                    currentPage >= totalPage ? totalPage : currentPage + 1
                  );
                  nextPageTicket(
                    currentPage >= totalPage ? totalPage : currentPage + 1
                  );
                }}
                className={styles.pagination_btn_right}
              >
                <ArrowRightIcon2 style={styles.pagination_icon} />
              </div>
            </div>
          </table>
        </section>
      </section>
      {/* {selectedTicket && sidebar && */}
      <div
        id="sidebar"
        className={`${styles.livechat_sidebar} ${
          selectedTicket && sidebar
            ? styles.livechat_slideIn
            : styles.livechat_slideOut
        } livechat`}
      >
        <div className={`${styles.livechat_sidebar_header} livechat`}>
          <div className={`livechat`}>
            <LiveChatIcon
              style={`${styles["livechat_sidebar_header_icon"]} livechat`}
            />
            Conversation
          </div>
          <div>
            <div
              style={{ gridTemplateColumns: "max-content" }}
              onClick={() => {
                handleOpenConv();
              }}
              className={`${styles["sla_status"]} livechat`}
            >
              <h4
                className={
                  selectedTicket?.slaExceeded
                    ? styles["angry"]
                    : styles["happy"]
                }
              >
                {selectedTicket?.slaExceeded ? "Exceeded SLA" : "Within SLA"}
              </h4>
            </div>
            <div
              style={{ gridTemplateColumns: "max-content" }}
              onClick={() => {
                handleOpenConv();
              }}
              className={`${styles["ticket_details_modal_button2"]} ${styles["view_conversation_button2"]} livechat`}
            >
              <h4 className={`livechat`}>Open Conversation</h4>
            </div>
            <div
              style={{ gridTemplateColumns: "max-content" }}
              onClick={() => {
                setEscalate(true);
              }}
              className={`${styles["ticket_details_modal_button2"]} ${styles["escalate_button"]} livechat`}
            >
              <h4 className={`livechat`}>Escalate</h4>
            </div>
            {!selectedTicket?.closed && (
              <div
                style={{ gridTemplateColumns: "max-content" }}
                onClick={() => {
                  setConfirmClose(true);
                  setTicketId(selectedTicket?.id);
                }}
                className={`${styles["ticket_details_modal_button2"]} ${styles["mark_resolved_button"]} livechat`}
              >
                <h4 className={`livechat`}>Close</h4>
              </div>
            )}
          </div>
        </div>
        <div className={styles["conversation-body-column"]}>
          {ticket.length > 0 && (
            <section id="conversations" className={`livechat`}>
              <section className={`${styles.left} livechat`}>
                <div
                  style={
                    selectedTicket && selectedTicket?.aiMode === "hybrid"
                      ? {
                          height: `calc(100vh - 20px - 1vh - 1vh - 95px - ${messageBoxHeight} - ${draftBoxHeight})`,
                        }
                      : {
                          height: `calc(100vh - 20px - 1vh - 1vh - 100px - 95px - ${draftBoxHeight})`,
                        }
                  }
                  className={`${
                    selectedTicket &&
                    ((selectedChannel === "chat" &&
                      selectedMessages &&
                      selectedMessages.length) ||
                      (selectedChannel === "email" &&
                        selectedMails &&
                        selectedMails.length))
                      ? styles["flex-end"]
                      : styles["flex-center"]
                  } ${styles["conversation-message"]} livechat`}
                >
                  {!selectedTicket && ticket && ticket.length > 0 && (
                    <p className={`${styles["select-message"]} livechat`}>
                      Select a message to display
                    </p>
                  )}
                  {selectedTicket &&
                    ((selectedChannel === "chat" &&
                      selectedMessages &&
                      selectedMessages.length > 0) ||
                      (selectedChannel === "email" &&
                        selectedMails &&
                        selectedMails.length > 0)) && (
                      <ul
                        ref={chatBoxRef}
                        style={
                          selectedChannel === "email"
                            ? {
                                display: "grid",
                                gap: "3rem",
                                alignContent: "start",
                              }
                            : {}
                        }
                        className={`${styles["chat-list"]} chat-list livechat`}
                      >
                        {selectedChannel === "chat" && messages}
                        {selectedChannel === "email" && mails}
                      </ul>
                    )}

                  {selectedTicket &&
                    selectedMessages &&
                    selectedMessages.length <= 0 && (
                      <p className={`${styles["select-message"]} livechat`}>
                        No messages here yet
                      </p>
                    )}
                </div>
                {draftMessage && selectedTicket && (
                  <div
                    id="draft_message"
                    ref={ref}
                    className={styles["draft_message"]}
                  >
                    <div
                      onClick={() => {
                        editDraftMessage(draftMessage);
                      }}
                      className={`${styles["draft_message_header"]} livechat`}
                    >
                      <h5 className={`livechat`}>Enif Suggestion</h5>
                    </div>
                    <p
                      onClick={() => {
                        editDraftMessage(draftMessage);
                      }}
                      className={`${styles["draft_message_content"]} livechat`}
                    >
                      {draftMessage.content}
                    </p>
                    <div
                      onClick={() => {
                        removeDraft(draftMessage);
                      }}
                      className={`${styles["close_draft_message"]} livechat`}
                    >
                      <CancelIcon
                        style={`${styles["close_draft_message_icon"]} livechat`}
                      />
                    </div>
                    <div
                      onClick={() => {
                        editDraftMessage(draftMessage);
                      }}
                    >
                      <EditIconBox style={`${styles["draft_icon"]} livechat`} />
                    </div>
                  </div>
                )}
                <div
                  className={`${styles["livechat_sidebar_bottom"]} livechat`}
                >
                  {selectedTicket && selectedTicket?.aiMode === "hybrid" && (
                    <div className={`${styles["text-area"]} livechat`}>
                      <div
                        className={`${styles["text-area-wrapper"]} livechat`}
                      >
                        <textarea
                          id="message"
                          name="message"
                          placeholder="Type a message..."
                          cols={80}
                          rows={1}
                          value={message}
                          onChange={(e) => handleMessage(e)}
                          className={`livechat`}
                        />
                        <div
                          className={`${styles["restructure"]} livechat`}
                          onClick={() => restructure(message)}
                        >
                          <RefreshIconStroke
                            style={`${styles["refresh_icon"]} ${
                              restructuring && styles["restructuring"]
                            } livechat`}
                          />
                        </div>
                        <button
                          onClick={() => {
                            selectedChannel === "chat"
                              ? sendMessage()
                              : sendMail();
                          }}
                          className={`${styles["send-message"]} livechat`}
                        >
                          <SendIcon style={`${styles["icons"]} livechat`} />
                        </button>
                      </div>
                    </div>
                  )}
                  <div
                    onClick={() => {
                      updateChatAiMode(
                        selectedTicket?.aiMode === "hybrid" ? "auto" : "hybrid"
                      );
                    }}
                    className={`${
                      selectedTicket?.aiMode === "hybrid"
                        ? styles["switch_mode_con2"]
                        : styles["switch_mode_con"]
                    } livechat`}
                  >
                    <h4 className={`livechat`}>Hybrid Switch</h4>
                    <AntSwitch
                      className={`livechat`}
                      checked={selectedTicket?.aiMode === "hybrid"}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                  </div>
                </div>
              </section>
            </section>
          )}
        </div>
      </div>
      {/* } */}
    </section>
  );
};

export default Tickets;
