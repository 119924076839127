import React, { FC, useState, } from "react";
import styles from "../../pages/Inventory/Inventory.module.css";
import { CategoryIcon, MoreIcon, ProductStatusIcon, QuantityIcon, ShopIcon2 } from "../../assets/icons";
import { NumericFormat } from "react-number-format";
import Modal from "../Modal/Modal";

interface PropType {
  product: any,
  setEditProduct: Function;
  handleDeleteProduct: Function;
  setAddPoduct: Function;
  setIsNew: Function;
}

const InventoryProductRow: FC<PropType> = ({ product, setEditProduct, setAddPoduct, setIsNew, handleDeleteProduct }) => {
    const [moreAction, setMoreAction] = useState(false)
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    const confirmDeleteAction = () => {
        setConfirmDelete(false)
        setIsNew(false)
        handleDeleteProduct()
    }

    return (
        <>
            <Modal isOpen={confirmDelete}>
                <div className={styles["delete_modal"]}>
                <div className={styles["modal-header"]}>
                    <div className={styles["header"]}>Delete Product</div>
                </div>
                <div className={styles["modal-content"]}>Are you sure you want to remove this product from the inventory ?</div>
                <div className={styles["modal-controls"]}>
                    <input type="button" value="No" onClick={() => {setConfirmDelete(false)}} />
                    <input
                    type="button"
                    value="Remove"
                    onClick={() => confirmDeleteAction()}
                    />
                </div>
                </div>
            </Modal>
            <tr>
                <td>
                    <div className={styles["product_name"]}>
                    <h5>{product.name}</h5>
                    <img alt={product.name} src={product.image} />
                    </div>
                </td>
                <td> 
                    <div>
                    <QuantityIcon style={styles["table_icon_fill"]} /> 
                    <p>{product.quantity}</p>
                    </div>
                </td>
                <td> 
                    <div>
                    <CategoryIcon style={styles["table_icon_fill"]} /> 
                    <p>{product.category}</p>
                    </div>
                </td>
                <td> 
                    <div>
                    <ShopIcon2 style={styles["table_icon_fill2"]} /> 
                    <p>
                        <NumericFormat
                            thousandsGroupStyle="thousand"
                            value={
                                product.price
                            }
                            prefix={product.currency}
                            decimalSeparator="."
                            displayType="text"
                            type="text"
                            thousandSeparator={true}
                            allowNegative={false}
                        />
                    </p>
                    </div>
                </td>
                <td> 
                    <div className={styles["edit_product"]}>
                    <ProductStatusIcon style={styles["table_icon_stroke"]} /> 
                    <p>{product.status}</p>
                    <div onClick={() => setMoreAction(!moreAction)} className={styles["edit_product_con"]}>
                        <MoreIcon style={styles["edit_product_icon"]} />
                        {moreAction &&
                        <div className={styles["more_option_con"]}>
                            <h5 onClick={() => {setIsNew(false); setAddPoduct(true); setEditProduct();}}>Edit Product</h5>
                            <h5 onClick={() => { setConfirmDelete(true)}}>Delete Product</h5>
                        </div>
                        }
                    </div>
                    </div>
                </td>
            </tr>
        </>
    );
};
export default InventoryProductRow;
