import { useEffect, useState } from "react";
import styles from "./Account.module.css";
import { checkuser, showToast } from "../../utilities/helpers";
import { CheckIcon, EditIcon, LockIcon } from "../../assets/icons";
import instance from "../../api";
import { AxiosError } from "axios";

export default function Personal() {
  checkuser()
  const [formState, setFormState] = useState({
    fullName: "",
    email: "",
    role: "",
    phoneNo: "",
  });

  const [passwordFormState, setPasswordFormState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  const [prevFormState, setPrevFormState] = useState({
    fullName: "",
    email: "",
    role: "",
    phoneNo: "",
  });

  const [edit, setEdit] = useState(false)
  const [editPassword, setEditPassword] = useState(false)
  const [edited, setEdited] = useState(false)
  const [team, setTeam] = useState<any>()
  const { fullName, email, role, phoneNo } = formState;
  const { oldPassword, newPassword } = passwordFormState;
  const userData = JSON.parse(localStorage.getItem("user") as string);
  useEffect(() => {
    getTeam()
  }, [])

  useEffect(() => {
    let token =getCookie("Authorization")
    if(localStorage.getItem("user") && token && localStorage.getItem("business") && team){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // const businessData = JSON.parse(localStorage.getItem("business") as string)[0];
      // setBusinessId(userData.businessId)
      setFormState({
        fullName: userData.firstName + " " + userData.lastName,
        email: userData.email,
        phoneNo: userData.phoneNo,
        role: team?.role
      })
      setPrevFormState({
        fullName: userData.firstName + " " + userData.lastName,
        email: userData.email,
        phoneNo: userData.phoneNo,
        role: team?.role
      })
    }else{
      // localStorage.removeItem("user")
      // window.location.assign('/login')
    }
  }, [team])

  function onChange(e: any) {
    setFormState((prev) => { 
      return {...prev, [e.target.name]: e.target.value }
    });
  }

  const getTeam = async () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      
      // setBusinessId(userData.businessId)
      await instance.get(
        `/api/team/${userData.businessId}/${userData.id}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res.data)
          setTeam(res.data.data)
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  function onChangePassword(e: any) {
    setPasswordFormState((prev) => { 
      return {...prev, [e.target.name]: e.target.value }
    });
  }

   const check = () => {
    if(
      prevFormState.fullName === formState.fullName &&
      prevFormState.email === formState.email &&
      prevFormState.phoneNo === formState.phoneNo &&
      prevFormState.role === formState.role
    ){
      setEdited(false)
    }else{
      setEdited(true)
    }
  }

  useEffect(()=> {
    check()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState])

  const getCookie = (cname:string) => {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  const changePassword =() => {
    let token = getCookie("Authorization")
    if(passwordFormState.confirmPassword !== passwordFormState.newPassword){
      showToast("secondary", "Error!", "Confirm password")
      return
    }
    
    instance.put("api/user/change-password", {
      "oldPassword": passwordFormState.oldPassword,
      "newPassword": passwordFormState.newPassword
    },
    {
      headers: {
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'Authorization': `Bearer ${token}`
      }
    }).then(res => {
      console.log(res.data)
      showToast("primary", "Success!", "Password changed successfully")
      setEditPassword(false)
      setPasswordFormState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "confirmPassword",
      })
    }).catch(err => {
      if (err instanceof AxiosError) {
        if (err.response?.status === 400) {
          const message = err.response.data?.message;
          showToast("secondary", "Error!", message)
        }
      }else{
        showToast("secondary", "Error!", err.message)
      }
    })
  }

  return (
    <>
      <section className={styles.wrapper}>
        <header>
          <h1>My Account</h1>
        </header>
        <section className={styles.content}>
          <div className={styles.content_header}>
            <h2>Personal details</h2>
            {userData.role==="super-admin"||userData.role == "owner"?<button disabled={edit && !edited} className={edited ? styles["edited"] : ""} onClick={() => setEdit(true)}>
              Edit Profile
              {edited ?
              <CheckIcon style={styles[""]} />
              :
              <EditIcon style={styles[""]} />
              }
            </button>:""}
          </div>
          <div className={styles.section1}>
            <div className={styles.form}>
              <div className={styles.form_row}>
                <div className={styles.form_group}>
                  <label htmlFor="fullName">Full Name</label>
                  <input
                    readOnly={!edit}
                    id="fullName"
                    name="fullName"
                    type="text"
                    defaultValue={fullName}
                    className={`${fullName && styles["error"]} ${edit && styles["edit_value"]}`}
                    onChange={onChange}
                  />
                </div>
                <div className={styles.form_group}>
                  <label htmlFor="email">Email</label>
                  <input
                    readOnly={!edit}
                    id="email"
                    name="email"
                    type="text"
                    defaultValue={email}
                    className={`${email && styles["error"]} ${edit && styles["edit_value"]}`}
                    onChange={onChange}
                  />
                </div>
              </div>
              <hr className={styles.line} />
              <div className={styles.form_row}>
                <div className={styles.form_group}>
                  <label htmlFor="phoneNo">Phone Number</label>
                  <input
                    readOnly={!edit}
                    id="phoneNo"
                    name="phoneNo"
                    type="text"
                    defaultValue={phoneNo}
                    className={`${phoneNo && styles["error"]} ${edit && styles["edit_value"]}`}
                    onChange={onChange}
                  />
                </div>
                <div className={styles.form_group}>
                  <label htmlFor="role">Your Role</label>
                  <input
                    readOnly={true}
                    id="role"
                    name="role"
                    type="text"
                    defaultValue={role}
                    className={`${role && styles["error"]}`}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>

            <div className={styles.image_card}>
              <img alt="user" src="/assets/images/profilenoimageuser.png" />
            </div>
          </div>
          <hr className={styles.line} />
          {editPassword &&
          <div style={{display: "grid", gridTemplateColumns: "2fr 1fr"}}>
            <div className={styles.form_row}>
              <div className={styles.form_group}>
                <label htmlFor="oldPassword">Old Password</label>
                <input
                  // readOnly={!edit}
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  defaultValue={oldPassword}
                  className={`${oldPassword && styles["error"]} ${styles["edit_value"]}`}
                  onChange={onChangePassword}
                />
              </div>
              <div className={styles.form_group}>
                <label htmlFor="newPassword">New Password</label>
                <input
                  // readOnly={!edit}
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  defaultValue={newPassword}
                  className={`${newPassword && styles["error"]} ${styles["edit_value"]}`}
                  onChange={onChangePassword}
                />
              </div>
            </div>
          </div>
          }
          <div style={{display: "grid", gridTemplateColumns: "2fr 1fr"}}>
            <div className={styles.form_row}>
              <div className={styles.form_group}>
                <label htmlFor="confirmPassword"> {editPassword && "Confirm"} Password</label>
                <input
                  readOnly={!editPassword}
                  id="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  defaultValue={"confirmPassword"}
                  className={` ${styles["password"]} ${editPassword && styles["edit_value"]}`}
                  onChange={onChangePassword}
                />
              </div>
              <div className={styles.form_group}>
                <p></p>
                <button className={styles.edit_password} onClick={() => {editPassword ? changePassword() : setEditPassword(true)}}>
                  {editPassword ? "Update" : "Change"} Password
                  <LockIcon style={styles[""]} />
                </button>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
