import React, { FC, useEffect, useState } from "react";
import styles from "../../pages/Order/orders.module.css";
import { calculateDuration } from "../../utilities/helpers";
import { ArrowLeftIcon } from "../../assets/icons";

interface OrderRowProps {
  action: string;
  order: any;
  selectOrder: Function;
  selectedOrder: any;
  setEscalate: Function;
  // handleAdminRemove?: (email: string) => void;
}

const OrderRow: FC<OrderRowProps> = ({ action, selectOrder, order, selectedOrder, setEscalate}) => {
    // const [timer, setTimer] = useState('00:00:00');
    const [selected, setSelected] = useState(false)

    useEffect(() => {
            
        // To calculate the time difference of two dates 
        // let id = setInterval(() => {
        //     var date1 = new Date(order.message?.createdAt); 
        //     var date2 = new Date(); 
        //     console.log(date2.getHours(), date1.getHours())
        //     console.log(date2.getDay(), date1.getDay())
        //     console.log(date2.getHours() - date1.getHours())
        //     console.log(date1.getSeconds() - date2.getSeconds())
        //     const total = Date.parse(order.message?.createdAt) + Date.parse(new Date().toDateString());
        //     const seconds = Math.floor((total / 1000) % 60);
        //     const minutes = Math.floor((total / 1000 / 60) % 60);
        //     const hours = Math.floor((total / 1000 / 60 / 60) % 24);

        //     setTimer(
        //         (hours > 9 ? hours : '0' + hours) + ':' +
        //         (minutes > 9 ? minutes : '0' + minutes) + ':'
        //         + (seconds > 9 ? seconds : '0' + seconds)
        //     )
        // }, 1000)
        // return clearInterval(id)
        
    }, [order])

    return (
        <tr style={(action === "closed") ? {gridTemplateColumns: "13% 15% 16% 11% 11% 11% 11%"} : {gridTemplateColumns: "13% 15% 16% 11% 11% 11% 11%"}} className={selected ? styles["selected"]: styles[""]}>
            {/* <td onClick={() => selectOrder(order)}>
                <input onChange={() => {}} checked={selected} type="checkbox" />
            </td> */}
            <td onClick={() => selectOrder(order)}>
            <h5>{order.id}</h5>
            </td>
            <td onClick={() => selectOrder(order)}>
            <h5>{order.customer}</h5>
            </td>
            <td onClick={() => selectOrder(order)}> 
            <h5>{order.productName}</h5>
            </td>
            <td onClick={() => selectOrder(order)}> 
            <h5>{order.price.$numberDecimal}</h5>
            </td>
            <td onClick={() => selectOrder(order)}> 
            <h5>{calculateDuration(order.updatedAt)}</h5>
            </td>
            <td onClick={() => selectOrder(order)}> 
                <h5 
                className={
                    order.status === "pending" ? styles["neutral"] : 
                    order.status === "cancelled" ? styles["angry"]: 
                    styles["happy"]
                }
                style={{textTransform: "capitalize"}}
                >{order.status}</h5>
            </td>
            {/* <td onClick={() => selectOrder(order)}> 
            <h5>{order.category}</h5>
            </td> */}
            {/* <td onClick={() => selectOrder(order)}> 
            <h5>{order.type}</h5>
            </td> */}
            {/* <td onClick={() => selectOrder(order)}> 
            <h5>{calculateDuration(order.escalatedResolvedTimer ? order.escalatedResolvedTimer : order.message?.createdAt)}</h5>
            </td> */}
            <td>
            {/* {order.escalated ? */}
            <div className={`${styles["order_details_modal_button2"]}`}>
                <h4>View order</h4>
                <ArrowLeftIcon style={styles["right_icon"]} />
            </div>
            {/* :
            <div style={{gridTemplateColumns: "max-content"}} onClick={() => {selectOrder(order); setEscalate(true)}} className={`${styles["order_details_modal_button2"]} ${styles["escalate_button"]}`}>
                <h4>Escalate</h4>
            </div>
            } */}
            </td>
        </tr>
    );
};

export default OrderRow;
