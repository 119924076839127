import React, { FC, useEffect, useState } from "react";
import styles from "./AttachmentCard.module.css";
import {
  CancelIcon,
  DownloadAttachmentIcon,
  DownloadIcon,
  FileAttachmentIcon,
  ImageAttachmentIcon,
  PDFAttachmentIcon,
  PaperClipIcon,
  SheetsAttachmentIcon,
  TextDocAttachmentIcon,
} from "../../assets/icons";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import Loading from "react-loading";
import { showToast } from "../../utilities/helpers";
interface AttachmentCard {
  attachment: any;
}

const AttachmentCard: FC<AttachmentCard> = ({ attachment }) => {
  const [showAttachment, setShowAttachment] = useState<boolean>(false);
  const [blob, setBlob] = useState<Blob>();
  let [downloadLoading, setDownloadLoading] = useState<boolean>(false)
  // const fileExtension =""
  let fileExtension = "non";
  if(attachment?.name){
     fileExtension = attachment?.name.split(".").pop().toLowerCase();
  }

useEffect(()=>{
  console.log(attachment.name,"attachment");

},[])
  const handleViewAttachment = (event: React.MouseEvent<HTMLDivElement>) => {
   if( fileIconMapping.images.includes(fileExtension)||fileIconMapping.pdfDocuments.includes(fileExtension)){
    const clickedElement = event.target as HTMLElement;
    setShowAttachment(!showAttachment);

   }else{
    setShowAttachment(!showAttachment);
    downloadResource(attachment.url, attachment.name);
  }
    
  };
  const forceDownload = (blobUrl: string, filename: string) => {
    const a = document.createElement("a");
    a.style.display = "none"; // Hide the element
    a.download = filename;
    a.href = blobUrl;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(blobUrl); // Clean up the URL object
  };

  const downloadResource = (url: string, filename?: string) => {
    setDownloadLoading(true);
    if (!filename) {
      // Fallback to extract filename from URL if not provided
      filename = url.split("/").pop()?.split("?")[0]; // Also removing query strings if present
    }
console.log(url);
    fetch(url, {
      headers: new Headers({
        Origin: window.location.origin,
      }),
      mode: "cors",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok: " + response.statusText);
        }
        return response.blob();
      })
      .then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, attachment.name);
        setDownloadLoading(false);
        setShowAttachment(false)
        showToast("primary", "Download Successful!","Download was Successful")

      })
      .catch((error) => {
        setDownloadLoading(false);
        setShowAttachment(false);
        showToast("secondary", "Error","There was an issue downloading attachment.")
        console.error("There was an issue downloading the file:", error);
      });
    
    function forceDownload(blobUrl:string, filename:string) {
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(blobUrl);
    }
  }   
  const fileIconMapping = {
    images: ["jpg", "jpeg", "png", "gif"],
    textDocuments: ["doc", "docx", "txt", "odt"],
    pdfDocuments: ["pdf"],
    archives: ["zip", "rar", "7z", "tar", "gz"],
    sheets: ["xls", "xlsx", "ods", "csv"],
  };

  return (
    <>
      <div className={styles["attachment"]} onClick={handleViewAttachment}>
        <div className={styles["attachment-left"]}>
          <div>
            {fileIconMapping.images.includes(fileExtension) ? (
              <ImageAttachmentIcon style={styles["file-attachment-icon"]} />
            ) : fileIconMapping.pdfDocuments.includes(fileExtension) ? (
              <PDFAttachmentIcon style={styles["file-attachment-icon"]} />
            ) : fileIconMapping.textDocuments.includes(fileExtension) ? (
              <TextDocAttachmentIcon style={styles["file-attachment-icon"]} />
            ) : fileIconMapping.sheets.includes(fileExtension) ? (
              <SheetsAttachmentIcon style={styles["file-attachment-icon"]} />
            ) : fileIconMapping.archives.includes(fileExtension) ? (
              <FileAttachmentIcon style={styles["file-attachment-icon"]} />
            ) : (
              <PaperClipIcon style={styles["file-attachment-icon"]} />
            )}
          </div>
          <div>
            <PaperClipIcon style={styles["clip-attachment-icon"]} />
          </div>
        </div>
        <div className={styles["attachment-right"]}>
          <div className={styles["right-text"]}>
            <p>{attachment?.name}</p>
            <p className={styles["shared-text"]}>Shared in cloud</p>
          </div>

          <div
            onClick={(e) => {
              e.stopPropagation();
              downloadResource(attachment.url, attachment.name);
            }}
            className={styles["download-text"]}
          >
            Download
          </div>
        </div>
      </div>

      {showAttachment &&  (
        <div
          className={styles["attachment-content"]}
          onClick={handleViewAttachment}
        >
          <div className={styles["top-icon-div"]}>
            <div
              onClick={(e) => {
                downloadResource(attachment.url, attachment.name);
                e.stopPropagation();
              }}
            >
              <DownloadAttachmentIcon style={styles["download_icon"]} />
            </div>

            <CancelIcon style={styles["cancel_icon"]} />
          </div>
          {
            fileIconMapping.pdfDocuments.includes(fileExtension) ?

            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div
             style={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              height: '750px',
              width:'600px'
          }}
          ><Viewer fileUrl={attachment.url}/></div> 
          </Worker>
          : fileIconMapping.images.includes(fileExtension)?
          <img src={attachment.url}
          width="" height="750px"/>
          :
          
            downloadLoading && <Loading/> 
          
          // <iframe
          //   src = {attachment.url}
          //   // src="https://enif-inventory.s3.amazonaws.com/uploads/1716310730324-cjbj78f2v.pdf.pdf"
          //   // type ={"application/pdf"}
          //   height="800px"
          //   width="800px"
          // /> 
}
        </div>
      )}
    </>
  );
};

export default AttachmentCard;
