import React, { FC, useEffect, useRef, useState } from "react";
import styles from "./Onboarding.module.css";
import {
  CopySVG,
  HexEscalationIcon,
  MessageIcon,
  OnboardingIcon,
  PaperPlaneSVG,
  PlaySVG,
  PuzzleIcon,
  RapidBusinessSVG2,
} from "../../assets/icons";
import OnbardingSocialCard from "../../components/OnboardingSocialCard/OnbardingSocialCard";
import OnbardingLinkBusinessModal from "../../components/OnbardingLinkBusinessModal/OnbardingLinkBusinessModal";
import instance from "../../api";
import { getCookie, showToast } from "../../utilities/helpers";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/Modal";

const host = window.location.host;
let redirectUrl: string;
if (host.includes('local')) {
    redirectUrl = 'http://' + host + '/callback/google';
} else {
    redirectUrl = 'https://' + host + '/callback/google';
}
// https://dev-business.enif.ai/callback/google

interface PropType {
  hasBusiness: boolean,
  businessApproved: boolean,
  isCompleted: boolean,
}

const Onboarding: FC<PropType> = ({hasBusiness, businessApproved, isCompleted}) => {
  const [ openModal, setOpenModal ] = useState(false);
  const [ businessId, setBusinessId ] = useState("");
  const [ step, setStep ] = useState< string | null> ("");
  const navigate = useNavigate()

  const childRef = useRef<any>(null);

    const clientId =
        // '167313760974-8q4smoj5h38sdiu5vhqkjabji4g2ujio.apps.googleusercontent.com';
        process.env.REACT_APP_GMAIL_CLIENT_ID;
    const scopes = [
        'https://www.googleapis.com/auth/gmail.readonly',
        'https://www.googleapis.com/auth/drive.metadata.readonly',
        'https://www.googleapis.com/auth/gmail.modify',
        'https://www.googleapis.com/auth/pubsub',
    ];
    const oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';
    // let redirectUri;

    // if (host.includes('local')) {
    //     redirectUri = 'http' + host + '/callback/google';
    // }
    // redirectUri = host + '/callback/google';
    // const redirectUri = host + 'http://localhost:5174/callback/google';
    const params: any = {
        access_type: 'offline',
        client_id: clientId,
        redirect_uri: redirectUrl,
        response_type: 'code',
        scope: scopes.join(' '),
        include_granted_scopes: 'true',
        prompt: 'consent',
        state: 'something',
    };

    const url = () => {
        const query = Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&');
        return `${oauth2Endpoint}?${query}`;
    };

    const authorize = () => {
        childRef.current = window.open(url(), 'GoogleOauth2', 'width=550,height=470,left=450,top=100,toolbar=1,status=1,');
    };

  useEffect(() => {

    if(localStorage.getItem("user")){
      // const userData = JSON.parse(localStorage.getItem("user") as string);
      const business: any = JSON.parse(localStorage.getItem("business") as string)
      if(business && business.businessId){
        if(business.completionStatus){
          let token = getCookie("Authorization")

          const handleMessage = (event: any) => {
            if (event.data.key === 'google:oauth2') {
                const data = event.data.data;
                console.log({ data });
                localStorage.setItem('gmailData', JSON.stringify(data))
                instance.post(`/api/integration/gmail/configure`,
                {
                  "code": data.code,
                  "businessId": businessId
                },
                {
                  headers: {
                    'Access-Control-Allow-Headers': 'X-Requested-With',
                    'Authorization': `Bearer ${token}`
                  }
                }
                ).then(res => {
                    console.log(res.data);
                    showToast("primary", "Success!", "Gmail configured successfully")
                    localStorage.setItem('tokens', JSON.stringify(res.data))
                })
                // if (data.state === params.state) {
                //     dispatch(connectChannels(data) as any);
                // }
            }
          };

          window.addEventListener('message', handleMessage);
          setOpenModal(false)
          setBusinessId(business.businessId)

          return () => {
            window.removeEventListener('message', handleMessage);
          };
        }else{
          setOpenModal(true)
        }
      }else{
        setOpenModal(true)
      }
    }else{
      setOpenModal(true)
    }
  }, [businessId])

  useEffect(() => {
    if(!businessId){
      setInterval(() => {
        if(localStorage.getItem("reloadonboarding")){
          let step = localStorage.getItem("reloadonboarding")
          if(step!.length>1){
            localStorage.removeItem("reloadonboarding")
            if(step === "Upload Inventory"){
              localStorage.setItem('mainpage', "item9")
              return navigate("/inventory")
            }

            if(step === "Upload FAQ"){
              localStorage.setItem('mainpage', "item5")
              return navigate("/training-center/faq")
            }
            
          }else{
            console.log(step)
            setStep(step)
            setOpenModal(true)
            localStorage.removeItem("reloadonboarding")
          }
        }
      }, 1000)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId])

  const copyToClipBoard = () => {
    let link = ""
    if(process.env.REACT_APP_BACKEND_END_BASE_URL === "https://test.enif.ai"){
      link = `https://dev.chat.enif.ai/${businessId}`
    }else{
      link = `https://chat.enif.ai/${businessId}`
    }
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(link).then(() => {
        showToast("primary", "Success!", "Copied")
      });
    } else {
        showToast("primary", "Success!", "Copied")
        return document.execCommand('copy', true, link);
    }
  }

  const copyTobusinessIdClipBoard = () => {
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(businessId).then(() => {
        showToast("primary", "Success!", "Copied")
      });
    } else {
        showToast("primary", "Success!", "Copied")
        return document.execCommand('copy', true, businessId);
    }
  }

  return (
    <section className={styles.wrapper}>
      <header>
        <OnboardingIcon style={styles["onboardingIcon"]} />
        <p>Setup your business</p>
      </header>
      <section className={styles.content}>
        <section className={styles["section-1"]}>
          <section className={styles["section-1-section"]}>
            <header className={styles["section-1-section-header"]}>
              <p>
                Get Started <span>by clicking your business</span>
              </p>
              <PuzzleIcon style={styles["puzzle-icon"]} />
            </header>
            <section className={styles["section-1-section-content"]}>
              <div onClick={openModal ? () => setOpenModal(true) : () => {}}>
                <OnbardingSocialCard social="instagram" />
              </div>
              <div onClick={openModal ? () => setOpenModal(true) : () => {}}>
                <OnbardingSocialCard social="whatsapp" />
              </div>
              <div onClick={openModal ? () => setOpenModal(true) : () => {}}>
                <OnbardingSocialCard social="facebook" />
              </div>
              <div onClick={openModal ? () => setOpenModal(true) : () => {authorize()}}>
                <OnbardingSocialCard social="mail" />
              </div>
            </section>
          </section>
        </section>

        <section className={styles["section-2"]}>
          <section className={styles["card"]}>
            <p>Rapid business growth & customers delight</p>
            <RapidBusinessSVG2 style={styles["rapid-icon"]} />
          </section>
          <section className={styles["card"]}>
            <div className={styles["card-2-thick"]}>
              <p>Quick video on how enif</p>
              <p>can help your business</p>
            </div>
            <div className={styles["card-2-thin"]}>
              <p>Lets get your business up</p>
              <p> and running in minutes</p>
            </div>
            <div className={styles["play"]}>
              <PlaySVG style={styles["icon"]} />
            </div>
          </section>
        </section>

        <section className={styles["section-3"]}>
          <section className={styles["section-3-header"]}>
            <MessageIcon style={styles["message-icon"]} />
            <p>Live Chat Setup</p>
          </section>
          <section className={styles["body"]}>
            <section className={styles["body-body"]}>
              <p className={styles["body-body-p-1"]}>
                Add the code to your website to start chatting
              </p>
              <p className={styles["body-body-p-2"]}>
                Status: chat widget not installed, no chats coming in 🔴
              </p>
              <section className={styles["flex"]}>
                <p>
                  Copy and paste this code before the <span>{`</body/>`}</span>{" "}
                  tag on every page of your website
                </p>
                <button onClick={!openModal ? () => copyToClipBoard(): () => {}} className={openModal ? styles["disable"]: styles[""]}>
                  Copy code <CopySVG style={styles["copy-svg"]} />
                </button>
              </section>
              <section className={styles["code"]}>
                <code>
                  <pre>
                    {`
          <!-- Tawk.to embedding code --><script type="text/
          javascript"> var Tawk_API = Tawk_API || {}; Tawk_API.visitor = { `}
                  </pre>
                </code>
              </section>
            </section>
          </section>
          <section className={styles["base"]}>
            <section>
              <p>Need help?</p>
              <button className={styles["button-1"]}>
                <PaperPlaneSVG style={styles["paper-plane"]} />
                Send to developer
              </button>
              <button className={styles["button-2"]}>
                <span>{`< >`}</span> Check to install guide
              </button>
            </section>
          </section>
        </section>
      </section>
      {((hasBusiness && !isCompleted) || !hasBusiness) &&
        <OnbardingLinkBusinessModal hasBusiness={hasBusiness} isCompleted={isCompleted} step={step} isOpen={openModal} onClose={() => {setOpenModal(false)}} />
      }
      {hasBusiness && !businessApproved && isCompleted &&
        <Modal isOpen={true} >
          <section
            className={`${
              styles["reviewwrapper"]
            }`}
          >
            <main className={styles["review_con"]}>
              <div className={styles["icon_container"]}>
                <div className={styles["icon_review_container"]}>
                  <HexEscalationIcon style={styles["icon_review"]} />
                </div>
              </div>

              <h4>
                Account under review
              </h4>

              <div>
                <p>
                  For any questions or assistance, please contact our support team at
                  info@enif.ai or 07067084674.
                </p>
                <p>
                  We appreciate your patience and thank you for choosing our service.
                </p>
              </div>

              <div className={styles["copy_to_clipboard_con"]}>
                <div className={styles.copy_to_clipboard}>
                  <p>{businessId}</p>
                </div>
                <button onClick={copyTobusinessIdClipBoard}>Copy</button>
              </div>
            </main>
          </section>
        </Modal>
      }
    </section>
  );
}

export default Onboarding;
