import React, { FC, ChangeEvent, useState } from "react";
import styles from "./teamRow.module.css";
import { MoreIcon } from "../../../../../assets/icons";
import Modal from "../../../../Modal/Modal";

interface user {
  team: string;
  id: string;
  role: string;
  fullName: string;
  email: string;
  status?: string;
  removeTeam: Function;
  handleEdit: Function;
  you: boolean;
  department: any;
  // handleAdminRemove?: (email: string) => void;
}

const TeamRow: FC<user> = (props) => {
  const [moreAction, setMoreAction] = useState(false);
  const [deleteTeamId, setDeleteTeamId] = useState<string | null>();
  const admin = "admin";
  const userData = JSON.parse(localStorage.getItem("user") as string);
  const handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
  };
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  console.log(deleteTeamId);

  const confirmTeamAction = () => {
    props.removeTeam(deleteTeamId);
    setConfirmDelete(false);
    setDeleteTeamId(null);
  };

  const userRoles =
    props.role.toLowerCase() !== admin ? (
      <div className={`${styles.dropdown} ${styles["div-dropdown"]}`}>
        {props.role}
      </div>
    ) : (
      <select
        className={styles.dropdown}
        name="roles"
        onChange={handleRoleChange}
      >
        <option value="">{props.role}</option>
        <option value="option1">Super-Admin</option>
      </select>
    );
  return (
    <>
      <Modal isOpen={confirmDelete}>
        <div className={styles["delete_modal"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]}>Delete team</div>
          </div>
          <div className={styles["modal-content"]}>
            Are you sure you want to remove this team member?
          </div>
          <div className={styles["modal-controls"]}>
            <input
              type="button"
              value="No"
              onClick={() => {
                setDeleteTeamId(null);
                setConfirmDelete(false);
              }}
            />
            <input
              type="button"
              value="Remove"
              onClick={() => confirmTeamAction()}
            />
          </div>
        </div>
      </Modal>
      <section className={styles.wrapper}>
        <div className={styles["user-profile"]}>
          <div className={styles["abv"]}>{props.fullName[0]}</div>
          <div>
            <h4>{props.fullName}</h4>
            <div className={styles["user_email"]}>
              <p>{props.email}</p>
              {props.you && <div>You</div>}
            </div>
          </div>
        </div>
        <div className={styles["controls"]}>
          {props.department?.department && (
            <div className={`${styles.dropdown} ${styles["div-dropdown"]}`}>
              {props.department?.department}
            </div>
          )}
          {userRoles}

          {(['owner', 'super-admin'].includes(userData.role)) ? (
            <div
              onMouseEnter={() => setMoreAction(true)}
              onMouseLeave={() => setMoreAction(false)}
              className={styles["edit_con"]}
            >
              {props.role !== "owner" && (
                <>
                  <MoreIcon style={styles["edit_icon"]} />
                  {moreAction && (
                    <div className={styles["more_option_con"]}>
                      <h5
                        onClick={() => {
                          props.handleEdit(props.id, props.team);
                        }}
                      >
                        Edit team
                      </h5>
                      <h5
                        onClick={() => {
                          setConfirmDelete(true);
                          setDeleteTeamId(props.id);
                        }}
                      >
                        Delete team
                      </h5>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
};

export default TeamRow;
