import { FormEvent, useEffect, useState } from "react";
import styles from "./SignIn.module.css";
import logo from "../../assets/images/enif_logo_full.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import instance from "../../api";
import { AxiosError } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import { getCookie, setCookie, showToast } from "../../utilities/helpers";
interface InputType {
  email: { value: string; isValid: null | boolean; isTouched: boolean };
  password: { value: string; isValid: null | boolean; isTouched: boolean };
  error: "";
  success: boolean;
}
export default function SignIn() {
  const navigate = useNavigate();
  const router = useLocation()
  const [formInput, setFormInputs] = useState<InputType>({
    email: { value: "", isValid: null, isTouched: false },
    password: { value: "", isValid: null, isTouched: false },
    error: "",
    success: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [password, setPassword] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  useEffect(() => {
    if(router?.search?.length > 0){
      if(router.search.includes("&")){
        let email = ""
        let searchArray = router.search.split("&")
        if(searchArray[0]?.split("=")[0].includes("email")){
          email = searchArray[0].split("=")[1]
        }
        if(searchArray[1]?.split("=")[0].includes("email")){
          email = searchArray[1].split("=")[1]
        }
        if(searchArray[2]?.split("=")[0].includes("email")){
          email = searchArray[2].split("=")[1]
        }
        console.log(email)
        setFormInputs((prevState) => ({
          ...prevState,
          email: {
            value: email,
            isValid: validateEmail(email),
            isTouched: true,
          },
        }))
      }
    }
  }, [router.search])

  const handleShowPassword = () => {
    setPassword((prevValue) => ({
      ...prevValue,
      showPassword: !prevValue.showPassword,
    }));
  };

  const disableSubmitButton = Object.values(formInput).some(
    (value) => value.isValid === false || (value.isTouched && !value.isValid)
  );

  const handleSignin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email: formInput.email.value,
      password: formInput.password.value,
    };
    try {
      const response = await instance.post("api/user/login", { ...data });
      const business: { businessId: string }= response.data?.business;
      console.log(response.data, business);
      const userData: {
        id: string;
        userId: string;
        email: string;
        firstName: string;
        lastName: string;
        token: string;
        businessId: string;
        phoneNo: string;
        password: string;
      } =
        business && business.businessId
          ? {
              id: response.data.user._id,
              userId: response.data.user.userId,
              email: response.data.user.email,
              firstName: response.data.user.firstName,
              lastName: response.data.user.lastName,
              phoneNo: response.data.user.phoneNo,
              password: response.data.user.password,
              token: "",
              businessId: business?.businessId,
            }
          : {
              id: response.data.user._id,
              userId: response.data.user.userId,
              email: response.data.user.email,
              firstName: response.data.user.firstName,
              lastName: response.data.user.lastName,
              phoneNo: response.data.user.phoneNo,
              password: response.data.user.password,
              token: "",
              businessId: "",
            };
      // const userData: {
      //   id: string;
      //   userId: string;
      //   email: string;
      //   firstName: string;
      //   lastName: string;
      //   token: string;
      //   businessId: string;
      // } = {
      //   id: response.data._id,
      //   userId: response.data.userId,
      //   email: response.data.email,
      //   firstName: response.data.firstName,
      //   lastName: response.data.lastName,
      //   token: "",
      //   businessId:
      //     Array.isArray(business) && business.length > 0
      //       ? business[0]?.businessId
      //       : "",
      // };
      console.log(business, userData);
      //change

      setCookie("Authorization", response.headers['authorization'], 2);

   
      // const prevuserData = JSON.parse(localStorage.getItem("user") as string);

      // if(userData.id !== prevuserData.id){
      //   localStorage.removeItem("selectedPlatforms")
      //   localStorage.removeItem("businessName")
      //   localStorage.removeItem("email")
      //   localStorage.removeItem("industry")
      //   localStorage.removeItem("phoneNumber")
      //   localStorage.removeItem("businessDescription")
      //   localStorage.removeItem("bankName")
      //   localStorage.removeItem("accountName")
      //   localStorage.removeItem("accountNumber")
      //   localStorage.removeItem("ownersFullName")
      //   localStorage.removeItem("businessPhoneNumber")
      //   localStorage.removeItem("businessAddress")
      //   localStorage.removeItem("plan")
      //   localStorage.removeItem("onboard1")
      //   localStorage.removeItem("onboard2")
      //   localStorage.removeItem("onboard3")
      // }

      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.setItem("business", JSON.stringify(business))
      localStorage.removeItem('mainpage');
      //FOR DEBUGGING USER
      const user  = JSON.stringify(localStorage.getItem('user'));
      // console.log(getCookie('Authorization'),"here with authorization",response.headers['authorization'],user);
      
      //TODO: REMOVE THIS AFTER TEAMS WHEN ROLE IS COMING WITH USER DATA AT SIGN IN RESPONSE


        if( business && business.businessId){ console.log("I am here")
          const userData = JSON.parse(localStorage.getItem("user") as string);
          // setBusinessId(userData.businessId)
          await instance.get(
            `/api/team/${userData.businessId}/${userData.id}`,
            {
              headers: {
                'Access-Control-Allow-Headers': 'X-Requested-With',
                'Authorization': `Bearer ${response.headers[`authorization`]}`
              }
            }).then(res => {
              let savedUser = localStorage.getItem('user');
              let userObject = savedUser ? JSON.parse(savedUser):null
              userObject.role = res.data.data.role
              localStorage.setItem('user',JSON.stringify(userObject))
          }).catch(e => {
            console.log(e.response.data)
          })
        }else{
          // localStorage.removeItem("user")
          // window.location.assign('/login')
        }

      setFormInputs((prev) => ({
        ...prev,
        email: { value: "", isValid: null, isTouched: false },
        password: { value: "", isValid: null, isTouched: false },
      }));
      setIsLoading(false);

      showToast("primary", "Success!", "Signed in successfully")
      if(business && business.businessId){
        console.log("I was here");
        localStorage.setItem("mainpage", "item1")
      }else{
        localStorage.setItem("mainpage", "onboarding")
      }
      navigate("/");
    } catch (error) {
      if (error instanceof AxiosError) {
        // if (error.response?.status === 400) {
        //   const message = error.response.data?.message;
        //   setFormInputs((prev) => ({
        //     ...prev,
        //     error: message,
        //   }));
        // }
        if (error.response?.status) {
          const message = error.response.data?.message;

          showToast("secondary", "Error!", message)
          setFormInputs((prev) => ({
            ...prev,
            error: message,
          }));
        }else{
          showToast("secondary", "Error!", error.message)
        }
      }
      setIsLoading(false);
      console.log(error);
    }
  };

  const validateEmail = (value: string) =>
    value.length > 0 && value.includes("@") && value.includes(".");

  const validatePassword = (value: string) => value.length > 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={logo} alt="enif" />
      </div>
      <div className={styles.body}>
        <p>Welcome back</p>
        <p>Log into your enif account</p>
        {formInput.error && (
          <div className={styles["error-message"]}>{formInput.error}</div>
        )}
        <div className={styles.formWrapper}>
          <form className={styles["signin-form"]} onSubmit={handleSignin}>
            <div>
              <input
                type="email"
                placeholder="Email Address"
                value={formInput.email.value}
                className={`${
                  !formInput.email.isValid &&
                  formInput.email.isTouched &&
                  styles["error"]
                }`}
                onFocus={() =>
                  setFormInputs((prevState) => {
                    return {
                      ...prevState,
                      email: { ...prevState.email, isTouched: true },
                    };
                  })
                }
                onChange={(e) =>
                  setFormInputs((prevState) => ({
                    ...prevState,
                    email: {
                      value: e.target.value,
                      isValid: validateEmail(e.target.value),
                      isTouched: true,
                    },
                  }))
                }
              />
            </div>
            {/* <div>
              <div
                className={`${styles.password} ${
                  !formInput.password.isValid &&
                  formInput.password.isTouched &&
                  styles["error"]
                }`}
              >
                <input
                  type="password"
                  placeholder="Enter password"
                  onFocus={() =>
                    setFormInputs((prevState) => {
                      return {
                        ...prevState,
                        password: { ...prevState.password, isTouched: true },
                      };
                    })
                  }
                  onChange={(e) =>
                    setFormInputs((prevState) => ({
                      ...prevState,
                      password: {
                        ...prevState.password,
                        value: e.target.value,
                        isValid: validatePassword(e.target.value),
                      },
                    }))
                  }
                />
              </div>
            </div> */}
            <div>
              <div
                className={`${styles.password} ${
                  !formInput.password.isValid &&
                  formInput.password.isTouched &&
                  styles["error"]
                }`}
              >
                <input
                  type={password.showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  onFocus={() =>
                    setFormInputs((prevState) => {
                      return {
                        ...prevState,
                        password: { ...prevState.password, isTouched: true },
                      };
                    })
                  }
                  onChange={(e) =>
                    setFormInputs((prevState) => ({
                      ...prevState,
                      password: {
                        ...prevState.password,
                        value: e.target.value,
                        isValid: validatePassword(e.target.value),
                      },
                    }))
                  }
                />
                <span onClick={handleShowPassword}>
                  {!password.showPassword ? (
                    <FontAwesomeIcon icon={faEye} />
                  ) : (
                    <FontAwesomeIcon icon={faEyeSlash} />
                  )}
                </span>
              </div>
            </div>
            <div>
              <input
                type="submit"
                value={isLoading ? "Loading..." : "Log In"}
                disabled={disableSubmitButton}
              />
            </div>
            <p>
              Don't have an account? <Link to="/signup">Sign up</Link>
            </p>
            <p style={{marginTop: "10px", fontSize: "14px"}}>
               <Link to="/forgot-password">Forgot Password?</Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}
