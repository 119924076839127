
import { CalenderIcon2, EsclamationIcon, FileIcon3 } from "../../../../assets/icons";
import { checkuser } from "../../../../utilities/helpers";
import styles from "./engagements.module.css";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const Engagements = () => {
  checkuser()
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35.71,
    height: 20,
    padding: 0,
    borderRadius: 17,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 16,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked': {
        transform: 'translateX(15px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#3BDD99',
        },
        '& > .MuiSwitch-thumb': {
            backgroundColor:
        theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
          },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor:
        theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
        width: 35.71,
        height: 20,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor:
        '#D1D1E0',
      boxSizing: 'border-box',
    },
}));

  return (
    <>
      <section className={styles.wrapper}>
        <header>
          {/* <h1>Choose your Premium</h1> */}
        </header>
        <section className={styles.content}>
          <h3>Engagement</h3>
          <div className={styles["engagements_con"]}>
            <p><EsclamationIcon style={styles["esclamation_icon"]} /> Enable or disable customer surveys to gather feedback and insights</p>
            <div className={styles["engagements_col"]}>
              <div className={styles["engagements"]}>
                <div className={styles["engagement"]}>
                  <div className={styles["channel"]}>
                    <div className={styles["channel_icon_con"]}>
                      <CalenderIcon2 style={`${styles["channel_icon2"]} ${styles["calendar_icon"]}`} />
                    </div>
                    <div>
                      <h4>Customer Engagement Survey</h4>
                    </div>
                  </div>
                  <div className={styles["controls"]}>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div className={styles["engagement"]}>
                  <div className={styles["channel"]}>
                    <div className={styles["channel_icon_con"]}>
                      <FileIcon3 style={`${styles["channel_icon2"]} ${styles["file_icon"]}`} />
                    </div>
                    <div>
                      <h4>Escalation to Jira</h4>
                    </div>
                  </div>
                  <div className={styles["controls"]}>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Engagements;
