import React, { FC, FormEvent, useEffect, useRef, useState } from "react";
import styles from "./OnbardingLinkBusinessModal.module.css";
import Modal from "../Modal/Modal";
import {
  FileIcon2,
  PlanAnnualyStarSVG,
  PlanCheckSVG,
  PlanMonthlyStarSVG,
  PlanQuarterlyStarSVG,
  SocialsFacebookSVG,
  SocialsGmailSVG,
  SocialsInstaSVG,
  SocialsTelegramSVG,
  SocialsTwitterSVG,
  SocialsWhatsappSVG,
  StepFourSVG,
  StepOneSVG,
  StepThreeSVG,
  StepTwoSVG,
  UrgencyIcon,
} from "../../assets/icons";
import Select from "react-select";
import constants from "../../utilities/constants";
import { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import ReactLoading from 'react-loading';
import instance from "../../api";
import { getCookie, showToast } from "../../utilities/helpers";
const { industries } = constants;
interface OnbardingLinkBusinessModalProps {
  isOpen: boolean;
  onClose: () => void;
  step: string | null;
  isCompleted: boolean;
  hasBusiness: boolean;
}

interface inputFieldType {
  value: string;
  isTouched: boolean;
  error: null | string;
}

const OnbardingLinkBusinessModal: FC<OnbardingLinkBusinessModalProps> = (
  props
) => {

  useEffect(() => {
    console.log(props.step)
    if(props.step){
      setStep(parseInt(props.step))
      return
    }
    getBanks()
    setStep(1)
    if(props.hasBusiness && !props.isCompleted){
      if(step !== 3){
        const business: any = JSON.parse(localStorage.getItem("business") as string)
        
        localStorage.setItem("businessName", business.businessName)
        localStorage.setItem("email", business.email)
        localStorage.setItem("industry", business.industry)
        localStorage.setItem("phoneNumber", business.phoneNo)
        if(referral){
           localStorage.setItem("referral", business.referral);
        }
       

        localStorage.setItem("businessDescription", business.description)
        // localStorage.setItem("bankName", JSON.stringify(selectedOption))
        // localStorage.setItem("accountNumber", business.accountNo)
        localStorage.setItem("businessPhoneNumber", business.ownerPhoneNo)
        localStorage.setItem("selectedPlatforms", business.companyInformation.selectPlatforms)

        if(business.businessAddress)
        localStorage.setItem("address", business.businessAddress)

        if(business.ownersFullName)
        localStorage.setItem("ownersFullName", business.ownersFullName)

        if(business.ownerPhoneNo)
        localStorage.setItem("businessPhoneNumber", business.ownerPhoneNo)
        setStep(3)
      }else{
        setStep(4)
      }
    }
    let savedSelectedPlatforms = localStorage.getItem("selectedPlatforms")
    var newstep: number = 1;
    if(savedSelectedPlatforms && JSON.parse(savedSelectedPlatforms as string).length > 0){
      newstep = 2
      let platforms = JSON.parse(savedSelectedPlatforms as string)
      setSelectedPlatforms((prev) => {
        return [...platforms]
      })
    }

    let savedBusinessName = localStorage.getItem("businessName")
    let savedEmail = localStorage.getItem("email")
    let savedIndustry = localStorage.getItem("industry")
    let savedPhoneNumber = localStorage.getItem("phoneNumber")
    let savedReferral = localStorage.getItem("referral")
    let savedBusinessDescription = localStorage.getItem("businessDescription")

    if(
      (newstep === 2) &&
      (savedBusinessName && savedBusinessName.length >= 3) &&
      (savedEmail && savedEmail.length >= 3) &&
      (savedIndustry && savedIndustry.length >= 3) &&
      (savedPhoneNumber && savedPhoneNumber.length >= 3) &&
      (savedBusinessDescription && savedBusinessDescription.length >= 3)
    ){
      console.log('dcdc')
      newstep = 3
      setBusinessName((prev) => ({
        ...prev,
        value: savedBusinessName as string,
      }))
      setSupportEmail((prev) => ({
        ...prev,
        value: savedEmail as string,
      }))
      setIndustry((prev) => ({
        ...prev,
        value: savedIndustry as string,
      }))
      setPhoneNumber((prev) => ({
        ...prev,
        value: savedPhoneNumber as string,
      }))
      setBusinessDescription((prev) => ({
        ...prev,
        value: savedBusinessDescription as string,
      }))
    }

    let savedBankName: any; 

    try{
      savedBankName = JSON.parse(localStorage.getItem("bankName") as string)
    }catch(e){
      savedBankName = localStorage.getItem("bankName")
    }
    let savedAccountName = localStorage.getItem("accountName")
    let savedAccountNumber = localStorage.getItem("accountNumber")
    let savedOwnersFullName = localStorage.getItem("ownersFullName")
    let savedBusinessPhoneNumber = localStorage.getItem("businessPhoneNumber")
    let savedBusinessAddress = localStorage.getItem("businessAddress")

    if(
      (newstep === 3) &&
      // (savedBankName && savedBankName.label?.length > 0) &&
      // (savedAccountName && savedAccountName.length >= 3) &&
      // (savedAccountNumber && savedAccountNumber.length >= 3) &&
      (savedOwnersFullName && savedOwnersFullName.length >= 3) &&
      (savedBusinessPhoneNumber && savedBusinessPhoneNumber.length >= 3) &&
      (savedBusinessAddress && savedBusinessAddress.length >= 3)
    ){
      newstep = 4
      setBankName((prev) => ({
        ...prev,
        value: `${savedBankName?.label}-${savedBankName?.value}` as string,
      }))
      setAccountName((prev) => ({
        ...prev,
        value: savedAccountName as string,
      }))
      setAccountNumber((prev) => ({
        ...prev,
        value: savedAccountNumber as string,
      }))
      setOwnerFullName((prev) => ({
        ...prev,
        value: savedOwnersFullName as string,
      }))
      setBusinessPhoneNumber((prev) => ({
        ...prev,
        value: savedBusinessPhoneNumber as string,
      }))
      setBusinessAddress((prev) => ({
        ...prev,
        value: savedBusinessAddress as string,
      }))
    }

    let savedPlan = localStorage.getItem("plan")
    if(savedPlan){
      setPlan(savedPlan)
    }

    const handleMessage = (event: any) => {
      let token = getCookie("Authorization")
      if (event.data.key === 'paystackpayment') {
        const data = event.data.data;
        console.log(data.trxref);
        localStorage.setItem('payment', JSON.stringify(data))
        instance.post(`/api/plan/pay/verify/${data.trxref}`, {}, 
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
            console.log(res.data);
            setPaymentLoading(false)
            showToast("primary", "Payment Successful!", "Your payment was successful. Thank you.")
            submitHandler()
            // localStorage.setItem('tokens', JSON.stringify(res.data))
        }).catch((err) => {
          setPaymentLoading(false)
          if (err instanceof AxiosError) {
            if (err.response?.status === 400) {
              const message = err.response.data?.message;
              console.log(message)
              showToast("secondary", "Payment Declined!", "Your payment has been declined. Please try again!")
              
            }
          }
        })
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
        window.removeEventListener('message', handleMessage);
    };

    // setStep(newstep)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, props.step])

  const navigate = useNavigate();
  const [step, setStep] = useState<number>(1);
  const [file, setFile] = useState<string | null>(null);
  const [banks, setBanks] = useState<any>();
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([]);
  const [businessName, setBusinessName] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [supportEmail, setSupportEmail] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [industry, setIndustry] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [phoneNumber, setPhoneNumber] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [referral, setReferral] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [businessDescription, setBusinessDescription] =
    useState<inputFieldType>({
      value: "",
      isTouched: false,
      error: null,
    });
  const [bankName, setBankName] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [accountName, setAccountName] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [accountNumber, setAccountNumber] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  // const [businessDoc, setBusinessDoc] = useState<File | null>(null);

  const [plan, setPlan] = useState('annually')

  const paymentRef = useRef<any>(null)

  const [errorMsg, setErrorMsg] = useState('')

  const [paymentLoading, setPaymentLoading] = useState(false)

  const [bankVerified, setBankVerified] = useState(false)

  const [ownerFullName, setOwnerFullName] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [businessPhoneNumber, setBusinessPhoneNumber] =
    useState<inputFieldType>({
      value: "",
      isTouched: false,
      error: null,
    });
  const [businessAddress, setBusinessAddress] = useState<inputFieldType>({
    value: "",
    isTouched: false,
    error: null,
  });
  const [generalError, setGeneralError] = useState<string | null>(null);
  const selectPlatform = (value: string) => {
    // console.log(value);
    const isExisting = selectedPlatforms.find((platform) => platform === value);
    if (isExisting) {
      setSelectedPlatforms((prev) => {
        let val = prev.filter((platform) => platform !== value)
        localStorage.setItem("selectedPlatforms", JSON.stringify(val))
        return val
      }
      );
    } else {
      setSelectedPlatforms((prev) => {
        const updatedPlatforms =[...prev,value];
        localStorage.setItem("selectedPlatforms", JSON.stringify(updatedPlatforms));
        return updatedPlatforms;
      });
      setGeneralError(null);
    }
  };

  const handleContinueClick = () => {
    const business: any = JSON.parse(localStorage.getItem("business") as string)
    // if(business && !business?.completionStatus){
    //   localStorage.setItem("onboard1", "done")
    //   localStorage.setItem("onboard2", "done")
    //   setStep(3)
    //   return
    // }
    console.log(bankVerified)
    if(step >= 1 && selectedPlatforms.length > 0){
      setStep(2)
      setErrorMsg("")
      localStorage.setItem("onboard1", "done")
      if(
        (step >= 2) &&
        (businessName.value && businessName.value.length >= 3) &&
        (supportEmail.value && supportEmail.value.length >= 3) &&
        (industry.value && industry.value.length >= 3) &&
        (phoneNumber.value && phoneNumber.value.length >= 3) &&
        (businessDescription.value && businessDescription.value.length >= 3)
      ){
        setStep(3)
        setErrorMsg("")
        localStorage.setItem("onboard2", "done")
        if(
          (step >= 3) &&
          // (bankName.value && bankName.value.length > 0) &&
          // (accountName.value && accountName.value.length >= 3) &&
          // (accountNumber.value && accountNumber.value.length >= 3) &&
          (ownerFullName.value && ownerFullName.value.length >= 3) &&
          (businessPhoneNumber.value && businessPhoneNumber.value.length >= 3) &&
          (businessAddress.value && businessAddress.value.length >= 3)
        ){
          // if(!bankVerified){
          //   setErrorMsg("Incorrect bank details")
          // }else{
            setStep(4)
            setErrorMsg("")
            localStorage.setItem("onboard3", "done")
          // }
        }else{
          validate()
        }
      }else{
        validate()
      }
    }else{
      validate()
    }
  };

  const validate = () => {
    if (step === 1 && selectedPlatforms.length <= 0) {
      setErrorMsg("Please, select at-least one platform to continue");
      return;
    }

    if(step === 2){
      if(!businessName.value || businessName.value.length < 3){
        setErrorMsg("Invalid business name")
        return
      }
      if(!supportEmail.value || supportEmail.value.length < 3){
        setErrorMsg("Invalid support email")
        return
      }
      if(!industry.value || industry.value.length < 3){
        setErrorMsg("Select industry")
        return
      }
      if(!phoneNumber.value || phoneNumber.value.length < 3){
        setErrorMsg("Invalid phone number")
        return
      }
      if(!businessDescription.value || businessDescription.value.length < 3){
        setErrorMsg("Invalid business description")
        return
      }
    }
    
    if(step === 3){
      // if(!bankName.value && bankName.value.length < 3){
      //   setErrorMsg("Invalid bank name")
      //   return
      // }
      // if(!accountName.value && accountName.value.length < 3){
      //   setErrorMsg("Invalid account name")
      //   return
      // }
      // if(!accountNumber.value && accountNumber.value.length < 3){
      //   setErrorMsg("Invalid account number")
      //   return
      // }
      if(!ownerFullName.value && ownerFullName.value.length < 3){
        setErrorMsg("Invalid full name")
        return
      }
      if(!businessPhoneNumber.value && businessPhoneNumber.value.length < 3){
        setErrorMsg("Invalid business phone number")
        return
      }
      if(!businessAddress.value && businessAddress.value.length < 3){
        setErrorMsg("Invalid business address")
        return
      }
    }

    setTimeout(() => {
      setErrorMsg("")
    }, 5000)
  }

  const validateEmail = (email: string) => {
    if (!email.includes("@") || !email.includes(".")) {
      return "invalid email address";
    }
    return null;
  };

  const validateInputField = (value: string) => {
    if (value.length <= 0) {
      return "Enter valid input value";
    }
    return null;
  };

  const customSelectStyles = {
    control: (provided: any, state: any) => ({
      ...provided,
      height: "50px",
      // border: "1px solid #f0f2f7",
      borderRadius: "6px",
      border: state.isFocused
        ? "1px solid #5100FF"
        : industry.error
        ? "1px solid red"
        : "1px solid #f0f2f7",
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        borderColor: "#f0f2f7",
      },
    }),
  };

  const makePayment = async (e: FormEvent, plan: string) => {
    let token = getCookie("Authorization")
    setPaymentLoading(true)
    e.preventDefault()
    setPlan(plan)
    let amount: number = 0
    if(plan === "annually"){
      amount = 120000
    }

    if(plan === "monthly"){
      amount = 35000
    }

    if(plan === "quarterly"){
      amount = 35000
    }
    console.log(amount)
    instance.post(`/api/plan/pay`,
    {
      plan: plan,
      amount: amount
    },
    {
      headers: {
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'Authorization': `Bearer ${token}`
      }
    })
    .then((res) => {
      console.log(res.data)
      if(res.data && res.data.message === "You still have valid payment ongoing"){
        instance.post(`/api/plan/pay/verify/${res.data.data.reference}`, {}, 
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
            console.log(res.data);
            showToast("primary", "Payment Successful!", "Your payment was successful. Thank you.")
            setPaymentLoading((prev) => false)
            submitHandler()
            // localStorage.setItem('tokens', JSON.stringify(res.data))
        }).catch((err) => {
          if (err instanceof AxiosError) {
            if (err.response?.status === 400) {
              const message = err.response.data?.message;
              console.log(message)
              setPaymentLoading((prev) => false)
              showToast("secondary", "Payment Declined!", "Your payment has been declined. Please try again!")
              
            }
          }
          setPaymentLoading((prev) => false)
        })
      }else{
        paymentRef.current = window.open(res.data.data.authorization_url, 'Precise Payment', 'width=390,height=570,left=450,top=100,toolbar=1,status=1,');
      }
    })
    .catch((e) => {
      setPaymentLoading(false)
      console.log(e)
    });
  }

  const submitHandler = async (e?: FormEvent) => {
    let token = getCookie("Authorization")
    e?.preventDefault()
    let selectedPlat = localStorage.getItem("selectedPlatforms")
    let savedBusinessName = localStorage.getItem("businessName")
    let savedEmail = localStorage.getItem("email")
    let savedIndustry = localStorage.getItem("industry")
    let savedPhoneNumber = localStorage.getItem("phoneNumber")
    let savedReferral = localStorage.getItem("referral")
    let savedBusinessDescription = localStorage.getItem("businessDescription")
    const userData = JSON.parse(localStorage.getItem("user") as string);
    const requestBody = {
      businessName: savedBusinessName,
      industry: savedIndustry,
      phoneNo: savedPhoneNumber, 
      supportEmail: savedEmail,
      refereeCode:savedReferral,
      description: savedBusinessDescription,
      completionStatus: false,
      companyInformation: {
        selectPlatforms: selectedPlat
      },
    };
    console.log(requestBody);
    // const formData = new FormData();
    // formData.append("userId", userData.userId);
    // formData.append("businessName", businessName.value);
    // formData.append("industry", industry.value);
    // formData.append("description", businessDescription.value);
    // formData.append("address", businessAddress.value);
    // formData.append("bankName", bankName.value);
    // formData.append("accountNo", "0150697308");
    // formData.append(
    //   "companyInformation",
    //   JSON.stringify({
    //     businessDescription: businessDescription.value,
    //     businessAddress: businessAddress.value,
    //     industry: industry.value,
    //     productTitle: productTitle.value,
    //   })
    // );

    // console.log(data, userData);
    try {
      const response: AxiosResponse = await instance.post(
        "api/business/create",
        { ...requestBody },
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log(response);
      const businessData: { businessId: string } = response.data;
      userData.businessId = businessData.businessId;
      if(businessData.businessId){
        localStorage.removeItem("user")
        localStorage.setItem("user", JSON.stringify(userData));
        localStorage.setItem("business", JSON.stringify(response.data));
        handleContinueClick()
        // localStorage.removeItem("selectedPlatforms")
        // localStorage.removeItem("businessName")
        // localStorage.removeItem("email")
        // localStorage.removeItem("industry")
        // localStorage.removeItem("phoneNumber")
        // localStorage.removeItem("businessDescription")
        // localStorage.removeItem("bankName")
        // localStorage.removeItem("accountName")
        // localStorage.removeItem("accountNumber")
        // localStorage.removeItem("ownersFullName")
        // localStorage.removeItem("businessPhoneNumber")
        // localStorage.removeItem("businessAddress")
        // localStorage.removeItem("plan")
        // localStorage.setItem("mainpage", "item5")
        // localStorage.removeItem("onboard1")
        // localStorage.removeItem("onboard2")
        // localStorage.removeItem("onboard3")
        setErrorMsg("")

        showToast("primary", "Success!", "Business created successfully")
        // window.location.reload()
        // return navigate(`/`);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        // if (error.response?.status === 400) {
        //   const message = error.response.data?.message;
        //   setFormInputs((prev) => ({
        //     ...prev,
        //     error: message,
        //   }));
        // }
        if (error.response?.status) {
          // const message = error.response.data?.message;
          const message = error.response.data?.message;
          console.log(message)
          if(message === "Business already exists"){
            showToast("secondary", "Error!", message)
            setErrorMsg(message)
            setStep(2)
            localStorage.removeItem("onboard2")
            localStorage.removeItem("onboard3")
            localStorage.removeItem("onboard4")
          }
          // setFormInputs((prev) => ({
          //   ...prev,
          //   error: message,
          // }));
          // setError(message);
        }else{
          showToast("secondary", "Error!", error.message)
        }
      }
      console.log(error);
    }
  };

  const updateHandler = async (e?: FormEvent) => {
    e?.preventDefault();
    console.log("ddd")
    let savedBankName = JSON.parse(localStorage.getItem("bankName") as string)
    let savedAccountName = localStorage.getItem("accountName")
    let savedAccountNumber = localStorage.getItem("accountNumber")
    let savedOwnersFullName = localStorage.getItem("ownersFullName")
    let savedBusinessPhoneNumber = localStorage.getItem("businessPhoneNumber")
    let savedBusinessAddress = localStorage.getItem("businessAddress")
    // let savedPlan = localStorage.getItem("plan")
    const userData = JSON.parse(localStorage.getItem("user") as string);
    const businessData = JSON.parse(localStorage.getItem("business") as string);
    const requestBody = {
      userId: userData.userId,
      businessId: businessData.businessId,
      address: savedBusinessAddress,
      bankName: savedBankName?.label,
      accountNo: savedAccountNumber,
      accountName: savedAccountName,
      ownerName: savedOwnersFullName,
      ownerPhoneNo: savedBusinessPhoneNumber
    };
    console.log(requestBody);
    const token = getCookie("Authorization")
    // console.log(data, userData);
    // return navigate(`/training-center/faq`);
    try {
      const response: AxiosResponse = await instance.post(
        "api/business/update",
        { ...requestBody },
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        },
        
      );
      console.log(response.data);
      const businessData = response.data;
      localStorage.setItem("business", JSON.stringify(response.data));
      userData.businessId = businessData.businessId;
      localStorage.setItem("user", JSON.stringify(userData));
      // setStep(4)
      handleContinueClick()
      // showToast("primary", "Success!", "You have successfully updated your profile.")
    } catch (error) {
      if (error instanceof AxiosError) {
        // if (error.response?.status === 400) {
        //   const message = error.response.data?.message;
        //   setFormInputs((prev) => ({
        //     ...prev,
        //     error: message,
        //   }));
        // }
        if (error.response?.status) {
          const message = error.response.data?.message;
          console.log(message)
          showToast("secondary", "Error!", message)
          // setFormInputs((prev) => ({
          //   ...prev,
          //   error: message,
          // }));
          // setError(message);
        }
      }
      console.log(error);
    }
  };

  const completeHandler = async (e?: FormEvent) => {
    e?.preventDefault();
    // let savedPlan = localStorage.getItem("plan")
    const userData = JSON.parse(localStorage.getItem("user")!);
    const requestBody = {
      userId: userData.userId,
      businessId: userData.businessId,
      completionStatus: true,
    };
    console.log(requestBody);

    // console.log(data, userData);
    // return navigate(`/training-center/faq`);
    try {
      const response: AxiosResponse = await instance.post(
        "api/business/update",
        { ...requestBody }
      );
      console.log(response.data);
      const businessData = response.data;
      localStorage.setItem("business", JSON.stringify(response.data));
      userData.businessId = businessData.businessId;
      localStorage.setItem("user", JSON.stringify(userData));
      localStorage.removeItem("selectedPlatforms")
      localStorage.removeItem("businessName")
      localStorage.removeItem("email")
      localStorage.removeItem("industry")
      localStorage.removeItem("phoneNumber")
      localStorage.removeItem("referral")
      localStorage.removeItem("businessDescription")
      localStorage.removeItem("bankName")
      localStorage.removeItem("accountName")
      localStorage.removeItem("accountNumber")
      localStorage.removeItem("ownersFullName")
      localStorage.removeItem("businessPhoneNumber")
      localStorage.removeItem("businessAddress")
      localStorage.removeItem("plan")
      localStorage.setItem("mainpage", "item5")
      localStorage.removeItem("onboard1")
      localStorage.removeItem("onboard2")
      localStorage.removeItem("onboard3")
      showToast("primary", "Success!", "You have successfully completed you onbording")
      window.location.reload()
      // return navigate(`/`);
    } catch (error) {
      if (error instanceof AxiosError) {
        // if (error.response?.status === 400) {
        //   const message = error.response.data?.message;
        //   setFormInputs((prev) => ({
        //     ...prev,
        //     error: message,
        //   }));
        // }
        if (error.response?.status) {
          const message = error.response.data?.message;
          console.log(message)
          showToast("secondary", "Error!", message)
          // setFormInputs((prev) => ({
          //   ...prev,
          //   error: message,
          // }));
          // setError(message);
        }
      }
      console.log(error);
    }
  };

  const changePlan = (plan: string) => {
    setPlan(plan)
    localStorage.setItem("plan", plan)
  }

  const uploadBusinessDoc = () => {
    const input = document.createElement("input");
    input.accept = ".pdf,.png,.jpeg,.jpg";
    input.id = "inventory";
    input.name = "inventory";
    input.type = "file";
    input.onchange = (ev) => handleUploadBusinessDoc(ev);
    input.hidden = true;
    input.click()
  }

  const handleUploadBusinessDoc = (ev: any) => {
    if(ev.target.files[0] !== undefined){
      let file = ev.target.files[0]
      setFile(file)
    }
  }

  const removeFile = () => {
    setFile(null);
  };

  const getBanks = () => {
    instance.get(`/api/business/get-banks`)
    .then((res) => {
      let banksA = []
      for(let i=0; i<res.data.length; i++){
        let bank = res.data[i]
        bank["value"] = bank["code"]
        bank["label"] = bank["name"]
        banksA.push(bank)
      }
      console.log(banks)
      setBanks(banksA);
      
    })
    .catch((e) => {
      setPaymentLoading(false)
      console.log(e)
    });
  }

  const verifyAccountNumber = () => {
    setBankVerified(false)
    setAccountName((prev) => ({
      ...prev,
      value: "Loading...",
    }))
    if(!bankName?.value && !accountNumber?.value){
      setBankVerified(false)
    }else{
      instance.post(`/api/business/verify-account`,
      {
        account_number: accountNumber.value,
        bank_code: bankName.value.split("-")[1]
      })
      .then((res) => {
        setAccountName((prev) => ({
          ...prev,
          value: res.data.data.account_name,
        }))
        setBankVerified(true)
        localStorage.setItem("accountName", res.data.data.account_name)
        
      })
      .catch((error) => {
        setBankVerified(false)
        setAccountName((prev) => ({
          ...prev,
          value: "",
        }))
        if (error instanceof AxiosError) {
          console.log(error)
          if (error.response) {
            if(error?.response.data.message === "Could not resolve account name. Check parameters or try again."){
              showToast("secondary", "Error!", "Account details not verified. Enter valid information.")
            }
            if(error?.response.data.message === "Unknown bank code: undefined"){
              showToast("secondary", "Error!", "Account details not verified. Enter valid information.")
            }
            if(error?.response.data.message === "account_number is required"){
              showToast("secondary", "Error!", "account number is required")
            }
          }
        }
      });
    }
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <p
        className={`${styles["error-message"]} ${
          generalError && styles["active"]
        }`}
      >
        {generalError}
      </p>
      <section
        className={`${step === 4 && styles["background-color-change"]} ${
          styles["wrapper"]
        }`}
      >
        {errorMsg.length > 0 && <p id={styles["errorMsg"]}>{errorMsg}</p>}
        <header>
          {step === 1 && <p>Link business to your desired platform</p>}
          {step === 2 && <p>Complete business information</p>}
          {step === 3 && <p>Verify business information</p>}
          {step === 4 && <p>Choose your premium</p>}

          {step === 1 && <StepOneSVG style={styles["step-one-svg"]} />}
          {step === 2 && <StepTwoSVG style={styles["step-one-svg"]} />}
          {step === 3 && <StepThreeSVG style={styles["step-one-svg"]} />}
          {step === 4 && <StepFourSVG style={styles["step-one-svg"]} />}
        </header>
        {step === 1 && (
          <p>Select all the platforms your business is currently active on.</p>
        )}
        {step === 4 && (
          <p>Unlock the Full Potential of Enif with our Simple Plans</p>
        )}

        {step === 1 && (
          <main className={styles["socials"]}>
            <section className={styles["socials-wrapper"]}>
              <div onClick={() => selectPlatform("instagram")} className={styles["social-buttons"]}>
                <SocialsInstaSVG style={styles["social-buttons-svg"]} />
                Instagram
                <input
                  type="checkbox"
                  checked= {selectedPlatforms.includes('instagram')}
                />
              </div>
              <div onClick={() => selectPlatform("gmail")} className={styles["social-buttons"]}>
                <SocialsGmailSVG style={styles["social-buttons-svg"]} />
                Gmail
                <input
                  type="checkbox"
                  checked= {selectedPlatforms.includes('gmail')}
                />
              </div>
              <div onClick={() => selectPlatform("whatsapp")} className={styles["social-buttons"]}>
                <SocialsWhatsappSVG style={styles["social-buttons-svg"]} />
                Whatsapp
                <input
                  type="checkbox"
                  checked= {selectedPlatforms.includes('whatsapp')}
                />
              </div>
              <div onClick={() => selectPlatform("telegram")} className={styles["social-buttons"]}>
                <SocialsTelegramSVG style={styles["social-buttons-svg"]} />
                Telegram{" "}
                <input
                  type="checkbox"
                  checked= {selectedPlatforms.includes('telegram')}
                />
              </div>
              <div onClick={() => selectPlatform("facebook")} className={styles["social-buttons"]}>
                <SocialsFacebookSVG style={styles["social-buttons-svg"]} />
                Facebook
                <input
                  type="checkbox"
                  checked= {selectedPlatforms.includes('facebook')}
                />
              </div>
              <div onClick={() => selectPlatform("twitter")} className={styles["social-buttons"]}>
                <SocialsTwitterSVG style={styles["social-buttons-svg"]} />
                Twitter
                <input
                  type="checkbox"
                  checked= {selectedPlatforms.includes('twitter')}
                />
              </div>
            </section>
          </main>
        )}
        {step === 2 && (
          <main className={styles["form-wrapper"]}>
            <section className={styles["form-row"]}>
              <div>
                <label
                  htmlFor="businessName"
                  className={`${businessName.error && styles["error-label"]}`}
                >
                  Business Name
                </label>
                <input
                  id="businessName"
                  type="text"
                  value={businessName.value}
                  className={`${businessName.error && styles["error-input"]} ${
                    businessName.error && styles["error"]
                  }`}
                  onBlur={() => {
                    setBusinessName((prev) => ({
                      ...prev,
                      isTouched: false,
                      error: validateInputField(prev.value),
                    }))
                    }
                  }
                  onChange={(e) =>{
                    setBusinessName((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("businessName", e.target.value)
                    }
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="supportEmail"
                  className={`${supportEmail.error && styles["error-label"]}`}
                >
                  Support Email
                </label>
                <input
                  id="supportEmail"
                  type="email"
                  value={supportEmail.value}
                  className={`${supportEmail.error && styles["error-input"]}`}
                  onBlur={() => {
                    setSupportEmail((prev) => ({
                      ...prev,
                      error: validateEmail(prev.value),
                    }))
                    }
                  }
                  onChange={(e) =>{
                    setSupportEmail((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("email", e.target.value)
                    }
                  }
                />
              </div>
            </section>
            <section className={styles["form-row"]}>
              {/* <div>
                <label htmlFor="industry">Industry</label>
                <input
                  type="text"
                  value={industry.value}
                  className={`${industry.error && styles["error"]}`}
                  onChange={(e) =>
                    setBusinessName((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                  id="industry"
                />
              </div> */}
              <div>
                <label
                  htmlFor="industry"
                  className={`${industry.error && styles["error-label"]}`}
                >
                  Industry
                </label>
                {/* <input
                  type="text"
                  value={industry.value}
                  className={`${industry.error && styles["error"]}`}
                  onChange={(e) =>
                    setBusinessName((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                  id="industry"
                /> */}
                <Select
                  styles={customSelectStyles}
                  id="industry"
                  isClearable={true}
                  options={industries}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setIndustry((prev) => ({
                        ...prev,
                        value: selectedOption.label,
                      }));
                      localStorage.setItem("industry", selectedOption.label)
                    }
                  }}
                  onBlur={() =>{
                    setIndustry((prev) => ({
                      ...prev,
                      error: validateInputField(prev.value),
                    }))
                    }
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="phoneNumber"
                  className={`${phoneNumber.error && styles["error-label"]}`}
                >
                  Phone Number
                </label>
                <input
                  id="phoneNumber"
                  type="tel"
                  value={phoneNumber.value}
                  className={`${phoneNumber.error && styles["error-input"]}`}
                  onBlur={() =>{
                    setPhoneNumber((prev) => ({
                      ...prev,
                      error: validateInputField(prev.value),
                    }))
                    }
                  }
                  onChange={(e) =>{
                    setPhoneNumber((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("phoneNumber", e.target.value)
                    }
                  }
                />
              </div>
             
            </section>
            <div className={styles["form-row"]}>
                <label
                  htmlFor="refereeCode"
                >
                 Referral Code {" "}
                 <em>(optional)</em>
                </label>
                <input
                  id="refereeCode"
                  type="text"
                  value={referral.value}
                 
                  onBlur={() => {
                    setReferral((prev) => ({
                      ...prev,
                      isTouched: false,
                      error: validateInputField(prev.value),
                    }))
                    }
                  }
                  onChange={(e) =>{
                    setReferral((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("referral", e.target.value)
                    }
                  }
                />
              </div>
            <section className={styles["form-row-textarea"]}>
              <label
                htmlFor="businessDescription"
                className={`${
                  businessDescription.error && styles["error-label"]
                }`}
              >
                Business Description
              </label>
              <textarea
                id="businessDescription"
                value={businessDescription.value}
                rows={4}
                className={`${
                  businessDescription.error && styles["error-input"]
                }`}
                onBlur={() =>{
                  setBusinessDescription((prev) => ({
                    ...prev,
                    error: validateInputField(prev.value),
                  }))
                  }
                }
                onChange={(e) =>{
                  setBusinessDescription((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }))
                  localStorage.setItem("businessDescription", e.target.value)
                  }
                }
              />
            </section>
          </main>
        )}
        {step === 3 && (
          <main className={styles["form-wrapper"]}>
            <section className={styles["form-row"]}>
              <div>
                <label htmlFor="bankName">Bank Name</label>
                {/* <input
                  id="bankName"
                  type="text"
                  value={bankName.value}
                  className={`${bankName.error && styles["error"]}`}
                  onChange={(e) =>{
                    setBankName((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("bankName", e.target.value)
                    }
                  }
                /> */}

                <Select
                  styles={customSelectStyles}
                  id="bankName"
                  defaultValue={bankName?.value.length > 0 && JSON.parse(localStorage.getItem("bankName") as string)}
                  options={banks}
                  isClearable={true}
                  isSearchable
                  onChange={(selectedOption:any) => {
                    if (selectedOption) {
                      setBankName((prev) => ({
                        ...prev,
                        value: `${selectedOption.label}-${selectedOption.value}`,
                      }));
                      console.log(selectedOption)
                      localStorage.setItem("bankName", JSON.stringify(selectedOption))
                    }
                  }}
                  onBlur={() =>{
                    setBankName((prev) => ({
                      ...prev,
                      error: validateInputField(prev.value),
                    }))
                    verifyAccountNumber()
                    }
                  }
                />
              </div>
              <div>
                <label htmlFor="accountName">Account Name</label>
                <input
                  id="accountName"
                  type="text"
                  contentEditable={false}
                  value={accountName.value}
                  className={`${accountName.error && styles["error"]}`}
                  // onChange={(e) =>{
                  //   setAccountName((prev) => ({
                  //     ...prev,
                  //     value: e.target.value,
                  //   }))
                  //   localStorage.setItem("accountName", e.target.value)
                  //   }
                  // }
                />
              </div>
            </section>
            <section className={styles["form-row"]}>
              <div>
                <label htmlFor="accountNumber">Account Number</label>
                <input
                  id="accountNumber"
                  type="text"
                  value={accountNumber.value}
                  className={`${accountNumber.error && styles["error"]}`}
                  onChange={(e) =>{
                    setAccountNumber((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("accountNumber", e.target.value)
                    }
                  }
                  onBlur={() => {
                    verifyAccountNumber()
                  }}
                />
              </div>
              <div>
                <label >
                  Valid Business Document (Optional)
                </label>
                {file ? (
                  <div className={styles["file-name"]}>
                    <FileIcon2 style={styles["file-icon"]} />
                    <p>{(file as unknown as File)?.name}</p>
                    <button onClick={removeFile}>X</button>
                  </div>
                  ):
                  <button onClick={uploadBusinessDoc}>
                  <FileIcon2 style={styles["fileIcon"]} />
                </button>
                }
              </div>
            </section>
            <div className={styles["icon-divider"]}>
              <UrgencyIcon style={styles["urgency-icon"]} />
              <p>
                Providing a business's account details will expedite your approval.
              </p>
            </div>
            <section className={styles["form-row"]}>
              <div>
                <label htmlFor="ownersFullName">Owner's full name</label>
                <input
                  id="ownersFullName"
                  type="text"
                  value={ownerFullName.value}
                  className={`${ownerFullName.error && styles["error"]}`}
                  onChange={(e) =>{
                    setOwnerFullName((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("ownersFullName", e.target.value)
                    }
                  }
                  
                />
              </div>
              <div>
                <label htmlFor="businessPhoneNumber">Phone No</label>
                <input
                  id="businessPhoneNumber"
                  type="text"
                  value={businessPhoneNumber.value}
                  className={`${businessPhoneNumber.error && styles["error"]}`}
                  onChange={(e) =>{
                    setBusinessPhoneNumber((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("businessPhoneNumber", e.target.value)
                    }
                  }
                />
              </div>
            </section>
            <section className={styles["form-row"]}>
              <div>
                <label htmlFor="businessAddress">
                  Business Address (Optional)
                </label>
                <input
                  id="businessAddress"
                  type="text"
                  value={businessAddress.value}
                  className={`${businessAddress.error && styles["error"]}`}
                  onChange={(e) => {
                    setBusinessAddress((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                    localStorage.setItem("businessAddress", e.target.value)
                    }
                  }
                  placeholder="75, Olonode street, Alagomeji Yaba, Lagos"
                />
              </div>
            </section>
          </main>
        )}
        {step === 4 && (
          <main className={styles["plans"]}>
            <section onClick={() => changePlan('monthly')}
              style={plan=== 'monthly' ? { borderColor: "#5101ff"}: {}}
            >
              <header>
                <PlanMonthlyStarSVG style={styles["plan-star"]} />
                <h2>Monthly</h2>
              </header>
              <main>
                <div className={styles["plan-amount"]}>
                  {/* <p>₦35,000.00</p> */}
                </div>
                <ul>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Automated customer response</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    Sell even when offline
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Automate multi-channel sales.</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>30-day money-back guarantee</p>
                  </li>
                </ul>
                <div className={styles["plan-button-div"]}>
                  <button style={(paymentLoading && plan=== 'monthly') ? {backgroundColor: "#5100ff", border: "none"} : {}} 
                  // onClick={(e) => !paymentLoading && makePayment(e, "monthly")}
                  >
                    {
                      !(paymentLoading && plan=== 'monthly')
                      ? 
                      "Get Started"
                      :
                      <div>
                        <ReactLoading type={"spin"} color={"rgb(255 255 255 / 60%)"} height={'16px'} width={'16px'} />
                      </div>
                    }
                  </button>
                </div>
              </main>
            </section>
            <section onClick={() => changePlan('quarterly')}
            style={plan=== 'quarterly' ? { borderColor:"#1bd370"}: {}}
            >
              <header>
                <PlanQuarterlyStarSVG style={styles["plan-star"]} />
                <h2>Quarterly</h2>
              </header>
              <main>
                <div className={styles["plan-amount"]}>
                  {/* <p>₦35,000.00</p> */}
                </div>
                <ul>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Core automation features</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Ideal for individuals and small teams</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Automate multi-channel sales.</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Full sales reports</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>100% money-back guarantee</p>
                  </li>
                </ul>
                <div className={styles["plan-button-div"]}>
                  <button style={(paymentLoading && plan=== 'quarterly') ? {backgroundColor: "#1cd370", border: "none"} : {}} 
                  // onClick={(e) => !paymentLoading && makePayment(e, "quarterly")}
                  >
                    {
                      !(paymentLoading && plan=== 'quarterly')
                      ? 
                      "Get Started"
                      :
                      <div>
                        <ReactLoading type={"spin"} color={"rgb(255 255 255 / 60%)"} height={'16px'} width={'16px'} />
                      </div>
                    }
                  </button>
                </div>
              </main>
            </section>
            <section onClick={() => changePlan('annually')}
            style={plan=== 'annually' ? { borderColor:"#e27612"} : {}}
            >
              <header>
                <PlanAnnualyStarSVG style={styles["plan-star"]} />
                <h2>Annually</h2> <p className={styles["badge"]}>50% off</p>
              </header>
              <main>
                <div className={styles["plan-amount"]}>
                  {/* <p>
                    ₦120,000 <span>₦240,000</span>
                  </p> */}
                </div>
                <ul>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Automated inventory tracking</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Automated customer responses</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Automate multi-channel sales.</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>WhatsApp sale notifications</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>Full sales reports</p>
                  </li>
                  <li>
                    <PlanCheckSVG style={styles["plan-check"]} />
                    <p>100% money-back guarantee</p>
                  </li>
                </ul>
                <div className={styles["plan-button-div"]}>
                  <button style={(paymentLoading && plan=== 'annually') ? {backgroundColor: "#E17817", border: "none"} : {}} className={`${styles["payment_loading"]}`} 
                  // onClick={(e) => !paymentLoading && makePayment(e, "annually")}
                  >
                    {
                      !(paymentLoading && plan === 'annually')
                      ? 
                      "Get Started"
                      :
                      <div>
                        <ReactLoading type={"spin"} color={"rgb(255 255 255 / 60%)"} height={'16px'} width={'16px'} />
                      </div>
                    }
                  </button>
                </div>
              </main>
            </section>
          </main>
        )}
        <div
          className={`${step === 4 && styles["button-wrapper-extra"]} ${
            styles["button-wrapper"]
          }`}
        >
          {step === 1 && selectedPlatforms.length > 0 && (
            <p>{selectedPlatforms.length} selected</p>
          )}
          
          {(step === 1) && (
            <button onClick={handleContinueClick}>
              Continue
            </button>
          )}

          {step === 2 && (
            <button onClick={submitHandler}>Continue</button>
          )}

          {step === 3 && (
            <button onClick={updateHandler}>Continue</button>
          )}

          {step === 4 && (
            <button onClick={completeHandler}>Activate my 14 days free trial</button>
          )}
        </div>
      </section>
    </Modal>
  );
};
export default OnbardingLinkBusinessModal;
