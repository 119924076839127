import React from "react";
import styles from "./Error.module.css";
import { useNavigate } from "react-router-dom";

export default function Error() {
  const navigate = useNavigate();

  const handleGoBack = () => {
    return navigate(-1);
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.center}>
        <h2 className={styles.status}>Oops!</h2>
        <div className={styles.message}>404 - PAGE NOT FOUND</div>
        <input type="button" onClick={handleGoBack} value="GO BACK" />
      </div>
    </div>
  );
}
