import React, { useEffect, useState } from "react";
import styles from "./GmailConversation.module.css";
import CheckIcon from "../../assets/images/conversation_checkbox.svg";
import { CalenderIcon, CollapseIcon, ReceiveIcon } from "../../assets/icons";
import { Link } from "react-router-dom";
import { formatDateToOrdinal2 } from "../../utilities/helpers";

interface Props {
  customer: string;
  date: Date;
  gmailId: string;
  handleConversationClicked: (gmailId: string) => void;
  selected: boolean;
  read: boolean;
  email: any;
  ticket: any;
}

const GmailConversation: React.FC<Props> = (props) => {
  const [isRead, setIsRead] = useState(false);
  const handleClick = () => {
    props.handleConversationClicked(props.ticket._id);
  };

  useEffect(() => {
    setIsRead(props.read)
  }, [props.read])

  return (
    <li
      className={`${styles["list-item"]} ${
        props.selected && styles["selected"]
      }`}
      onClick={handleClick}
    >
        <Link onClick={() => localStorage.setItem("selectedEmail", JSON.stringify(props.ticket))} to={`/thread/${props.ticket._id}`} >
      <div className={styles.checkIcons}>
        {props.selected ?
        <div className={styles.checkIcon1}>
          <img src={CheckIcon} alt="check" />
        </div>:
        <div className={styles.checkIcon2}>
          <ReceiveIcon style={styles.receiveIcon} />
          <div className={styles.count}>{props.ticket.emailCount}</div>
        </div>
        }
      </div>
      
      <div className={styles["message-text"]}>
        <h3 style={{fontFamily: isRead ? "Axiforma Light": "Axiforma"}}>{props.ticket?.subject ?? props.ticket?.title}</h3>
        <div className={styles["message-text_bottom"]}>
          <h6 style={{fontFamily: isRead ? "Axiforma Light": "Axiforma"}}>{props.customer}</h6>
          <span className={styles["message-date"]}>
            <CalenderIcon style={styles["calenderIcon"]} />
            <h6>
              {props.ticket?.createdAt && formatDateToOrdinal2(props.ticket?.createdAt)}
            </h6>
          </span>
        </div>
      </div>
      </Link>

      {/* <div className={styles["toggle_escalated"]}>
        {props.ticket?.escalated ? <p className={styles["escalated"]}>Open</p> : <p className={styles["not_escalated"]}>Closed</p>}
        <CollapseIcon style={styles["icon"]} />
      </div> */}
      <div 
      style={{
        backgroundColor: props.ticket?.closed ? "#EEE5FF" : props.ticket?.escalated ? "#FFDDF5" : "#F0F2F7",
        border: props.ticket?.closed ? "0.5px solid #DCCCFF" : props.ticket?.escalated ? "0.5px solid #F8D2ED" : "0.5px solid #DFE1E5",
      }} 
      className={styles["toggle_escalated"]}>
        <p className={styles["escalated"]}>{props.ticket?.escalated ? "Escalated" : props.ticket?.closed ? "Closed" : "AI Closed"}</p>
        {/* <CollapseIcon style={styles["icon"] } /> */}
      </div>
      {/* <span className={styles["message-date"]}>Aug 04</span> */}
    </li>
  );
};

export default GmailConversation;
