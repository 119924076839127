import { FC, useContext, useEffect, useState } from "react";
import styles from "../SideBar.module.css";
import childstyles from "./childside.module.css";
import {
  ActiveUserIcon,
  AdminIcon,
  AgentIcon,
  AllChatStrokecon,
  AnalysisIcon,
  ArrowLeftIcon,
  ArrowRightIcon2,
  BellStrokeIcon,
  BoltIcon,
  CalenderIcon,
  ChannelIcon,
  CheckedCircleStrokeIcon,
  ClosedIcon,
  CollapseIcon,
  ConversationIconStroke,
  CustomerEngagementIconStroke,
  CustomerIcon,
  CustomerMrrStrokeIcon,
  DepartmentsIcon,
  DocsIcon,
  EmailStrokeIcon,
  EscalatedChatIcon,
  FilterIconStroke,
  InVentoryIcon,
  InstagramStrokeIcon,
  IntegrateIconStroke,
  MailStrokeIcon,
  MenuStrokeIcon,
  MenuStrokeIcon2,
  MessagesIcon,
  ModeSwitchIcon,
  PerformanceIcon,
  PhoneIcon2,
  PieChartIcon,
  QuestionIcon,
  ReadStrokeIcon,
  RefreshIcon,
  ResolvedIcon2,
  SalesPilotIconStroke,
  SocialsTelegramSVG,
  TeamsIcon,
  TelegramIcon,
  TwitterStrokeIcon,
  UnreadChatIStrokecon,
  UserStrokeIcon,
  UserStrokeIcon3,
  VerifiedUserStrokeIcon,
  WhatsppStrokeIcon,
} from "../../../assets/icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Telegram } from "@mui/icons-material";
import { Chart as ChartJS, ArcElement } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import instance from "../../../api";
import {
  calculateDuration,
  formatDateToOrdinal2,
  getCookie,
  showToast,
} from "../../../utilities/helpers";
import { AxiosError } from "axios";
import Logo from "../../../assets/images/enif_logo.svg";
import CommentIcon from "../../../assets/images/conversation_uncomment.svg";
import StarIcon from "../../../assets/images/conversation_star.svg";
import ConversationChildSide from "./conversationChildSide/ConversationChildSide";
import { EnifContext } from "../../../utilities/context";

ChartJS.register(ArcElement);

interface PropType {
  getSelectedMainSideBarMenu: (item: string) => void;
  selectedMainNavItem: null | string;
  showSideBar: boolean;
  toggleSidebar: () => void;
  analysis: any;
  businessId: any;
  businessApproved: boolean;
}

const ChildSide: FC<PropType> = ({
  getSelectedMainSideBarMenu,
  selectedMainNavItem,
  showSideBar,
  toggleSidebar,
  analysis,
  businessId,
  businessApproved,
}) => {
  const router = useLocation();
  const navigate = useNavigate();
  const [dropIndex, setDropIndex] = useState<number>();
  const [step, setStep] = useState<number>(1);
  const [nextStep, setNextStep] = useState<string>("Link Your Business");
  const [currentStepPercent, setCurrentStepPercent] = useState<number>(10);
  const [departments, setDepartments] = useState([]);
  const [emails, setEmails] = useState<Array<any>>([]);
  const [selectedChat, setSelectedChat] = useState<any>();
  const [comments, setComments] = useState<Array<any>>();
  // const [team, setTeam] = useState<any>()
  const [totalActive, setTotalActive] = useState(0);
  const [totalResolved, setTotalResolved] = useState(0);
  const [totalRead, setTotalRead] = useState(0);
  const [totalUnRead, setTotalUnRead] = useState(0);
  const [totalEscalated, setTotalEscalated] = useState(0);
  const [totalClosed, setTotalClosed] = useState(0);
  const [selectFilter, setSelectFilter] = useState(false);
  const [filterEmail, setFilterEmail] = useState("All tickets");
  const [totalMails, setTotalMails] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [totalRead, setTotalRead] = useState(0)
  // const [totalUnread, setTotalUnread] = useState(0)
  let descriptiveHeader;
  let { threadId, conversations } = useParams();
  const { team } = useContext(EnifContext);

  useEffect(() => {
    localStorage.removeItem("onboard1");
    localStorage.removeItem("onboard2");
    localStorage.removeItem("onboard3");
    localStorage.removeItem("onboard4");
    const token = getCookie("Authorization");
    if (businessId && businessApproved) {
      setStep(4);
      instance
        .get(`api/business/get/${businessId}`, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data.business);
          // setDepartments(res.data.business.departments)
          if (res.data.knowledgeBase) {
            if (
              res.data.knowledgeBase.faqs?.length > 0 &&
              res.data.knowledgeBase.knowledgeBase?.length > 0
            ) {
              setNextStep("Completed");
              setCurrentStepPercent(100);
              return;
            } else if (
              !res.data.knowledgeBase.faqs ||
              res.data.knowledgeBase.faqs?.length <= 0
            ) {
              setNextStep("Upload FAQ");
              setCurrentStepPercent(70);
              return;
            } else if (
              !res.data.knowledgeBase.knowledgeBase ||
              res.data.knowledgeBase.knowledgeBase?.length <= 0
            ) {
              setNextStep("Upload Inventory");
              setCurrentStepPercent(95);
              return;
            }
          } else {
            setNextStep("Upload FAQ");
            setCurrentStepPercent(70);
          }
        })
        .catch((e) => {
          console.log(e.response.data);
        });
      instance
        .get(`/api/faq/${businessId}/get`, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data && res.data.length > 0) {
          } else {
            setNextStep("Upload FAQ");
            setCurrentStepPercent(70);
          }
        })
        .catch((e) => {
          if (e instanceof AxiosError) {
            const errMessage = e.response?.data?.message;
            if (errMessage === "Business doesn't exists") {
              localStorage.removeItem("business");
              const userData = JSON.parse(localStorage.getItem("user")!);
              userData.businessId = null;
              localStorage.setItem("user", JSON.stringify(userData));
              window.location.reload();
            }
            console.log(errMessage);
          }
          console.log(e.response.data);
        });
    } else if (businessId) {
      localStorage.setItem("onboard1", "done");
      localStorage.setItem("onboard2", "done");
      setStep(3);
      onboarding();
    } else {
      console.log(selectedMainNavItem);
      if (selectedMainNavItem === "onboarding") {
        let ite: string = localStorage.getItem("mainpage") as string;
        getSelectedMainSideBarMenu(ite);
        setInterval(() => {
          onboarding();
        }, 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, selectedMainNavItem]);

  // useEffect(() => {
  //   if(router.pathname.includes("tickets"))
  //   getTeam()
  // }, [router.pathname])

  useEffect(() => {
    const token = getCookie("Authorization");

    getCounts(router.pathname);

    console.log(threadId, businessId);

    if (threadId) {
      let url = `api/email-forwarder/ticket/${businessId}?page=${currentPage}`;
      if (filterEmail === "Escalated") {
        url = url + `&escalated=true&closed=false`;
      } else if (filterEmail === "Closed") {
        url = url + `&closed=true`;
      } else if (filterEmail === "AI Closed") {
        url = url + `&escalated=false&closed=false`;
      }
      instance
        .get(url, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.data) {
            setTotalMails(response.data.pages);
            setEmails(response.data.data);
            setCurrentPage(currentPage);
          }
        })
        .catch((error) => {
          if (error instanceof AxiosError) {
            const errMessage = error.response?.data?.message;
            console.log(errMessage);
          }
        });
      // getCounts()
    }
  }, [businessId, threadId, filterEmail, router.pathname]);

  const getCounts = (pathname: string) => {
    let url: string = "";

    if (pathname.includes("email")) {
      url = `/api/email-forwarder/ticket/${businessId}/counts`;
    } else if (pathname.includes("live-chat")) {
      url = "api/chat/counts";
    } else if (pathname.includes("tickets")) {
      url = "api/ticket/counts";
    } else {
      return;
    }
    const token = getCookie("Authorization");
    instance
      .get(url, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        // if(response.data.data){
        //   setTotalMails(response.data.total)
        //   setEmails(response.data.data);
        //   setCurrentPage(1)
        // }
        if (response.data.data) {
          setTotalActive(response.data.data.all);
          setTotalEscalated(response.data.data.escalated);
          setTotalResolved(response.data.data.resolved);
          setTotalRead(response.data.data.read);
          setTotalUnRead(response.data.data.unread);
          setTotalClosed(response.data.data.closed);
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          console.log(errMessage);
        }
      });
  };

  useEffect(() => {
    console.log(conversations);
  }, [conversations]);

  useEffect(() => {
    if (
      router.pathname.includes("live-chat") ||
      router.pathname.includes("tickets")
    ) {
      setInterval(() => {
        if (
          localStorage.getItem("selectedChat") &&
          (router.pathname.includes("live-chat") ||
            router.pathname.includes("tickets"))
        ) {
          let chat = JSON.parse(localStorage.getItem("selectedChat") as string);
          let comments = JSON.parse(localStorage.getItem("comments") as string);
          if (selectedChat) {
            if (
              chat.email !== selectedChat.email &&
              chat.phoneNo !== selectedChat.phoneNo
            ) {
              setSelectedChat(chat);
              setComments(comments);
            }
          } else {
            setSelectedChat(chat);
            setComments(comments);
          }
        } else {
          // localStorage.removeItem("selectedChat")
          setSelectedChat(null);
          setComments([]);
        }
      }, 1000);
    } else {
      localStorage.removeItem("selectedChat");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname]);

  useEffect(() => {
    console.log(router.pathname);
    if (
      team &&
      router.pathname.includes("tickets") &&
      departments.length >= 0
    ) {
      // Get departments
      const token = getCookie("Authorization");

      instance
        .get(`api/ticket/counts/department`, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDepartments(res.data.data);
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.pathname, businessId, team]);

  switch (selectedMainNavItem) {
    case "onboarding":
      descriptiveHeader = "Connections";
      break;
    case "item1":
      descriptiveHeader = "KPI";
      break;
    case "item2":
      descriptiveHeader = "Social";
      break;
    case "item3":
      descriptiveHeader = "ChatBot";
      break;
    case "conversations":
      descriptiveHeader = "Conversation";
      break;
    case "item4":
      descriptiveHeader = "Email";
      break;
    case "item5":
      descriptiveHeader = "Training";
      break;
    case "item6":
      descriptiveHeader = "Connection";
      break;
    case "item7":
      descriptiveHeader = "Customer";
      break;
    case "item8":
      descriptiveHeader = "Tickets";
      break;
    case "item9":
      descriptiveHeader = "Shop";
      break;
    case "item10":
      descriptiveHeader = "Customer Eng..";
      break;
    case "analysis":
      descriptiveHeader = "Analytics";
      break;
    case "item11":
      descriptiveHeader = "Notifications";
      break;
    case "item12":
      descriptiveHeader = "Search";
      break;
    case "item13":
      descriptiveHeader = "Settings";
      break;
    case "item14":
      descriptiveHeader = "Log out";
      break;
    case "item15":
      descriptiveHeader = "My Account";
      break;
    default:
      descriptiveHeader = "Settings";
  }

  let deptEscalated = team
    ? team?.role === "owner"
      ? departments.map((department: any) => {
          return {
            text: department.department,
            // icon: <UserStrokeIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: `tickets/escalated?department=${department.department}`,
            counter: department.ticketsCount.escalated,
            badgeBgColor: "#000000",
            badgeColor: "#FFFFFF",
          };
        })
      : [
          {
            text: team?.departmentId.department,
            // icon: <UserStrokeIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: `tickets/escalated?department=${team?.departmentId.department}`,
          },
        ]
    : [];

  let deptAll = team
    ? team?.role === "owner"
      ? departments.map((department: any) => {
          return {
            text: department.department,
            // icon: <TeamsIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: `tickets/all?department=${department.department}`,
            counter: department.ticketsCount.all,
            badgeBgColor: "#000000",
            badgeColor: "#FFFFFF",
          };
        })
      : [
          {
            text: team?.departmentId.department,
            // icon: <TeamsIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: `tickets/all?department=${team?.departmentId.department}`,
          },
        ]
    : [];

  let deptClosed = team
    ? team?.role === "owner"
      ? departments.map((department: any) => {
          return {
            text: department.department,
            // icon: <TeamsIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: `tickets/closed?department=${department.department}`,
            counter: department.ticketsCount.closed,
            badgeBgColor: "#000000",
            badgeColor: "#FFFFFF",
          };
        })
      : [
          {
            text: team?.departmentId.department,
            // icon: <TeamsIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: `tickets/closed?department=${team?.departmentId.department}`,
          },
        ]
    : [];

  let deptResolved = team
    ? team?.role === "owner"
      ? departments.map((department: any) => {
          return {
            text: department.department,
            // icon: <TeamsIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: `tickets/resolved?department=${department.department}`,
            counter: department.ticketsCount.resolved,
            badgeBgColor: "#000000",
            badgeColor: "#FFFFFF",
          };
        })
      : [
          {
            text: team?.departmentId.department,
            // icon: <UserStrokeIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: `tickets/resolved?department=${team?.departmentId.department}`,
          },
        ]
    : [];

  // console.log(dept, team, departments)

  // const getTeam = async () => {
  //   let token = getCookie("Authorization")
  //   if(localStorage.getItem("user") && token){
  //     const userData = JSON.parse(localStorage.getItem("user") as string);
  //     // setBusinessId(userData.businessId)
  //     instance.get(
  //       `/api/team/${userData.businessId}/${userData.id}`,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       }).then(res => {
  //         console.log(res.data)
  //         setTeam(res.data.data)
  //     }).catch(e => {
  //       console.log(e.response.data)
  //     })
  //   }else{
  //     localStorage.removeItem("user")
  //     window.location.assign('/login')
  //   }
  // }

  // const getTicket = () => {

  //   if(localStorage.getItem("user")){
  //     let token = getCookie("Authorization")
  //     // const userData = JSON.parse(localStorage.getItem("user") as string);

  //     instance.get(
  //       "/api/ticket/counts",
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       }
  //     ).then(res => {

  //         if(res.data.data){
  //           setTotalActive(res.data.data.all)
  //           setTotalClosed(res.data.data.closed)
  //           setTotalEscalated(res.data.data.escalated)
  //           setTotalResolved(res.data.data.resolved)
  //         }
  //     }).catch(e => {
  //       console.log(e.response.data)
  //     })
  //   }
  // }

  const sideBarContent: any = {
    KPI: [
      {
        text: "Customer MRR",
        icon: (
          <CustomerMrrStrokeIcon style={styles["sidebar_child_icon_stroke2"]} />
        ),
      },
      {
        text: "Total Customers",
        icon: <CustomerIcon style={styles["sidebar_child_icon_stroke2"]} />,
        data: analysis?.totalCustomers,
      },
      {
        text: "Happy Customers",
        icon: (
          <VerifiedUserStrokeIcon
            style={styles["sidebar_child_icon_stroke2"]}
          />
        ),
        data:
          analysis?.feedbackSentiments?.Happy &&
          parseInt(analysis?.feedbackSentiments?.Happy.split("%")[0]) + "%",
      },
      {
        text: "Total Escalation",
        icon: <MenuStrokeIcon style={styles["sidebar_child_icon_stroke2"]} />,
        data:
          analysis?.escalationRate &&
          parseInt(analysis?.escalationRate.split("%")[0]) + "%",
      },
      {
        text: "Total Resolved",
        icon: (
          <CheckedCircleStrokeIcon
            style={styles["sidebar_child_icon_stroke2"]}
          />
        ),
      },
      {
        text: "Active Customers",
        icon: <ActiveUserIcon style={styles["sidebar_child_icon2"]} />,
        data: analysis?.totalCustomers,
      },
    ],
    Connections: [
      {
        text: "Customer MRR",
        icon: (
          <CustomerMrrStrokeIcon style={styles["sidebar_child_icon_stroke2"]} />
        ),
      },
      {
        text: "Total Customers",
        icon: <CustomerIcon style={styles["sidebar_child_icon_stroke2"]} />,
        data: analysis?.totalCustomers,
      },
      {
        text: "Happy Customers",
        icon: (
          <VerifiedUserStrokeIcon
            style={styles["sidebar_child_icon_stroke2"]}
          />
        ),
        data: analysis?.feedbackSentiments?.Happy,
      },
      {
        text: "Total Escalation",
        icon: <MenuStrokeIcon style={styles["sidebar_child_icon_stroke2"]} />,
        data: analysis?.escalationRate,
      },
      {
        text: "Total Resolved",
        icon: (
          <CheckedCircleStrokeIcon
            style={styles["sidebar_child_icon_stroke2"]}
          />
        ),
      },
      {
        text: "Active Customers",
        icon: <ActiveUserIcon style={styles["sidebar_child_icon2"]} />,
        data: analysis?.totalCustomers,
      },
    ],
    ChatBot: [
      {
        text: `All`,
        link: "/live-chat/all",
        icon: <AllChatStrokecon style={styles["sidebar_child_icon_stroke"]} />,
        counter: totalActive,
        badgeBgColor: "#000000",
        badgeColor: "#FFFFFF",
      },
      {
        text: `Unread`,
        link: "/live-chat/unread",
        icon: (
          <UnreadChatIStrokecon style={styles["sidebar_child_icon_stroke"]} />
        ),
        counter: totalUnRead,
        badgeBgColor: "#000000",
        badgeColor: "#FFFFFF",
      },
      {
        text: `Read`,
        link: "/live-chat/read",
        icon: <ReadStrokeIcon style={styles["sidebar_child_icon_stroke"]} />,
        counter: totalRead,
        badgeBgColor: "#000000",
        badgeColor: "#FFFFFF",
      },
      {
        text: `Escalated`,
        link: "/live-chat/escalated",
        icon: <EscalatedChatIcon style={styles["sidebar_child_icon"]} />,
        counter: totalEscalated,
        badgeBgColor: "#fc3301",
        badgeColor: "#FFFFFF",
      },
      {
        text: `Resolved`,
        link: "/live-chat/resolved",
        icon: <ResolvedIcon2 style={styles["sidebar_child_icon"]} />,
        counter: totalResolved,
        badgeBgColor: "#1CD75A",
        badgeColor: "#FFFFFF",
      },
      {
        text: `Closed`,
        link: "/live-chat/closed",
        icon: <ClosedIcon style={styles["sidebar_child_icon"]} />,
        counter: totalClosed,
        badgeBgColor: "#1CD75A",
        badgeColor: "#FFFFFF",
      },
    ],
    Email: [
      {
        text: "All",
        link: "/email/all",
        icon: <AllChatStrokecon style={styles["sidebar_child_icon_stroke"]} />,
        counter: totalActive,
        badgeBgColor: "#000000",
        badgeColor: "#FFFFFF",
      },
      {
        text: "Unread",
        link: "/email/unread",
        counter: totalUnRead,
        badgeBgColor: "#fc3301",
        badgeColor: "#FFFFFF",
        icon: (
          <UnreadChatIStrokecon style={styles["sidebar_child_icon_stroke"]} />
        ),
      },
      {
        text: "Read",
        link: "/email/read",
        counter: totalRead,
        badgeBgColor: "#fc3301",
        badgeColor: "#FFFFFF",
        icon: <ReadStrokeIcon style={styles["sidebar_child_icon_stroke"]} />,
      },
      {
        text: "Escalated",
        link: "/email/escalated",
        counter: totalEscalated,
        badgeBgColor: "#fc3301",
        badgeColor: "#FFFFFF",
        icon: <EscalatedChatIcon style={styles["sidebar_child_icon"]} />,
      },
      {
        text: "AI Closed",
        link: "/email/resolved",
        counter: totalResolved,
        badgeBgColor: "#1CD75A",
        badgeColor: "#FFFFFF",
        icon: <ReadStrokeIcon style={styles["sidebar_child_icon_stroke"]} />,
      },
      {
        text: "Closed",
        link: "/email/closed",
        counter: totalResolved,
        badgeBgColor: "#1CD75A",
        badgeColor: "#FFFFFF",
        icon: <ReadStrokeIcon style={styles["sidebar_child_icon_stroke"]} />,
      },
    ],
    Customer: [
      {
        text: "ALL CUSTOMERS",
        icon: <CustomerIcon style={styles["sidebar_child_icon_stroke"]} />,
        link: "customers/all",
      },
      {
        // link: 'customers/escalated',
        hasSub: true,
        sub: [
          {
            text: "Instagram",
            icon: (
              <InstagramStrokeIcon
                style={styles["sidebar_child_icon_stroke"]}
              />
            ),
            link: "customers?channel=instagram",
          },
          {
            text: "X",
            icon: <TwitterStrokeIcon style={styles["sidebar_child_icon"]} />,
            link: "customers?channel=twitter",
          },
          {
            text: "Whatsapp",
            icon: <WhatsppStrokeIcon style={styles["sidebar_child_icon"]} />,
            link: "customers?channel=whatsapp",
          },
          {
            text: "Telegram",
            icon: <TelegramIcon style={styles["sidebar_child_icon"]} />,
            link: "customers?channel=telegram",
          },
          {
            text: "Email",
            icon: (
              <EmailStrokeIcon style={styles["sidebar_child_icon_stroke"]} />
            ),
            link: "customers?channel=email",
          },
        ],
      },
    ],
    Tickets: [
      {
        text: "ALL TICKETS",
        link: "tickets/all",
        counter: totalActive,
        badgeBgColor: "#000000",
        badgeColor: "#FFFFFF",
        hasSub: true,
        sub: deptAll,
      },
      {
        text: "AI CLOSED",
        link: "tickets/resolved",
        counter: totalResolved,
        badgeBgColor: "#1CD75A",
        badgeColor: "#FFFFFF",
        hasSub: true,
        sub: deptResolved,
      },
      {
        text: "ESCALATED",
        link: "tickets/escalated",
        counter: totalEscalated,
        badgeBgColor: "#fc3301",
        badgeColor: "#FFFFFF",
        hasSub: true,
        sub: deptEscalated,
      },
      {
        text: "CLOSED",
        link: "tickets/closed",
        counter: totalClosed,
        badgeBgColor: "#fc3301",
        badgeColor: "#FFFFFF",
        hasSub: true,
        sub: deptClosed,
      },
    ],
    Shop: [
      // {
      //   text: "Deals",
      // },
      {
        text: "Inventory",
        icon: <InVentoryIcon style={styles["sidebar_child_icon_stroke"]} />,
        link: "/shop/inventory",
      },
      {
        text: "New Orders",
        icon: <InVentoryIcon style={styles["sidebar_child_icon_stroke"]} />,
        link: "/shop/orders",
      },
    ],
    Analytics: [
      {
        text: "Analysis",
        link: "analytics/overview",
        // hasSub: false,
        icon: <AnalysisIcon style={styles["sidebar_child_icon"]} />,
        // openSub: true,
        // sub: [
        //   {
        //     text: "Engagement",
        //     icon: <AnalysisIcon style={styles["sidebar_child_icon"]} />,
        //     link: 'analysis/engagement'
        //   },
        //   {
        //     text: "Conversations",
        //     icon: <ConversationIconStroke style={styles["sidebar_child_icon"]} />,
        //     link: 'analysis/conversations'
        //   },
        //   {
        //     text: "Orders",
        //     icon: <InVentoryIcon style={styles["sidebar_child_icon_stroke"]} />,
        //     link: 'analysis/orders'
        //   },
        //   // {
        //   //   text: "Channels",
        //   //   icon: <InVentoryIcon style={styles["sidebar_child_icon_stroke"]} />,
        //   //   link: 'analysis/channels'
        //   // }
        //   ,
        // ]
      },
      {
        icon: <PieChartIcon style={styles["sidebar_child_icon"]} />,
        text: "Ticket Report",
        link: "analytics/ticket-report",
      },
      {
        text: "Perfomance",
        icon: <PerformanceIcon style={styles["sidebar_child_icon_stroke"]} />,
        link: "analytics/performance",
      },
      {
        text: "Channels",
        icon: <ChannelIcon style={styles["sidebar_child_icon_stroke"]} />,
        link: "analytics/channels",
      },
      {
        text: "Sales Pilot",
        icon: <SalesPilotIconStroke style={styles["sidebar_child_icon"]} />,
        link: "analytics/sales-pilot",
      },

      // {
      //   icon: <SalesPilotIconStroke style={styles["sidebar_child_icon"]} />,
      //   text: "Sales Pilot",
      //   // link: 'settings/channels'
      // },
      // {
      //   icon: <CustomerEngagementIconStroke style={styles["sidebar_child_icon"]} />,
      //   text: "Customer Engagement",
      //   // link: 'settings/channels'
      // }
    ],
    "My Account": [
      {
        text: "PERSONAL",
        link: "/account/personal",
      },
      {
        text: "BUSINESS",
        link: "/account/business",
      },
    ],
    Settings: [
      {
        text: "GENERAL",
        hasSub: true,
        sub: [
          {
            text: "Admin",
            icon: <AdminIcon style={styles["sidebar_child_icon"]} />,
            link: "settings/admins",
          },
          {
            text: "Team",
            icon: <TeamsIcon style={styles["sidebar_child_icon_stroke"]} />,
            link: "settings/teams",
          },
          {
            text: "Department",
            icon: <DepartmentsIcon style={styles["sidebar_child_icon"]} />,
            link: "settings/departments",
          },
          {
            text: "Mode Switch",
            icon: (
              <ModeSwitchIcon style={styles["sidebar_child_icon_stroke"]} />
            ),
            link: "settings/mode",
          },
          {
            text: "Agent",
            icon: <AgentIcon style={styles["sidebar_child_icon"]} />,
            link: "settings/agents",
          },
        ],
      },
      {
        text: "CHANNELS",
        link: "settings/channels",
      },
      {
        text: "CONFIGURATIONS",
        hasSub: true,
        sub: [
          {
            text: "Notifications",
            icon: (
              <BellStrokeIcon style={styles["sidebar_child_icon_stroke"]} />
            ),
            link: "settings/notifications",
          },
          {
            text: "Engagement",
            icon: (
              <MenuStrokeIcon2 style={styles["sidebar_child_icon_stroke"]} />
            ),
            link: "settings/engagement",
          },
        ],
      },
      {
        text: "BILLING",
        hasSub: true,
        sub: [
          {
            text: "Subscriptions",
            icon: <BoltIcon style={styles["sidebar_child_icon"]} />,
            link: "settings/subscriptions",
          },
        ],
      },
      {
        text: "DEVELOPER",
        hasSub: true,
        sub: [
          {
            text: "Integrations",
            icon: (
              <IntegrateIconStroke
                style={styles["sidebar_child_icon_stroke"]}
              />
            ),
            link: "settings/integrations",
          },
          {
            text: "Docs",
            icon: <DocsIcon style={styles["sidebar_child_icon"]} />,
            link: "settings/docs",
          },
        ],
      },
    ],
  };

  const onboarding = () => {
    let savedSelectedPlatforms = localStorage.getItem("selectedPlatforms");
    var newstep: number = 1;
    let isDone1 = localStorage.getItem("onboard1");
    if (
      savedSelectedPlatforms &&
      JSON.parse(savedSelectedPlatforms as string).length > 0 &&
      isDone1 &&
      isDone1 === "done"
    ) {
      newstep = 2;
    }

    let savedBusinessName = localStorage.getItem("businessName");
    let savedEmail = localStorage.getItem("email");
    let savedIndustry = localStorage.getItem("industry");
    let savedPhoneNumber = localStorage.getItem("phoneNumber");
    let savedBusinessDescription = localStorage.getItem("businessDescription");
    let isDone2 = localStorage.getItem("onboard2");

    if (
      newstep === 2 &&
      savedBusinessName &&
      savedBusinessName.length >= 3 &&
      savedEmail &&
      savedEmail.length >= 3 &&
      savedIndustry &&
      savedIndustry.length >= 3 &&
      savedPhoneNumber &&
      savedPhoneNumber.length >= 3 &&
      savedBusinessDescription &&
      savedBusinessDescription.length >= 3 &&
      isDone2 &&
      isDone2 === "done"
    ) {
      newstep = 3;
    }

    let savedBankName = JSON.parse(localStorage.getItem("bankName") as string);
    let savedAccountName = localStorage.getItem("accountName");
    let savedAccountNumber = localStorage.getItem("accountNumber");
    let savedOwnersFullName = localStorage.getItem("ownersFullName");
    let savedBusinessPhoneNumber = localStorage.getItem("businessPhoneNumber");
    let savedBusinessAddress = localStorage.getItem("businessAddress");
    let isDone3 = localStorage.getItem("onboard3");

    if (
      newstep === 3 &&
      // (savedBankName && savedBankName.label.length > 0) &&
      // (savedAccountName && savedAccountName.length >= 3) &&
      // (savedAccountNumber && savedAccountNumber.length >= 3) &&
      savedOwnersFullName &&
      savedOwnersFullName.length >= 3 &&
      savedBusinessPhoneNumber &&
      savedBusinessPhoneNumber.length >= 3 &&
      savedBusinessAddress &&
      savedBusinessAddress.length >= 3 &&
      isDone3 &&
      isDone3 === "done"
    ) {
      newstep = 4;
    }

    // let savedPlan = localStorage.getItem("plan")

    if (newstep === 1) {
      setNextStep("Link Your Business");
      setCurrentStepPercent(10);
    } else if (newstep === 2) {
      setNextStep("Update Business Info");
      setCurrentStepPercent(25);
    } else if (newstep === 3) {
      setNextStep("Verify Business Info");
      setCurrentStepPercent(40);
    } else if (newstep === 4) {
      setNextStep("Choose Your Premium");
      setCurrentStepPercent(55);
    }

    setStep(newstep);
  };

  const handleIndex = (index: number) => {
    if (index === dropIndex) {
      setDropIndex(Infinity);
      // return
    } else {
      setDropIndex(index);
    }
  };

  const refreshPage = () => {
    navigate(0);
  };

  const chartData = {
    datasets: [
      {
        data: [currentStepPercent, 100 - currentStepPercent],
        backgroundColor: ["rgba(250, 250, 250, 1)", "rgba(250, 250, 250, .2)"],
        borderWidth: 0,
        cutout: "79%",
      },
    ],
  };

  const openOnboarding = () => {
    if (!businessId) {
      localStorage.setItem("reloadonboarding", step.toString());
    } else {
      localStorage.setItem("reloadonboarding", nextStep);
    }
  };

  const selectComment = (id: string) => {
    navigate(router.pathname + "?comment=" + id);
  };

  const handleConversationClicked = async (email: any) => {
    if (localStorage.getItem("selectedEmail")) {
      let selectedEmail: any = JSON.parse(
        localStorage.getItem("selectedEmail")!
      );
      if (selectedEmail && selectedEmail.aiMode === "hybrid") {
        updateChatAiMode("auto", selectedEmail._id);
        nextEmail(currentPage);
      }
      localStorage.setItem("selectedEmail", JSON.stringify(email));
    }
  };

  const updateChatAiMode = (mode: any, id?: string) => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      // console.log(selectedEmail)
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance
        .post(
          `api/chat/mode/business/${userData.businessId}/ticket/${id}`,
          {
            aiMode: mode,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          showToast("primary", "Success!", "Mode changed successfully");
          // if(!id){
          //   let prevCon = res.data.data;

          //   prevCon["_id"] = res.data.data["id"];
          //   setSelectedEmail(prevCon);
          // }
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const nextEmail = (num: number) => {
    let token = getCookie("Authorization");
    instance
      .get(`api/email-forwarder/ticket/${businessId}?page=${num}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.data) {
          setTotalMails(response.data.pages);
          setEmails(response.data.data);
          setCurrentPage(response.data.currentPage);
        }
        // setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          // setError(errMessage);
        }
        // setIsLoading(false);
      });
  };

  return (
    <>
      {descriptiveHeader !== "Training" && showSideBar && (
        <section
          className={`${styles["child"]} ${
            !showSideBar && styles["slide-out"]
          } ${threadId && childstyles["no_padding"]}`}
          style={
            descriptiveHeader === "KPI" ? { backgroundColor: "#FFFFFF" } : {}
          }
        >
          {!threadId && !conversations && (
            <>
              <header className={styles.dashboard}>Dashboard</header>
              <article
              className={styles["child-article"]}
                style={{
                  width: "inherit",
                  display: "grid",
                  height: "85%",
                  gridTemplateRows: `${
                    descriptiveHeader === "Connections" &&
                    currentStepPercent < 100
                      ? "max-content max-content max-content 1fr"
                      : "max-content max-content 1fr"
                  }`,
                  // overflowX: "hidden",
                }}
              >
                <div className={styles.controls}>
                  <p>{descriptiveHeader}</p>{" "}
                  <div className={styles["icon-wrapper"]}>
                    <div onClick={refreshPage}>
                      <RefreshIcon style={styles["icon"]} />
                    </div>
                    <div onClick={() => toggleSidebar()}>
                      <CollapseIcon style={styles["icon"]} />
                    </div>
                  </div>{" "}
                </div>
                <nav style={{ width: "inherit" }}>
                  {descriptiveHeader === "Connections" ? (
                    <div className={styles["connection_steps"]}>
                      <div className={styles["connection_onboading_col1"]}>
                        <div
                          className={`${styles["connection_onboading_number"]} 
                ${
                  step === 1
                    ? styles["connection_onboading_number_current"]
                    : ""
                }
              `}
                        >
                          1
                        </div>
                        <div
                          className={styles["connection_onboading_number_line"]}
                        ></div>
                        <div
                          className={`${
                            styles["connection_onboading_number"]
                          } ${
                            step === 2
                              ? styles["connection_onboading_number_current"]
                              : ""
                          }
              `}
                        >
                          2
                        </div>
                        <div
                          className={styles["connection_onboading_number_line"]}
                        ></div>
                        <div
                          className={`${styles["connection_onboading_number"]} 
                ${
                  step === 3
                    ? styles["connection_onboading_number_current"]
                    : ""
                }
              `}
                        >
                          3
                        </div>
                        <div
                          className={styles["connection_onboading_number_line"]}
                        ></div>
                        <div
                          className={`${styles["connection_onboading_number"]} 
                ${
                  step === 4
                    ? styles["connection_onboading_number_current"]
                    : ""
                }
              `}
                        >
                          4
                        </div>
                      </div>
                      <div className={styles["connection_onboading_col2"]}>
                        <div
                          className={`${
                            styles["connection_onboading_step_content"]
                          } ${
                            step !== 1
                              ? styles["connection_onboading_step_current"]
                              : ""
                          }
              `}
                        >
                          <h2>Link your business</h2>
                          <p>Link your business</p>
                        </div>
                        <div
                          className={`${
                            styles["connection_onboading_step_content"]
                          } ${
                            step !== 2
                              ? styles["connection_onboading_step_current"]
                              : ""
                          }
              `}
                        >
                          <h2>Complete business Info</h2>
                          <p>Link your business</p>
                        </div>
                        <div
                          className={`${
                            styles["connection_onboading_step_content"]
                          } ${
                            step !== 3
                              ? styles["connection_onboading_step_current"]
                              : ""
                          }
              `}
                        >
                          <h2>Verify business</h2>
                          <p>Link your business</p>
                        </div>
                        <div
                          className={`${
                            styles["connection_onboading_step_content"]
                          } ${
                            step !== 4
                              ? styles["connection_onboading_step_current"]
                              : ""
                          }
              `}
                        >
                          <h2>Start selling</h2>
                          <p>Link your business</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <ul>
                      {sideBarContent[descriptiveHeader] &&
                        sideBarContent[descriptiveHeader].map(
                          (content: any, index: number) => {
                            return (
                              <li>
                                {content["hasSub"] ? (
                                  <div
                                    className={styles["chidside_dropdown"]}
                                    style={
                                      dropIndex === index ||
                                      content["openSub"] ||
                                      router.pathname.includes(
                                        content["sub"][0]?.link
                                      )
                                        ? { gap: "1rem" }
                                        : { gap: "0" }
                                    }
                                  >
                                    <div
                                      onClick={
                                        content["text"]
                                          ? () => handleIndex(index)
                                          : () => {}
                                      }
                                      className={
                                        styles["chidside_dropdown_toggle"]
                                      }
                                    >
                                      {content["link"] ? (
                                        <Link
                                          className={
                                            router.pathname +
                                              router.search.replaceAll(
                                                "%20",
                                                " "
                                              ) ===
                                            "/" + content["link"]
                                              ? styles.hover
                                              : ""
                                          }
                                          to={content["link"]}
                                        >
                                          {content["icon"] && content["icon"]}
                                          <p>{content["text"]}</p>
                                          {content["counter"] !== undefined && (
                                            <div
                                              // style={{backgroundColor: content["badgeBgColor"], color: content["badgeColor"]}}
                                              className={childstyles["badge"]}
                                            >
                                              {content["counter"]}
                                            </div>
                                          )}
                                        </Link>
                                      ) : (
                                        content["text"]
                                      )}
                                      {/* {(content['text'] && !content['openSub']) && <CollapseIcon style={styles["icon"] + " " + ((dropIndex === index || router.pathname.includes(content['sub'][0]?.link)) && styles["rotate_icon"])} />} */}
                                      {!content["text"] && <hr />}
                                    </div>
                                    {(dropIndex === index ||
                                      content["openSub"] ||
                                      router.pathname.includes(
                                        content["sub"][0]?.link
                                      ) ||
                                      !content["text"]) && (
                                      <div
                                        className={
                                          styles["chidside_dropdown_content"]
                                        }
                                      >
                                        {content["sub"]?.map((sub: any) => {
                                          return (
                                            <>
                                              {sub["link"] ? (
                                                <Link
                                                  className={
                                                    router.pathname +
                                                      router.search.replaceAll(
                                                        "%20",
                                                        " "
                                                      ) ===
                                                    "/" + sub["link"]
                                                      ? styles.hover
                                                      : ""
                                                  }
                                                  to={sub["link"]}
                                                >
                                                  <div
                                                    className={
                                                      styles[
                                                        "chidside_dropdown_content_left"
                                                      ]
                                                    }
                                                  >
                                                    {sub["icon"] && sub["icon"]}
                                                    <p>{sub["text"]}</p>
                                                  </div>
                                                  {sub["counter"] !==
                                                    undefined && (
                                                    <div
                                                      // style={{backgroundColor: sub["badgeBgColor"], color: sub["badgeColor"]}}
                                                      className={
                                                        childstyles["badge2"]
                                                      }
                                                    >
                                                      {sub["counter"]}
                                                    </div>
                                                  )}
                                                </Link>
                                              ) : (
                                                sub["text"]
                                              )}
                                            </>
                                          );
                                        })}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <>
                                    {content["link"] ? (
                                      <Link
                                        className={
                                          router.pathname.includes(
                                            content["link"]
                                          )
                                            ? styles.hover
                                            : ""
                                        }
                                        to={content["link"]}
                                      >
                                        <div
                                          className={
                                            styles["childside-chat-left"]
                                          }
                                        >
                                          {content["icon"] && content["icon"]}
                                          <p
                                            style={
                                              content["icon"]
                                                ? { marginLeft: "0.8rem" }
                                                : {}
                                            }
                                          >
                                            {content["text"]}
                                          </p>
                                          <h2>
                                            {content["data"] && content["data"]}
                                          </h2>
                                        </div>

                                        {content["counter"] !== undefined && (
                                          <div
                                            // style={{backgroundColor: content["badgeBgColor"], color: content["badgeColor"]}}
                                            className={childstyles["badge"]}
                                          >
                                            {content["counter"]}
                                          </div>
                                        )}
                                      </Link>
                                    ) : (
                                      <div
                                        style={{
                                          display: "grid",
                                          gridTemplateColumns:
                                            "max-content 1fr max-content",
                                          alignItems: "center",
                                          gap: "9px",
                                        }}
                                      >
                                        {content["icon"] && content["icon"]}
                                        <p>{content["text"]}</p>
                                        <p>
                                          {content["data"] && content["data"]}
                                        </p>
                                      </div>
                                    )}
                                  </>
                                )}
                              </li>
                            );
                          }
                        )}
                    </ul>
                  )}
                </nav>
                {descriptiveHeader === "Connections" &&
                  currentStepPercent < 100 && (
                    <div
                      className={styles["onboarding_progress"]}
                      onClick={openOnboarding}
                    >
                      <div className={styles["onboarding_progress_background"]}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/backgroundonboardingProgressImg.png"
                          }
                          alt="backgroundonboardingProgressImg"
                        />
                      </div>
                      <div className={styles["onboarding_progress_content"]}>
                        <div className={styles["onboarding_progress_chart"]}>
                          <Doughnut data={chartData} width={41} height={41} />
                          <h4>{currentStepPercent + "%"}</h4>
                        </div>
                        <div className={styles["onboarding_progress_text"]}>
                          <h5>{nextStep}</h5>
                          <p>Complete your profile to unlock all features</p>
                        </div>
                      </div>
                    </div>
                  )}
                {descriptiveHeader === "Connections" && (
                  <div className={styles["bottom_connection"]}>
                    <QuestionIcon style={styles["bottom_connection_icon"]} />
                    <h2>Having touble ?</h2>
                    <p>
                      Feel free to contact us and we will always help you
                      through the process
                    </p>
                    <button>Update</button>
                  </div>
                )}
                {selectedChat && (
                  <>
                    {comments && comments.length > 0 && (
                      <div className={childstyles.more_details_con}>
                        <div className={childstyles.more_details_header_con}>
                          <div
                            className={`${childstyles.more_details} ${childstyles.more_details_active}`}
                          >
                            <MessagesIcon
                              style={childstyles["more_details_icon2"]}
                            />
                            <h4>Comment Log</h4>
                          </div>
                        </div>
                        {/* {selectedConversation && ( */}
                        <div className={childstyles.comments_content_con}>
                          {comments.map((comment) => {
                            return (
                              <div
                                onClick={() => selectComment(comment.id)}
                                className={childstyles.comment}
                              >
                                <div className={childstyles.comment_row1}>
                                  <div
                                    className={childstyles.comment_departments}
                                  >
                                    {comment.ticketId.department}
                                  </div>
                                  <p className={childstyles.comment_date}>
                                    {calculateDuration(comment.createdAt)}
                                  </p>
                                </div>
                                <div className={childstyles.comment_row2}>
                                  <p className={childstyles.comment_msg}>
                                    {comment.comment}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {/* )} */}
                      </div>
                    )}
                    <div className={childstyles.more_details_con}>
                      <div className={childstyles.more_details_header_con}>
                        <div
                          className={`${childstyles.more_details} ${childstyles.more_details_active}`}
                        >
                          <UserStrokeIcon3
                            style={childstyles["more_details_icon_stroke"]}
                          />
                          <h4>Account Info.</h4>
                        </div>
                      </div>
                      {/* {selectedConversation && ( */}
                      <div className={childstyles.more_details_content_con}>
                        <div className={childstyles.more_details}>
                          <MailStrokeIcon
                            style={childstyles["more_details_icon_stroke"]}
                          />
                          <h4>{selectedChat?.email}</h4>
                        </div>
                        <div className={childstyles.more_details}>
                          <PhoneIcon2
                            style={childstyles["more_details_icon"]}
                          />
                          <h4>{selectedChat?.phoneNo}</h4>
                        </div>
                      </div>
                      {/* )} */}
                    </div>
                  </>
                )}
              </article>
            </>
          )}
          {threadId && (
            <div className={childstyles.gmails_con}>
              <div className={childstyles.gmails_con_header}>
                <img src={Logo} alt="enif" />
                <header className={childstyles.dashboard}>Dashboard</header>
              </div>
              <div className={childstyles.gmails_con_body}>
                <div className={childstyles.gmails_con_filter}>
                  <h2>Conversation</h2>
                  <div
                    onMouseEnter={() => setSelectFilter(true)}
                    onMouseLeave={() => setSelectFilter(false)}
                    className={`${childstyles.dropdown} `}
                  >
                    <span
                      className={``}
                      onClick={() => {
                        setSelectFilter(true);
                      }}
                    >
                      <FilterIconStroke
                        style={`${childstyles["filter_icon"]} `}
                      />
                    </span>
                    <p
                      className={``}
                      onClick={() => {
                        setSelectFilter(true);
                      }}
                    >
                      {filterEmail}
                    </p>
                    <span
                      className={``}
                      onClick={() => {
                        setSelectFilter(true);
                      }}
                    >
                      <CollapseIcon
                        style={`${childstyles["collapse_icon"]} `}
                      />
                    </span>
                    {selectFilter && (
                      <div className={`${childstyles["more_option_con"]} `}>
                        <h5
                          className={``}
                          onClick={() => {
                            setFilterEmail("All tickets");
                            setSelectFilter(false);
                          }}
                        >
                          All tickets
                        </h5>
                        <h5
                          className={``}
                          onClick={() => {
                            setFilterEmail("Escalated");
                            setSelectFilter(false);
                          }}
                        >
                          Escalated
                        </h5>
                        <h5
                          className={``}
                          onClick={() => {
                            setFilterEmail("Closed");
                            setSelectFilter(false);
                          }}
                        >
                          Closed
                        </h5>
                        <h5
                          className={``}
                          onClick={() => {
                            setFilterEmail("AI Closed");
                            setSelectFilter(false);
                          }}
                        >
                          AI Closed
                        </h5>
                      </div>
                    )}
                  </div>
                </div>
                <div className={childstyles.gmails}>
                  {emails.map((email) => {
                    return (
                      <div
                        className={`${childstyles.gmail_email} ${
                          email._id === threadId && childstyles.selected
                        }`}
                      >
                        <Link
                          onClick={() => handleConversationClicked(email)}
                          to={`/thread/${email._id}`}
                        >
                          <h2
                            style={{
                              fontFamily: email.read
                                ? "Axiforma Light"
                                : "Axiforma",
                            }}
                          >
                            {email.subject ?? email.title}
                          </h2>

                          <div className={childstyles["gmail_email_more"]}>
                            <h6
                              style={{
                                fontFamily: email.read
                                  ? "Axiforma Light"
                                  : "Axiforma",
                              }}
                            >
                              {email.customer}
                            </h6>
                            <span className={childstyles["email-date"]}>
                              <CalenderIcon
                                style={childstyles["calenderIcon"]}
                              />
                              <h6>
                                {email.createdAt &&
                                  formatDateToOrdinal2(email.createdAt)}
                              </h6>
                            </span>
                          </div>
                        </Link>
                        <div className={childstyles["gmail_email_bottom"]}>
                          <div
                            style={
                              filterEmail === "All tickets"
                                ? {
                                    backgroundColor: email?.closed
                                      ? "#EEE5FF"
                                      : email?.escalated
                                      ? "#FFDDF5"
                                      : "#F0F2F7",
                                    border: email?.closed
                                      ? "0.5px solid #DCCCFF"
                                      : email?.escalated
                                      ? "0.5px solid #F8D2ED"
                                      : "0.5px solid #DFE1E5",
                                  }
                                : {
                                    backgroundColor:
                                      filterEmail === "Closed"
                                        ? "#EEE5FF"
                                        : filterEmail === "Escalated"
                                        ? "#FFDDF5"
                                        : "#F0F2F7",
                                    border:
                                      filterEmail === "Closed"
                                        ? "0.5px solid #DCCCFF"
                                        : filterEmail === "Escalated"
                                        ? "0.5px solid #F8D2ED"
                                        : "0.5px solid #DFE1E5",
                                  }
                            }
                            className={childstyles["toggle_escalated"]}
                          >
                            {filterEmail === "All tickets" ? (
                              <p className={childstyles["escalated"]}>
                                {email?.escalated
                                  ? "Escalated"
                                  : email?.closed
                                  ? "Closed"
                                  : "AI Closed"}
                              </p>
                            ) : (
                              <p className={childstyles["escalated"]}>
                                {filterEmail === "Escalated"
                                  ? "Escalated"
                                  : filterEmail === "Closed"
                                  ? "Closed"
                                  : "AI Closed"}
                              </p>
                            )}
                            {/* <CollapseIcon style={childstyles["icon"] } /> */}
                          </div>
                          <div className={childstyles["gmail_email_icons"]}>
                            <img src={CommentIcon} alt="star" />
                            <img src={StarIcon} alt="star" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={childstyles.pagination}>
                  <div
                    onClick={() => {
                      setCurrentPage(currentPage <= 1 ? 1 : currentPage - 1);
                      nextEmail(currentPage <= 1 ? 1 : currentPage - 1);
                    }}
                    className={childstyles.pagination_btn_left}
                  >
                    <ArrowLeftIcon style={childstyles.pagination_icon} />
                  </div>
                  <div>
                    <h5
                      className={childstyles.pagination_num}
                    >{`${currentPage} of ${totalMails}`}</h5>
                  </div>
                  <div
                    onClick={() => {
                      setCurrentPage(
                        currentPage >= totalMails ? totalMails : currentPage + 1
                      );
                      nextEmail(
                        currentPage >= totalMails ? totalMails : currentPage + 1
                      );
                    }}
                    className={childstyles.pagination_btn_right}
                  >
                    <ArrowRightIcon2 style={childstyles.pagination_icon} />
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* {conversations && 
        <>
          <header style={{height: "85px"}} className={styles.dashboard}>Dashboard</header>
          <ConversationChildSide team={team} toggleSidebar={toggleSidebar} refreshPage={refreshPage} />
        </>
      } */}
        </section>
      )}
    </>
  );
};
export default ChildSide;
