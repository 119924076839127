
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const GoogleCallback = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        const queryObject = Object.fromEntries(searchParams.entries());
        // Access the query parameters here
        console.log(queryObject);
        // eslint-disable-next-line no-restricted-globals
        opener.postMessage({ data: queryObject, key: 'google:oauth2' });
        // eslint-disable-next-line no-restricted-globals
        self.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    return <div>Setting Up Google</div>;
};

export default GoogleCallback;
