import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';


const Payment = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const queryObject = Object.fromEntries(searchParams.entries());
        console.log(queryObject, setSearchParams);

        // eslint-disable-next-line no-restricted-globals
        opener.postMessage({ data: queryObject, key: 'paystackpayment' });

        if(queryObject)
        // eslint-disable-next-line no-restricted-globals
        self.close();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams]);

    return <div>Verifing Payment</div>;
};

export default Payment;