import React, { FC } from "react";
import styles from "./AddProductCard.module.css";
import { CheckIcon, EditIcon, PackageIcon, UploadIcon } from "../../assets/icons";

interface PropType {
  setAddPoduct: Function,
  setAddProductType: Function,
  setEditProduct?: Function,
  product?: any,
}

const AddProductCard: FC<PropType> = ({ setAddPoduct, setAddProductType, setEditProduct, product }) => {

  // const [productDescription, setProductDescriptionƒ] = useState<string | null>(
  //   null
  // );
  // const [productDescription, setProductDescriptionƒ] = useState<string | null>(
  //   null
  // );
  // const [productDescription, setProductDescriptionƒ] = useState<string | null>(
  //   null
  // );
  return (
    <div className={`${styles["grid-item"]} ${styles.hover}`}>
      {product && <div className={styles["added_icon"]}>
        <CheckIcon style={styles["green_check"]}/>
      </div>
      }
      <PackageIcon style={styles["package-icon"]} />
      {product ?
      <button onClick={() => {setAddPoduct(true); setEditProduct!()}}>
      <p>Edit</p> <EditIcon style={`${styles["upload-icon"]}`} />
      </button>
      :
      <button onClick={() => {setAddPoduct(true); setAddProductType("multiple")}}>
      <p>Add</p> <UploadIcon style={`${styles["upload-icon"]}`} />
      </button>
      }
    </div>
  );
};
export default AddProductCard;
