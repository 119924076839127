import React, { useEffect, useRef, useState } from "react";
import styles from "../Gmail.module.css";
import CheckIcon from "../../../assets/images/conversation_checkbox.svg";
import CommentIcon from "../../../assets/images/conversation_uncomment.svg";
import StarIcon from "../../../assets/images/conversation_star.svg";
import {
  checkuser,
  formatDate,
  formatDateToOrdinal,
  getCookie,
  showToast,
} from "../../../utilities/helpers";
import { Link, useParams } from "react-router-dom";
import instance from "../../../api";
import { AxiosError } from "axios";
import constants from "../../../utilities/constants";
import {
  CalenderIcon,
  CancelIcon,
  CollapseIcon,
  EnifIcon,
  EscalatedIcon,
  FilterIconStroke,
  RefreshIconStroke,
  SatisfactionIcon,
  SearchIcon,
  SendIcon,
  SentIcon,
  StatusIcon,
  TicketIcon,
  UrgencyIcon,
  EmailStrokeIcon,
} from "../../../assets/icons";
import Modal from "../../../components/Modal/Modal";
import { styled, Switch } from "@mui/material";
import Parser from "html-react-parser";
import AttachmentCard from "../../../components/AttachmentCard/AttachmentCard";

export default function Thread() {
  checkuser();
  const businessId: string | null = JSON.parse(localStorage.getItem("user")!)[
    "businessId"
  ];
  let { threadId } = useParams();
  const [selectedEmail, setSelectedEmail] = useState<any>();
  const [threads, setThreads] = useState<Array<any>>([]);
  const chatBoxRef = useRef<HTMLUListElement>(null);
  const [confirmResolve, setConfirmResolve] = useState<boolean>(false);
  const [confirmClose, setConfirmClose] = useState<boolean>(false);
  const [closeMessage, setCloseMessage] = useState<string>();
  const [escalateMessage, setEscalateMessage] = useState<string>();
  const [departments, setDepartments] = useState<Array<any>>();
  const [selectedDepartment, setSelectedDepartment] = useState<any | null>();
  const [confirmEscalate, setConfirmEscalate] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [escalateId, setEscalateId] = useState<string | null>();
  const [selectFilter, setSelectFilter] = useState<string>("");
  const [filterEmail, setFilterEmail] = useState("All tickets");
  const [message, setMessage] = useState("");
  const [messageBoxHeight, setMessageBoxHeight] = useState("100px");
  const [restructuring, setRestructuring] = useState<boolean>(false);

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35.71,
    height: 20,
    padding: 0,
    borderRadius: 17,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 16,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(15px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#3BDD99",
        },
        "& > .MuiSwitch-thumb": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#FFFFFF" : "#FFFFFF",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 14,
      height: 14,
      borderRadius: "50%",
      backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF" : "#FFFFFF",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      width: 35.71,
      height: 20,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor: "#D1D1E0",
      boxSizing: "border-box",
    },
  }));

  useEffect(() => {
    setMessageBoxHeight("100px"); 
  }, []);

  useEffect(() => {
    getDepartments();
    if (localStorage.getItem("selectedEmail")) {
      let email: any = JSON.parse(localStorage.getItem("selectedEmail")!);
      setSelectedEmail(email);
    }

    getEmails();
  }, [businessId, threadId]);

  const getEmails = () => {
    let token = getCookie("Authorization");
    instance
      .get(`api/email-forwarder/${threadId}?page=1`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setThreads(response.data);
        scrollToBottom();
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          console.log(errMessage);
        }
      });
  };

  let moodClass = "";

  if (selectedEmail && selectedEmail?.sentiment === constants.mood.angry) {
    moodClass = styles["high-color"];
  } else if (
    selectedEmail &&
    selectedEmail?.sentiment === constants.mood.neutral
  ) {
    moodClass = styles["mid-color"];
  } else if (
    selectedEmail &&
    selectedEmail?.sentiment === constants.mood.happy
  ) {
    moodClass = styles["chill-color"];
  } else {
    moodClass = styles["high-color"];
  }

  const reAssignTicket = () => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/ticket/reassign-escalated/${businessId}`,
        {
          departmentId: selectedDepartment?.id,
          ticketId: selectedEmail?._id,
          comment: escalateMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // nextPage(currentPage)
        if (selectedEmail) {
          selectedEmail._id = res.data.data.id;
          setSelectedEmail((prev: any) => res.data.data);
        }
        // setSelectedEmail(res.data.data)
        showToast(
          "primary",
          "Success!",
          "Conversation reassigned successfully"
        );
        setConfirmEscalate(false);
        setSelectedDepartment(null);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const data = err.response.data;
            showToast("secondary", "Error!", data?.data[0].message);
          } else {
            showToast("secondary", "Error!", "Error reassigning");
          }
        } else {
          showToast("secondary", "Error!", "Error reassigning");
        }
      });
    setEscalateMessage("");
  };

  const getDepartments = () => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      // const userData = JSON.parse(localStorage.getItem("user") as string);
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(userData.businessId)
      if (business)
        instance
          .get(`api/department/${business.id}`, {
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res);
            setDepartments(res.data.data);
          })
          .catch((e) => {
            console.log(e.response.data);
          });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const closeTicket = (ticketIds = selectedEmail?._id) => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/ticket/close-escalated-ticket/${businessId}`,
        {
          ticketId: ticketIds,
          comment: closeMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // nextPage(currentPage)
        if (selectedEmail) {
          // getComments(selectedEmail._id)
          let con = selectedEmail;
          con.closed = true;
          setSelectedEmail(con);
        }
        setCloseMessage("");
        showToast("primary", "Success!", "Chat closed successfully");
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const data = err.response.data;
            showToast("secondary", "Error!", data?.data[0].message);
          }
        }
      });
    setCloseMessage("");
    setConfirmClose(false);
  };

  const searchDepartment = (e: any) => {
    let token = getCookie("Authorization");
    if (e.target.value !== "") {
      if (localStorage.getItem("user") && token) {
        const business = JSON.parse(localStorage.getItem("business") as string);
        // setBusinessId(business.businessId)
        if (business)
          instance
            .get(`api/department/${business.id}?name=${e.target.value}`, {
              headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              console.log(res);
              setDepartments(res.data.data);
            })
            .catch((e) => {
              console.log(e.response.data);
            });
      } else {
        localStorage.removeItem("user");
        window.location.assign("/login");
      }
    } else {
      getDepartments();
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      const element: any = document.querySelector(".chat-list");
      if (element) {
        element.behavior = "smooth";
        element.scrollTop = element.scrollHeight;
        console.log(element.scrollHeight);
      }
    }, 10);
  };
  const formatMessage = (message: string) => {
    let formattedMessage = message;
  
    // Regular expression to match URLs with or without parentheses
    const linkRegex = /((http|https):\/\/[^\s()]+)/g;
  
    formattedMessage = formattedMessage.replace(linkRegex, (match) => {
      let url = match; // URL extracted from the match
  
      // Remove parentheses from the URL if present
      url = url.replace(/[()]/g, '');
  
      // Check if the URL is an image URL
      if (/\.(jpg|jpeg|png|gif|webp)$/i.test(url)) {
        return `<br><img className="" src="${url}" alt="product image" /><br>`;
      } else {
        return `<a className="" href="${url}" target="_blank">${url}</a><br>`;
      }
    });
  
    // Remove parentheses from formattedMessage
    formattedMessage = formattedMessage.replace(/[()]/g, '');
  
    // Handle line breaks
    formattedMessage = formattedMessage.replace(/\n/g, '<br>');
  
    return formattedMessage;
  };
 
  const handleMessage = (e: any) => {
    setMessage(e.target.value);
    // businessTyping(e)
    let textareaMsg = document.getElementById("message");
    if (textareaMsg) {
      console.log(textareaMsg?.scrollHeight);
      let textareaRows = e.target.value.split("\n");
      console.log(textareaRows);

      if (
        textareaMsg?.scrollHeight >= 100 &&
        textareaMsg?.scrollHeight <= 280
      ) {
        textareaMsg.style.height = "auto";
        textareaMsg.style.height =
          (textareaMsg?.scrollHeight).toString() + "px";
        setMessageBoxHeight((textareaMsg?.scrollHeight).toString() + "px");
        // textareaMsg.setAttribute("rows", (textareaRows.length))
      } else {
        textareaMsg.style.height = "auto";
        textareaMsg.style.height = "280px";
        setMessageBoxHeight("280px");
      }
      scrollToBottom();
    }
  };

  async function sendMessage() {
    // businessTyping(false)
    if (message.length < 1) {
      return;
    }
    // console.log(editMessage, selectedConversation)
    let token = getCookie("Authorization");
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let data = {
        ticketId: selectedEmail?._id,
        businessId: userData.businessId,
        reply: message,
        // "id": threads[threads.length-1]?.id
      };
      const response = await instance.post(
        "api/email-forwarder/reply",
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      // let conv: any = selectedConversation?.messages.map((msg: any) => {
      //   if(msg.id === editMessage.id){
      //     return {
      //       id: editMessage.id,
      //       role: editMessage.role,
      //       content: message,
      //       created_date: editMessage.createdAt,
      //       status: "sent"
      //     }
      //   }
      //   return msg
      // })
      // getMessages(selectedConversation ? selectedConversation?.id : '')
      // getMessagess(selectedConversation ? selectedConversation?.id : '', selectedConversation?.email ?? "")
      // setSelectedConversation((prevState: any) => {
      //   if (prevState) {
      //     return {
      //       ...prevState,
      //       messages: [...conv],
      //     };
      //   }
      // });
      setMessage("");
      getEmails();
      resetTextarea();
      // setEditMessage(null)
    } catch (error) {
      if (error instanceof AxiosError) {
      }
      console.log(error);
    }
  }

  const updateChatAiMode = (mode: any, id?: string) => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      console.log(selectedEmail);
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance
        .post(
          `api/chat/mode/business/${userData.businessId}/ticket/${
            id ? id : selectedEmail?._id
          }`,
          {
            aiMode: mode,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          showToast("primary", "Success!", "Mode changed successfully");
          if (mode === "auto") {
            setMessageBoxHeight("100px");
            setMessage("");
          }
          if (!id) {
            let prevCon = res.data.data;

            prevCon["_id"] = res.data.data["id"];
            setSelectedEmail(prevCon);
          }
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  async function restructure(message: string) {
    if (message.length <= 0) {
      return;
    }
    setRestructuring(true);
    let token = getCookie("Authorization");
    try {
      let data = {
        mail: message,
      };
      const response = await instance.post(
        "api/email-forwarder/restructure",
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showToast("primary", "Success!", "Corrected");
      // setSelectMode(false)
      console.log(response.data.data);
      setMessage(response.data.data);
      setRestructuring(false);
      const business: { businessId: string }[] | [] = response.data?.business;
      console.log(response.data, business);
    } catch (error) {
      if (error instanceof AxiosError) {
      }
      console.log(error);
      setRestructuring(false);
    }
  }

  const resetTextarea = () => {
    let textareaMsg = document.getElementById("message");
    if (textareaMsg) {
      console.log(textareaMsg?.scrollHeight);
      textareaMsg.style.height = "100px";
      setMessageBoxHeight("100px");
      scrollToBottom();
    }
    scrollToBottom();
  };

  return (
    <div className={styles.wrapper2}>
      <Modal isOpen={confirmEscalate}>
        <div className={styles["confirmation_modal"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]} style={{ marginBottom: ".5rem" }}>
              Confirm Ticket Reassignment
            </div>
          </div>
          <div
            className={styles["modal-content"]}
            style={{ margin: "0vh 0 1rem 0" }}
          >
            Are you sure you want to reassign this ticket to another department?
          </div>
          {selectedDepartment ? (
            <div className={styles["selected_department"]}>
              <h4>{selectedDepartment.department}</h4>
              <div
                onClick={() => setSelectedDepartment(null)}
                className={styles["remove_department"]}
              >
                <CancelIcon style={styles["cancel_icon"]} />
              </div>
            </div>
          ) : (
            <div style={{ width: "144px", height: "45px" }}>
              <div className={styles["escalate_department_con"]}>
                <div className={styles["search_input"]}>
                  <div id="search_con" className={styles["search2"]}>
                    <SearchIcon style={styles["search_icon"]} />
                    <input
                      onFocus={() => setSearching(true)}
                      onChange={(e) => {
                        searchDepartment(e);
                        setSearching(true);
                      }}
                      id="search"
                      placeholder="Search for department"
                    />
                  </div>
                </div>
                {searching && (
                  <div className={styles["department_con"]}>
                    {departments?.map((department) => {
                      return (
                        <div
                          onClick={() => {
                            setSelectedDepartment(department);
                          }}
                          className={styles["department"]}
                        >
                          <h4>{department.department}</h4>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={styles.form_group}>
            <label htmlFor="escalateMessage">Comment</label>
            <textarea
              id="escalateMessage"
              value={escalateMessage}
              onChange={(e) => setEscalateMessage(e.target.value)}
            />
          </div>
          {/* <div className={styles.form_group}>
          <label htmlFor="resolveMessage">Message</label>
          <textarea
            id="resolveMessage"
            value={resolveMessage}
            onChange={(e) => setResolveMessage(e.target.value)}
          />
        </div> */}
          <div className={styles["modal-controls"]}>
            <input
              type="button"
              value="Cancel"
              onClick={() => {
                setEscalateId(null);
                setConfirmEscalate(false);
                setSelectedDepartment(null);
              }}
            />
            <input
              type="button"
              value="Confirm"
              onClick={() => reAssignTicket()}
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={confirmClose}>
        <div className={styles["confirmation_modal"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]}>Confirm Conversation Close</div>
          </div>
          <div className={styles["modal-content"]}>
            Are you sure you want to close this conversation?
          </div>
          <div className={styles.form_group}>
            <label htmlFor="closeMessage">Comment</label>
            <textarea
              id="closeMessage"
              value={closeMessage}
              onChange={(e) => setCloseMessage(e.target.value)}
            />
          </div>
          <div className={styles["modal-controls"]}>
            <input
              type="button"
              value="No"
              onClick={() => {
                setConfirmClose(false);
              }}
            />
            <input type="button" value="Yes" onClick={() => closeTicket()} />
          </div>
        </div>
      </Modal>
      <div className={styles["conversation-column"]}>
        <header>
          <ul>
            <Link to={"/email/all"}>
              <li className={styles["go_back"]}>
                <CollapseIcon style={styles["icon"]} />
              </li>
            </Link>

            <li className={styles["button"]}>All Chats</li>
            <li>
              <img src={CheckIcon} alt="check-box" />
            </li>
            <li>
              <img src={CommentIcon} alt="comment" />
            </li>
            <li>
              <img src={StarIcon} alt="star" />
            </li>
          </ul>
        </header>
      </div>
      <div className={styles["conversation-body-column"]}>
        <section>
          <section className={styles.left}>
            <div
              style={
                selectedEmail && selectedEmail.aiMode === "hybrid"
                  ? {
                      height: `calc(100vh - 20px - 1vh - 1vh - 95px - ${messageBoxHeight})`,
                    }
                  : { height: `calc(100vh - 95px)` }
              }
              className={`${
                selectedEmail && threadId && threads && threads.length
                  ? styles["flex-end"]
                  : styles["flex-center"]
              } ${styles["conversation-message"]}`}
            >
              {!selectedEmail && threads && threads.length > 0 && (
                <p className={styles["select-message"]}>
                  Select a message to display
                </p>
              )}
              <div className={styles.left_header}>
                <h3>{threads?.length > 0 && threads[0].subject}</h3>
                <p className={styles["left-header_right"]}>
                <EmailStrokeIcon style={`${styles["live_chat_channel_icon_stroke"]}`} />
                  {selectedEmail?.email}
                  </p>
              </div>
              {selectedEmail && threads && threads.length > 0 && (
                <ul ref={chatBoxRef} className={styles["chat-list"]}>
                  {threads.map((thread: any) => {console.log(thread.attachments[0]);
                    return (
                      <>
                        {thread.content && (
                          <li className={styles["message"]}>
                            <div className={styles["message-image-wrapper"]}>
                              {thread.role === "assistance" ? (
                                <div className={styles["logo-enif"]}>
                                  <EnifIcon style={styles["logo-enifs"]} />
                                </div>
                              ) : (
                                <div className={styles["logo-name"]}>
                                  {selectedEmail.customer[0]?.toUpperCase()}
                                </div>
                              )}
                              <div className={styles["message-wrapper"]}>
                                <div className={styles["message-top"]}>
                                  <div
                                    style={{
                                      display: "grid",
                                      gap: "10px",
                                      gridAutoFlow: "column",
                                    }}
                                  >
                                    <p className={styles["name"]}>
                                      {thread.role === "assistance"
                                        ? "ENIF"
                                        : selectedEmail.customer}
                                    </p>
                                    <div
                                      onMouseEnter={() =>
                                        setSelectFilter(thread.id)
                                      }
                                      onMouseLeave={() => setSelectFilter("")}
                                      className={`${styles.dropdown} `}
                                    >
                                      {/* <span className={``} onClick={() => {setSelectFilter(thread.id)}}><FilterIconStroke style={`${styles["filter_icon"]} `} /></span> */}
                                      {/* <p className={``} onClick={() => {setSelectFilter(thread.id)}}>{filterEmail}</p> */}
                                      <span
                                        className={``}
                                        onClick={() => {
                                          setSelectFilter(thread.id);
                                        }}
                                      >
                                        <CollapseIcon
                                          style={`${styles["collapse_icon"]} `}
                                        />
                                      </span>
                                      {selectFilter === thread.id && (
                                        <div
                                          className={`${styles["more_option_con2"]} `}
                                        >
                                          <div>
                                            <h5 className={``}>from:</h5>
                                            <h6 className={``}>
                                              {thread.from}
                                            </h6>
                                          </div>
                                          <div>
                                            <h5 className={``}>to:</h5>
                                            <h6 className={``}>{thread.to}</h6>
                                          </div>
                                          <div>
                                            <h5 className={``}>subject:</h5>
                                            <h6 className={``}>
                                              {thread.subject}
                                            </h6>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <p className={styles["date"]}>
                                    {thread.originalMailDate &&
                                      formatDate(
                                        new Date(thread.originalMailDate)
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className={styles["content"]}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: formatMessage(thread.content)
                                  // thread.content
                                  //   ?.replaceAll("\r\n\r\n", "<br><br>")
                                  //   .replace(/\r\n$/, "")
                                  //   .replaceAll("\n\n", "<br>")
                                  //   .replace(/\n/g, ""),
                                }}
                              >
                                {/* {thread.content} */}
                                {/* {Parser(thread.content?.replaceAll("\r\n\r\n", "<br><br>").replace(/\r\n$/, "").replace(/\n/g, '<br>'))} */}
                              </p>
                             
                              { thread?.attachments.length>0 &&
                              
                               <div>
                                <hr/>
                                <h1 style={{ padding: "10px 0px" }}>
                                  {thread?.attachments.length}  <span> &nbsp;Attachment(s)</span> 
                                </h1> 
                              <div className={styles["attachments_div"]} >
                              
                              {
                                
                                thread?.attachments.map((attachment:any)=>{ 
                                  // const attachmentObj = attachment ?JSON.parse(attachment):null;
                                  // console.log("this is attachment Obj", JSON.parse(thread?.attachments[0]))
                                  return(
                                    
                                    <AttachmentCard attachment ={attachment}/>
                                  )
                                })}
                              </div>
                              </div>
                               }
                              
                             
                              {/* <embed src={JSON.parse(thread.attachments[0] || "[]")?.[0]?.url ?? ""} width="200px" height="180px" /> */}
                              {thread.role === "assistance" && (
                                <>
                                  {thread.status === "sent" && (
                                    <SentIcon style={styles["sent_icon"]} />
                                  )}
                                  {thread.status === "draft" && (
                                    <SentIcon style={styles["draft_icon"]} />
                                  )}
                                </>
                              )}
                            </div>
                          </li>
                        )}
                        <li
                          style={{
                            height: "1px",
                            backgroundColor: "#F0F2F7",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              top: "-18px",
                              right: "calc(50% - 17px)",
                              backgroundColor: "white",
                              padding: "7px 1rem",
                              fontFamily: "Axiforma",
                              fontSize: "10px",
                              fontWeight: "500",
                              lineHeight: "20px",
                              letterSpacing: "0em",
                              textAlign: "center",
                              color: "#949699",
                            }}
                          >
                            {new Date(thread.originalMailDate).toLocaleString(
                              "default",
                              {
                                month: "short",
                                day: "2-digit",
                              }
                            )}
                          </div>
                        </li>
                        {thread.assistantResponse && (
                          <li className={styles["message"]}>
                            <div className={styles["message-image-wrapper"]}>
                              <div className={styles["logo-enif"]}>
                                <EnifIcon style={styles["logo-enifs"]} />
                              </div>

                              <div className={styles["message-wrapper"]}>
                                <div className={styles["message-top"]}>
                                  <div
                                    style={{
                                      display: "grid",
                                      gap: "10px",
                                      gridAutoFlow: "column",
                                    }}
                                  >
                                    <p className={styles["name"]}>ENIF</p>
                                    <div
                                      onMouseEnter={() =>
                                        setSelectFilter("ENIF")
                                      }
                                      onMouseLeave={() => setSelectFilter("")}
                                      className={`${styles.dropdown} `}
                                    >
                                      {/* <span className={``} onClick={() => {setSelectFilter(thread.id)}}><FilterIconStroke style={`${styles["filter_icon"]} `} /></span> */}
                                      {/* <p className={``} onClick={() => {setSelectFilter(thread.id)}}>{filterEmail}</p> */}
                                      <span
                                        className={``}
                                        onClick={() => {
                                          setSelectFilter("ENIF");
                                        }}
                                      >
                                        <CollapseIcon
                                          style={`${styles["collapse_icon"]} `}
                                        />
                                      </span>
                                      {selectFilter === "ENIF" && (
                                        <div
                                          className={`${styles["more_option_con2"]} `}
                                        >
                                          <div>
                                            <h5 className={``}>from:</h5>
                                            <h6 className={``}>{thread.to}</h6>
                                          </div>
                                          <div>
                                            <h5 className={``}>to:</h5>
                                            <h6 className={``}>
                                              {thread.from}
                                            </h6>
                                          </div>
                                          <div>
                                            <h5 className={``}>subject:</h5>
                                            <h6 className={``}>
                                              {thread.subject}
                                            </h6>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <p className={styles["date"]}>
                                    {thread.assistantResponseDate &&
                                      formatDate(
                                        new Date(thread.assistantResponseDate)
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className={styles["content"]}>
                              <p>
                                {thread.assistantResponse &&
                                  Parser(
                                    thread.assistantResponse
                                      ?.replace("\n", "<br>")
                                      .replaceAll("\n", "")
                                      .replaceAll("<br />\n\n", "")
                                      .replaceAll("\n\n<br/>", "")
                                      .replaceAll("\n\n<br>", "")
                                      .replaceAll("\n\n", "<br>")
                                      .replaceAll("\n  \n<br>", "")
                                      .replaceAll("\n  \n<br/>", "")
                                      .replaceAll("\n  \n<br />", "")
                                      .replaceAll("\n  \n", "<br>")
                                      .replaceAll("\n<br>", "")
                                      .replaceAll("\n<br/>", "")
                                      .replaceAll("\n<br />", "")
                                      .replaceAll("\n \n<br>", "")
                                      .replace(/\n/g, "")
                                  )}
                              </p>
                              {thread.role === "assistance" && (
                                <>
                                  {thread.status === "sent" && (
                                    <SentIcon style={styles["sent_icon"]} />
                                  )}
                                  {thread.status === "draft" && (
                                    <SentIcon style={styles["draft_icon"]} />
                                  )}
                                </>
                              )}
                            </div>
                          </li>
                        )}
                      </>
                    );
                  })}
                </ul>
              )}

              {selectedEmail && threads && threads.length <= 0 && (
                <p className={styles["select-message"]}>No messages here yet</p>
              )}
            </div>
            {selectedEmail && selectedEmail.aiMode === "hybrid" && (
              <div className={styles["text-area"]}>
                <div className={styles["text-area-wrapper"]}>
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Type a message..."
                    cols={80}
                    rows={1}
                    value={message}
                    onChange={(e) => handleMessage(e)}
                    // onBlur={(e) => businessTyping(false)}
                    // onFocus={(e) => businessTyping(true)}
                  />
                  <div
                    className={styles["restructure"]}
                    onClick={() => restructure(message)}
                  >
                    <RefreshIconStroke
                      style={`${styles["refresh_icon"]} ${
                        restructuring && styles["restructuring"]
                      }`}
                    />
                  </div>
                  <button
                    disabled={!message.trim()}
                    onClick={sendMessage}
                    className={styles["send-message"]}
                  >
                    {<SendIcon style={styles["icons"]} />}
                  </button>
                </div>
              </div>
            )}
          </section>
          <section className={styles.right}>
            <div className={styles.right_row1}>
              <div className={styles["calendar"]}>
                <CalenderIcon style={styles["icons"]} />
                <p>
                  {selectedEmail
                    ? formatDateToOrdinal(new Date(selectedEmail.created_date))
                    : formatDateToOrdinal(new Date())}
                </p>
              </div>
              {selectedEmail && (
                <>
                  <div className={styles["others"]}>
                    <StatusIcon style={styles["icons"]} />
                    <div className={styles["others-child"]}>
                      <p>Status</p>
                      <p className={styles["chill-color"]}>Online</p>
                    </div>
                  </div>
                  <div className={styles["others"]}>
                    <TicketIcon style={styles["icons"]} />
                    <div className={styles["others-child"]}>
                      <p>Ticket Class</p>
                      <p className={styles["mid-color"]}>
                        {selectedEmail?.category}
                      </p>
                    </div>
                  </div>
                  <div className={styles["others"]}>
                    <TicketIcon style={styles["icons"]} />
                    <div className={styles["others-child"]}>
                      <p>Ticket Type</p>
                      <p className={styles["mid-color"]}>
                        {selectedEmail.type}
                      </p>
                    </div>
                  </div>
                  <div className={styles["others"]}>
                    <TicketIcon style={styles["icons"]} />
                    <div className={styles["others-child"]}>
                      <p>Ticket Desc.</p>
                      <p className={styles["mid-color"]}>
                        {selectedEmail.title}
                      </p>
                    </div>
                  </div>
                  <div className={styles["others"]}>
                    <TicketIcon style={styles["icons"]} />
                    <div className={styles["others-child"]}>
                      <p>Ticket Dept.</p>
                      <p className={styles["mid-color"]}>
                        {selectedEmail.department}
                      </p>
                    </div>
                  </div>
                  <div className={styles["others"]}>
                    <TicketIcon style={styles["icons"]} />
                    <div className={styles["others-child"]}>
                      <p>Ticket Sentiment</p>
                      <p className={moodClass}>{selectedEmail.sentiment}</p>
                    </div>
                  </div>
                  <div className={styles["others"]}>
                    <UrgencyIcon style={styles["icons"]} />
                    <div className={styles["others-child"]}>
                      <p>Urgency</p>
                      <p>{selectedEmail.urgency}</p>
                    </div>
                  </div>
                  <div className={styles["others"]}>
                    <EscalatedIcon style={styles["icons"]} />
                    <div className={styles["others-child"]}>
                      <p>Escalated</p>
                      <p
                        className={`${
                          selectedEmail.escalated
                            ? styles["chill-color"]
                            : styles["high-color"]
                        }`}
                      >
                        {selectedEmail.escalated ? "Yes" : "No"}
                      </p>
                    </div>
                  </div>
                  <div className={styles["others"]}>
                      <SatisfactionIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Satisfaction Score</p>
                        <p className={styles["chill-color"]}>{selectedEmail.css||"-"}</p>
                      </div>
                    </div>
                </>
              )}
            </div>
            <div className={styles.right_row2}>
              <div className={styles.right_row2_col1}>
                {selectedEmail && (
                  <div
                    onClick={() => {
                      setConfirmEscalate(true);
                      setEscalateId(selectedEmail._id);
                    }}
                    className={`${styles["resolved_button"]} ${styles["mark_escalate_button"]}`}
                  >
                    <h4>Escalate</h4>
                    {/* <EsclamationIcon style={styles["mark_escalate_icon"]} /> */}
                  </div>
                )}
                {selectedEmail && !selectedEmail.closed && (
                  <div
                    onClick={() => {
                      setConfirmClose(true);
                    }}
                    className={`${styles["resolved_button"]} ${styles["mark_resolved_button"]}`}
                  >
                    <h4>Close</h4>
                    {/* <CheckedCircleStrokeIcon style={styles["mark_resolved_icon"]} /> */}
                  </div>
                )}
              </div>

              {/* {selectedEmail && selectedEmail.escalated && (
                <div onClick={() => {setConfirmResolve(true); setResolveId(selectedEmail._id)}} className={`${styles["resolved_button"]} ${styles["mark_resolved_button"]}`}>
                  <h4>Escalate Ticket</h4>
                  <CheckedCircleStrokeIcon style={styles["mark_resolved_icon"]} />
                </div>
              )} */}
              {selectedEmail && (
                <div
                  onClick={() => {
                    updateChatAiMode(
                      selectedEmail.aiMode === "hybrid" ? "auto" : "hybrid"
                    );
                  }}
                  className={`${styles["switch_mode_con"]}`}
                >
                  <h4>Hybrid Switch</h4>
                  <AntSwitch
                    checked={selectedEmail.aiMode === "hybrid"}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                </div>
              )}
            </div>
          </section>
        </section>
      </div>
    </div>
  );
}
