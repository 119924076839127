// import { useEffect, useState } from "react";
import styles from "./teams.module.css";
import { checkuser, getCookie, showToast } from "../../../../utilities/helpers";
import General from "../general";
import { CancelIcon, CollapseIcon, InviteIcon, SearchIcon } from "../../../../assets/icons";
import Modal from "../../../Modal/Modal";
import { useEffect, useState } from "react";
import instance from "../../../../api";
import { AxiosError } from "axios";
import TeamRow from "./TeamRow/TeamRow";

export default function Teams() {
  const [ openModal, setOpenModal ] = useState(false);
  // const [teamEmail, setTeamEmail] = useState('')
  const [teamRole, setTeamRole] = useState('')
  // const [teamFirstName, setTeamFirstName] = useState('')
  // const [teamLastName, setTeamLastName] = useState('')
  // const [teamPhoneNo, setTeamPhoneNo] = useState('')
  const [selectRole, setSelectRole] = useState(false)
  const [teams, setTeams] = useState<Array<any>>()
  const [ isLoading, setIsLoading ] = useState(true);
  const [ user, setUser ] = useState<any>();
  const [ editId, setEditId ] = useState<number | null>();
  const [departments, setDepartments] = useState<Array<any>>()
  const [selectedDepartment, setSelectedDepartment] = useState<any | null>()
  const [searching, setSearching] = useState<boolean>(false);
  const [editTeam, setEditTeam] = useState(false);
  const [editTeamRole, setEditTeamRole] = useState("");
  const [errors, setErrors] = useState<any>({});
  const [formState, setFormState] = useState({
    teamEmail: "",
    teamFirstName: "",
    teamPhoneNo: "",
    teamLastName: "",
  });

  const {teamEmail, teamFirstName, teamPhoneNo, teamLastName} = formState

  const userData = JSON.parse(localStorage.getItem("user") as string);
  checkuser()

  useEffect(() => {
    setIsLoading(true)
    getTeams()
    getDepartments()
  }, [])

  const addTeam = async () => {
    if(!validate()){
      return
    }

    let role = ""
    if(teamRole === "Admin" ){
      role = "member"
    }else if(teamRole === "Sup admin" ){
      role = "super-admin"
    }

    try {
      let data = {
        "inviteeEmail": teamEmail.trim(),
        "role": role,
        "first_name": teamFirstName.trim(),
        "last_name": teamLastName.trim(),
        "phone": teamPhoneNo.trim()
      }
      let token = getCookie("Authorization")
      const response = await instance.post(`api/admin/invite/${userData.businessId}/business`, { ...data },
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      showToast("primary", "Success!", "Invite sent successfully")
      setFormState({
        teamEmail: "",
        teamFirstName: "",
        teamPhoneNo: "",
        teamLastName: "",
      })
      setTeamRole('')
      setOpenModal(false)
      getTeams()

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
    }
  }

  function onChange(e: any) {
    setFormState({ ...formState, [e.target.name]: e.target.value });
    let err: any = errors
    if(e.target.value.length <= 0){
      err[e.target.name] = true;
      setErrors(err)
    }else{
      err[e.target.name] = false;
      setErrors(err)
    }
  }

  const removeTeam = async (id:any) => {
    if(id <= 0){
      return
    }
    let token = getCookie("Authorization")

    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      const response = await instance.delete(`api/team/${userData.businessId}/${id}`,
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      showToast("secondary", "Success!", "Team member deleted successfully")
      setOpenModal(false)
      getTeams()

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
    }
  }

  const getTeams = async () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      setUser(userData)
      // setBusinessId(userData.businessId)
      instance.get(
        `/api/team/${userData.businessId}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res.data)
          setTeams(res.data.data)
          setIsLoading(false)
      }).catch(e => {
        console.log(e.response.data)
        setIsLoading(false)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  const handleEdit = (id: number, team: any) => {
    setEditId(id)
    if(team.role === "member"){
      setEditTeamRole("Admin")
    }
    if(departments && team.departmentId)
    for(let i=0; i<departments?.length; i++){
      if(departments[i].id === team.departmentId._id){
        setSelectedDepartment(departments[i])
      }
    }
    setEditTeam(true)
  }

  const getDepartments = () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(business.businessId)
      if(business)
      instance.get(
        `api/department/${business.id}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res)
          setDepartments(res.data.data)
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  const searchDepartment = (e: any) => {
    let token = getCookie("Authorization")
    if(e.target.value !== ""){
      if(localStorage.getItem("user") && token){
        const business = JSON.parse(localStorage.getItem("business") as string);
        // setBusinessId(business.businessId)
        if(business)
        instance.get(
          `api/department/${business.id}?name=${e.target.value}`,
          {
            headers: {
              'Access-Control-Allow-Headers': 'X-Requested-With',
              'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            console.log(res)
            setDepartments(res.data.data)
        }).catch(e => {
          console.log(e.response.data)
        })
      }else{
        localStorage.removeItem("user")
        window.location.assign('/login')
      }
    }else{
      getDepartments()
    }
  }

  const updateTeamMember = async () => {
    let token = getCookie("Authorization")
    const userData = JSON.parse(localStorage.getItem("user") as string);
    let role = ''
    if(editTeamRole === "Admin" ){
      role = "member"
    }else if(editTeamRole === "Sup admin" ){
      role = "super-admin"
    }

    try {
      let data = {
        "departmentId": selectedDepartment.id,
        "role": role,
      }
      const response = await instance.patch(`api/team/${userData.businessId}/${editId}`, 
      { ...data },
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      showToast("primary", "Success!", "Team member edited successfully")
      setEditTeam(false)
      setEditId(null)
      setEditTeamRole("")
      setFormState({
        teamEmail: "",
        teamFirstName: "",
        teamPhoneNo: "",
        teamLastName: "",
      })
      setSelectedDepartment(null)
      getTeams()

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
    }
  }

  const validate = () => {
    let err: any = {}
    if(teamEmail.length <= 0){
      err["teamEmail"] = true;
      setErrors(err)
    }
    if(teamRole.length <= 0){
      err["teamRole"] = true;
      setErrors(err)
    }
    if(teamPhoneNo.length <= 0){
      err["teamPhoneNo"] = true;
      setErrors(err)
    }
    if(teamLastName.length <= 0){
      err["teamLastName"] = true;
      setErrors(err)
    }
    if(teamFirstName.length <= 0){
      err["teamFirstName"] = true;
      setErrors(err)
    }

    if(teamEmail.length <= 0 || teamRole.length <= 0 || teamPhoneNo.length <= 0 || teamLastName.length <= 0 || teamFirstName.length <= 0){
      return false
    }

    return true;
  }

  return (
    <General actionText="Invite" setOpenModal={setOpenModal}>
      <div className={styles.teams_container}>
        
        {!isLoading && 
        <>
          {teams && teams.length > 0 ?
          <div className={styles.usersList}>
            {teams.map((team, index) => (
              <TeamRow
                key={index}
                id={team.id}
                team={team}
                role={team.role}
                fullName={`${team.userId?.firstName} ${team.userId?.lastName}`}
                email={team.userId?.email}
                removeTeam={removeTeam}
                handleEdit={handleEdit}
                you={team.userId?._id === user.id}
                department={team.departmentId}
              />
            ))}
          </div>
          :
          <div className={styles.no_teams}>
            <p>You have no active team member</p>
            <div onClick={() => setOpenModal(true)} className={styles.create_team}>
              <h4>Invite Someone</h4>
              <InviteIcon style={styles.inviteicon} />
            </div>
          </div>
          }
        </>
        }
        <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
            <div className={styles.team_form}>
              <h2>Invite a member</h2>
              <p></p>
              <div className={styles.form_group2}>
                <label htmlFor="teamRole">Role</label>
                <div
                  style={errors["teamRole"] ? {border: "1px solid #fc3401"}: {}}
                >
                  <p onClick={() => {setSelectRole(!selectRole)}}>{teamRole}</p>
                  <span onClick={() => {setSelectRole(!selectRole)}}><CollapseIcon style={styles["collapse_icon"]} /></span>
                  {selectRole &&
                  <div className={styles["more_option_con"]}>
                    <h5 onClick={() => {setTeamRole("Admin"); setSelectRole(false);}}>Admin</h5>
                    <h5 onClick={() => {setTeamRole("Sup admin"); setSelectRole(false);}}>Sup Admin</h5>
                  </div>
                  }
                </div>
              </div>

              <div className={styles.form_group}>
                <label htmlFor="teamFirstName">First Name</label>
                <input
                  style={errors["teamFirstName"] ? {border: "1px solid #fc3401"}: {}}
                  id="teamFirstName"
                  name="teamFirstName"
                  type="text"
                  value={teamFirstName}
                  onChange={onChange}
                  required
                />
              </div>

              <div className={styles.form_group}>
                <label htmlFor="teamLastName">Last Name</label>
                <input
                  style={errors["teamLastName"] ? {border: "1px solid #fc3401"}: {}}
                  id="teamLastName"
                  name="teamLastName"
                  type="text"
                  value={teamLastName}
                  onChange={onChange}
                  required
                />
              </div>

              <div className={styles.form_group}>
                <label htmlFor="teamEmail">Email Address</label>
                <input
                  style={errors["teamEmail"] ? {border: "1px solid #fc3401"}: {}}
                  id="teamEmail"
                  name="teamEmail"
                  type="text"
                  value={teamEmail}
                  onChange={onChange}
                  required
                />
              </div>

              <div className={styles.form_group}>
                <label htmlFor="teamPhoneNo">Phone Number</label>
                <input
                  style={errors["teamPhoneNo"] ? {border: "1px solid #fc3401"}: {}}
                  id="teamPhoneNo"
                  name="teamPhoneNo"
                  type="text"
                  value={teamPhoneNo}
                  onChange={onChange}
                  required
                />
              </div>
              <div onClick={() => addTeam()} className={styles.send_team}>
                <h4>{editId ? "Edit Member" : "Send Invite"}</h4>
                <InviteIcon style={styles.sendicon} />
              </div>
            </div>
        </Modal>

        <Modal isOpen={editTeam}>
          <div className={styles["confirmation_modal"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]} style={{marginBottom: ".5rem"}}>Edit Team Member</div>
          </div>
          {/* <div className={styles["modal-content"]} style={{margin: "0vh 0 1rem 0"}}>
            Are you sure you want to reassign this ticket to another department?</div> */}
          <div className={styles.form_group2}>
            <label htmlFor="editTeamRole">Role</label>
            <div>
              <p onClick={() => {setSelectRole(true)}}>{editTeamRole}</p>
              <span onClick={() => {setSelectRole(true)}}><CollapseIcon style={styles["collapse_icon"]} /></span>
              {selectRole &&
              <div className={styles["more_option_con"]}>
                <h5 onClick={() => {setEditTeamRole("Admin"); setSelectRole(false)}}>Admin</h5>
                <h5 onClick={() => {setEditTeamRole("Sup admin"); setSelectRole(false)}}>Sup Admin</h5>
              </div>
              }
            </div>
          </div>
          {selectedDepartment ? 
          <div className={styles["selected_department"]}>
            <h4>{selectedDepartment.department}</h4>
            <div onClick={() => setSelectedDepartment(null)} className={styles["remove_department"]}>
              <CancelIcon style={styles["cancel_icon"]} />
            </div>
          </div>
          :
          <div style={{width: "144px", height: "85px"}}>
            <div className={styles["escalate_department_con"]}>
              <div className={styles["search_input"]}>
                <div id="search_con" className={styles["search2"]}>
                  <SearchIcon style={styles["search_icon"]} />
                  <input onChange={(e) => {searchDepartment(e); setSearching(true)}} id="search" placeholder="Search for department" />
                </div>
              </div>
              {searching && 
              <div className={styles["department_con"]}>
              {departments?.map((department) => {
                return (
                  <div onClick={() => {setSelectedDepartment(department)}} className={styles["department"]}>
                    <h4>{department.department}</h4>
                  </div>
                )
              })}
              </div>
              }
            </div>
          </div>
          }
          <div className={styles["modal-controls"]}>
              <input type="button" value="Cancel" onClick={() => {setEditId(null); setEditTeam(false); setSelectedDepartment(null)}} />
              <input
              type="button"
              value="Update"
              onClick={() => updateTeamMember()}
              />
          </div>
          </div>
        </Modal>
      </div>
    </General>
  );
}
