import React, { FC, ChangeEvent, useState } from "react";
import styles from "./adminRow.module.css";
import { MoreIcon } from "../../../../../assets/icons";
import Modal from "../../../../Modal/Modal";
import { log } from "console";

interface user {
  team: string;
  id: string;
  role: string;
  fullName: string;
  email: string;
  status?: string;
  removeTeam: Function;
  you: boolean
  // handleAdminRemove?: (email: string) => void;
}

const AdminRow: FC<user> = (props) => {
  const [moreAction, setMoreAction] = useState(false)
  const [deleteTeamId, setDeleteTeamId] = useState<string | null>()
  const admin = "admin";
  const handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
  };
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  console.log(deleteTeamId)
  const userData = JSON.parse(localStorage.getItem('user') as string);
  console.log('to checkuser admin', userData.role);
  
  const confirmTeamAction = () => {
    props.removeTeam(deleteTeamId)
    setConfirmDelete(false)
    setDeleteTeamId(null)
  }

  const userRoles =
    props.role.toLowerCase() !== admin ? (
      <div className={`${styles.dropdown} ${styles["div-dropdown"]}`}>
        {props.role}
      </div>
    ) : (
      <select
        className={styles.dropdown}
        name="roles"
        onChange={handleRoleChange}
      >
        <option value="">{props.role}</option>
        <option value="option1">Super-Admin</option>
      </select>
    );
  return (
    <>
    <Modal isOpen={confirmDelete}>
      <div className={styles["delete_modal"]}>
      <div className={styles["modal-header"]}>
          <div className={styles["header"]}>Delete team</div>
      </div>
      <div className={styles["modal-content"]}>Are you sure you want to remove this team?</div>
      <div className={styles["modal-controls"]}>
          <input type="button" value="No" onClick={() => {setDeleteTeamId(null); setConfirmDelete(false)}} />
          <input
          type="button"
          value="Remove"
          onClick={() => confirmTeamAction()}
          />
      </div>
      </div>
    </Modal>
    <section className={styles.wrapper}>     
      <div className={styles["user-profile"]}>
        <div className={styles["abv"]}>
          {props.fullName[0]}
        </div>
        <div>
          <h4>{props.fullName}</h4>
          <div className={styles["user_email"]}>
            <p>{props.email}</p>
            {props.you &&
            <div>You</div>
            }
          </div>
        </div>
      </div>
      <div className={styles["controls"]}>
        {userRoles}
        {(['owner', 'super-admin'].includes(userData.role))&&
           <div onMouseEnter={() => setMoreAction(true)} onMouseLeave={() => setMoreAction(false)} className={styles["edit_con"]}>
           {props.role !== "owner" &&
             <>
               <MoreIcon style={styles["edit_icon"]} />
               {moreAction &&
               <div className={styles["more_option_con"]}>
                   {/* <h5>Edit team</h5> */}
                   <h5 onClick={() => {setConfirmDelete(true); setDeleteTeamId(props.id)}}>Delete team</h5>
               </div>
               }
             </>
           }
           </div>
        }
       
      </div>
    </section>
    </>
  );
};

export default AdminRow;
