import React, { FC } from "react";
import styles from "./Modal.module.css";
import { CancleIcon } from "../../assets/icons";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;
  return (
    <div className={`${styles["modal-overlay"]} modalId`} id="modalId">
      <div className={styles['hide']}></div>
      <div className={styles["modal"]}>
        {onClose && 
        <div className={styles["modal-close"]} onClick={onClose}>
          <CancleIcon style={styles.cancel_icon} />
        </div>
        }
        {children}
      </div>
    </div>
  );
};

export default Modal;
