import { useEffect, useState } from "react";
import styles from "./mode.module.css";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { checkuser, getCookie, showToast } from "../../../../utilities/helpers";
import instance from "../../../../api";
import { AxiosError } from "axios";
import { CircuitIcon, EsclamationIcon, EyeStrokeIcon, HybridIcon, MarkStrokeIcon } from "../../../../assets/icons";

export default function Mode() {
  checkuser()
  const [businessDetails, setBusinessDetails] = useState<any>()
  const [mode, setMode] = useState("")
  const [selMode, setSelMode] = useState("")
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35.71,
    height: 20,
    padding: 0,
    borderRadius: 17,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 16,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked': {
        transform: 'translateX(15px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#3BDD99',
        },
        '& > .MuiSwitch-thumb': {
            backgroundColor:
        theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
          },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor:
        theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
        width: 35.71,
        height: 20,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor:
        '#D1D1E0',
      boxSizing: 'border-box',
    },
  }));

  useEffect(() => {
      let token = getCookie("Authorization")
      if(localStorage.getItem("user") && token){
        const userData = JSON.parse(localStorage.getItem("user") as string);
        // setBusinessId(userData.businessId)
        instance.get(
          `api/business/get/${userData.businessId}`,
          {
            headers: {
              'Access-Control-Allow-Headers': 'X-Requested-With',
              'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            console.log(res.data)
            setMode(res.data.aiMode)
            setBusinessDetails(res.data)
        }).catch(e => {
          console.log(e.message)
        })
      }else{
        localStorage.removeItem("user")
        window.location.assign('/login')
      }
  }, [])

  console.log(businessDetails)

  const updateMode = async (mode: string) => {
    setMode(mode)
    let token = getCookie("Authorization")
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let data = {
        "userId": userData.userId, 
        "businessId": userData.businessId, 
        "aiMode": mode
      }
      const response = await instance.post("api/business/update", { ...data },
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      showToast("primary", "Success!", "Mode updated successfully")
      const business: { businessId: string }[] | [] = response.data?.business;
      console.log(response.data, business);
    } catch (error) {
      if (error instanceof AxiosError) {
        
      }
      console.log(error);
    }
  }

  return (
    <>
    <section className={styles.wrapper}>
      <header>
        {/* <h1>Choose your Premium</h1> */}
      </header>
      <section className={styles.content}>
        <h3>Mode switch</h3>
        <div className={styles["modes_con"]}>
          <p><EsclamationIcon style={styles["esclamation_icon"]} />Switch between all three Enif modes</p>
          <div className={styles["modes_col"]}>
            <div className={styles["modes"]}>
              <div style={selMode === "auto"? {borderColor: "#5100FF"}: {}} onClick={() => {setSelMode("auto")}} className={styles["mode"]}>
                <div className={styles["channel"]}>
                  <div className={styles["channel_icon_con"]}>
                    <CircuitIcon style={`${styles["channel_icon2"]} ${styles["auto_icon"]}`} />
                  </div>
                  <div>
                    <h4>Automatic mode</h4>
                  </div>
                </div>
                <div className={styles["controls"]}>
                  <AntSwitch checked={mode === "auto"} onChange={() => updateMode("auto")} inputProps={{ 'aria-label': 'ant design' }} />
                </div>
              </div>
              <div style={selMode === "supervised"? {borderColor: "#5100FF"}: {}} onClick={() => {setSelMode("supervised")}} className={styles["mode"]}>
                <div className={styles["channel"]}>
                  <div className={styles["channel_icon_con"]}>
                    <EyeStrokeIcon style={`${styles["channel_icon2"]} ${styles["supervised_icon"]}`} />
                  </div>
                  <div>
                    <h4>Supervised mode</h4>
                  </div>
                </div>
                <div className={styles["controls"]}>
                  <AntSwitch checked={mode === "supervised"} onChange={() => updateMode("supervised")} inputProps={{ 'aria-label': 'ant design' }} />
                </div>
              </div>
              <div style={selMode === "hybrid"? {borderColor: "#5100FF"}: {}} onClick={() => {setSelMode("hybrid")}} className={styles["mode"]}>
                <div className={styles["channel"]}>
                  <div className={styles["channel_icon_con"]}>
                    <HybridIcon style={`${styles["channel_icon2"]} ${styles["hybrid_icon"]}`} />
                  </div>
                  <div>
                    <h4>Hybrid mode</h4>
                  </div>
                </div>
                <div className={styles["controls"]}>
                  <AntSwitch checked={mode === "hybrid"} onChange={() => updateMode("hybrid")} inputProps={{ 'aria-label': 'ant design' }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className={styles.settings_sidebar}>
        {selMode && 
        <>
          <div className={styles.settings_sidebar_header}>
            <div className={styles.settings_sidebar_header_mode}>
              <h5>
              {selMode === "auto" &&
                "Automated mode"
                }
                {selMode === "supervised" &&
                "Supervised mode"
                }
                {selMode === "hybrid" &&
                "Hybrid mode"
                }
              </h5>
              <p>
                {selMode === "auto" &&
                "Automated Customer Interaction"
                }
                {selMode === "supervised" &&
                "Refines Customer Interaction"
                }
                {selMode === "hybrid" &&
                "Seamlessly Controlled Interactions"
                }
              </p>
            </div>
            <div className={styles.settings_sidebar_header_icon}>
              {selMode === "auto" &&
              <CircuitIcon style={`${styles["auto_icon"]}`} />
              }
              {selMode === "supervised" &&
              <EyeStrokeIcon style={`${styles["supervised_icon"]}`} />
              }
              {selMode === "hybrid" &&
              <HybridIcon style={`${styles["hybrid_icon"]}`} />
              }
            </div>
          </div>
          <div className={styles.settings_sidebar_body}>

            {selMode === "auto" &&
            <div className={`${styles.settings_sidebar_section} ${styles.settings_sidebar_section2}`}>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Enif's operates independently.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Handles customer interactions across channels.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Doesn't require agent input.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Responds to queries or creates support tickets.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Escalate to agent when necessary.</p>
              </header>
            </div>
            }
            {selMode === "supervised" &&
            <div className={`${styles.settings_sidebar_section} ${styles.settings_sidebar_section2}`}>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Enif suggests customer responses as drafts.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Humans enhance and modify the drafts.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>After Agent review, Enif assists in structuring the response.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>The refined reply is sent to customers through various channels.</p>
              </header>
            </div>
            }
            {selMode === "hybrid" &&
            <div className={`${styles.settings_sidebar_section} ${styles.settings_sidebar_section2}`}>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Enif interacts based on user preference.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Follows context and agent permission..</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Agent can seamlessly take over, even mid-chat or on any channel.</p>
              </header>
              <header>
                <MarkStrokeIcon style={styles.settings_sidebar_icon_stroke} />
                <p>Admin has control over AI, similar to a switchable assistant.</p>
              </header>
            </div>
            }
          </div>
        </>
        }
        {!selMode && 
        <div className={styles.no_mode_con}>
          <div className={styles.no_mode_icon}>
            <EsclamationIcon style={styles[""]} />
          </div>
          <p>Select your preferred mode for more info</p>
        </div>
        }
      </div>
    </section>
  </>
  );
}