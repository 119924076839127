// import React, { useState } from "react";
import { FormEvent, useRef, useState } from "react";
import { PlanAnnualyStarSVG, PlanCheckSVG, PlanMonthlyStarSVG, PlanQuarterlyStarSVG, TimePauseIcon } from "../../../assets/icons";
import styles from "./subscription.module.css";
import instance from "../../../api";
import { checkuser, getCookie, showToast } from "../../../utilities/helpers";
import { AxiosError } from "axios";
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement);

const Subscriptions = () => {
  checkuser()
  const [plan, setPlan] = useState('annually')
  const paymentRef = useRef<any>(null)

  const makePayment = async (e: FormEvent, plan: string) => {
    let token = getCookie("Authorization")
    e.preventDefault()
    setPlan(plan)
    let amount: number = 0
    if(plan === "annually"){
      amount = 120000
    }

    if(plan === "monthly"){
      amount = 35000
    }

    if(plan === "quarterly"){
      amount = 35000
    }
    console.log(amount)
    instance.post(`/api/plan/pay`,
    {
      plan: plan,
      amount: amount
    },
    {
      headers: {
        'Access-Control-Allow-Headers': 'X-Requested-With',
        'Authorization': `Bearer ${token}`
      }
    })
    .then((res) => {
      console.log(res.data)
      if(res.data && res.data.message === "You still have valid payment ongoing"){
        instance.post(`/api/plan/pay/verify/${res.data.data.reference}`, {}, 
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
            console.log(res.data);
            showToast("primary", "Payment Successful!", "Your payment was successful. Thank you.")
            // submitHandler(e)
            // localStorage.setItem('tokens', JSON.stringify(res.data))
        }).catch((err) => {
          if (err instanceof AxiosError) {
            if (err.response?.status === 400) {
              const message = err.response.data?.message;
              console.log(message)
              showToast("secondary", "Payment Declined!", "Your payment has been declined. Please try again!")
              
            }
          }
        })
      }else{
        paymentRef.current = window.open(res.data.data.authorization_url, 'Precise Payment', 'width=390,height=570,left=450,top=100,toolbar=1,status=1,');
      }
    })
    .catch((e) => {
      console.log(e)
    });
  }

  const changePlan = (plan: string) => {
    setPlan(plan)
  }

  const chartData = {
    datasets: [
      {
        data: [1, 14],
        backgroundColor: [
          'rgba(250, 250, 250, 1)',
          'rgba(250, 250, 250, .2)',
        ],
        borderWidth: 0,
        cutout: '79%'
      },
    ],
  };

  return (
    <>
      <section className={styles.wrapper}>
        <header>
          <h1>Choose your Premium</h1>
        </header>
        <section className={styles.content}>
          <div className={styles["plans_con"]}>
            <p>Unlock the Full Potential of Enif with Our Simple Plans</p>
            <div className={styles["plans_col"]}>
              <main className={styles["plans"]}>
                <section onClick={() => changePlan('monthly')}
                  style={plan=== 'monthly' ? { borderColor: "#5101ff"}: {}}
                >
                  <header>
                    <PlanMonthlyStarSVG style={styles["plan-star"]} />
                    <h2>Monthly</h2>
                  </header>
                  <main>
                    <div className={styles["plan-amount"]}>
                      {/* <p>₦35,000.00</p> */}
                    </div>
                    <ul>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Automated customer response</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        Sell even when offline
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Automate multi-channel sales.</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>30-day money-back guarantee</p>
                      </li>
                    </ul>
                    <div className={styles["plan-button-div"]}>
                      <button >Get Started</button>
                    </div>
                  </main>
                </section>
                <section onClick={() => changePlan('annually')}
                style={plan=== 'annually' ? { borderColor:"#e27612"} : {}}
                >
                  <header>
                    <PlanAnnualyStarSVG style={styles["plan-star"]} />
                    <h2>Annually</h2> <p className={styles["badge"]}>50% off</p>
                  </header>
                  <main>
                    <div className={styles["plan-amount"]}>
                      {/* <p>
                        ₦120,000 <span>₦240,000</span>
                      </p> */}
                    </div>
                    <ul>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Automated inventory tracking</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Automated customer responses</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Automate multi-channel sales.</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>WhatsApp sale notifications</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Full sales reports</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>100% money-back guarantee</p>
                      </li>
                    </ul>
                    <div className={styles["plan-button-div"]}>
                      <button >
                        Get Started
                      </button>
                    </div>
                  </main>
                </section>
                <section onClick={() => changePlan('quarterly')}
                style={plan=== 'quarterly' ? { borderColor:"#1bd370"}: {}}
                >
                  <header>
                    <PlanQuarterlyStarSVG style={styles["plan-star"]} />
                    <h2>Quarterly</h2>
                  </header>
                  <main>
                    <div className={styles["plan-amount"]}>
                      {/* <p>₦35,000.00</p> */}
                    </div>
                    <ul>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Core automation features</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Ideal for individuals and small teams</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Automate multi-channel sales.</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>Full sales reports</p>
                      </li>
                      <li>
                        <PlanCheckSVG style={styles["plan-check"]} />
                        <p>100% money-back guarantee</p>
                      </li>
                    </ul>
                    <div className={styles["plan-button-div"]}>
                      <button >Get Started</button>
                    </div>
                  </main>
                </section>
              </main>
              <div  className={styles["onboarding_progress"]}>
                <div className={styles["onboarding_progress_background"]}>
                  {/* <img src={process.env.PUBLIC_URL+ '/assets/images/backgroundonboardingProgressImg.png'} alt="backgroundonboardingProgressImg" /> */}
                </div>
                <div className={styles["onboarding_progress_content"]}>
                  <div className={styles["onboarding_progress_chart"]}>
                    <Doughnut 
                      data={chartData} 
                      width={64}
                      height={64}
                    />
                    <TimePauseIcon style={styles["time_pause_icon"]} />
                  </div>
                  <div className={styles["onboarding_progress_text"]}>
                    <h5>Free plan</h5>
                    <p>13 days left</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Subscriptions;
