import React, { useState } from "react";
import styles from "./Department.module.css";
import Admin from "../../components/Settings/SettingsUser";
import Modal from "../../components/SettingsModal/SettingsModal";
let users = [
  {
    name: "Ihunna Promise",
    role: "Operation",
    avatar: "hello",
    email: "ihunna.Adeniyi@credpal.com",
    status: "Active",
  },
  {
    name: "Victor Adeniyi",
    role: "Engineering",
    avatar: "hello",
    email: "victor.Adeniyi@credpal.com",
    status: "Inactive",
  },
  {
    name: "Joshua Ibukun",
    role: "Support",
    avatar: "hello",
    email: "support.Adeniyi@credpal.com",
    status: "Active",
  },
  {
    name: "Oluseyi Teniola",
    role: "Sales",
    avatar: "hello",
    email: "oluseyi.Adeniyi@credpal.com",
    status: "Active",
  },
  {
    name: "Ogunbiyi Fatimat",
    role: "Finance",
    avatar: "hello",
    email: "ogunbiyi.Adeniyi@credpal.com",
    status: "Active",
  },
];

const Department = () => {
  const [modal, setModal] = useState<{
    show: boolean;
    email: null | string;
  }>({ show: false, email: null });
  const handleRemoveUser = (email: string) => {
    users = users.filter((user) => user.email !== email);
    setModal((prev) => ({ ...prev, show: false, email: null }));
  };

  const openModal = (email: string) => {
    setModal((prev) => ({ ...prev, show: true, email: email }));
    console.log(email);
  };

  const closeModal = () => {
    setModal((prev) => ({ ...prev, show: false, email: null }));
  };
  return (
    <>
      {modal.show && (
        <Modal
          email={modal.email!}
          closeModal={closeModal}
          handleRemoveUser={handleRemoveUser}
          header="Remove User"
          body="Are you sure you want to remove this user from the users list?"
        />
      )}
      <section className={styles.wrapper}>
        <header>
          <h1>Department</h1>
        </header>
        <section className={styles.content}>
          <div className={styles.usersList}>
            {users.map((user) => (
              <Admin
                key={user.name}
                name={user.name}
                role={user.role}
                avatar={user.avatar}
                email={user.email}
                status={user.status}
                handleAdminRemove={openModal}
              />
            ))}
          </div>
        </section>
      </section>
    </>
  );
};

export default Department;
