import React, { ChangeEvent, FC, FormEvent, useEffect, useRef, useState } from "react";
import styles from "./NewProductModal.module.css";
import Modal from "../Modal/Modal";
import Select from "react-select";
import constants from "../../utilities/constants";
import { CancelIcon, CollapseIcon, PackageIcon, UploadIcon } from "../../assets/icons";
const options: { key: string; label: string }[] = constants.categories;
interface NewProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  saveProduct: Function;
  editProduct: any;
  updateProduct: any;
  editSavedProduct: Function;
  isNew: boolean;
  updateInventory: Function;
}
const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    border: "none",
    backgroundColor: "#f8f7fa",
    outline: "none",
    borderRadius: "6px",
    marginBottom: "20px",
    boxShadow: state.isFocused ? "0 0 0 1px #5100FF" : provided.boxShadow,
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    // display: "none", // Hide the placeholder
    fontSize: "14px",
    fontWeight: "400",
  }),
  focus: (provided: any, state: any) => ({
    ...provided,
    display: "none", // Hide the placeholder
    outline: "1px solid red",
    border: "none",
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }) ,
};

const NewProductModal: FC<NewProductModalProps> = ({ isOpen, onClose, saveProduct, editProduct, editSavedProduct, isNew, updateProduct, updateInventory }) => {
  const [productImage, setProductImage] = useState<any | null>(null);
  const [productCategory, setProductCategory] = useState<string | "">("");
  const [productTitle, setProductTitle] = useState<string>("");
  const [productQty, setProductQty] = useState<string>("");
  const [productPrice, setProductPrice] = useState<string>("");
  const [productCurrency, setProductCurrency] = useState<string>("₦");
  const [productDescription, setProductDescription] = useState<string>("");
  const [productImageURL, setProductImageURL] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [editCurrency, setEditCurrency] = useState<boolean>(false);
  const handleProductTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProductTitle(e.target.value);
  };
  const handleProductPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    // if (typeof parseInt(e.target.value) === "number")
    updateTextView(e.target.value);
  };

  function updateTextView(val: string){
    var num = getNumber(val);
    if(num===0){
      setProductPrice("");
    }else{
      setProductPrice((prev) => (num.toLocaleString()));
    }
  }
  function getNumber(_str: string){
    var arr : Array<any> = _str.split('');
    var out = [];
    for(var cnt=0;cnt<arr.length;cnt++){
      if(isNaN(arr[cnt])===false){
        out.push(arr[cnt]);
      }
    }
    return Number(out.join(''));
  }
  const handleProductQTYChange = (e: ChangeEvent<HTMLInputElement>) => {
    // if (typeof e.target.value === "number")
      setProductQty(e.target.value);
  };
  const handleProductDescriptionChange = (
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    setProductDescription(e.target.value);
  };
  const productImageRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if(editProduct){
      let
        productCategory,
        productImage,
        productTitle,
        productQty,
        productPrice,
        productDescription,
        productCurrency

      if(isNew){
        productCategory =  editProduct.productCategory
        productImage =  editProduct.productImage;
        productTitle =  editProduct.productTitle;
        productQty =  editProduct.productQty;
        productPrice =  editProduct.productPrice;
        productDescription =  editProduct.productDescription;
        productCurrency =  editProduct.productCurrency;
      }else{
        productCategory =  editProduct.category 
        productImage =  editProduct.image;
        productTitle =  editProduct.name;
        productQty =  editProduct.quantity;
        productPrice =  editProduct.price;
        productDescription =  editProduct.description;
        productCurrency =  editProduct.currency || "₦";
      }

      setProductCategory(productCategory)
      setProductImage( isNew ? productImage: null)
      setProductTitle(productTitle)
      setProductQty(productQty)
      updateTextView(productPrice)
      setProductCurrency(productCurrency)
      setProductDescription(productDescription)
      setProductImageURL( isNew ? URL.createObjectURL(productImage): productImage)
    }else if(updateProduct){
      let
        productCategory,
        productImage,
        productTitle,
        productQty,
        productPrice,
        productDescription,
        productCurrency

      productCategory =  updateProduct.category 
      productImage =  updateProduct.image;
      productTitle =  updateProduct.name;
      productQty =  updateProduct.quantity;
      productPrice =  updateProduct.price;
      productDescription =  updateProduct.description;
      productCurrency =  updateProduct.currency || "₦";
      

      setProductCategory(productCategory)
      setProductTitle(productTitle)
      setProductQty(productQty)
      updateTextView(productPrice)
      setProductCurrency(productCurrency)
      setProductDescription(productDescription)
      setProductImageURL(productImage)
    }else{
      setProductCategory("")
      setProductImage(null)
      setProductTitle("")
      setProductQty("")
      setProductPrice("")
      setProductDescription("")
      setProductImageURL("")
      setProductCurrency("₦")
    }
    setEditCurrency(false)
    setErrorMsg("")
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[editProduct, isNew, updateProduct])

  const handleCategoryChange = (
    selectedOption: {
      key: string;
      label: string;
    } | null
  ) => {
    // console.log(selectedOption);
    selectedOption && setProductCategory(selectedOption.label);
  };
  const handleFileupload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setProductImage(e.target.files?.[0]);
      let productImageUrl = URL.createObjectURL(e.target.files[0])
      setProductImageURL(productImageUrl)
    }
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true)

    if(productCategory.length > 0 && 
      productTitle.length > 0 &&
      productQty.length > 0 &&
      productPrice.length > 0 &&
      productCurrency.length > 0 &&
      productImage &&
      productDescription.length > 0
      ){
      let res = await saveProduct({
        productCategory,
        productImage,
        productTitle,
        productQty,
        productPrice,
        productCurrency,
        productDescription
      })

      if(res){
        setProductCategory("")
        setProductImage(null)
        setProductTitle("")
        setProductQty("")
        setProductPrice("")
        setProductCurrency("₦")
        setProductDescription("")
        setProductImageURL("")
        onClose()
      }
      setLoading(false)
    }else{
      setLoading(false)
      if(productTitle.length <= 0){
        setErrorMsg("add product title")
        return
      }
      if(productCategory.length <= 0){
        setErrorMsg("add product category")
        return
      }
      if(productPrice.length <= 0){
        setErrorMsg("add product price")
        return
      }
      if(productQty.length <= 0){
        setErrorMsg("add product quantity")
        return
      }
      if(productDescription.length <= 0){
        setErrorMsg("add product description")
        return
      }
      if( productImage || productImageURL.length <= 0){
        setErrorMsg("add product image")
        return
      }
    }
  };

  const handleEdit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true)
    console.log(
      productCategory?.length, 
      productTitle?.length,
      productQty?.length,
      productPrice?.length,
      productCurrency?.length,
      (!isNew || productImage),
      productDescription?.length
    )

    if(productCategory?.length > 0 && 
      productTitle?.length > 0 &&
      productQty?.length > 0 &&
      productPrice?.length > 0 &&
      productCurrency?.length > 0 &&
      (!isNew || productImage) &&
      productDescription?.length > 0
      ){
      let res = editSavedProduct({
        productCategory,
        productImage,
        productTitle,
        productQty,
        productPrice,
        productCurrency,
        productDescription,
        productImageURL
      })

      setProductCategory("")
      setProductImage(null)
      setProductTitle("")
      setProductQty("")
      setProductPrice("")
      setProductCurrency("₦")
      setProductDescription("")
      setProductImageURL("")
      if(res){
        onClose()
        setLoading(false)
      }
    }else{
      setLoading(false)
      if(!productTitle || productTitle.length <= 0){
        setErrorMsg("add product title")
        return
      }
      if(!productCategory || productCategory.length <= 0){
        setErrorMsg("add product category")
        return
      }
      if(!productPrice || productPrice.length <= 0){
        setErrorMsg("add product price")
        return
      }
      if(!productQty || productQty.length <= 0){
        setErrorMsg("add product quantity")
        return
      }
      if(!productDescription || productDescription.length <= 0){
        setErrorMsg("add product description")
        return
      }
      if( productImage || productImageURL.length <= 0){
        setErrorMsg("add product image")
        return
      }
    }
  };

  const handleUpdate = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true)
    console.log(
      productCategory?.length, 
      productTitle?.length,
      productQty?.length,
      productPrice?.length,
      productCurrency?.length,
      (!isNew || productImage),
      productDescription?.length
    )

    if(productCategory?.length > 0 && 
      productTitle?.length > 0 &&
      productQty?.length > 0 &&
      productPrice?.length > 0 &&
      productCurrency?.length > 0 &&
      (productImageURL) &&
      productDescription?.length > 0
      ){
      let res = updateInventory({
        productCategory,
        productImage,
        productTitle,
        productQty,
        productPrice,
        productCurrency,
        productDescription,
        productImageURL
      })

      setProductCategory("")
      setProductImage(null)
      setProductTitle("")
      setProductQty("")
      setProductPrice("")
      setProductCurrency("₦")
      setProductDescription("")
      setProductImageURL("")
      if(res){
        onClose()
        setLoading(false)
      }
    }else{
      setLoading(false)
      if(!productTitle || productTitle.length <= 0){
        setErrorMsg("add product title")
        return
      }
      if(!productCategory || productCategory.length <= 0){
        setErrorMsg("add product category")
        return
      }
      if(!productPrice || productPrice.length <= 0){
        setErrorMsg("add product price")
        return
      }
      if(!productQty || productQty.length <= 0){
        setErrorMsg("add product quantity")
        return
      }
      if(!productDescription || productDescription.length <= 0){
        setErrorMsg("add product description")
        return
      }
      if( productImage || productImageURL.length <= 0){
        setErrorMsg("add product image")
        return
      }
    }
  };

  const removeImage = () => {
    setProductImageURL("")
    setProductImage(null)
  }

  const uploadImage = (
    <>
    {productImageURL ? 
    <div className={styles["product_image_container"]}>
      <div onClick={removeImage} className={styles["remove_icon_con"]}>
        <CancelIcon style={styles["remove_icon"]} />
      </div>
      <img className={styles["product_image"]} src={productImageURL} alt="product" />
    </div>
      :
    <div className={`${styles["grid-item"]} ${styles.hover}`}>

      <PackageIcon style={styles["package-icon"]} />
      <button
        onClick={(e) => {
          e.preventDefault();
          if (productImageRef.current) {
            productImageRef.current.click();
          }
        }}
      >
        <p>Add</p> <UploadIcon style={`${styles["upload-icon"]}`} />
      </button>
    </div>
    }
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <section className={styles["wrapper"]}>
        <section className={styles["header"]}>
          <p>{ editProduct ? "Edit Product" : "New Product"}</p>
          {errorMsg.length > 0 && <p className={styles["error_msg"]}>{errorMsg}</p>}
        </section>
        <form className={styles["form"]} onSubmit={editProduct ? handleEdit : updateProduct ? handleUpdate : handleSubmit}>
          <section className={styles["content_wrapper-1"]}>
            <input
              type="text"
              placeholder="Product Title"
              value={productTitle}
              onChange={handleProductTitleChange}
            />
            <div className={styles["price_con"]}>
              <input
                type="text"
                placeholder="0"
                value={productPrice}
                onChange={handleProductPriceChange}
                className={styles["price_input_padding"]}
              />
              <div onClick={() => setEditCurrency(!editCurrency)} className={styles["currency_dropdown"]}>
                <p>{productCurrency}</p>
                <CollapseIcon style={styles["dropicon"]} />
              </div>
              {editCurrency &&
              <div className={styles["currencies"]}>
                <p onClick={() => {setProductCurrency("₦"); setEditCurrency(false)}}>₦</p>
                <p onClick={() => {setProductCurrency("$"); setEditCurrency(false)}}>$</p>
              </div>
              }
            </div>
            <input
              type="text"
              placeholder="Product QTY"
              value={productQty}
              onChange={handleProductQTYChange}
            />
            <Select
              isSearchable
              isClearable
              options={options}
              menuPortalTarget={document.body}
              maxMenuHeight={150}
              onChange={handleCategoryChange}
              styles={customStyles}
              placeholder="Product Category"
            />
            <textarea
              className={styles["textarea"]}
              placeholder="Product Description"
              onChange={handleProductDescriptionChange}
              value={productDescription}
            />
            <input
              type="file"
              ref={productImageRef}
              onChange={handleFileupload}
              id={styles["file-upload"]}
            />
          </section>
          <section className={styles["content_wrapper-2"]}>
            {/* <AddProductCard /> */}
            {uploadImage}
            <section className={styles["submit-wrapper"]}>
              <input type="submit" value={loading ? "Saving...": editProduct ? "Save Changes" : "Save Product"} id={styles["submit"]} />
            </section>
          </section>
        </form>
      </section>
    </Modal>
  );
};
export default NewProductModal;
