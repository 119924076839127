import styles from "./social.module.css";

// import { io } from "socket.io-client";
import { checkuser } from "../../utilities/helpers";
import ComingSoon from "../../components/ComingSoon/ComingSoon";

const Socials = () => {
  checkuser();
  return (
    <div id="livechat_con" className={styles.wrapper}>
      <div id="conversation_col" className={styles["conversation-column"]}>
        <header>Conversation</header>
        <section>
          <div className={styles["messages-top-controls"]}>

          </div>
          <div className={styles.messages}>
            <ul>
              <p className={styles["text-align"]}>No conversations yet!</p>
            </ul>
          </div>
        </section>
      </div>
      <div className={styles["conversation-body-column"]}>
        {/* <header>
          <div className={styles["conversation-body-column_col"]}>
            <Link to="/live-chat/all">
              <div className={`${styles.chat} ${filter === "all" && styles.unread}`}>All chats</div>
            </Link>
            <Link to="/live-chat/unread">
            <div className={`${styles.chat} ${filter === "unread" && styles.unread}`}>Unread</div>
            </Link>
            <Link to="/live-chat/read">
            <div className={`${styles.chat} ${filter === "read" && styles.unread}`}>Read</div>
            </Link>
            <Link to="/live-chat/escalated">
            <div className={`${styles.chat} ${filter === "escalated" && styles.unread}`}>Escalated</div>
            </Link>
            <Link to="/live-chat/resolved">
            <div className={`${styles.chat} ${filter === "resolved" && styles.unread}`}>Resolved</div>
            </Link>
          </div>
          <div className={styles.form_group2}>
            <div onClick={() => {setSelectMode(true)}}>
              {
              mode === 'auto' &&
              <CircuitIcon style={`${styles.mode_icon} ${styles.auto_icon}`} />
              }
              {
              mode === 'hybrid' &&
              <HybridIcon style={`${styles.mode_icon} ${styles.hybrid_icon}`} />
              }
              <p onClick={() => {setSelectMode(true)}}>
                {mode === "auto" &&
                "Automatic"
                }
                {mode === "hybrid" &&
                "Manual"
                }
              </p>
              <span onClick={() => {setSelectMode(true)}}><CollapseIcon style={styles["collapse_icon"]} /></span>
              {selectMode &&
              <div id="more_option_con" className={styles["more_option_con"]}>
                <div className={`${mode === 'auto' && styles.selectedMode}`}>
                  <div className={styles["more_option"]} onClick={() => {updateMode("Automatic Mode"); setSelectMode(false)}}>
                    <CircuitIcon style={`${styles.mode_icon} ${styles.auto_icon}`} />
                    <h5>Automatic</h5>
                    {mode === 'auto' &&
                    <MarkStrokeIcon style={`${styles.selected_icon}`} />
                    }
                  </div>
                </div>
                <div className={`${mode === 'hybrid' && styles.selectedMode}`}>
                  <div className={styles["more_option"]} onClick={() => {updateMode("Hybrid Mode"); setSelectMode(false)}}>
                    <HybridIcon style={`${styles.mode_icon} ${styles.hybrid_icon}`} />
                    <h5>Manual</h5>
                    {mode === 'hybrid' &&
                    <MarkStrokeIcon style={`${styles.selected_icon}`} />
                    }
                  </div>
                </div>
                <div>
                  <Link to="/settings/mode">
                    <div className={styles["learn_more"]}>
                      <h5>Learn more</h5>
                      <UrgencyIcon style={styles["icons"]} />
                    </div>
                  </Link>
                </div>
              </div>
              }
            </div>
          </div>
        </header> */}
          <section id="conversations">
            <section className={styles.left}>
              {/* <div
              style={selectedConversation && selectedConversation.aiMode === "hybrid" ? {height: `calc(100vh - 20px - 1vh - 1vh - 95px - ${messageBoxHeight} - ${draftBoxHeight})`}: {height: `calc(100vh - 95px - ${draftBoxHeight})`}}
                className={`${
                  selectedConversation && selectedTicketId && selectedMessages && selectedMessages.length
                    ? styles["flex-end"]
                    : styles["flex-center"]
                } ${styles["conversation-message"]}`}
              >
                {!selectedConversation && chats && chats.length > 0 && (
                  <p className={styles["select-message"]}>
                    Select a message to display
                  </p>
                )}
                
              </div> */}
              <ComingSoon />
            </section>
            
          </section>
      </div>
    </div>
  );
}


export default Socials
