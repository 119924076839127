import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./SavedFAQs.module.css";
import createFaqStyles from "../CreateFAQ/createFAQ.module.css";
import {
  ArrowRightIcon2,
  ArrowLeftIcon,
  AddFAQSVG,
  AllChatStrokecon,
  BinSVG,
  CancelIcon,
  CheckIcon,
  CollapseIcon,
  DownloadCloudIconStroke,
  EditFAQSVG,
  EmptyLineIconStroke,
  EsclamationIcon,
  ExportIconStroke,
  EyeIcon,
  FileIcon2,
  QuestionIcon2,
  ReplyIcon,
  TrainingIcon,
  UploadIcon,
  UploadIcon2,
} from "../../../assets/icons";
import { AxiosError } from "axios";
import FAQ from "../../../components/FAQ/FAQ";
import {
  formatText,
  getCookie,
  getParagraphs,
  showToast,
  validateArrayOfObjects,
} from "../../../utilities/helpers";
import Modal from "../../Modal/Modal";
import instance from "../../../api";
import { Link } from "react-router-dom";
import CategorisedFAQ from "../../CategorisedFAQ/CategorisedFAQ";
import { saveAs } from "file-saver";
import { Document, Packer, Paragraph, TextRun } from "docx";

interface FAQsType {
  _id: string;
  question: string;
  response: string;
}

interface SavedFaqsProps {
  faqs: FAQsType[];
  setFaqs: Function;
  suggestedFaqs: Array<any>;
  setSuggestedFaqs: Function;
  hasCategorizedFaq: boolean;
  categorizedFaqs: Array<any>;
  getCategorizeFaq: Function;
  hasFaq: any;
  setHasFaq: Function;
}

const SavedFAQs: FC<SavedFaqsProps> = ({
  faqs,
  setFaqs,
  suggestedFaqs,
  setSuggestedFaqs,
  hasCategorizedFaq,
  categorizedFaqs,
  getCategorizeFaq,
}) => {
  const [selectFaqIndex, setSelectFaqIndex] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [suggestedFaqId, setSuggestedFaqId] = useState<string | null>(null);
  const [action, setAction] = useState<"edit" | "delete" | "add" | null>(null);
  // const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  const [isEditingQuestionLoading, setIsEditingQuestionLoading] =
    useState<boolean>(false);
  const [isEditingReplyLoading, setIsEditingReplyLoading] =
    useState<boolean>(false);
  const [selectedFaqIds, setSelectedFaqIds] = useState<Array<string>>();
  const [selectedCategorisedFaqIds, setSelectedCategorisedFaqIds] =
    useState<any>();
  const [selectedSuggestedFaqIds, setSelectedSuggestedFaqIds] =
    useState<Array<string>>();
  const [selectedNewFaq, setSelectedNewFaq] = useState<any>();
  const [isAddingLoading, setIsAddingLoading] = useState<boolean>(false);
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [confirmDeleteSuggested, setConfirmDeleteSuggested] =
    useState<boolean>(false);
  const [newQuestionFaq, setQuestionNewFaq] = useState<{
    value: string;
    isTouched: boolean;
    error: string | null;
  }>({ value: "", isTouched: false, error: null });
  const [newReplyFaq, setReplyNewFaq] = useState<{
    value: string;
    isTouched: boolean;
    error: string | null;
  }>({ value: "", isTouched: false, error: null });
  const [updateQuestionFaq, setUpdateQuestionFaq] = useState<{
    value: string;
    isTouched: boolean;
    error: string | null;
  }>({ value: "", isTouched: false, error: null });
  const [updateReplyFaq, setUpdateReplyFaq] = useState<{
    value: string;
    isTouched: boolean;
    error: string | null;
  }>({ value: "", isTouched: false, error: null });
  const [updateSuggestedReplyFaq, setUpdateSuggestedReplyFaq] = useState<{
    value: string;
    isTouched: boolean;
    error: string | null;
  }>({ value: "", isTouched: false, error: null });
  const [updateCategorisedReplyFaq, setUpdateCategorisedReplyFaq] = useState<{
    value: string;
    isTouched: boolean;
    error: string | null;
  }>({ value: "", isTouched: false, error: null });
  let right;
  const user = JSON.parse(localStorage.getItem("user")!);
  const userData = {
    userId: user?.userId,
    businessId: user?.businessId,
  };
  const [replySuggestedFaq, setReplySuggestedFaq] = useState<boolean>(false);
  const [editCategorisedFaq, setEditCategorisedFaq] = useState<boolean>(false);
  const [selectedCategorisedFaq, setSelectedCategorisedFaq] = useState<any>();
  const [createProduct, setCreateProduct] = useState<boolean>(false);
  const [tab, setTab] = useState<string>("saved");
  const [productName, setProductName] = useState<string>();
  const [productDescription, setProductDescription] = useState<string>();
  const [products, setProducts] = useState<Array<any>>();
  const [showProducts, setShowProducts] = useState<boolean>(false);
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [confirmDeleteProduct, setConfirmDeleteProduct] =
    useState<boolean>(false);
  const [confirmDeleteCategorised, setConfirmDeleteCategorised] = useState<
    string | null
  >();
  const [categorisedDropDown, setCategorisedDropDown] = useState<
    string | null
  >();
  const [uploadFAQ, setUploadFAQ] = useState<boolean>(false);
  const [uploadCategoriedFaq, setUploadCategoriedFaq] =
    useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [fileContent, setFileContent] = useState<string>("");
  const [showText, setShowText] = useState(false);
  const [text, setText] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [savedProducts, setSavedProducts] = useState<Array<any>>([]);
  const uploadRef = useRef<HTMLInputElement | null>(null);
  const textRef = useRef<HTMLInputElement | null>(null);
  const [productId, setProductId] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [totalSuggestedFaq, setTotalSuggestedFaq] = useState<number>(1);
  const limit = 10;
  // console.log(userData);
  // console.log(error);

  useEffect(() => {
    getProducts();
    getSuggestedFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedItem = (index: any) => {
    setSelectFaqIndex(index);
  };

  const selectFaqs = (id: string) => {
    if (selectedFaqIds) {
      const index = selectedFaqIds.indexOf(id);
      console.log(index);
      if (index >= 0) {
        let ids = selectedFaqIds;
        ids.splice(index, 1);
        console.log(ids);
        setSelectedFaqIds((prev: any) => [...ids]);
      } else {
        setSelectedFaqIds((prev: any) => [...prev, id]);
      }
    } else {
      setSelectedFaqIds((prev: any) => [id]);
    }
  };

  const selectCategorisedFaqs = (id: string, productId: string) => {
    let newSelectedCategorisedFaqIds = selectedCategorisedFaqIds;
    if (
      newSelectedCategorisedFaqIds &&
      newSelectedCategorisedFaqIds[productId]
    ) {
      if (newSelectedCategorisedFaqIds[productId]) {
        const index = newSelectedCategorisedFaqIds[productId].indexOf(id);
        console.log(index);
        if (index >= 0) {
          let ids = newSelectedCategorisedFaqIds[productId];
          ids.splice(index, 1);
          newSelectedCategorisedFaqIds[productId] = ids;
          console.log(ids);

          setSelectedCategorisedFaqIds((prev: any) => ({
            ...newSelectedCategorisedFaqIds,
          }));
        } else {
          let catfaqs = newSelectedCategorisedFaqIds[productId];
          catfaqs.push(id);
          newSelectedCategorisedFaqIds[productId] = catfaqs;
          console.log(newSelectedCategorisedFaqIds);
          setSelectedCategorisedFaqIds((prev: any) => ({
            ...newSelectedCategorisedFaqIds,
          }));
        }
      } else {
        newSelectedCategorisedFaqIds[productId] = [id];
        setSelectedCategorisedFaqIds((prev: any) => ({
          ...newSelectedCategorisedFaqIds,
        }));
      }
    } else {
      let newSelectedCategorisedFaqIds: any = {};
      newSelectedCategorisedFaqIds[productId] = [id];
      setSelectedCategorisedFaqIds((prev: any) => newSelectedCategorisedFaqIds);
    }
  };

  const selectSuggestedFaqs = (id: string) => {
    if (selectedSuggestedFaqIds) {
      const index = selectedSuggestedFaqIds.indexOf(id);
      console.log(index);
      if (index >= 0) {
        let ids = selectedSuggestedFaqIds;
        ids.splice(index, 1);
        console.log(ids);
        setSelectedSuggestedFaqIds((prev: any) => [...ids]);
      } else {
        setSelectedSuggestedFaqIds((prev: any) => [...prev, id]);
      }
    } else {
      setSelectedSuggestedFaqIds((prev: any) => [id]);
    }
  };

  const handleAddDetectedFaq = (e: FormEvent<HTMLFormElement>) => {
    setIsEditingReplyLoading(true);
    e.preventDefault();
    let token = getCookie("Authorization");
    console.log({ productId, updateSuggestedReplyFaq });
    // const newFAQs = faqs.filter((faq, index) => selectFaqIndex !== index);
    // console.log(newFAQs);
    instance
      .post(
        `/api/detected-faq/reply/${suggestedFaqId}`,
        {
          reply: updateSuggestedReplyFaq.value,
          productId: productId,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        showToast("primary", "Success!", "FAQ added");
        let newFaq = faqs;
        newFaq.push(response.data.data);
        setFaqs(newFaq);
        setIsEditingReplyLoading(false);
        const sugg = suggestedFaqs.filter(
          (faq, index) => suggestedFaqId !== faq.faqId
        );
        console.log(sugg);
        setSuggestedFaqs(sugg);
        setSelectedNewFaq(null);
        setUpdateSuggestedReplyFaq((prev) => ({
          ...prev,
          value: "",
        }));
        setProductId(null);
        setReplySuggestedFaq(false);
      })
      .catch((error) => {
        console.log(error);
        if (error instanceof AxiosError) {
          if (error.response) {
            setError(error?.response.data.message);
            showToast("secondary", "Error!", "Error adding FAQ");
          }
        } else {
          setError(error.message);
        }
        setIsEditingReplyLoading(false);
      });
  };

  const nextPageTicket = (num: number) => {
    // setSelectedFilterDepartment(null)
    // setSelectedFilterDate(null)
    let token = getCookie("Authorization");
    setIsLoading(true);
    if (localStorage.getItem("user")) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      let url = `/api/detected-faq/business/${userData.businessId}?page=${num}&limit=${limit}`;

      instance
        .get(url, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.data.length >= 0) {
            // if(department){
            //   const tickets = res.data.data.filter((chat:any) => chat.department === department);
            //   setTickets(tickets)
            // }else{
            // setTickets(res.data.data);
            // }
            // setHasTicket(true);
            setSuggestedFaqs(res.data.data);
          }
          // setTotalTicket(res.data.total);
          setTotalSuggestedFaq(res.data.total);
          setTotalPage(res.data.pages);
          setCurrentPage(res.data.currentPage);
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
          console.log(e.response.data);
          if (
            e.response.data === "This business doesn't have a knowledge base"
          ) {
            // setHasTicket(false);
          }
          // setHasTicket(false);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  right = (
    <div className={styles["new_detected_con"]}>
      {suggestedFaqs.length <= 0 ? (
        <div className={styles["new_detected_emptycontent"]}>
          <div className={styles["new_detected_content_col"]}>
            <h4 className={styles["right-p-1"]}>New detected FAQs</h4>
            <p className={styles["right-p-2"]}>
              Newly detected customer frequently asked questions would show up
              here, once you go live.
            </p>
            <p className={styles["right-p-3"]}>
              You will be notified to provide the appropriate feedback.
            </p>
          </div>
          <div className={styles["new_detected_content_col"]}>
            <div className={styles["new_detected_content_icon"]}>
              <QuestionIcon2 style={styles["question_icon"]} />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles["new_detected_content"]}>
          {/* <div className={styles["new_detected_content_icon"]}>
          <QuestionIcon2 style={styles["question_icon"]} />
        </div> */}
          <div className={styles["new_detected_content_faqs"]}>
            {suggestedFaqs.map((suggestedFaq: any) => {
              return (
                <div
                  key={suggestedFaq?.faqId}
                  className={styles["new_detected_content_faqs_con"]}
                >
                  <div
                    onClick={() => {
                      setSelectedNewFaq(suggestedFaq);
                    }}
                    style={{
                      backgroundColor:
                        selectedNewFaq?.faqId === suggestedFaq.faqId
                          ? "#e5eeff"
                          : "",
                    }}
                    className={styles["new_detected_content_faqs_question"]}
                  >
                    <div
                      onClick={() => selectSuggestedFaqs(suggestedFaq.faqId)}
                    >
                      <input
                        className={`livechat`}
                        onChange={() => {}}
                        checked={
                          selectedSuggestedFaqIds
                            ? selectedSuggestedFaqIds.find(
                                (id) => id === suggestedFaq.faqId
                              )
                              ? true
                              : false
                            : false
                        }
                        type="checkbox"
                      />
                    </div>
                    <div
                      className={styles["new_detected_content_faq_question"]}
                    >
                      <h4>Question : </h4>
                      <p>{suggestedFaq.question}</p>
                    </div>

                    {suggestedFaq.ticketId &&
                      selectedNewFaq?.faqId === suggestedFaq.faqId && (
                        <Link
                          className={styles["view_icon"]}
                          to={`/live-chat?ticketId=${suggestedFaq.ticketId}`}
                        >
                          <EyeIcon style={styles["icon"]} />
                        </Link>
                      )}
                    {selectedNewFaq?.faqId === suggestedFaq.faqId && (
                      <button
                        className={styles["reply_icon"]}
                        onClick={() => {
                          setSuggestedFaqId(suggestedFaq.faqId);
                          setReplySuggestedFaq(true);
                        }}
                      >
                        <ReplyIcon style={styles[""]} />
                      </button>
                    )}
                  </div>
                  {/* {suggestedFaq.faqId === suggestedFaqId &&
              <div className={styles["update-faq"]}>
                <form onSubmit={handleAddDetectedFaq} >
                  <textarea
                    placeholder="Enter possible possible question"
                    cols={10}
                    rows={2}
                    value={updateReplyFaq.value}
                    onChange={(e) =>
                      setUpdateReplyFaq((prev) => ({
                        ...prev,
                        value: e.target.value,
                      }))
                    }
                  />
                  <div>
                    <input
                      type="submit"
                      value={isEditingReplyLoading ? "Loading..." : "Update"}
                      className={styles["input-button"]}
                      disabled={isEditingReplyLoading ? true : false}
                    />
                  </div>
                </form>
              </div>
              } */}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {suggestedFaqs.length <= 0 && (
        <div className={styles["button-wrapper"]}>
          <button>Provide Answers</button>
        </div>
      )}
    </div>
  );

  const handleEdit = () => {
    // const toBeEditedFAQ = faqs[selectFaqIndex!];
    console.log(selectFaqIndex);
    const toBeEditedFAQ = faqs.filter(
      (faq, index) => selectFaqIndex === faq._id
    );
    console.log(toBeEditedFAQ);
    if (typeof selectFaqIndex === "object" && action === "edit") {
      console.log(selectFaqIndex);
      setError(
        (prev) =>
          "To edit an item, first click on the item and then click on the delete button"
      );
      return;
    }
    setAction("edit");
    if (toBeEditedFAQ[0]?.question) {
      setUpdateQuestionFaq((prev) => ({
        ...prev,
        value: toBeEditedFAQ[0]?.question,
      }));
      setUpdateReplyFaq((prev) => ({
        ...prev,
        value: toBeEditedFAQ[0]?.response,
      }));
    }
  };

  const confirmDeleteAction = () => {
    if (typeof selectFaqIndex === "object") {
      setError(
        (prev) => "To delete an item click on the item and then click on delete"
      );
      return;
    }
    setConfirmDelete(false);
    handleDelete();
  };

  const handleDelete = () => {
    if (typeof selectFaqIndex === "object") {
      setError(
        (prev) => "To delete an item click on the item and then click on delete"
      );
      return;
    }
    console.log();
    let token = getCookie("Authorization");
    // eslint-disable-next-line no-restricted-globals
    // let confirmDelete = confirm("Are you sure you want to delete this?");
    // setIsDeleteLoading(true);
    // const newFAQs = faqs.filter((faq, index) => selectFaqIndex !== index);
    // console.log(newFAQs);
    instance
      .delete(`/api/faq/${userData.businessId}/${selectFaqIndex}/delete`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        getFaqs();
        setConfirmDelete(false);
        showToast("secondary", "Success!", "FAQ removed");
        // setIsDeleteLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            setError(error?.response.data.message);
            showToast("secondary", "Error!", error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        // setIsDeleteLoading(false);
        console.log(error);
      });
    setSelectFaqIndex(null);
  };

  const handleDeletes = () => {
    console.log();
    let token = getCookie("Authorization");
    // eslint-disable-next-line no-restricted-globals
    // let confirmDelete = confirm("Are you sure you want to delete this?");
    // setIsDeleteLoading(true);
    // const newFAQs = faqs.filter((faq, index) => selectFaqIndex !== index);
    // console.log(newFAQs);
    instance
      .delete(`/api/faq/${userData.businessId}/delete`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
        data: {
          faqs: selectedFaqIds,
        },
      })
      .then((response) => {
        console.log(response.data);
        setSelectedFaqIds([]);
        setSelectFaqIndex(null);
        setConfirmDelete(false);
        getFaqs();
        showToast("secondary", "Success!", "FAQ removed");
        // setIsDeleteLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            setError(error?.response.data.message);
            showToast("secondary", "Error!", error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        // setIsDeleteLoading(false);
        console.log(error);
      });
    setSelectFaqIndex(null);
  };

  const handleDeleteCategorised = () => {
    console.log();
    let token = getCookie("Authorization");
    // eslint-disable-next-line no-restricted-globals
    // let confirmDelete = confirm("Are you sure you want to delete this?");
    // setIsDeleteLoading(true);
    // const newFAQs = faqs.filter((faq, index) => selectFaqIndex !== index);
    // console.log(newFAQs);
    let ids = [selectedCategorisedFaq.faqProductId];
    instance
      .delete(`/api/faq/categorize/business/${userData.businessId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
        data: {
          faqProducts: ids,
        },
      })
      .then((response) => {
        console.log(response.data);
        setSelectedFaqIds([]);
        setSelectedCategorisedFaq(undefined);
        setConfirmDeleteCategorised("");
        setSelectedCategorisedFaqIds(undefined);
        getCategorizeFaq();
        getFaqs();
        showToast("secondary", "Success!", response.data.message);
        // setIsDeleteLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            setError(error?.response.data.message);
            showToast("secondary", "Error!", error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        // setIsDeleteLoading(false);
        console.log(error);
      });
    setSelectFaqIndex(null);
  };

  const handleDeleteManyCategorised = () => {
    console.log();
    let token = getCookie("Authorization");
    // eslint-disable-next-line no-restricted-globals
    // let confirmDelete = confirm("Are you sure you want to delete this?");
    // setIsDeleteLoading(true);
    // const newFAQs = faqs.filter((faq, index) => selectFaqIndex !== index);
    // console.log(newFAQs);
    let ids =
      selectedCategorisedFaqIds[Object.keys(selectedCategorisedFaqIds)[0]];
    instance
      .delete(`/api/faq/categorize/business/${userData.businessId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
        data: {
          faqProducts: ids,
        },
      })
      .then((response) => {
        console.log(response.data);
        setSelectedFaqIds([]);
        setSelectFaqIndex(null);
        setConfirmDeleteCategorised("");
        setSelectedCategorisedFaqIds(undefined);
        getCategorizeFaq();
        getFaqs();
        showToast("secondary", "Success!", response.data.message);
        // setIsDeleteLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            setError(error?.response.data.message);
            showToast("secondary", "Error!", error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        // setIsDeleteLoading(false);
        console.log(error);
      });
    setSelectFaqIndex(null);
  };

  const handleDeleteSuggested = () => {
    // if (typeof selectedNewFaq === "object") {
    //   setError(
    //     (prev) => "To delete an item click on the item and then click on delete"
    //   );
    //   return;
    // }
    console.log();
    let token = getCookie("Authorization");
    // eslint-disable-next-line no-restricted-globals
    // let confirmDelete = confirm("Are you sure you want to delete this?");
    // setIsDeleteLoading(true);
    // const newFAQs = faqs.filter((faq, index) => selectFaqIndex !== index);
    // console.log(newFAQs);
    instance
      .delete(`/api/detected-faq/business/${userData.businessId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
        data: {
          faqs: [selectedNewFaq.faqId],
        },
      })
      .then((response) => {
        console.log(response.data);
        getSuggestedFaqs();
        setConfirmDeleteSuggested(false);
        setSelectedNewFaq(null);
        setSelectedSuggestedFaqIds([]);
        showToast("secondary", "Success!", "FAQ removed");
        // setIsDeleteLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            setError(error?.response.data.message);
            showToast("secondary", "Error!", error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        // setIsDeleteLoading(false);
        console.log(error);
      });
    // setSelectFaqIndex(null)
  };

  const handleDeletesSuggested = () => {
    console.log();
    let token = getCookie("Authorization");
    // eslint-disable-next-line no-restricted-globals
    // let confirmDelete = confirm("Are you sure you want to delete this?");
    // setIsDeleteLoading(true);
    // const newFAQs = faqs.filter((faq, index) => selectFaqIndex !== index);
    // console.log(newFAQs);
    instance
      .delete(`/api/detected-faq/business/${userData.businessId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
        data: {
          faqs: selectedSuggestedFaqIds,
        },
      })
      .then((response) => {
        console.log(response.data);
        setSelectedSuggestedFaqIds([]);
        setSelectedNewFaq(null);
        setConfirmDeleteSuggested(false);
        getSuggestedFaqs();
        showToast("secondary", "Success!", "FAQ removed");
        // setIsDeleteLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            setError(error?.response.data.message);
            showToast("secondary", "Error!", error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        // setIsDeleteLoading(false);
        console.log(error);
      });
    // setSelectFaqIndex(null)
  };

  const handleAdd = () => {
    setAction("add");
  };

  const handleUpdateFaqQuestion = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsEditingQuestionLoading(true);
    // faqs[selectFaqIndex!].question = updateQuestionFaq.value;
    let token = getCookie("Authorization");
    // console.log(updateQuestionFaq.value, newFaqs);
    instance
      .patch(
        `/api/faq/${userData.businessId}/${selectFaqIndex}/update`,
        {
          faq: { question: updateQuestionFaq.value },
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        // setFaqs(response.data.faqs);
        let index = faqs.findIndex((faq) => faq._id === selectFaqIndex);
        console.log(index, "sdfdsw");
        if (index > -1) {
          let newFaq = faqs[index];
          let newFaqs = faqs;
          newFaq["question"] = response.data.question;
          newFaqs[index] = newFaq;
          setFaqs(newFaqs);
        }
        showToast("primary", "Success!", "FAQ question updated");
        setIsEditingQuestionLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            showToast("secondary", "Error!", error?.response.data.message);
            setError(error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        setIsEditingQuestionLoading(false);
        console.log(error);
      });
  };

  const handleUpdateFaqReply = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsEditingReplyLoading(true);
    // console.log(updateReplyFaq.value, newFaqs);
    let token = getCookie("Authorization");
    // console.log(updateQuestionFaq.value, newFaqs);
    instance
      .patch(
        `/api/faq/${userData.businessId}/${selectFaqIndex}/update`,
        {
          faq: { response: updateReplyFaq.value },
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        // setFaqs(response.data.faqs);
        let index = faqs.findIndex((faq) => faq._id === selectFaqIndex);
        if (index > -1) {
          let newFaq = faqs[index];
          let newFaqs = faqs;
          newFaq["response"] = response.data.response;
          newFaqs[index] = newFaq;
          setFaqs(newFaqs);
        }
        setAction(null);
        setUpdateQuestionFaq((prev) => ({
          ...prev,
          value: "",
        }));
        setUpdateReplyFaq((prev) => ({
          ...prev,
          value: "",
        }));
        showToast("primary", "Success!", "FAQ reply updated");
        setIsEditingReplyLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            showToast("secondary", "Error!", error?.response.data.message);
            setError(error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        setIsEditingReplyLoading(false);
        console.log(error);
      });
  };

  const handleAddFAQ = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newFaqs = [
      ...faqs,
      { question: newQuestionFaq.value, response: newReplyFaq.value },
    ];
    console.log(newFaqs);
    setIsAddingLoading(true);
    const user = JSON.parse(localStorage.getItem("user")!);
    const userData = {
      userId: user?.userId,
      businessId: user?.businessId,
    };
    // const newFAQs = faqs.filter((faq, index) => selectFaqIndex !== index);
    // console.log(newFAQs);
    let token = getCookie("Authorization");

    instance
      .post(
        `/api/faq/${userData.businessId}/create`,
        {
          faqs: [
            { question: newQuestionFaq.value, response: newReplyFaq.value },
          ],
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        showToast("primary", "Success!", "FAQ added");
        // setFaqs(response.data.faqs);
        getFaqs();
        setIsAddingLoading(false);
        setQuestionNewFaq((prev) => ({ ...prev, value: "" }));
        setReplyNewFaq((prev) => ({ ...prev, value: "" }));
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            setError(error?.response.data.message);
            showToast("secondary", "Error!", "Error adding FAQ");
          }
        } else {
          setError(error.message);
        }
        setIsAddingLoading(false);
        console.log(error);
      });
  };

  if (typeof selectFaqIndex === "string" && action === "edit") {
    right = (
      <div className={styles["new_detected_con"]}>
        <div className={styles["update-faq"]}>
          <header>Update FAQs</header>
          <form onSubmit={handleUpdateFaqQuestion}>
            <header>Question</header>
            <textarea
              placeholder="Enter possible possible question"
              cols={10}
              rows={2}
              value={updateQuestionFaq.value}
              onChange={(e) =>
                setUpdateQuestionFaq((prev) => ({
                  ...prev,
                  value: e.target.value,
                }))
              }
            />
            <div>
              <input
                type="submit"
                value={isEditingQuestionLoading ? "Loading..." : "Update"}
                className={styles["input-button"]}
                disabled={isEditingQuestionLoading ? true : false}
              />
            </div>
          </form>
          <form onSubmit={handleUpdateFaqReply}>
            <header>Response</header>
            <textarea
              placeholder="Enter possible possible response"
              cols={10}
              rows={10}
              value={updateReplyFaq.value}
              onChange={(e) =>
                setUpdateReplyFaq((prev) => ({
                  ...prev,
                  value: e.target.value,
                }))
              }
            />
            <div>
              <input
                type="submit"
                value={isEditingReplyLoading ? "Loading..." : "Update"}
                className={styles["input-button"]}
                disabled={isEditingReplyLoading ? true : false}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  if (action === "add") {
    right = (
      <div className={styles["new_detected_con"]}>
        <div className={styles["update-faq"]}>
          <header>Add more FAQ</header>
          <form>
            <header>Question</header>
            <textarea
              placeholder="Enter possible possible question"
              cols={10}
              rows={2}
              value={newQuestionFaq.value}
              onChange={(e) =>
                setQuestionNewFaq((prev) => ({
                  ...prev,
                  value: e.target.value,
                }))
              }
            />
          </form>
          <form onSubmit={handleAddFAQ}>
            <header>Response</header>
            <textarea
              placeholder="Enter possible possible response"
              cols={10}
              rows={10}
              value={newReplyFaq.value}
              onChange={(e) =>
                setReplyNewFaq((prev) => ({ ...prev, value: e.target.value }))
              }
            />
            <div>
              <input
                type="submit"
                value={isAddingLoading ? "Loading..." : "Add"}
                disabled={isAddingLoading ? true : false}
                className={styles["input-button"]}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }

  const getFaqs = () => {
    let token = getCookie("Authorization");
    instance
      .get(`/api/faq/uncategorized-faqs/business/${userData.businessId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.data && res.data.data.length > 0) {
          setFaqs(res.data.data);
        } else {
          setFaqs([]);
        }
      })
      .catch((e) => {
        console.log(e.response.data);

        // setHasKnowledgeBase(false)
      });
  };

  const getProducts = () => {
    let token = getCookie("Authorization");
    instance
      .get(`/api/faq/business/${userData.businessId}/product-category`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.data) {
          setProducts(res.data.data);
        }
        // setHasKnowledgeBase(true)
        // if(res.data.faqs && res.data.faqs.length > 0){
        //   setFaqs(res.data.faqs)
        //   setHasFaq(true)
        // }else{
        //   setHasFaq(false)
        // }
      })
      .catch((e) => {
        console.log(e.response.data);
      });
  };

  const getSuggestedFaqs = () => {
    let token = getCookie("Authorization");
    instance
      .get(`/api/detected-faq/business/${userData.businessId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log({ detectedFaq: res.data });
        setSuggestedFaqs(res.data.data);
        setTotalSuggestedFaq(res.data.total);
        setTotalPage(res.data.pages);
        setCurrentPage(res.data.currentPage);
        // setHasKnowledgeBase(true)
        // if(res.data.faqs && res.data.faqs.length > 0){
        //   setFaqs(res.data.faqs)
        //   setHasFaq(true)
        // }else{
        //   setHasFaq(false)
        // }
      })
      .catch((e) => {
        console.log(e.response.data);
      });
  };

  const createNewProduct = (ifAddMore: boolean) => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/faq/business/${userData.businessId}/product-category`,
        {
          name: productName,
          description: productDescription,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        handleUploadSaveCategoriedFaq(res.data.data.id);
        setSavedProducts((prev) => [
          ...prev,
          { productName, productDescription, file, fileContent },
        ]);
        if (res.data.data) {
          getProducts();
          if (selectedFaqIds) {
            categoriseFaq(res.data.data.id);
          }
          setProductName("");
          setProductDescription("");
          if (!ifAddMore) {
            setCreateProduct(false);
            setSavedProducts((prev) => []);
          }
          showToast("primary", "Success!", res.data.message);
        }
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            // setError(error?.response.data.message);
            showToast("secondary", "Error!", error?.response.data.message);
          }
        } else {
          // setError(error.message);
        }
        // setHasKnowledgeBase(false)
      });
  };

  const categoriseFaq = (productId: any) => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/faq/categorize/business/${userData.businessId}/product/${productId}`,
        {
          faqs: selectedFaqIds,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setProductName("");
        setProductDescription("");
        setCreateProduct(false);
        setShowProducts(false);
        getCategorizeFaq();
        setSelectedFaqIds(undefined);
        setSelectedProduct(undefined);
        setSelectFaqIndex(null);
        getFaqs();
        showToast("primary", "Success!", res.data.message);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            // setError(error?.response.data.message);
            showToast("secondary", "Error!", error?.response.data.message);
          }
        } else {
          // setError(error.message);
        }

        // setHasKnowledgeBase(false)
      });
  };

  const handleSelectedProduct = (product: any) => {
    console.log({ promiseProduct: product });
    if (product) {
      setSelectedProduct(product);
      setProductName(product.name);
      setProductDescription(product.description);
      setProductId(product?.id);
    } else {
      setSelectedProduct(undefined);
      setProductName("");
      setProductDescription("");
    }
    setShowProducts(false);
  };

  const handleUpdateCategorisedFaqQuestion = (
    e: ChangeEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    setIsEditingQuestionLoading(true);
    // faqs[selectFaqIndex!].question = updateQuestionFaq.value;
    let token = getCookie("Authorization");
    // console.log(updateQuestionFaq.value, newFaqs);
    instance
      .patch(
        `/api/faq/${userData.businessId}/${selectFaqIndex}/update`,
        {
          faq: { question: updateQuestionFaq.value },
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        // setFaqs(response.data.faqs);
        let index = faqs.findIndex((faq) => faq._id === selectFaqIndex);
        console.log(index, "sdfdsw");
        if (index > -1) {
          let newFaq = faqs[index];
          let newFaqs = faqs;
          newFaq["question"] = response.data.question;
          newFaqs[index] = newFaq;
          setFaqs(newFaqs);
        }
        showToast("primary", "Success!", "FAQ question updated");
        setIsEditingQuestionLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            showToast("secondary", "Error!", error?.response.data.message);
            setError(error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        setIsEditingQuestionLoading(false);
        console.log(error);
      });
  };

  const handleUpdateCategorisedFaqReply = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsEditingReplyLoading(true);
    // console.log(updateReplyFaq.value, newFaqs);
    let token = getCookie("Authorization");
    // console.log(updateQuestionFaq.value, newFaqs);
    instance
      .patch(
        `/api/faq/${userData.businessId}/${selectedCategorisedFaq?._id}/update?productId=${productId}`,
        {
          faq: { response: updateCategorisedReplyFaq.value },
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        // setFaqs(response.data.faqs);
        let index = faqs.findIndex(
          (faq) => faq._id === selectedCategorisedFaq?._id
        );
        if (index > -1) {
          let newFaq = faqs[index];
          let newFaqs = faqs;
          newFaq["response"] = response.data.response;
          newFaqs[index] = newFaq;
          setFaqs(newFaqs);
        }
        setAction(null);
        setUpdateCategorisedReplyFaq((prev) => ({
          ...prev,
          value: "",
        }));
        getCategorizeFaq();
        showToast("primary", "Success!", "FAQ reply updated");
        setIsEditingReplyLoading(false);
        setEditCategorisedFaq(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            showToast("secondary", "Error!", error?.response.data.message);
            setError(error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        setIsEditingReplyLoading(false);
        console.log(error);
      });
  };

  const handleDeleteProduct = () => {
    setIsEditingReplyLoading(true);
    // console.log(updateReplyFaq.value, newFaqs);
    let token = getCookie("Authorization");
    // console.log(updateQuestionFaq.value, newFaqs);
    instance
      .delete(
        `/api/faq/business/${userData.businessId}/product/${selectedProduct.id}`,
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        // setFaqs(response.data.faqs);
        // let index = faqs.findIndex(faq => faq._id === selectedCategorisedFaq?._id);
        // if(index > -1){
        //   let newFaq = faqs[index];
        //   let newFaqs = faqs;
        //   newFaq["response"] = response.data.response;
        //   newFaqs[index] = newFaq;
        //   setFaqs(newFaqs)
        // }
        // setAction(null)
        // setUpdateCategorisedReplyFaq((prev) => ({
        //   ...prev,
        //   value: "",
        // }));
        getCategorizeFaq();
        getFaqs();
        showToast("primary", "Success!", "Product deleted successfully");
        setConfirmDeleteProduct(false);
        setSelectedCategorisedFaq(undefined);
        setEditCategorisedFaq(false);
        setProductName("");
        setProductDescription("");
        setSelectedProduct(undefined);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          if (error.response) {
            showToast("secondary", "Error!", error?.response.data.message);
            setError(error?.response.data.message);
          }
        } else {
          setError(error.message);
        }
        setIsEditingReplyLoading(false);
        console.log(error);
      });
  };

  function generateWordDocument(jsonData: any) {
    const document = new Document({
      sections: [
        {
          children: [
            ...jsonData
              .map((data: any) => {
                const arr: Paragraph[] = [];
                arr.push(
                  createInstitutionHeader("Question", `${data.question}`)
                );

                arr.push(
                  createInstitutionHeader("Response", `${data.response}`)
                );

                return arr;
              })
              .reduce((prev: any, curr: any) => prev.concat(curr), []),
          ],
        },
      ],
    });

    return document;
  }

  const createInstitutionHeader = (name: string, value: string): Paragraph => {
    return new Paragraph({
      children: [
        new TextRun({
          text: `${name} : `,
          bold: true,
        }),
        new TextRun({
          text: `${value} \n`,
        }),
      ],
    });
  };

  const handleDownload = () => {
    let data: any = [];
    if (tab === "saved") {
      data = faqs;
    } else {
      let format = [];
      for (let i = 0; i < categorizedFaqs.length; i++) {
        let faqs = categorizedFaqs[i].faqs;
        for (let j = 0; j < faqs.length; j++) {
          format.push({
            question: faqs[j].question,
            response: faqs[j].answer,
          });
        }
      }
      data = format;
    }

    const doc = generateWordDocument(data);

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `faqs-${new Date().toLocaleString()}.docx`);
    });
  };

  const faqComponents = faqs.map((faq, index) => (
    <FAQ
      key={faq._id}
      index={faq._id}
      question={faq.question}
      response={faq.response}
      onClick={handleSelectedItem}
      selected={faq._id === selectFaqIndex}
      selectFaqs={selectFaqs}
      selectedFaqIds={selectedFaqIds ?? []}
    />
  ));
  const categorisedFaqComponents = categorizedFaqs.map((categorizedFaq) => {
    console.log({ categorizedFaq: categorizedFaq });
    return (
      <CategorisedFAQ
        key={categorizedFaq}
        categorisedFaq={categorizedFaq}
        setEditCategorisedFaq={setEditCategorisedFaq}
        setSelectedCategorisedFaq={setSelectedCategorisedFaq}
        selectedCategorisedFaqIds={selectedCategorisedFaqIds ?? []}
        selectCategorisedFaqs={selectCategorisedFaqs}
        setConfirmDeleteCategorised={setConfirmDeleteCategorised}
        faqProductIds={categorizedFaq.faqProductIds}
        categorisedDropDown={categorisedDropDown}
        setCategorisedDropDown={setCategorisedDropDown}
        handleSelectedProduct={handleSelectedProduct}
        setTab={setTab}
        setConfirmDeleteProduct={setConfirmDeleteProduct}
        uploadFaq={setUploadCategoriedFaq}
        setProductId={setProductId}
      />
    );
  });

  const handleUpload = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };
  const handleUploadSave = async () => {
    setIsLoading(true);
    //send file here and navigate
    if (!file && text === "") {
      setError("Upload a valid document");
      return;
    }
    const userData: any = JSON.parse(localStorage.getItem("user")!);
    const formattedFileContent = fileContent
      ? formatText(fileContent)
      : formatText(text);
    // console.log(formattedFileContent);
    // return;
    console.log(formattedFileContent);
    console.log(validateArrayOfObjects(formattedFileContent));
    if (formattedFileContent.length <= 0) {
      setError("Faq is either empty or format is malformed");
      console.log("Faq is either empty or format is malformed");
      return;
    }
    const formData = {
      faqs: formattedFileContent,
    };
    console.log(formattedFileContent);
    // return;
    try {
      let token = getCookie("Authorization");
      // let request = hasKnowledgeBase ? 'update': 'create'
      const { data } = await instance.post(
        `/api/faq/${userData.businessId}/create`,
        formData,
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      getFaqs();

      showToast("primary", "Success!", "FAQ uploaded");
      setIsLoading(false);
      setUploadFAQ(false);
      setTab("saved");
      setFile(null);
      setFileContent("");
      console.log(data);
      // navigate(`/training-center/faq`);
    } catch (err: any) {
      if (err instanceof AxiosError) {
        console.log(err.response?.data.message);
        setError(err.response?.data.message);
        showToast("secondary", "Error!", "Error uploading FAQ");
      } else {
        console.log(err);
        setError(err);
        setIsLoading(false);
      }
    }
  };

  const uploadDocument = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      setFile(e.target.files[0]);

      reader.onload = (e) => {
        const content = e.target?.result;
        const paragraphs = getParagraphs(content);
        setFileContent(paragraphs.join(""));
      };
      reader.onerror = (err) => console.error(err);

      reader.readAsBinaryString(e.target.files[0]);
    }
  };

  const removeFile = () => {
    setFile(null);
  };

  const handleUploadCategoriedFaq = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
    }
  };
  const handleUploadSaveCategoriedFaq = async (productId: string) => {
    setIsLoading(true);
    //send file here and navigate
    if (!file && text === "") {
      setError("Upload a valid document");
      return;
    }
    const userData: any = JSON.parse(localStorage.getItem("user")!);
    const formattedFileContent = fileContent
      ? formatText(fileContent)
      : formatText(text);
    // console.log(formattedFileContent);
    // return;
    console.log(formattedFileContent);
    console.log(validateArrayOfObjects(formattedFileContent));
    if (formattedFileContent.length <= 0) {
      setError("Faq is either empty or format is malformed");
      console.log("Faq is either empty or format is malformed");
      return;
    }
    const formData = {
      faqs: formattedFileContent,
    };
    console.log(formattedFileContent);
    // return;
    try {
      let token = getCookie("Authorization");
      // let request = hasKnowledgeBase ? 'update': 'create'
      const { data } = await instance.post(
        `/api/faq/product/${productId}/business/${userData.businessId}`,
        formData,
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      getCategorizeFaq();

      showToast("primary", "Success!", "FAQ uploaded");
      setIsLoading(false);
      setProductName("");
      setProductDescription("");
      setSelectedProduct(undefined);
      setUploadCategoriedFaq(false);
      setFile(null);
      setFileContent("");
      console.log(data);
      // navigate(`/training-center/faq`);
    } catch (err: any) {
      if (err instanceof AxiosError) {
        console.log(err.response?.data.message);
        setError(err.response?.data.message);
        showToast("secondary", "Error!", "Error uploading FAQ");
      } else {
        console.log(err);
        setError(err);
        setIsLoading(false);
      }
    }
  };

  const uploadCategoriedFaqDocument = (e: ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      setFile(e.target.files[0]);

      reader.onload = (e) => {
        const content = e.target?.result;
        const paragraphs = getParagraphs(content);
        setFileContent(paragraphs.join(""));
      };
      reader.onerror = (err) => console.error(err);

      reader.readAsBinaryString(e.target.files[0]);
    }
  };

  const removeCategoriedFaqFile = () => {
    setFile(null);
  };

  // const handleTextChange = () => {
  //   if (file) {
  //     return;
  //   }
  //   setShowText(true);
  //   if (textRef.current) {
  //     textRef.current.focus();
  //     console.log("should focus");
  //   }
  // };
  console.log({ suggestedFaqs });
  return (
    <section className={styles.wrapper}>
      <header>
        <TrainingIcon style={styles["trainingIcon"]} />
        <p>Product Knowledge Center</p>
        {/* <button className={styles["faq_btn"]} onClick={() => handleDownload()}>
          <ExportIconStroke style={styles["faq_btn-icon_stroke"]} />
          Export FAQ
        </button>
        <button className={styles["faq_btn"]} onClick={() => setUploadFAQ(true)}>
          <UploadIcon2 style={styles["faq_btn-icon"]} />
          Upload FAQ
        </button> */}
        {selectedFaqIds && selectedFaqIds.length > 0 && (
          <button
            className={styles["categorise_faq_btn"]}
            onClick={() => setCreateProduct(true)}
          >
            <AllChatStrokecon style={styles["button-icon"]} />
            Categorise FAQ
          </button>
        )}
      </header>
      {((selectedFaqIds && selectedFaqIds?.length === 1) ||
        !selectedFaqIds) && (
        <Modal isOpen={confirmDelete}>
          <div className={styles["delete_modal"]}>
            <div className={styles["modal-header"]}>
              <div className={styles["header"]}>Delete FAQ</div>
            </div>
            <div className={styles["modal-content"]}>
              Are you sure you want to remove this FAQ from the list ?
            </div>
            <div className={styles["modal-controls"]}>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setConfirmDelete(false);
                }}
              />
              <input
                type="button"
                value="Remove"
                onClick={() => confirmDeleteAction()}
              />
            </div>
          </div>
        </Modal>
      )}
      {selectedFaqIds && selectedFaqIds?.length > 1 && (
        <Modal isOpen={confirmDelete}>
          <div className={styles["delete_modal"]}>
            <div className={styles["modal-header"]}>
              <div className={styles["header"]}>Delete FAQs</div>
            </div>
            <div className={styles["modal-content"]}>
              Are you sure you want to remove this FAQs from the list ?
            </div>
            <div className={styles["modal-controls"]}>
              <div
                style={{
                  flex: 1,
                  fontFamily: "Axiforma",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#313233",
                }}
              >
                {`${selectedFaqIds?.length} faqs selected`}
              </div>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setConfirmDelete(false);
                }}
              />
              <input
                type="button"
                value="Remove"
                onClick={() => handleDeletes()}
              />
            </div>
          </div>
        </Modal>
      )}

      {((selectedSuggestedFaqIds && selectedSuggestedFaqIds?.length === 1) ||
        !selectedSuggestedFaqIds) && (
        <Modal isOpen={confirmDeleteSuggested}>
          <div className={styles["delete_modal"]}>
            <div className={styles["modal-header"]}>
              <div className={styles["header"]}>Delete suggested FAQ</div>
            </div>
            <div className={styles["modal-content"]}>
              Are you sure you want to remove this FAQ from the list ?
            </div>
            <div className={styles["modal-controls"]}>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setConfirmDeleteSuggested(false);
                }}
              />
              <input
                type="button"
                value="Remove"
                onClick={() => handleDeleteSuggested()}
              />
            </div>
          </div>
        </Modal>
      )}
      {selectedSuggestedFaqIds && selectedSuggestedFaqIds?.length > 1 && (
        <Modal isOpen={confirmDeleteSuggested}>
          <div className={styles["delete_modal"]}>
            <div className={styles["modal-header"]}>
              <div className={styles["header"]}>Delete suggested FAQs</div>
            </div>
            <div className={styles["modal-content"]}>
              Are you sure you want to remove this FAQs from the list ?
            </div>
            <div className={styles["modal-controls"]}>
              <div
                style={{
                  flex: 1,
                  fontFamily: "Axiforma",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "#313233",
                }}
              >
                {`${selectedSuggestedFaqIds?.length} faqs selected`}
              </div>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setConfirmDeleteSuggested(false);
                }}
              />
              <input
                type="button"
                value="Remove"
                onClick={() => handleDeletesSuggested()}
              />
            </div>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={uploadFAQ}
        onClose={() => {
          setUploadFAQ(false);
          setFile(null);
        }}
      >
        <div style={{ padding: "0" }} className={styles["delete_modal"]}>
          <section id="uploadfaq_box" className={createFaqStyles["sec-2"]}>
            {/* <p onClick={handleTextChange}>
                Copy Paste your business FAQ here
              </p>
              <p>OR</p> */}
            {file && (
              <p className={createFaqStyles["file-name"]}>
                <FileIcon2 style={createFaqStyles["file-icon"]} />
                {file?.name}
                <button onClick={removeFile}>X</button>
              </p>
            )}
            <section className={createFaqStyles["child-sec"]}>
              <input
                type="file"
                ref={uploadRef}
                className={createFaqStyles["file-field"]}
                onChange={uploadDocument}
                accept=".docx"
              />
              <button
                id="uploadfaq_btn"
                className={createFaqStyles["upload-button"]}
                onClick={handleUpload}
                disabled={text.length > 0 ? true : false}
              >
                <UploadIcon style={createFaqStyles["upload-icon"]} /> Upload a
                DOC instead
              </button>
              <button
                id="savefaq_btn"
                className={createFaqStyles["upload-button-2"]}
                onClick={handleUploadSave}
                // disabled={false}
                disabled={
                  file || text.length > 0 ? false : isLoading ? true : true
                }
              >
                {isLoading ? "Loading..." : "Save"}
              </button>
            </section>
          </section>
        </div>
      </Modal>

      <Modal
        isOpen={uploadCategoriedFaq}
        onClose={() => {
          setUploadCategoriedFaq(false);
          setFile(null);
        }}
      >
        <div style={{ padding: "0" }} className={styles["delete_modal"]}>
          <section id="uploadfaq_box" className={createFaqStyles["sec-2"]}>
            {/* <p onClick={handleTextChange}>
                Copy Paste your business FAQ here
              </p>
              <p>OR</p> */}
            {file && (
              <p className={createFaqStyles["file-name"]}>
                <FileIcon2 style={createFaqStyles["file-icon"]} />
                {file?.name}
                <button onClick={removeCategoriedFaqFile}>X</button>
              </p>
            )}
            <section className={createFaqStyles["child-sec"]}>
              <input
                type="file"
                ref={uploadRef}
                className={createFaqStyles["file-field"]}
                onChange={uploadCategoriedFaqDocument}
                accept=".docx"
              />
              <button
                id="uploadfaq_btn"
                className={createFaqStyles["upload-button"]}
                onClick={handleUploadCategoriedFaq}
                disabled={text.length > 0 ? true : false}
              >
                <UploadIcon style={createFaqStyles["upload-icon"]} /> Upload
                file
              </button>
              <button
                id="savefaq_btn"
                className={createFaqStyles["upload-button-2"]}
                onClick={() =>
                  handleUploadSaveCategoriedFaq(selectedProduct.id)
                }
                // disabled={false}
                disabled={
                  file || text.length > 0 ? false : isLoading ? true : true
                }
              >
                {isLoading ? "Loading..." : "Save"}
              </button>
            </section>
          </section>
        </div>
      </Modal>

      {selectedProduct && confirmDeleteProduct && (
        <Modal isOpen={confirmDeleteProduct}>
          <div className={styles["delete_modal"]}>
            <div className={styles["modal-header"]}>
              <div className={styles["header"]}>Delete Product</div>
            </div>
            <div className={styles["modal-content"]}>
              Are you sure you want to remove this product from the list ?
            </div>
            <div className={styles["modal-controls"]}>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setConfirmDeleteProduct(false);
                }}
              />
              <input
                type="button"
                value="Remove"
                onClick={() => handleDeleteProduct()}
              />
            </div>
          </div>
        </Modal>
      )}

      {selectedCategorisedFaqIds &&
        Object.keys(selectedCategorisedFaqIds).length > 0 && (
          <Modal isOpen={confirmDeleteCategorised === "many"}>
            <div className={styles["delete_modal"]}>
              <div className={styles["modal-header"]}>
                <div className={styles["header"]}>Delete FAQ</div>
              </div>
              <div className={styles["modal-content"]}>
                Are you sure you want to remove this FAQs from the list ?
              </div>
              <div className={styles["modal-controls"]}>
                <div
                  style={{
                    flex: 1,
                    fontFamily: "Axiforma",
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "20px",
                    letterSpacing: "0em",
                    textAlign: "left",
                    color: "#313233",
                  }}
                >
                  {`${
                    selectedCategorisedFaqIds[
                      Object.keys(selectedCategorisedFaqIds)[0]
                    ].length
                  } faqs selected`}
                </div>
                <input
                  type="button"
                  value="No"
                  onClick={() => {
                    setConfirmDeleteCategorised(null);
                  }}
                />
                <input
                  type="button"
                  value="Remove"
                  onClick={() => handleDeleteManyCategorised()}
                />
              </div>
            </div>
          </Modal>
        )}

      {selectedCategorisedFaq && (
        <Modal isOpen={confirmDeleteCategorised === "single"}>
          <div className={styles["delete_modal"]}>
            <div className={styles["modal-header"]}>
              <div className={styles["header"]}>Delete FAQ</div>
            </div>
            <div className={styles["modal-content"]}>
              Are you sure you want to remove this FAQ from the list ?
            </div>
            <div className={styles["modal-controls"]}>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setConfirmDeleteCategorised(null);
                }}
              />
              <input
                type="button"
                value="Remove"
                onClick={() => handleDeleteCategorised()}
              />
            </div>
          </div>
        </Modal>
      )}

      <Modal
        isOpen={replySuggestedFaq}
        onClose={() => setReplySuggestedFaq(false)}
      >
        <div className={styles["reply_suggested_faq"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]}>Reply FAQ</div>
          </div>
          <div className={styles["update_suggested-faq"]}>
            <form onSubmit={handleAddDetectedFaq}>
              <label>Question</label>
              <textarea
                placeholder="Enter possible possible question"
                cols={10}
                rows={2}
                value={selectedNewFaq?.question}
              />
            </form>
            <form onSubmit={handleAddDetectedFaq}>
              <label>Response</label>
              <textarea
                placeholder="Enter possible possible question"
                cols={10}
                rows={5}
                value={updateSuggestedReplyFaq.value}
                onChange={(e) =>
                  setUpdateSuggestedReplyFaq((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }))
                }
              />
              <select
                className={styles["reply-products-select"]}
                onChange={(e) => setProductId(e.target.value)}
              >
                <option>Select Product</option>
                {products?.map((product) => {
                  return (
                    <option key={product?.id} value={product?.id}>
                      {product.name}
                    </option>
                  );
                })}
              </select>
              <div className={styles["update_suggested-faq_input"]}>
                <input
                  type="submit"
                  value={isEditingReplyLoading ? "Loading..." : "Update"}
                  className={styles["input-button"]}
                  disabled={isEditingReplyLoading ? true : false}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <Modal isOpen={createProduct} onClose={() => setCreateProduct(false)}>
        <div className={styles["create_faq_category"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]}>Add Product</div>
          </div>
          <div className={styles["create_faq_category_form"]}>
            {savedProducts?.map((product: any) => {
              return (
                <form>
                  <div className={styles["create_faq_category_form_group"]}>
                    <label>Product Name</label>
                    <input
                      placeholder="Enter product name"
                      value={product.productName}
                      disabled
                      // onChange={(e) =>setProductName(e.target.value)}
                    />
                  </div>
                  <div className={styles["create_faq_category_form_group"]}>
                    <label>Product Description</label>
                    <textarea
                      placeholder="Enter product description"
                      cols={10}
                      rows={1}
                      value={product.productDescription}
                      disabled
                      // onChange={(e) =>setProductDescription(e.target.value)}
                    />
                  </div>
                  <div className={styles["create_faq_category_form_group"]}>
                    <label>Product Description</label>
                    <div
                      style={{
                        backgroundColor: "#EFFCF8",
                        border: "1px dashed #A0EBD4",
                        width: "160px",
                        marginTop: "0",
                      }}
                      className={styles.uploadfileButton}
                    >
                      <p className={createFaqStyles["file-name"]}>
                        <FileIcon2 style={createFaqStyles["file-icon"]} />
                        {product.file?.name}
                        {/* <button onClick={removeCategoriedFaqFile}>X</button> */}
                      </p>
                    </div>
                  </div>
                  <CheckIcon style={styles["checkIcon"]} />
                </form>
              );
            })}
            <form onSubmit={handleAddDetectedFaq}>
              <div className={styles["create_faq_category_form_group"]}>
                <label>Product Name</label>
                <input
                  placeholder="Enter product name"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
                {/* <div onClick={() => {selectedProduct ? handleSelectedProduct(null) : setShowProducts(!showProducts)}} className={styles["products_collapse"]}>
                  {selectedProduct ? 
                  <CancelIcon style={styles["products_collapse_icon_cancel"]} />
                  :
                  <CollapseIcon style={styles["products_collapse_icon"]} />
                  }
                </div> */}
                {showProducts && (
                  <div className={styles["products"]}>
                    {products &&
                      products.map((product) => {
                        return (
                          <div
                            onClick={() => handleSelectedProduct(product)}
                            className={styles["product"]}
                          >
                            {product.name}
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
              <div className={styles["create_faq_category_form_group"]}>
                <label>Product Description</label>
                <textarea
                  placeholder="Enter product description"
                  cols={10}
                  rows={1}
                  value={productDescription}
                  onChange={(e) => setProductDescription(e.target.value)}
                />
              </div>
            </form>
            <section className={styles["sec-1"]}>
              <section className={styles?.["sec-head"]}>
                <p>
                  Please attach the FAQ document for your product in the
                  specified format.
                </p>
              </section>
              <section className={styles?.["sec-body"]}>
                <div>
                  <EsclamationIcon style={styles.esclamation_icon} />
                </div>
                <div>
                  <p>
                    <span style={{ color: "#5100FF" }}>Example</span>
                  </p>
                  <p>
                    <span>Question</span>: Hello business, I will like to know
                    your business address.
                  </p>
                  <p>
                    <span>Response</span>: We do not have a physical store, but
                    you contact us on 080784***** and mail us at store@us.com
                  </p>
                </div>
              </section>
              {file ? (
                <div
                  style={{
                    backgroundColor: "#EFFCF8",
                    border: "1px dashed #A0EBD4",
                  }}
                  className={styles.uploadfileButton}
                >
                  <p className={createFaqStyles["file-name"]}>
                    <FileIcon2 style={createFaqStyles["file-icon"]} />
                    {file?.name}
                    <button onClick={removeCategoriedFaqFile}>X</button>
                  </p>
                </div>
              ) : (
                <div
                  onClick={handleUploadCategoriedFaq}
                  className={styles.uploadfileButton}
                >
                  <input
                    type="file"
                    ref={uploadRef}
                    className={createFaqStyles["file-field"]}
                    onChange={uploadCategoriedFaqDocument}
                    accept=".docx"
                  />
                  <DownloadCloudIconStroke style={styles.downloadIcon} />
                  Click to attach a doc. file
                </div>
              )}
            </section>
          </div>
          <div className={styles["modal-controls"]}>
            <input
              type="button"
              value="Add more"
              onClick={() => createNewProduct(true)}
            />
            <input
              type="button"
              value="Save Products"
              onClick={() => {
                createNewProduct(false);
              }}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={editCategorisedFaq}
        onClose={() => setEditCategorisedFaq(false)}
      >
        <div className={styles["reply_suggested_faq"]}>
          <div className={styles["modal-header"]}>
            <div className={styles["header"]}>Edit FAQ</div>
          </div>
          <div className={styles["update_suggested-faq"]}>
            <form onSubmit={handleAddDetectedFaq}>
              <label>Question</label>
              <textarea
                placeholder="Enter possible possible question"
                cols={10}
                rows={1}
                value={selectedCategorisedFaq?.question}
              />
            </form>
            <form onSubmit={handleUpdateCategorisedFaqReply}>
              <label>Response</label>
              <textarea
                placeholder="Enter possible possible question"
                cols={10}
                rows={2}
                defaultValue={selectedCategorisedFaq?.answer}
                onChange={(e) =>
                  setUpdateCategorisedReplyFaq((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }))
                }
              />
              <select
                className={styles["reply-products-select"]}
                onChange={(e) => setProductId(e.target.value)}
              >
                {products?.map((product) => {
                  return (
                    <option
                      key={product?.id}
                      value={product?.id}
                      selected={product?.id === productId}
                    >
                      {product.name}
                    </option>
                  );
                })}
              </select>
              <div className={styles["update_suggested-faq_input"]}>
                <input
                  type="submit"
                  value={isEditingReplyLoading ? "Loading..." : "Update"}
                  className={styles["input-button"]}
                  disabled={isEditingReplyLoading ? true : false}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
      {/* {hasCategorizedFaq &&  */}
      <div className={styles.faq_tab_con}>
        <div className={`${styles.faq_tab} ${styles.faq_tab_hover}`}>
          Frequently Asked Questions
        </div>
        {/* <div className={`${styles.faq_tab} ${tab === "categorised" && styles.faq_tab_hover}`}>
          Categorised FAQ
        </div> */}
      </div>
      {/* } */}
      <section
        style={{
          paddingTop: "0px",
          top: "172px",
          height: "calc(100vh - 172px)",
        }}
        className={styles.content}
      >
        {/* {tab === "saved" &&
        <section className={styles.left}>
          <section className={styles["saved-faq"]}>
            <section className={styles["saved-faq-header_con"]}>
              <section className={styles["saved-faq-header"]}>
                <header className={styles["head"]}>Saved FAQs</header>
                <section className={styles["tail"]}>
                  {(!selectFaqIndex && !selectedFaqIds) && 
                  <button onClick={handleAdd}>
                    <AddFAQSVG style={styles["button-icon"]} />
                    Add more FAQs
                  </button>
                  }
                  {(selectFaqIndex && !selectedFaqIds) && 
                  
                  <button onClick={handleEdit}>
                    <EditFAQSVG style={styles["button-icon"]} />
                    Edit
                  </button>
                  }
                  {selectedFaqIds && selectedFaqIds.length === 1 && 
                  
                  <button onClick={handleEdit}>
                    <EditFAQSVG style={styles["button-icon"]} />
                    Edit
                  </button>
                  }
                  {(selectedFaqIds || selectFaqIndex) && 
                  <button onClick={() => setConfirmDelete(true)}>
                    <BinSVG style={styles["button-icon"]} />
                    Delete
                  </button>
                  }
                </section>
              </section>
            </section>
            <section className={styles["saved-faq-body_con"]}>
              <section className={styles["saved-faq-body"]}>
                {faqComponents}
              </section>
            </section>
          </section>
        </section>
        } */}
        <section className={styles.left}>
          <section className={styles["saved-faq"]}>
            <section className={styles["saved-faq-header_con"]}>
              <section className={styles["saved-faq-header"]}>
                <header className={styles["head"]}>Product Categories</header>
                {hasCategorizedFaq && (
                  <div
                    onClick={() => setCreateProduct(true)}
                    className={`${styles.faq_add_product}`}
                  >
                    Add Product
                  </div>
                )}
              </section>
            </section>
            <section className={styles["saved-faq-body_con"]}>
              <section
                style={{ padding: "0" }}
                className={styles["saved-faq-body"]}
              >
                {hasCategorizedFaq ? (
                  categorisedFaqComponents
                ) : (
                  <div className={styles["coming-soon-container"]}>
                    <div className={styles["coming-soon-container_content"]}>
                      <EmptyLineIconStroke
                        style={styles["coming-soon_button_empty_icon"]}
                      />
                      <p className={styles["coming-soon-description"]}>
                        You have no categorised FAQ
                      </p>
                      <div
                        onClick={() => {
                          setCreateProduct(true);
                        }}
                        className={styles["coming-soon_button"]}
                      >
                        <p>Add a product</p>
                        <AddFAQSVG style={styles["coming-soon_button_icon"]} />
                        {/* <div></div> */}
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </section>
          </section>
        </section>
        <section className={styles.right_con}>
          <section className={styles.right}>
            {suggestedFaqs.length > 0 && (
              <div className={styles.right_head}>
                <h4>Newly detected FAQs</h4>
                {suggestedFaqs &&
                  Array.isArray(suggestedFaqs) &&
                  suggestedFaqs.length > 0 && <p className={styles["totalSuggestedFaq"]}>{totalSuggestedFaq}</p>}
                {(selectedNewFaq ||
                  (selectedSuggestedFaqIds &&
                    selectedSuggestedFaqIds.length > 0)) && (
                  <button onClick={() => setConfirmDeleteSuggested(true)}>
                    <BinSVG style={styles["button-icon"]} />
                    Delete
                  </button>
                )}
              </div>
            )}
            {right}
            {suggestedFaqs &&
              Array.isArray(suggestedFaqs) &&
              suggestedFaqs.length > 0 && (
                <div className={styles.pagination}>
                  <div
                    onClick={() => {
                      setCurrentPage(currentPage <= 1 ? 1 : currentPage - 1);
                      nextPageTicket(currentPage <= 1 ? 1 : currentPage - 1);
                    }}
                    className={styles.pagination_btn_left}
                  >
                    <ArrowLeftIcon style={styles.pagination_icon} />
                  </div>
                  <div>
                    <h5
                      className={styles.pagination_num}
                    >{`${currentPage} of ${totalPage}`}</h5>
                  </div>
                  <div
                    onClick={() => {
                      setCurrentPage(
                        currentPage >= totalPage ? totalPage : currentPage + 1
                      );
                      nextPageTicket(
                        currentPage >= totalPage ? totalPage : currentPage + 1
                      );
                    }}
                    className={styles.pagination_btn_right}
                  >
                    <ArrowRightIcon2 style={styles.pagination_icon} />
                  </div>
                </div>
              )}
          </section>
        </section>
      </section>
    </section>
  );
};
export default SavedFAQs;
