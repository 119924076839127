import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Signup from "./pages/Signup/Signup";
import Error from "./pages/Error/Error";
import MainLayout from "./layout/MainLayout";
import LiveChat from "./pages/LiveChat/LiveChat";
import Settings from "./pages/Settings/Settings";
import Department from "./pages/Settings/Department";
import Inventory from "./pages/Inventory/Inventory";
import TrainingCenter from "./pages/TrainingCenter/TrainingCenter";
import SignIn from "./pages/Signin/SignIn";
import TrainingCenterFAQ from "./pages/TrainingCenter/TrainingCenterFAQ/TrainingCenterFAQ";
import Personal from "./pages/Account/Personal";
import Business from "./pages/Account/Business";
import Teams from "./components/Settings/general/teams/Teams";
import Departments from "./components/Settings/general/departments/Departments";
import Gmail from "./pages/Gmail/Gmail";
import Mode from "./components/Settings/general/mode/Mode";
import './App.css';
import Dashboard from "./pages/Dashboard/Dashboard";
import Tickets from "./pages/Tickets/Tickets";
import Payment from "./pages/payment/payment";
import Channels from "./components/Settings/Channel/Channel";
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import GoogleCallback from "./pages/GoogleCallback/googleCallback";
import Customers from "./pages/Customer/Customer";

import "./fonts/font/Axiforma-Light.ttf";
import "./fonts/font/Axiforma-Medium.ttf";
import "./fonts/font/Axiforma-Bold.ttf";
import "./fonts/font/Axiforma-SemiBold.ttf";
import "./fonts/font/Axiforma-Regular.ttf";
import Admins from "./components/Settings/general/admin/Admins";
import Agents from "./components/Settings/general/agent/Agents";
import Subscriptions from "./components/Settings/Subscription/Subscription";
import Notifications from "./components/Settings/configuration/Notification/Notification";
import Engagements from "./components/Settings/configuration/Engagements/Engagements";
import Thread from "./pages/Gmail/Thread/Thread";
import ComingSoon from "./components/ComingSoon/ComingSoon";
import Orders from "./pages/Order/Orders";
import Integrations from "./components/Settings/Developers/Integrations/Integrations";
import Socials from "./pages/Socials/Social";
import Analysis from "./pages/Analysis/Analysis";
import Engagement from "./pages/Analysis/Engagement/Engagement";
import Conversations from "./pages/Analysis/Conversations/Conversations";
import OrdersAnalysis from "./pages/Analysis/Orders/Orders";
import AllInOne from "./pages/AllInOne/AllInOne";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Channel from "./pages/Analysis/Channel/Channel";
import TicketReport from "./pages/Analysis/TicketReport/TicketReport";

const userData = JSON.parse(localStorage.getItem("user") as string);
const socket = new WebSocket(`wss://${process.env.REACT_APP_BACKEND_END_BASE_URL?.split("//")[1]}`, userData?.businessId ? userData.businessId : "1234");

const router = createBrowserRouter([
  { path: "/signup", element: <Signup />, errorElement: <Error /> },
  { path: "/login", element: <SignIn />, errorElement: <Error /> },
  { path: "/forgot-password", element: <ForgotPassword />, errorElement: <Error /> },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "live-chat", element: <LiveChat socket={socket} /> },
      { path: "live-chat/:filter", element: <LiveChat socket={socket} /> },
      { path: "email", element: <Gmail /> },
      { path: "email/:filter", element: <Gmail /> },
      { path: "socials", element: <Socials /> },
      { path: "thread/:threadId", element: <Thread /> },
      // { path: "conversations/:conversations", element: <AllInOne socket={socket} /> },
      // { path: "conversations/:conversations/:userId", element: <AllInOne socket={socket} /> },
      {
        path: "settings",
        children: [
          {
            index: true,
            element: <Settings />,
          },
          { path: "admins", element: <Admins /> },
          { path: "department", element: <Department /> },
          { path: "teams", element: <Teams /> },
          { path: "departments", element: <Departments /> },
          { path: "mode", element: <Mode /> },
          { path: "channels", element: <Channels /> },
          { path: "agents", element: <Agents /> },
          { path: "subscriptions", element: <Subscriptions /> },
          { path: "notifications", element: <Notifications /> },
          { path: "engagement", element: <Engagements /> },
          { path: "integrations", element: <Integrations /> },
        ],
      },
      {
        path: "shop",
        children: [
          {
            index: true,
            element: <Inventory />,
          },
          { path: "inventory", element: <Inventory /> },
          { path: "orders", element: <Orders /> },
        ],
      },
      {
        path: "training-center",
        element: <TrainingCenter />,
        // children: [{ path: "faq/:id", element: <TrainingCenterFAQ /> }],
      },
      {
        path: "training-center/faq",
        element: <TrainingCenterFAQ />,
      },
      {
        path: "callback/google",
        element: <GoogleCallback />,
      },
      {
        path: "account",
        children: [
          {
            index: true,
            element: <Settings />,
          },
          { path: "personal", element: <Personal /> },
          { path: "business", element: <Business /> },
        ],
      },
      {
        path: "tickets/:value",
        element: <Tickets socket={socket} />
      },
      {
        path: "customers/:value",
        element: <Customers />
      },
      {
        path: "customers",
        element: <Customers />
      },
      { path: "payment", element: <Payment /> },
      // { path: "analytics", 
      //   children: [
      //     {
      //       index: true,
      //       element: <Analysis />,
      //     },
      //     { path: ":filter", element: <Analysis /> },
      //     { path: "analysis", element: <Analysis /> },
      //     { path: "analysis/:filter", element: <Analysis /> },
      //     { path: "sales-pilot", element: <Engagement /> },
      //     { path: "conversations", element: <Conversations /> },
      //     { path: "orders", element: <OrdersAnalysis /> },
      //     { path: "channels", element: <Channel /> },
      //     {path:"ticket-report",element:<Analysis/>},
      //     {path:"ticket-report/:navitem",element:<Analysis/>},
      //     { path: "performance/:filter", element: <Analysis /> },
      //     { path: "performance", element: <Analysis /> },
      //   ],
      // },
    ],
    errorElement: <ComingSoon />,
  },
  { path: "*", element: <MainLayout />, children:[
    { path: "socials", element: <ComingSoon /> },
    { path: "connection", element: <ComingSoon /> },
    { path: "customer_engagement", element: <ComingSoon /> },
    { path: "notification", element: <ComingSoon /> },
    { path: "search", element: <ComingSoon /> },
  ]}
]);

function App() {
  return <>
  <ToastContainer />
  <RouterProvider router={router} />
  </>
}

export default App;
