import { FormEvent, useEffect, useState } from "react";
import styles from "./Account.module.css";
import { checkuser, getCookie, showToast } from "../../utilities/helpers";
import instance from "../../api";
import { CheckIcon, CollapseIcon, EditIcon } from "../../assets/icons";
import { AxiosError, AxiosResponse } from "axios";

export default function Business() {
  checkuser()
  const [formState, setFormState] = useState({
    businessName: "",
    email: "",
    phoneNo: "",
    sla: "",
    address: "",
    businessDescription: "",
  });
  const [prevFormState, setPrevFormState] = useState({
    businessName: "",
    email: "",
    phoneNo: "",
    sla: "",
    address: "",
    businessDescription: "",
  });
  const [edit, setEdit] = useState(false)
  const [edited, setEdited] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [businessDetails, setBusinessDetails] = useState<any>()
  const [selectRole, setSelectRole] = useState(false)
  const [selectContactUs, setSelectContactUs] = useState(false)
  const [slaTime, setSLATime] = useState('')
  const [contactUsMedium, setContactUsMedium] = useState('')
  const [prevContactUsMedium, setPrevContactUsMedium] = useState('')
  const [team, setTeam] = useState<any>({
    role:""
  })
  const { businessName, email, phoneNo, sla, address, businessDescription } = formState;
  const userData = JSON.parse(localStorage.getItem("user") as string);
  useEffect(() => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      // setBusinessId(userData.businessId)
      getBusiness();
      getTeam();

    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }, [])
  
  const getBusiness = () => {
    let token = getCookie("Authorization")
    
    instance.get(
      `api/business/get/${userData.businessId}`,
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data)
        setBusinessDetails(res.data)
        setFormState({
          businessName: res.data.businessName,
          email: res.data.supportEmail,
          phoneNo: res.data.phoneNo,
          sla: res.data.sla ? parseInt(res.data.sla, 10).toString() : "",
          address: res.data.address,
          businessDescription: res.data.description,
        })
        setPrevFormState({
          businessName: res.data.businessName,
          email: res.data.supportEmail,
          phoneNo: res.data.phoneNo,
          sla: res.data.sla ? res.data.sla : "",
          address: res.data.address,
          businessDescription: res.data.description,
        })
        setContactUsMedium(res.data.contactUsMedium)
        setPrevContactUsMedium(res.data.contactUsMedium)
        if(res.data.sla){
          if (res.data.sla.includes("hr")) {
            setSLATime("Hours");
          } else if (res.data.sla.includes("min")) {
            setSLATime("Minutes");
          } else if (res.data.sla.includes("sec")){
            setSLATime("Seconds");
          }
        }
    }).catch(e => {
      console.log(e.response.data)
    })
  }

  function onChange(e: any) {
    setFormState({ ...formState, [e.target.name]: e.target.value });
  }

  const check = () => {
    let time = ''
    if(slaTime === "Hours"){
      time = "hr"
    }else if(slaTime === "Minutes"){
      time = "min"
    }else if(slaTime === "Seconds"){
      time = "sec"
    }
    console.log(slaTime, time, sla)

    if(
      prevFormState.businessName === formState.businessName &&
      prevFormState.email === formState.email &&
      prevFormState.phoneNo === formState.phoneNo &&
      prevFormState.sla === sla+time &&
      prevFormState.address === formState.address &&
      prevFormState.businessDescription === formState.businessDescription &&
      prevContactUsMedium === contactUsMedium
    ){
      setEdited(false)
    }else{
      setEdited(true)
    }
  }

  useEffect(()=> {
    check()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState, slaTime, contactUsMedium])

  const submitHandler = async (e?: FormEvent) => {
    let token = getCookie("Authorization")
    e?.preventDefault();
    console.log(parseInt(businessDetails?.sla, 10))
    const userData = JSON.parse(localStorage.getItem("user")!);
    if(!phoneNo && !email){
      alert("You must include your phone number or email address")
      return
    }
    setIsLoading(true)
    let time = ''
    if(slaTime === "Hours"){
      time = "hr"
    }else if(slaTime === "Minutes"){
      time = "min"
    }else{
      time = "sec"
    }

    const requestBody = {
      userId: userData.userId,
      businessId: userData.businessId,
      businessName: businessName,
      supportEmail: email,
      sla: sla+time,
      phoneNo: phoneNo,
      description: businessDescription,
      address: address,
      contactUsMedium: contactUsMedium,
    };
    console.log(requestBody);

    // console.log(data, userData);
    // return navigate(`/training-center/faq`);
    try {
      const response: AxiosResponse = await instance.post(
        "api/business/update",
        { ...requestBody },
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log(response.data);
      const businessData = response.data;
      // userData.businessId = businessData.businessId;
      // localStorage.setItem("user", JSON.stringify(userData));
      if(businessData.businessId){
        showToast("primary", "Success!", "You have successfully updated your profile.")
      }else{
        getBusiness()
        showToast("secondary", "Error!", "Error updating business profile")
      }
      setEdited(false)
      setEdit(false)
      setIsLoading(false)
    } catch (error) {
      if (error instanceof AxiosError) {
        // if (error.response?.status === 400) {
        //   const message = error.response.data?.message;
        //   setFormInputs((prev) => ({
        //     ...prev,
        //     error: message,
        //   }));
        // }
        if (error.response?.status) {
          const message = error.response.data?.message;
          console.log(message)
          showToast("secondary", "Error!", message)
          // setFormInputs((prev) => ({
          //   ...prev,
          //   error: message,
          // }));
          // setError(message);
        }
      }
      setIsLoading(false)
      console.log(error);
    }
  };

  const getTeam = async () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance.get(
        `/api/team/${userData.businessId}/${userData.id}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log("Ishould be with Team" , res.data.data);
          setTeam(res.data.data)
          console.log('This is Team',team)
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }
  return (
    <>
      <section className={styles.wrapper}>
        <header>
          <h1>My Account</h1>
        </header>
        <section className={styles.content}>
          <div className={styles.content_header}>
            <h2>Business details</h2>{
              userData.role==="super-admin"||userData.role == "owner"?
               <button disabled={edit && !edited} className={edited ? styles["edited"] : ""} onClick={edited ? () => submitHandler() : () => setEdit(true)}>
               {edited ? "Save  Changes": isLoading ? "Loading..." : "Edit Profile"}
               {edited ?
               <CheckIcon style={styles[""]} />
               :
               <EditIcon style={styles[""]} />
               }
             </button>:""
            }
           
          </div>
          <div className={styles.section1}>
            <div className={styles.form}>
              <div className={styles.form_row}>
                <div className={styles.form_group}>
                  <label htmlFor="businessName">Business Name</label>
                  <input
                    readOnly={true}
                    id="businessName"
                    name="businessName"
                    type="text"
                    defaultValue={businessDetails?.businessName}
                    className={`${businessName && styles["error"]}`}
                  />
                </div>
                <div className={styles.form_group}>
                  <label htmlFor="email">Support Email</label>
                  <input
                    readOnly={!edit}
                    id="email"
                    name="email"
                    type="text"
                    defaultValue={businessDetails?.supportEmail}
                    className={`${email && styles["error"]} ${edit && styles["edit_value"]}`}
                    onChange={onChange}
                  />
                </div>
              </div>
              <hr className={styles.line} />
              <div className={styles.form_row}>
                <div className={styles.form_group}>
                  <label htmlFor="phoneNo">Support Phone No.</label>
                  <input
                    readOnly={!edit}
                    id="phoneNo"
                    name="phoneNo"
                    type="text"
                    defaultValue={businessDetails?.phoneNo}
                    className={`${phoneNo && styles["error"]} ${edit && styles["edit_value"]}`}
                    onChange={onChange}
                  />
                </div>
                <div className={styles.form_group}>
                  <label htmlFor="sla">SLA</label>
                  <div style={{display: "grid", gridTemplateColumns: "calc(30% - 5px) calc(55% - 5px)", gap: "10px"}}>
                  <input
                    readOnly={!edit}
                    id="sla"
                    name="sla"
                    type="number"
                    value={parseInt(sla, 10).toString()}
                    className={`${sla && styles["error"]} ${edit && styles["edit_value"]}`}
                    onChange={onChange}
                  />
                  <div onMouseEnter={() => setSelectRole(true)} onMouseLeave={() => setSelectRole(false)} className={`${styles.dropdown} `}>
                      <p className={``} onClick={() => {setSelectRole(true)}}>{slaTime}</p>
                      <span className={``} onClick={() => {setSelectRole(true)}}><CollapseIcon style={`${styles["collapse_icon"]} `} /></span>
                      {selectRole && edit &&
                      <div className={`${styles["more_option_con"]} `}>
                          <h5 className={``} onClick={() => {setSLATime("Hours"); setSelectRole(false)}}>Hours</h5>
                          <h5 className={``} onClick={() => {setSLATime("Minutes"); setSelectRole(false)}}>Minutes</h5>
                          <h5 className={``} onClick={() => {setSLATime("Seconds"); setSelectRole(false)}}>Seconds</h5>
                      </div>
                      }
                  </div>
                  </div>
                </div>
              </div>
              <hr className={styles.line} />
              <div className={styles.form_row}>
                <div className={styles.form_group}>
                  <label htmlFor="address">Address</label>
                  <input
                    readOnly={!edit}
                    id="address"
                    name="address"
                    type="text"
                    defaultValue={businessDetails?.address}
                    className={`${address && styles["error"]} ${edit && styles["edit_value"]}`}
                    onChange={onChange}
                  />
                </div>
                <div className={styles.form_group}>
                  <label htmlFor="contactUsMedium">Contact Us Medium</label>
                  {/* <div style={{display: "grid", gridTemplateColumns: "calc(30% - 5px) calc(55% - 5px)", gap: "10px"}}> */}
                  
                  <div onMouseEnter={() => setSelectContactUs(true)} onMouseLeave={() => setSelectContactUs(false)} className={`${styles.dropdown} `}>
                      <p className={``} onClick={() => {setSelectContactUs(true)}}>{contactUsMedium === "phone" ? "Phone Number": contactUsMedium === "email" ? "Email Address" : contactUsMedium === "none" ? "None" : "Both"}</p>
                      <span className={``} onClick={() => {setSelectContactUs(true)}}><CollapseIcon style={`${styles["collapse_icon"]} `} /></span>
                      {selectContactUs && edit &&
                      <div className={`${styles["more_option_con"]} `}>
                          <h5 className={``} onClick={() => {setContactUsMedium("both"); setSelectContactUs(false)}}>Both</h5>
                          <h5 className={``} onClick={() => {setContactUsMedium("email"); setSelectContactUs(false)}}>Email Address</h5>
                          <h5 className={``} onClick={() => {setContactUsMedium("phone"); setSelectContactUs(false)}}>Phone Number</h5>
                          <h5 className={``} onClick={() => {setContactUsMedium("none"); setSelectContactUs(false)}}>None</h5>
                      </div>
                      }
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <hr className={styles.line} />
              <div className={styles.form_group}>
                <label htmlFor="businessDescription">Business Description</label>
                <textarea
                  readOnly={!edit}
                  rows={4}
                  id="businessDescription"
                  name="businessDescription"
                  defaultValue={businessDetails?.description}
                  className={`${businessDescription && styles["error"]} ${edit && styles["edit_value"]}`}
                  onChange={onChange}
                />
              </div>
            </div>

            <div className={styles.image_card}>
              <img alt="user" src="/assets/images/profilenoimageuser.png" />
            </div>

          </div>
          <hr className={styles.line} />
        </section>
      </section>
    </>
  );
}
