import React, { useState } from "react";
import styles from "./Settings.module.css";
import Admin from "../../components/Settings/SettingsUser";
import Modal from "../../components/SettingsModal/SettingsModal";
let users = [
  {
    name: "Ihunna Promise",
    role: "Super-Admin",
    avatar: "hello",
    email: "ihunna.Adeniyi@credpal.com",
  },
  {
    name: "Victor Adeniyi",
    role: "Admin",
    avatar: "hello",
    email: "victor.Adeniyi@credpal.com",
  },
  {
    name: "Joshua Ibukun",
    role: "Admin",
    avatar: "hello",
    email: "joshua.Adeniyi@credpal.com",
  },
  {
    name: "Oluseyi Teniola",
    role: "Admin",
    avatar: "hello",
    email: "oluseyi.Adeniyi@credpal.com",
  },
];

export default function Settings() {
  const [modal, setModal] = useState<{
    show: boolean;
    email: null | string;
  }>({ show: false, email: null });
  const handleRemoveAdmin = (email: string) => {
    users = users.filter((user) => user.email !== email);
    setModal((prev) => ({ ...prev, show: false, email: null }));
  };

  const openModal = (email: string) => {
    setModal((prev) => ({ ...prev, show: true, email: email }));
    console.log(email);
  };

  const closeModal = () => {
    setModal((prev) => ({ ...prev, show: false, email: null }));
  };
  return (
    <>
      {modal.show && (
        <Modal
          email={modal.email!}
          closeModal={closeModal}
          handleRemoveUser={handleRemoveAdmin}
          header="Remove Admin"
          body="Are you sure you want to remove this admin from the admin list?"
        />
      )}
      <section className={styles.wrapper}>
        <header>
          <h1>Admin</h1>
          <input type="button" value="Add Admin" />
        </header>
        <section className={styles.content}>
          <div className={styles.usersList}>
            {users.map((user) => (
              <Admin
                key={user.name}
                name={user.name}
                role={user.role}
                avatar={user.avatar}
                email={user.email}
                handleAdminRemove={openModal}
              />
            ))}
          </div>
        </section>
      </section>
    </>
  );
}
