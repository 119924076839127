import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./TrainingCenter.module.css";
import {
  FileIcon,
  TrainingIcon,
} from "../../assets/icons";
import instance from "../../api";
import constants from "../../utilities/constants";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { checkuser, getCookie, showToast } from "../../utilities/helpers";
import { AxiosError, AxiosResponse } from "axios";
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement);

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    height: "7vh",
    border: "none",
    backgroundColor: "#f8f7fa",
    outline: "none",
    borderRadius: "6px",
    boxShadow: state.isFocused ? "0 0 0 1px #5100FF" : provided.boxShadow,
    marginTop: "1vh",
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    display: "none", // Hide the placeholder
  }),
  focus: (provided: any, state: any) => ({
    ...provided,
    display: "none", // Hide the placeholder
    outline: "1px solid red",
    border: "none",
  }),
};
// const customStyles2 = {
//   control: (provided: any, state: any) => ({
//     ...provided,
//     height: "7vh",
//     border: "none",
//     backgroundColor: "#f8f7fa",
//     outline: "none",
//     borderRadius: "6px",
//     boxShadow: state.isFocused ? "0 0 0 1px #5100FF" : provided.boxShadow,
//     marginTop: "1vh",
//   }),
//   placeholder: (provided: any, state: any) => ({
//     ...provided,
//     display: "none", // Hide the placeholder
//   }),
//   focus: (provided: any, state: any) => ({
//     ...provided,
//     display: "none", // Hide the placeholder
//     outline: "1px solid red",
//     border: "none",
//   }),
// };
const options: { value: string; label: string }[] = constants.industries;
// const options2: { key: string; label: string }[] = constants.nigerianBanks;

const TrainingCenter = () => {
  checkuser()
  const navigate = useNavigate();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [nextStep, setNextStep] = useState<string>("Link Your Business");
  const [currentStepPercent, setCurrentStepPercent] = useState<number>(10);
  const [businessName, setBusinessName] = useState({
    value: "",
    isValid: null,
    isTouched: false,
  });
  const [industry, setIndustry] = useState({
    value: "",
    isValid: null,
    isTouched: false,
  });
  const [businessAddress, setBusinessAddress] = useState({
    value: "",
    isValid: null,
    isTouched: false,
  });
  const [businessDescription, setBusinessDescription] = useState({
    value: "",
    isValid: null,
    isTouched: false,
  });
  // const [productTitle, setProductTitle] = useState({
  //   value: [""],
  //   isValid: null,
  //   isTouched: false,
  // });
  const [bankName, setBankName] = useState({
    value: "",
    isValid: null,
    isTouched: false,
  });
  const [accountName, setAccountName] = useState({
    value: "",
    isValid: null,
    isTouched: false,
  });
  const [accountNumber, setAccountNumber] = useState({
    value: "",
    isValid: null,
    isTouched: false,
  });
  // const [businessDoc, setBusinessDoc] = useState<File | null>(null);

  // const [error, setError] = useState("");
  // const [businessDetails, setBusinessDetails] = useState<any>()
  // const [success, setSuccess] = useState(false);

  useEffect(() => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance.get(
        `api/business/get/${userData.businessId}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res.data)
          // setBusinessDetails(res.data)

          setBusinessName((prev) => ({
            ...prev,
            value: res.data.businessName as string,
          }))
          setAccountNumber((prev) => ({
            ...prev,
            value: res.data.accountNo as string,
          }))
          setBankName((prev) => ({
            ...prev,
            value: res.data.bankName as string,
          }))
          setIndustry((prev) => ({
            ...prev,
            value: res.data.industry as string,
          }))
          setBusinessAddress((prev) => ({
            ...prev,
            value: res.data.address as string,
          }))
          setBusinessDescription((prev) => ({
            ...prev,
            value: res.data.description as string,
          }))

          if(res.data.knowledgeBase){
            if(res.data.knowledgeBase.faqs?.length>0 && res.data.knowledgeBase.knowledgeBase?.length>0){
              setNextStep("Completed")
              setCurrentStepPercent(100)
              return
            }else if(!res.data.knowledgeBase.faqs || res.data.knowledgeBase.faqs?.length <= 0){
              setNextStep("Upload FAQ")
              setCurrentStepPercent(70)
              return
            }else if(!res.data.knowledgeBase.knowledgeBase || res.data.knowledgeBase.knowledgeBase?.length <= 0){
              setNextStep("Upload Inventory")
              setCurrentStepPercent(95)
              return
            }
          }else{
            setNextStep("Upload FAQ")
            setCurrentStepPercent(70)
          }
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }, [])

  const handleButtonClick = (e: FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const selectedFiles = e.target.files;
    if (selectedFiles && selectedFiles.length > 0) {
      // const selectedFile = selectedFiles[0];
      // setBusinessDoc(selectedFile);
      // Process the selected file
    }
  };

  const onSelectIndustryChange = (selectedOption: any) => {
    setIndustry((prev) => ({ ...prev, value: selectedOption.label }));
  };

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    const userData = JSON.parse(localStorage.getItem("user")!);
    const requestBody = {
      userId: userData.userId,
      businessId: userData.businessId,
      businessName: businessName.value,
      industry: industry.value,
      description: businessDescription.value,
      address: businessAddress.value,
      bankName: bankName.value,
      accountNo: accountNumber.value,
      accountName: accountName.value,
    };
    console.log(requestBody);

    // console.log(data, userData);
    // return navigate(`/training-center/faq`);
    try {
      let token = getCookie("Authorization")
      const response: AxiosResponse = await instance.post(
        "api/business/update",
        { ...requestBody },
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log(response.data);
      const businessData = response.data;
      userData.businessId = businessData.businessId;
      localStorage.setItem("user", JSON.stringify(userData));
      showToast("primary", "Success!", "You have successfully updated your profile.")
      return navigate(`/training-center/faq`);
      // return navigate(`/training-center/${userData.businessId}`);
    } catch (error) {
      if (error instanceof AxiosError) {
        // if (error.response?.status === 400) {
        //   const message = error.response.data?.message;
        //   setFormInputs((prev) => ({
        //     ...prev,
        //     error: message,
        //   }));
        // }
        if (error.response?.status) {
          const message = error.response.data?.message;
          console.log(message)
          showToast("secondary", "Error!", message)
          // setFormInputs((prev) => ({
          //   ...prev,
          //   error: message,
          // }));
          // setError(message);
        }
      }
      console.log(error);
    }
  };

  const chartData = {
    datasets: [
      {
        data: [currentStepPercent, 100 - currentStepPercent],
        backgroundColor: [
          'rgba(250, 250, 250, 1)',
          'rgba(250, 250, 250, .2)',
        ],
        borderWidth: 0,
        cutout: '79%'
      },
    ],
  };

  const onboardingProcess = () => {
    if(nextStep === "Upload Inventory"){
      localStorage.setItem('mainpage', "item9")
      return navigate("/inventory")
    }

    if(nextStep === "Upload FAQ"){
      localStorage.setItem('mainpage', "item5")
      return navigate("/training-center/faq")
    }
  }

  return (
    <section className={styles.wrapper}>
      <header>
        <TrainingIcon style={styles["trainingIcon"]} />
        <p>Training Center</p>
      </header>
      <section className={styles.content}>
        <div className={styles.left}>
          <form
            className={styles["training-center-form"]}
            onSubmit={submitHandler}
          >
            <div className={styles["twin-input"]}>
              <div className={styles.left}>
                <label>Business Name</label>
                <input
                  type="text"
                  placeholder="Business Name"
                  defaultValue={businessName.value}
                  onChange={(e) =>
                    setBusinessName((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                />
              </div>
              <div className={styles.right}>
                <label>Industry</label>
                {/* <input
                  type="text"
                  placeholder="Industry"
                  onChange={(e) =>
                    setIndustry((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                /> */}
                <Select
                  // isSearchable
                  // isClearable
                  value={{ value: industry.value.toLowerCase(), label: industry.value }}
                  // menuPortalTarget={document.body}
                  maxMenuHeight={150}
                  onChange={onSelectIndustryChange}
                  styles={customStyles}
                  options={options}
                />
              </div>
            </div>
            <div className={styles["input-field"]}>
              <label>Company Address</label>
              <input
                type="text"
                placeholder="Company Address"
                defaultValue={businessAddress.value}
                onChange={(e) =>
                  setBusinessAddress((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }))
                }
              />
            </div>
            <div className={styles["input-field"]}>
              <label>Company Description</label>
              <textarea
                rows={3}
                placeholder="Company Description"
                defaultValue={businessDescription.value}
                onChange={(e) =>
                  setBusinessDescription((prev) => ({
                    ...prev,
                    value: e.target.value,
                  }))
                }
              />
            </div>
            <hr />
            <div className={styles["twin-input"]}>
              <div className={styles.left}>
                <label>
                  Bank Name <span>**</span>
                </label>
                {/* <input type="text" placeholder="Business Name" /> */}

                {/* <Select
                  // isSearchable
                  // isClearable
                  options={options2}
                  menuPortalTarget={document.body}
                  maxMenuHeight={150}
                  onChange={onSelectBankNameChange}
                  styles={customStyles2}
                /> */}
                <input
                  type="text"
                  placeholder="Bank name"
                  defaultValue={bankName.value}
                  onChange={(e) =>
                    setBankName((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                />
              </div>
              <div className={styles.right}>
                <label htmlFor="accountName">
                  Account Name <span>**</span>
                </label>
                <input
                  id="accountName"
                  type="text"
                  placeholder="Account Name"
                  onChange={(e) =>
                    setAccountName((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className={styles["twin-input"]}>
              <div className={styles.left}>
                <label htmlFor="accountNo">
                  Account No <span>**</span>
                </label>
                <input
                  type="text"
                  placeholder="Account Number"
                  defaultValue={accountNumber.value}
                  id="accountNo"
                  onChange={(e) =>
                    setAccountNumber((prev) => ({
                      ...prev,
                      value: e.target.value,
                    }))
                  }
                />
              </div>
              <div className={styles.right}>
                <label htmlFor={styles["hiddenFileInput"]}>
                  Valid Business Documents <span>**</span>
                </label>
                <button
                  className={styles["file-button"]}
                  onClick={handleButtonClick}
                >
                  <FileIcon style={styles["file-icon"]} />
                </button>
                <input
                  ref={fileInputRef}
                  id={styles["hiddenFileInput"]}
                  type="file"
                  accept=".pdf,.png,.jpeg,.jpg"
                  placeholder="Business Industry"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <hr />
            <input
              className={styles["save-changes"]}
              type="submit"
              value="Save Changes"
            />
          </form>
        </div>
        <div className={styles.right}>
          <div onClick={onboardingProcess} className={styles["onboarding_progress"]}>
            <div className={styles["onboarding_progress_background"]}>
              <img src={process.env.PUBLIC_URL+ '/assets/images/backgroundonboardingProgressImg.png'} alt="backgroundonboardingProgressImg" />
            </div>
            <div className={styles["onboarding_progress_content"]}>
              <div className={styles["onboarding_progress_chart"]}>
                <Doughnut 
                  data={chartData} 
                  width={64}
                  height={64}
                />
                <h4>{currentStepPercent + "%"}</h4>
              </div>
              <div className={styles["onboarding_progress_text"]}>
                <h5>{nextStep}</h5>
                <p>Complete your profile to unlock all features</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
export default TrainingCenter;
