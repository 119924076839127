import React, { FC } from "react";
import styles from "./CategorisedFAQ.module.css";
import {
  AddFAQSVG,
  BinSVG,
  CollapseIcon,
  EditFAQSVG,
  UploadIcon2,
} from "../../assets/icons";

interface PropTypes {
  categorisedFaq: any;
  setEditCategorisedFaq: Function;
  setSelectedCategorisedFaq: Function;
  selectedCategorisedFaqIds: any;
  selectCategorisedFaqs: Function;
  setConfirmDeleteCategorised: Function;
  faqProductIds: any;
  categorisedDropDown: any;
  setCategorisedDropDown: Function;
  handleSelectedProduct: Function;
  setTab: Function;
  setConfirmDeleteProduct: Function;
  uploadFaq: Function;
  setProductId: Function;
}

const CategorisedFAQ: FC<PropTypes> = ({
  categorisedFaq,
  setEditCategorisedFaq,
  setSelectedCategorisedFaq,
  selectedCategorisedFaqIds,
  selectCategorisedFaqs,
  setConfirmDeleteCategorised,
  faqProductIds,
  categorisedDropDown,
  setCategorisedDropDown,
  handleSelectedProduct,
  setTab,
  setConfirmDeleteProduct,
  uploadFaq,
  setProductId,
}) => {
  // const responseText1 = response.split(":")[0];
  // const responseText2 = response.split(":")[1];
  console.log(categorisedFaq, selectedCategorisedFaqIds);

  return (
    <section
      className={`${styles["categorised_faq"]}`}
      onClick={() => {
        // console.log("You little piece of shit", {
        //   categorisedFaqId: categorisedFaq.faqProductCat._id,
        // });
        setProductId(categorisedFaq.faqProductCat._id);
      }}
    >
      <div
        style={
          categorisedFaq?.faqProductCat?._id === categorisedDropDown
            ? {
                backgroundColor: "#EFF4FF",
                borderBottom: "1.5px solid #DFE1E5",
              }
            : {}
        }
        onClick={() =>
          setCategorisedDropDown(
            categorisedFaq?.faqProductCat?._id === categorisedDropDown
              ? null
              : categorisedFaq?.faqProductCat?._id
          )
        }
        className={styles["product_category"]}
      >
        <div className={styles["product_category_icon"]}>
          {categorisedFaq?.faqProductCat?.name[0]}
        </div>
        <h4>{categorisedFaq?.faqProductCat?.name}</h4>
        {/* <p>{categorisedFaq?.faqs.length} FAQ</p> */}
        {categorisedDropDown === categorisedFaq?.faqProductCat?._id && (
          <>
            <div
              onClick={() => {
                handleSelectedProduct({
                  id: categorisedFaq?.faqProductCat?._id,
                  name: categorisedFaq?.faqProductCat?.name,
                  description: categorisedFaq?.faqProductCat?.description,
                });
                setTab("saved");
              }}
              className={styles.product_category_btn}
            >
              <AddFAQSVG style={styles.product_category_btn_icon} />
            </div>
            <div
              onClick={() => {
                uploadFaq(true);
                handleSelectedProduct({
                  id: categorisedFaq?.faqProductCat?._id,
                  name: categorisedFaq?.faqProductCat?.name,
                  description: categorisedFaq?.faqProductCat?.description,
                });
              }}
              className={styles.product_category_btn}
            >
              <UploadIcon2 style={styles.product_category_btn_icon} />
            </div>
            <div
              onClick={() => {
                setConfirmDeleteProduct(true);
                handleSelectedProduct({
                  id: categorisedFaq?.faqProductCat?._id,
                  name: categorisedFaq?.faqProductCat?.name,
                  description: categorisedFaq?.faqProductCat?.description,
                });
              }}
              className={styles.product_category_btn}
            >
              <BinSVG style={styles.product_category_btn_icon} />
            </div>
          </>
        )}
        <CollapseIcon
          style={`${styles["product_category_btn_icon_stoke"]} ${
            categorisedDropDown === categorisedFaq?.faqProductCat?._id &&
            styles["product_category_btn_icon_stoke_rotate"]
          } `}
        />
      </div>
      {/* {categorisedDropDown === categorisedFaq?.faqProductCat?._id && */}
      <div
        className={`${styles.ques_res_box} ${
          categorisedDropDown === categorisedFaq?.faqProductCat?._id
            ? styles.question_slideIn
            : styles.question_slideOut
        }`}
      >
        {categorisedFaq?.faqs.map((faq: any, index: number) => {
          return (
            <div className={styles["ques_res_con"]}>
              <div
                onClick={() =>
                  selectCategorisedFaqs(
                    faqProductIds[index],
                    categorisedFaq?.faqProductCat?._id
                  )
                }
                className={styles["ques_res_checkbox_con"]}
              >
                <input
                  checked={
                    selectedCategorisedFaqIds[
                      categorisedFaq?.faqProductCat?._id
                    ]?.find(
                      (categorisedFaq: any) =>
                        categorisedFaq === faqProductIds[index]
                    )
                      ? true
                      : false
                  }
                  className={`livechat`}
                  onChange={() => {}}
                  type="checkbox"
                />
              </div>
              <div className={styles["question-div"]}>
                <p>
                  <span className={styles["big"]}>Question: </span>
                  {faq?.question}
                </p>
              </div>
              <div
                style={{ height: "19px" }}
                className={styles["question-div_action"]}
                onClick={() => {
                  setEditCategorisedFaq(true);
                  setSelectedCategorisedFaq(faq);
                }}
              >
                <EditFAQSVG style={styles["ques_res_icon2"]} />
              </div>
              <div
                style={{ height: "19px" }}
                className={styles["question-div_action"]}
                onClick={() => {
                  setConfirmDeleteCategorised("single");
                  setSelectedCategorisedFaq({
                    ...faq,
                    faqProductId: faqProductIds[index],
                  });
                }}
              >
                <BinSVG style={styles["ques_res_icon"]} />
              </div>
            </div>
          );
        })}

        {/* <div className={styles["buttons"]}>
                <button>
                    <AddFAQSVG style={styles["button-icon"]} />
                    Add more FAQs
                </button>
                <div style={selectedCategorisedFaqIds[categorisedFaq?.faqProductCat?._id]?.length > 1 ? {opacity: "1"} : {}} onClick={() => selectedCategorisedFaqIds[categorisedFaq?.faqProductCat?._id]?.length > 1 ? setConfirmDeleteCategorised("many") : {}} className={styles["delete_button"]}>
                    Delete
                </div>
            </div> */}
      </div>
      {/* } */}
    </section>
  );
};

export default CategorisedFAQ;
