import React, { useEffect, useState } from "react";
import SavedFAQs from "../../../components/TrainCenter/SavedFAQ/SavedFAQs";
import CreateFAQ from "../../../components/TrainCenter/CreateFAQ/CreateFAQ";
import instance from "../../../api";
import { checkuser, getCookie } from "../../../utilities/helpers";

interface FAQsType {
  _id: string;
  question: string;
  response: string;
}

const TrainingCenterFAQ = () => {
  checkuser()
  //   let { id } = useParams();
  const [businessId, setBusinessId] = useState('');
  const [faqs, setFaqs] = useState<FAQsType[]>([]);
  const [hasKnowledgeBase, setHasKnowledgeBase] = useState(false)
  const [hasCategorizedFaq, setHasCategorizedFaq] = useState(false)
  const [hasFaq, setHasFaq] = useState(false)
  const [suggestedFaqs, setSuggestedFaqs] = useState([])
  const [categorizedFaqs, setCategorizedFaqs] = useState([])

  useEffect(() => {
    if(localStorage.getItem("user")){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      setBusinessId(userData.businessId)
      let token = getCookie("Authorization")
      if(userData.businessId){
        instance.get(
          `/api/faq/uncategorized-faqs/business/${userData.businessId}`,
          {
            headers: {
              'Access-Control-Allow-Headers': 'X-Requested-With',
              'Authorization': `Bearer ${token}`
            }
          }
        ).then(res => {
            console.log(res)
            setHasKnowledgeBase(true)
            if(res.data.data && res.data.data.length > 0){
              setFaqs(res.data.data)
              setHasFaq(true)
            }
        }).catch(e => {
          console.log(e.response.data)
        
          setHasKnowledgeBase(false)
        })

        instance.get(
          `/api/detected-faq/business/${userData.businessId}`,
          {
            headers: {
              'Access-Control-Allow-Headers': 'X-Requested-With',
              'Authorization': `Bearer ${token}`
            }
          }
          ).then(res => {
            console.log(res.data)
            setSuggestedFaqs(res.data.data)
            setHasKnowledgeBase(true)
            // if(res.data.faqs && res.data.faqs.length > 0){
            //   setFaqs(res.data.faqs)
            //   setHasFaq(true)
            // }else{
            //   setHasFaq(false)
            // }
        }).catch(e => {
          console.log(e.response.data)
        
          setHasKnowledgeBase(false)
        })
      }else{
        setHasKnowledgeBase(false)
      }
      getCategorizeFaq()
    }
  }, [businessId, faqs.length])

  const getCategorizeFaq = () => {
    let token = getCookie("Authorization")
    const userData = JSON.parse(localStorage.getItem("user") as string);
    instance.get(
      `/api/faq/categorize/business/${userData.businessId}`,
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      }
    ).then(res => {
        console.log(res)
        setHasKnowledgeBase(true)
        if(res.data.data.length > 0){
          setHasFaq(true)
          setHasCategorizedFaq(true)
          setCategorizedFaqs(res.data.data)
        }else{
          setHasCategorizedFaq(false)
          setCategorizedFaqs([])
        }
        
    }).catch(e => {
      console.log(e.response.data)
    
      // setHasKnowledgeBase(false)
    })
  }

  return (
    <>
    {/* {hasFaq ?  */}
      <SavedFAQs hasCategorizedFaq={hasCategorizedFaq} categorizedFaqs={categorizedFaqs} getCategorizeFaq={getCategorizeFaq} setSuggestedFaqs={setSuggestedFaqs} suggestedFaqs={suggestedFaqs} faqs={faqs} setFaqs={setFaqs} hasFaq={hasFaq} setHasFaq={setHasFaq} />
  
    </>
  );
};
export default TrainingCenterFAQ;
