import React, { FC, ChangeEvent, useState } from "react";
import styles from "./departmentRow.module.css";
import { MoreIcon } from "../../../../../assets/icons";
import Modal from "../../../../Modal/Modal";

interface user {
  department: string;
  index: string;
  role: string;
  avatar: string;
  email: string;
  status?: string;
  deleteDepartment: Function;
  handleEdit: Function;
  description: String
  // handleAdminRemove?: (email: string) => void;
}

const DepartmentRow: FC<user> = (props) => {
  const [moreAction, setMoreAction] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState<string | null>()
  const admin = "admin";
  const handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
  };
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const userData = JSON.parse(localStorage.getItem("user") as string);
  const confirmDeleteAction = () => {
      setConfirmDelete(false)
      props.deleteDepartment(deleteIndex)
  }

  const userRoles =
    props.role?.toLowerCase() !== admin ? (
      <div className={`${styles.dropdown} ${styles["div-dropdown"]}`}>
        {10}
      </div>
    ) : (
      <select
        className={styles.dropdown}
        name="roles"
        onChange={handleRoleChange}
      >
        <option value="">{props.role}</option>
        <option value="option1">Super-Admin</option>
      </select>
    );
  return (
    <>
    <Modal isOpen={confirmDelete}>
      <div className={styles["delete_modal"]}>
      <div className={styles["modal-header"]}>
          <div className={styles["header"]}>Delete Department</div>
      </div>
      <div className={styles["modal-content"]}>Are you sure you want to remove this department?</div>
      <div className={styles["modal-controls"]}>
          <input type="button" value="No" onClick={() => {setDeleteIndex(null); setConfirmDelete(false)}} />
          <input
          type="button"
          value="Remove"
          onClick={() => confirmDeleteAction()}
          />
      </div>
      </div>
    </Modal>
    <section className={styles.wrapper}>
      
      <div className={styles["user-profile"]}>
        <div className={styles["abv"]}>
          {props.department[0]}
        </div>
        <div>
          <h4>{props.department}</h4>
          <p>{props.description}</p>
        </div>
      </div>
     { (['owner', 'super-admin'].includes(userData.role))?<div className={styles["controls"]}>
        {userRoles}
        <div onMouseEnter={() => setMoreAction(true)} onMouseLeave={() => setMoreAction(false)} className={styles["edit_con"]}>
            <MoreIcon style={styles["edit_icon"]} />
            {moreAction &&
            <div className={styles["more_option_con"]}>
                <h5 onClick={() => {props.handleEdit(props.index, props.department)}}>Edit Department</h5>
                <h5 onClick={() => {setConfirmDelete(true); setDeleteIndex(props.index)}}>Delete Department</h5>
            </div>
            }
        </div>
      </div>:""}
    </section>
    </>
  );
};

export default DepartmentRow;
