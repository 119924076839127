// import React, { useState } from "react";
import { useEffect, useRef, useState } from "react";
import instance from "../../../api";
import {
  CalenderIcon2,
  CheckIcon,
  CollapseIcon,
  CopyStrokeIcon,
  EmailIcon,
  EsclamationIcon,
  GmailIcon,
  InstagramIcon2,
  LinkIcon,
  LiveChatIcon,
  PhoneIcon,
  SettingStrokeIcon,
  SlackIcon,
  TwitterIcon,
  WhatsappIcon2,
} from "../../../assets/icons";
import { checkuser, getCookie, showToast } from "../../../utilities/helpers";
import styles from "./channel.module.css";
import Modal from "../../Modal/Modal";

const host = window.location.host;
let redirectUrl: string;
if (host.includes("local")) {
  redirectUrl = "http://" + host + "/callback/google";
} else {
  redirectUrl = "https://" + host + "/callback/google";
}

const Channels = () => {
  const [isGmailConnected, setIsGmailConnected] = useState(false);
  const [color, setColor] = useState("#5100FF");
  const [isLiveChat, setIsLiveChat] = useState(false);
  const [isSlack, setIsSlack] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [slackWebhook, setSlackWebhook] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [sidebar, setSidebar] = useState(false);
  const [howToIndex, setHowToIndex] = useState<number | null>(1);
  const [howToConnect, setHowToConnect] = useState(false);
  const [adding, setAdding] = useState<boolean>(false);
  const [email, setEmail] = useState<any>();
  const [emailToBeBlacklisted, setEmailToBeBlacklisted] = useState<any>();
  const [configuredEmails, setConfiguredEmails] = useState<any>();
  const [blacklistedEmails, setBlacklistedEmails] = useState<any>();
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
  const [deleteEmail, setDeleteEmail] = useState("");
  const [confirmWhitelist, setConfirmWhitelist] = useState<boolean>(false);
  const [whitelistEmail, setWhitelistEmail] = useState("");
  checkuser();

  useEffect(() => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if (userData.businessId) {
        instance
          .get(`api/business/get/${userData.businessId}`, {
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            let business = res.data;
            let emailForwarderEmails = business?.emailForwarderEmails
              ? JSON.parse(business?.emailForwarderEmails)
              : [];
            let blacklistedEmails = business?.blackListedEmails
              ? JSON.parse(business?.blackListedEmails)
              : [];
            setEmailAddress(
              `${business.businessName?.toLowerCase()}@${
                process.env.REACT_APP_EMAIL_FORWARDER_DOMAIN
              }`
            );
            setConfiguredEmails(emailForwarderEmails);
            setBlacklistedEmails(blacklistedEmails);
            if (business.gmail.isConfigured) {
              setIsGmailConnected(true);
            }
          })
          .catch((e) => {
            console.log(e.message);
          });

        const handleMessage = (event: any) => {
          if (event.data.key === "google:oauth2") {
            const data = event.data.data;
            console.log({ data });
            localStorage.setItem("gmailData", JSON.stringify(data));
            instance
              .post(
                `/api/integration/gmail/configure`,
                {
                  code: data.code,
                  businessId: userData.businessId,
                },
                {
                  headers: {
                    "Access-Control-Allow-Headers": "X-Requested-With",
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log(res.data);
                setIsGmailConnected(true);
                showToast(
                  "primary",
                  "Success!",
                  "Gmail configured successfully"
                );
                localStorage.setItem("tokens", JSON.stringify(res.data));
              });
            // if (data.state === params.state) {
            //     dispatch(connectChannels(data) as any);
            // }
          }
        };

        window.addEventListener("message", handleMessage);

        return () => {
          window.removeEventListener("message", handleMessage);
        };
      }
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  }, []);

  useEffect(() => {
    const handelClick = (evt: any) => {
      console.log(sidebar);
      let targetEl = evt.target; // clicked element

      let sidebarCon = document.getElementById("sidebar");
      let channelCon = document.getElementsByClassName("channel");
      // console.log(channelCon)
      // console.log(sidebarCon)
      if (targetEl === sidebarCon) {
        console.log("sdfs");
        return;
      } else {
        for (let i = 0; i < channelCon.length; i++) {
          if (targetEl === channelCon[i]) {
            console.log(channelCon[i]);
            setSidebar((prev) => true);
            return;
          }
        }

        if (sidebar && targetEl !== sidebarCon) {
          console.log("afwf");
          setSidebar((prev) => false);
          setHowToConnect((prev) => false);
        }
      }
    };

    document
      .getElementById("channel_con")!
      .addEventListener("click", handelClick, false);

    return () => {
      document
        ?.getElementById("channel_con")
        ?.removeEventListener("click", handelClick);
    };
  }, [sidebar]);

  const childRef = useRef<any>(null);

  const clientId =
    // '167313760974-8q4smoj5h38sdiu5vhqkjabji4g2ujio.apps.googleusercontent.com';
    process.env.REACT_APP_GMAIL_CLIENT_ID;
  const scopes = [
    "https://www.googleapis.com/auth/gmail.readonly",
    "https://www.googleapis.com/auth/drive.metadata.readonly",
    "https://www.googleapis.com/auth/gmail.modify",
    "https://www.googleapis.com/auth/pubsub",
  ];
  const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  const params: any = {
    access_type: "offline",
    client_id: clientId,
    redirect_uri: redirectUrl,
    response_type: "code",
    scope: scopes.join(" "),
    include_granted_scopes: "true",
    prompt: "consent",
    state: "something",
  };

  const url = () => {
    const query = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");
    return `${oauth2Endpoint}?${query}`;
  };

  const authorize = () => {
    childRef.current = window.open(
      url(),
      "GoogleOauth2",
      "width=550,height=470,left=450,top=100,toolbar=1,status=1,"
    );
  };

  const colorChange = (e: any) => {
    setColor(e.target.value);
  };

  const copyToClipBoard = () => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    let link = `https://chat.enif.ai/${userData.businessId}`;
    if ("clipboard" in navigator) {
      navigator.clipboard.writeText(link).then(() => {
        showToast("primary", "Success!", "Copied");
      });
    } else {
      showToast("primary", "Success!", "Copied");
      return document.execCommand("copy", true, link);
    }
  };

  const configureSlack = () => {
    console.log(slackWebhook);
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if (userData.businessId) {
        instance
          .post(`api/integration/slack/configure/${userData.businessId}`, {
            webhook: slackWebhook,
          })
          .then((res) => {
            console.log(res.data);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const addedEmail = () => {
    console.log(email);
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if (userData.businessId) {
        instance
          .post(
            `api/email-forwarder/${userData.businessId}/persist-emails`,
            {
              emails: [email],
            },
            {
              headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            setConfiguredEmails(res.data.data);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const blacklistEmail = () => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if (userData.businessId) {
        instance
          .post(
            `api/email-forwarder/${userData.businessId}/blacklist-emails`,
            {
              emails: [emailToBeBlacklisted],
            },
            {
              headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            const blockedEmails = res.data.data?.blackListedEmails
              ? JSON.parse(res.data.data?.blackListedEmails)
              : [];
            setBlacklistedEmails(blockedEmails);
            setEmailToBeBlacklisted("");
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const handelDeleteEmail = (configuredEmail: string) => {
    console.log(email);
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if (userData.businessId) {
        let token = getCookie("Authorization");
        instance
          .delete(`api/email-forwarder/${userData.businessId}/delete-email`, {
            data: { email: configuredEmail },
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res.data);
            setConfiguredEmails(res.data.data);
            setDeleteEmail("");
            setConfirmDelete(false);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const handleWhitelistEmail = (email: string) => {
    console.log(email);
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if (userData.businessId) {
        let token = getCookie("Authorization");
        console.log(email);
        instance
          .post(
            `api/email-forwarder/${userData.businessId}/whitelist-email`,
            { email: email },
            {
              headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            // setConfiguredEmails(res.data.data);
            const blacklistedEmails = res.data.data.blackListedEmails
              ? JSON.parse(res.data.data.blackListedEmails)
              : [];
            console.log({ blacklistedEmails });
            setBlacklistedEmails(blacklistedEmails);
            setWhitelistEmail("");
            setConfirmWhitelist(false);
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const handleHowToIndex = (index: number) => {
    if (index === howToIndex) {
      setHowToIndex(Infinity);
    } else {
      setHowToIndex(index);
    }
  };

  return (
    <>
      <section id="channel_con" className={styles.wrapper}>
        <Modal isOpen={confirmDelete}>
          <div className={`${styles["delete_modal"]} channel`}>
            <div className={`${styles["modal-header"]} channel`}>
              <div className={`${styles["header"]} channel`}>Delete Email</div>
            </div>
            <div className={`${styles["modal-content"]} channel`}>
              Please confirm that you have disconnected the email from your
              email settings to remove the configured email.
            </div>
            <div className={`${styles["modal-controls"]} channel`}>
              <input
                className={`channel`}
                type="button"
                value="No"
                onClick={() => {
                  setDeleteEmail("");
                  setConfirmDelete(false);
                }}
              />
              <input
                className={`channel`}
                type="button"
                value="Remove"
                onClick={() => handelDeleteEmail(deleteEmail)}
              />
            </div>
          </div>
        </Modal>
        <Modal isOpen={confirmWhitelist}>
          <div className={`${styles["delete_modal"]} channel`}>
            <div className={`${styles["modal-header"]} channel`}>
              <div className={`${styles["header"]} channel`}>Unblock Email</div>
            </div>
            <div className={`${styles["modal-content"]} channel`}>
              {/* Please confirm that you want to whitelist previously blacklisted
              email. */}
              Please confirm that you want to unblock "{whitelistEmail}"
            </div>
            <div className={`${styles["modal-controls"]} channel`}>
              <input
                className={`channel`}
                type="button"
                value="No"
                onClick={() => {
                  setWhitelistEmail("");
                  setConfirmWhitelist(false);
                }}
              />
              <input
                className={`channel`}
                type="button"
                value="Remove"
                onClick={() => handleWhitelistEmail(whitelistEmail)}
              />
            </div>
          </div>
        </Modal>
        <header>
          <h1>Connect your business</h1>
        </header>
        <section className={styles.content}>
          <div className={styles.channels}>
            <section className={styles.channel_box}>
              <div className={`${styles["channel"]} channel`}>
                <div className={`${styles["channel_icon_con"]} channel`}>
                  <EmailIcon style={styles["channel_icon2"]} />
                </div>
                <div>
                  <h4 className={`channel`}>Email Forwarder</h4>
                  <p className={`channel`}>
                    Streamline software projects, sprints, tasks and bug
                    tracking
                  </p>
                </div>
              </div>
              <div className={`${styles["controls"]} channel`}>
                <p className={`channel`}>Learn more</p>
                <button
                  aria-label="Connect to email"
                  className={`${
                    isGmailConnected ? styles["connected"] : ""
                  } channel`}
                  onClick={() => {
                    setIsEmail(true);
                    setIsSlack(false);
                    setIsLiveChat(false);
                    setSidebar(true);
                  }}
                >
                  {isGmailConnected && (
                    <CheckIcon style={styles["check_icon"]} />
                  )}
                  {isGmailConnected ? "Connected" : "Connect"}
                </button>
              </div>
            </section>
            <section className={styles.channel_box}>
              <div className={`${styles["channel"]} channel`}>
                <div
                  style={{ backgroundColor: "#DDF3D7" }}
                  className={`${styles["channel_icon_con"]} channel`}
                >
                  <WhatsappIcon2 style={`${styles["channel_icon"]} channel`} />
                </div>
                <div>
                  <h4 className={`channel`}>Whatsapp</h4>
                  <p className={`channel`}>
                    Streamline software projects, sprints, tasks and bug
                    tracking
                  </p>
                </div>
              </div>
              <div className={`${styles["controls"]} channel`}>
                <p className={`channel`}>Learn more</p>
                <button aria-label="Whatsapp connect" className={`channel`}>
                  Connect
                </button>
              </div>
            </section>
            <section className={styles.channel_box}>
              <div className={`${styles["channel"]} channel`}>
                <div
                  style={{ backgroundColor: "#E7F6FF" }}
                  className={`${styles["channel_icon_con"]} channel`}
                >
                  <TwitterIcon style={styles["channel_icon2"]} />
                </div>
                <div>
                  <h4 className={`channel`}>Twitter</h4>
                  <p className={`channel`}>
                    Streamline software projects, sprints, tasks and bug
                    tracking
                  </p>
                </div>
              </div>
              <div className={`${styles["controls"]} channel`}>
                <p className={`channel`}>Learn more</p>
                <button aria-label="Twitter connect" className={`channel`}>
                  Connect
                </button>
              </div>
            </section>
            <section className={styles.channel_box}>
              <div className={`${styles["channel"]} channel`}>
                <div
                  style={{ backgroundColor: "#FAF0FF" }}
                  className={`${styles["channel_icon_con"]} channel`}
                >
                  <InstagramIcon2 style={styles["channel_icon2"]} />
                </div>
                <div>
                  <h4 className={`channel`}>Instagram</h4>
                  <p className={`channel`}>
                    Streamline software projects, sprints, tasks and bug
                    tracking
                  </p>
                </div>
              </div>
              <div className={`${styles["controls"]} channel`}>
                <p className={`channel`}>Learn more</p>
                <button className={`channel`}>Connect</button>
              </div>
            </section>
            <section className={styles.channel_box}>
              <div className={`${styles["channel"]} channel`}>
                <div
                  style={{ backgroundColor: "#FFF3F0" }}
                  className={`${styles["channel_icon_con"]} channel`}
                >
                  <GmailIcon style={styles["channel_icon2"]} />
                </div>
                <div>
                  <h4 className={`channel`}>Gmail</h4>
                  <p className={`channel`}>
                    Streamline software projects, sprints, tasks and bug
                    tracking
                  </p>
                </div>
              </div>
              <div className={`${styles["controls"]} channel`}>
                <p className={`channel`}>Learn more</p>
                <button
                  aria-label="Gmail connect"
                  className={`${
                    isGmailConnected ? styles["connected"] : ""
                  } channel`}
                  onClick={!isGmailConnected ? () => authorize() : () => {}}
                >
                  {isGmailConnected && (
                    <CheckIcon style={styles["check_icon"]} />
                  )}
                  {isGmailConnected ? "Connected" : "Connect"}
                </button>
              </div>
            </section>
            <section className={styles.channel_box}>
              <div className={`${styles["channel"]} channel`}>
                <div
                  style={{ backgroundColor: "#F0F2FF" }}
                  className={`${styles["channel_icon_con"]} channel`}
                >
                  <LiveChatIcon style={styles["channel_icon2"]} />
                </div>
                <div>
                  <h4 className={`channel`}>Live Chat</h4>
                  <p className={`channel`}>
                    Streamline software projects, sprints, tasks and bug
                    tracking
                  </p>
                </div>
              </div>
              <div className={`${styles["controls"]} channel`}>
                <p className={`channel`}>Learn more</p>
                <button
                  aria-label="Live chat connect"
                  className={`channel`}
                  onClick={() => {
                    setIsLiveChat(true);
                    setIsSlack(false);
                    setIsEmail(false);
                    setSidebar(true);
                  }}
                >
                  Connect
                </button>
              </div>
            </section>
            <section className={styles.channel_box}>
              <div className={`${styles["channel"]} channel`}>
                <div
                  style={{ backgroundColor: "#F0F2FF" }}
                  className={`${styles["channel_icon_con"]} channel`}
                >
                  <SlackIcon style={styles["channel_icon2"]} />
                </div>
                <div>
                  <h4 className={`channel`}>Slack</h4>
                  <p className={`channel`}>
                    Streamline software projects, sprints, tasks and bug
                    tracking
                  </p>
                </div>
              </div>
              <div className={`${styles["controls"]} channel`}>
                <p className={`channel`}>Learn more</p>
                <button
                  aria-label="Slack connect"
                  className={`channel`}
                  onClick={() => {
                    setIsSlack(true);
                    setIsEmail(false);
                    setIsGmailConnected(false);
                    setSidebar(true);
                  }}
                >
                  Connect
                </button>
              </div>
            </section>
          </div>
        </section>
        {sidebar && isLiveChat && (
          <div id="sidebar" className={`${styles.live_chat_sidebar} channel`}>
            <div className={`${styles.live_chat_sidebar_header} channel`}>
              <div>
                <LiveChatIcon
                  style={`${styles["live_chat_sidebar_header_icon"]} channel`}
                />
                Live Chat Integration
              </div>
              <button aria-label="Live Chat Integration" className={`channel`}>
                Connect
              </button>
            </div>
            <div className={`${styles.live_chat_sidebar_body} channel`}>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section1} channel`}
              >
                <header className={`channel`}>
                  <LinkIcon
                    style={`${styles.live_chat_sidebar_icon} channel`}
                  />
                  <p className={`channel`}>Widget Web Link</p>
                </header>
                <div>
                  https:develop.d1m8rf0thvhgcn.amplifyapp.com/7e5615051ae14d4b9caf071a8db1c92c00c790320c45443dadeb63cbd615adc4cc79ff883f8d4ace8a72cbba7cb86c2a7e5615051ae14d4b9caf071a8db1c92c00c790320c45443dadeb63cbd615adc4cc79ff883f8d4ace8a72cbba7cb86c2a7e5615051ae14d4b9caf071a8db1c92c00c790320c45443dadeb63cbd615adc4cc79ff883f8d4ace8a72cbba7cb86c2a
                  <section onClick={copyToClipBoard}>
                    <div>
                      <p className={`channel`}>Copy</p>
                      <CopyStrokeIcon style={`${styles.copy_icon} channel`} />
                    </div>
                  </section>
                </div>
              </div>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section1} channel`}
              >
                <header className={`channel`}>
                  <CalenderIcon2
                    style={`${styles.live_chat_sidebar_icon} channel`}
                  />
                  <p className={`channel`}>Widget Script</p>
                </header>
                <div>
                  {`<script>document.body.onload=function(){const e=document.createElement("iframe");
                var t=document.createElement("div"),n=document.createElement("button");
                document.createElement("img");const l=document.createElement("span");
                l.innerHTML="&#x1F4AC;",
                e.src="https://chat.enif.ai/7e5615051ae14d4b9caf071a8db1c92c00c790320c45443dadeb63cbd615adc4c
                c79ff883f8d4ace8a72cbba7cb86c2a",e.width="0",e.height="800",
                e.style.scale="0",e.style.transition="all 0.5s ease-in-out",
                e.style.transformOrigin="bottom right",n.style.width="4.6rem",
                n.style.height="4.6rem",n.style.background="#ddd",n.style.zIndex="9998"
                ,n.style.borderRadius="50%",n.style.position="fixed",n.style.right="3%",
                n.style.bottom="3%",l.style.zIndex="10000",l.style.width="2rem",
                l.style.minWidth="2rem",l.style.color="white",n.appendChild(l),
                t.appendChild(e),t.appendChild(n),t.style.position="fixed",
                t.style.zIndex="60",t.style.right="5%",t.style.bottom="0%",
                document.body.appendChild(t),n.addEventListener("click",()=>{"0"==e.width?(e.width="400",
                e.style.scale="1",l.innerHTML="&#x2715"):(e.width="0",e.style.sc
                ale="0",l.innerHTML="&#x1F4AC;")})}()<script>`}
                  <section onClick={copyToClipBoard}>
                    <div>
                      <p className={`channel`}>Copy</p>
                      <CopyStrokeIcon style={`${styles.copy_icon} channel`} />
                    </div>
                  </section>
                </div>
              </div>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2}`}
              >
                <header className={`channel`}>
                  <LinkIcon
                    style={`${styles.live_chat_sidebar_icon} channel`}
                  />
                  <p className={`channel`}>Business APK link</p>
                </header>
                <div>
                  https://chat.enif.ai/183dcb2f-73f6-47c7-91c0-720b38bca297f
                  b69b188-f1b4-47bc-abd4-bc5e47ea83fe
                  <section onClick={copyToClipBoard}>
                    <div>
                      <p className={`channel`}>Copy</p>
                      <CopyStrokeIcon style={`${styles.copy_icon} channel`} />
                    </div>
                  </section>
                </div>
              </div>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2}`}
              >
                <header className={`channel`}>
                  <PhoneIcon
                    style={`${styles.live_chat_sidebar_icon} channel`}
                  />
                  <p className={`channel`}>API Key</p>
                </header>
                <div>
                  183dcb2f-73f6-47c7-91c0-720b38bca297fb69b188-f1b4-47bc-abd4-bc5e47ea83fe
                  <section onClick={copyToClipBoard}>
                    <div>
                      <p className={`channel`}>Copy</p>
                      <CopyStrokeIcon style={`${styles.copy_icon} channel`} />
                    </div>
                  </section>
                </div>
              </div>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2}`}
              >
                <header className={`channel`}>
                  <SettingStrokeIcon
                    style={`${styles.live_chat_sidebar_icon_stroke} channel`}
                  />
                  <p className={`channel`}>Configuration</p>
                </header>
              </div>
              <div className={styles.live_chat_sidebar_bottom}>
                <div>
                  <h5>Change Colour</h5>
                  <div>
                    <p className={`channel`}>{color.replace("#", "")}</p>
                    <input
                      type="color"
                      defaultValue={"#5100FF"}
                      onChange={colorChange}
                      className={styles.color_box}
                      id="style2"
                    />
                    {/* <div className={styles.color_box}>
                    
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {sidebar && isSlack && (
          <div id="sidebar" className={`${styles.live_chat_sidebar} channel`}>
            <div className={`${styles.live_chat_sidebar_header} channel`}>
              <div>
                <SlackIcon
                  style={`${styles["live_chat_sidebar_header_icon"]} channel`}
                />
                Slack Integration
              </div>
              <button aria-label="Slack Integration" onClick={configureSlack}>
                Connect
              </button>
            </div>
            <div className={`${styles.live_chat_sidebar_body} channel`}>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2}`}
              >
                <header className={`channel`}>
                  <LinkIcon
                    style={`${styles.live_chat_sidebar_icon} channel`}
                  />
                  <p className={`channel`}>Business APK link</p>
                </header>
                <input
                  value={slackWebhook}
                  onChange={(e) => setSlackWebhook(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
        {sidebar && isEmail && !howToConnect && (
          <div id="sidebar" className={`${styles.live_chat_sidebar} channel`}>
            <div className={`${styles.live_chat_sidebar_header} channel`}>
              <div>
                <EmailIcon
                  style={`${styles["live_chat_sidebar_header_icon"]} channel`}
                />
                Email Forwarder
              </div>
              <button
                aria-label="How to connect"
                onClick={() => {
                  setHowToConnect(true);
                }}
                className={`${styles["how_to_button"]} channel`}
              >
                How to connect email
                <EsclamationIcon style={styles["button_escalation"]} />
              </button>
            </div>
            <div className={`${styles.live_chat_sidebar_body} channel`}>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2}`}
              >
                <header className={`channel`}>
                  <LinkIcon
                    style={`${styles.live_chat_sidebar_icon} channel`}
                  />
                  <p className={`channel`}>Enif Email</p>
                </header>
                <input
                  className={`channel`}
                  value={emailAddress}
                  // onChange={(e) => setSlackWebhook(e.target.value)}
                />
              </div>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2} channel`}
              >
                <header className={`channel`}>
                  <LinkIcon style={styles.live_chat_sidebar_icon} />
                  <p className={`channel`}>Configure email</p>
                </header>
                <textarea
                  className={`channel`}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button
                onClick={() => {
                  !adding && addedEmail();
                }}
                className={`${styles["add_department_button"]} channel`}
              >
                {adding ? "Loading..." : "Add"}
              </button>
              {!configuredEmails && (
                <div
                  className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2}`}
                >
                  <header className={`channel`}>
                    <EsclamationIcon
                      style={`${styles.button_escalation} channel`}
                    />
                    <p className={`channel`}>
                      To complete the integration process, kindly add and save
                      the email address you configure to forward emails on your
                      dashboard.
                    </p>
                  </header>
                </div>
              )}
            </div>

            <div className={`${styles.connected_departments_con} channel`}>
              <header className={`channel`}>
                <SettingStrokeIcon
                  style={styles.live_chat_sidebar_icon_stroke}
                />
                <p> Configured Emails</p>
              </header>
              {!configuredEmails ? (
                <div
                  className={`${styles.no_connected_department_con} channel`}
                >
                  <div
                    className={`${styles.no_connected_department_icon} channel`}
                  >
                    <EsclamationIcon style={styles[""]} />
                  </div>
                  <p className={`channel`}>You have not configured any email</p>
                </div>
              ) : (
                <div className={`${styles.connected_departments} channel`}>
                  {configuredEmails?.map((configuredEmail: any) => {
                    return (
                      <div className={`${styles.connected_department} channel`}>
                        {configuredEmail}
                        <div
                          className={`channel`}
                          onClick={() => {
                            setConfirmDelete(true);
                            setDeleteEmail(configuredEmail);
                          }}
                        >
                          <p className={`channel`}>x</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            {/* <header>
              <EmailIcon
                style={`${styles["live_chat_sidebar_header_icon"]} ${styles.live_chat_sidebar_body_header} channel`}
              />
              Blocked Emails
            </header> */}

            <div
              className={`${styles.live_chat_sidebar_body} ${styles.live_chat_sidebar_body111} channel`}
            >
              <header>
                <EmailIcon
                  style={`${styles["live_chat_sidebar_header_icon"]} channel`}
                />
                Blocked Emails
              </header>
              <div
                className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2} channel`}
              >
                <header className={`channel`}>
                  <LinkIcon style={styles.live_chat_sidebar_icon} />
                  <p className={`channel`}>Blocked email</p>
                </header>
                <textarea
                  className={`channel`}
                  value={emailToBeBlacklisted}
                  onChange={(e) => setEmailToBeBlacklisted(e.target.value)}
                />
              </div>
              <button
                onClick={() => {
                  !adding && blacklistEmail();
                }}
                className={`${styles["add_department_button"]} channel`}
              >
                {adding ? "Loading..." : "Add"}
              </button>
              {!blacklistedEmails ||
                (blacklistedEmails.length <= 0 && (
                  <div
                    className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2}`}
                  >
                    <header className={`channel`}>
                      <EsclamationIcon
                        style={`${styles.button_escalation} channel`}
                      />
                      <p className={`channel`}>
                        You can add and save the email address you intend to
                        block on your dashboard.
                      </p>
                    </header>
                  </div>
                ))}
            </div>

            <div className={`${styles.connected_departments_con} channel`}>
              <header className={`channel`}>
                <SettingStrokeIcon
                  style={styles.live_chat_sidebar_icon_stroke}
                />
                <p>Blocked Emails</p>
              </header>
              {!blacklistedEmails || blacklistedEmails.length <= 0 ? (
                <div
                  className={`${styles.no_connected_department_con} channel`}
                >
                  <div
                    className={`${styles.no_connected_department_icon} channel`}
                  >
                    <EsclamationIcon style={styles[""]} />
                  </div>
                  <p className={`channel`}>
                    You have not blocked any email addresses yet
                  </p>
                </div>
              ) : (
                <div className={`${styles.connected_departments} channel`}>
                  {blacklistedEmails?.map((blacklistedEmail: any) => {
                    return (
                      <div className={`${styles.connected_department} channel`}>
                        {blacklistedEmail}
                        <div
                          className={`channel`}
                          onClick={() => {
                            setConfirmWhitelist(true);
                            setWhitelistEmail(blacklistedEmail);
                          }}
                        >
                          <p className={`channel`}>x</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
        {sidebar && isEmail && howToConnect && (
          <div id="sidebar" className={`${styles.live_chat_sidebar} channel`}>
            <div className={`${styles.live_chat_sidebar_header} channel`}>
              <div className={`channel`}>
                <EmailIcon style={styles["live_chat_sidebar_header_icon"]} />
                How to connect your email
              </div>
            </div>
            <div className={`${styles.live_chat_sidebar_body_how} channel`}>
              <div
                className={`${styles.live_chat_sidebar_section_how} channel`}
              >
                <header
                  onClick={() => handleHowToIndex(1)}
                  className={`channel`}
                >
                  <SettingStrokeIcon
                    style={`${styles.live_chat_sidebar_how_icon_stroke} channel`}
                  />
                  <p className={`channel`}>
                    Access Email Forwarder Configuration
                  </p>
                  <CollapseIcon
                    style={`${styles["icon_colapse"]} ${
                      howToIndex === 1 && styles["rotate_icon"]
                    } channel`}
                  />
                </header>
                {howToIndex === 1 && (
                  <div className={`${styles.how_process_con} channel`}>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>Go to your Enif dashboard.</p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Click on the settings button <span>(gear icon)</span> on
                        the left sidebar.
                      </p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Click on the <span>email forwarder</span> and copy your
                        Enif email address
                      </p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Click on <span>“Connect”</span> to open the
                        configuration screen.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${styles.live_chat_sidebar_section_how} channel`}
              >
                <header
                  onClick={() => handleHowToIndex(2)}
                  className={`channel`}
                >
                  <SettingStrokeIcon
                    style={`${styles.live_chat_sidebar_how_icon_stroke} channel`}
                  />
                  <p className={`channel`}>
                    Now, follow these steps within your email account
                  </p>
                  <CollapseIcon
                    style={`${styles["icon_colapse"]} ${
                      howToIndex === 2 && styles["rotate_icon"]
                    } channel`}
                  />
                </header>
                {howToIndex === 2 && (
                  <div className={`${styles.how_process_con} channel`}>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Find Forwarding Options: Navigate to the settings or
                        preferences section of your email account.
                      </p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Activate Forwarding: Toggle the switch or checkbox to
                        enable email forwarding.
                      </p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Enter Forwarding Address: Paste your Enif email address
                        where you want emails forwarded.
                      </p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Verify Forwarding Address (if required): Look for a
                        verification link sent to your Enif email..
                      </p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Choose Forwarding Options (if available): Select
                        preferences like keeping copies in your inbox.
                      </p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Save Changes: Find and click on a <span>"Save"</span>{" "}
                        button to confirm your forwarding settings.
                      </p>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`${styles.live_chat_sidebar_section_how} channel`}
              >
                <header
                  onClick={() => handleHowToIndex(3)}
                  className={`channel`}
                >
                  <SettingStrokeIcon
                    style={`${styles.live_chat_sidebar_how_icon_stroke} channel`}
                  />
                  <p className={`channel`}>Return to your Enif dashboard:</p>
                  <CollapseIcon
                    style={`${styles["icon_colapse"]} ${
                      howToIndex === 3 && styles["rotate_icon"]
                    } channel`}
                  />
                </header>
                {howToIndex === 3 && (
                  <div className={`${styles.how_process_con} channel`}>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Click on the settings button <span>(gear icon)</span> in
                        the left sidebar.
                      </p>
                    </div>
                    <div className={`${styles.how_process} channel`}>
                      <div className={`channel`}></div>
                      <p className={`channel`}>
                        Add the email address you configured and save the
                        changes.
                      </p>
                    </div>
                  </div>
                )}
              </div>

              {/* <div className={`${styles.live_chat_sidebar_section_how} channel`}>
              <div className={`${styles.how_process_con} channel`}>
                <div className={`${styles.how_process} channel`}>
                  <div className={`channel`}></div>
                  <p className={`channel`}>Once added, you’ll see the Webhook URL. Copy this URL (e.g.)</p>
                </div>
                <div className={`${styles.code_div} channel`}>
                  https://hooks.slack.com/services/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
                </div>
                <div className={`${styles.how_process} channel`}>
                  <div className={`channel`}></div>
                  <p className={`channel`}>On the configuration screen, find a text-box.</p>
                </div>
                <div className={`${styles.how_process} channel`}>
                  <div className={`channel`}></div>
                  <p className={`channel`}>Enter the copied Slack webhook URL.</p>
                </div>
                <div className={`${styles.how_process} channel`}>
                  <div className={`channel`}></div>
                  <p className={`channel`}>Click on the <span>“Connect”</span> button located at the top of the text-box.</p>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Channels;
