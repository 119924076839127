import axios from "axios";
import { getCookie } from "../utilities/helpers";

const api_url =
  process.env.REACT_APP_BACKEND_END_BASE_URL ||
  "https://api.enif.ai";

// const api_url =
//   process.env.BACKEND_END_BASE_URL ||
//   "https://web-production-1bfc.up.railway.app";

let token = getCookie("Authorization")

const instance = axios.create({ 
  baseURL: api_url, 
  headers: {
    'Access-Control-Allow-Headers': 'X-Requested-With',
    'Authorization': `Bearer ${token}`
  } 
});

export default instance;
