import React, { FC, useEffect, useState } from "react";
import Logo from "../../../assets/images/enif_logo.svg";
import styles from "../SideBar.module.css";
import {
  AnalysisIcon,
  ChatBotIcon,
  ConnectionIcon,
  CustomerEngagementIcon,
  CustomerIcon,
  EmailIcon,
  LogoutIcon,
  NotificationsIcon,
  OverviewIcon,
  SearchIcon,
  SettingsIcon,
  ShopIcon,
  SocialIcon,
  TicketStrokeIcon,
  TrainingCenterIcon,
} from "../../../assets/icons";
import { Link, useLocation } from "react-router-dom";

interface PropType {
  getSelectedMainSideBarMenu: (item: string) => void;
  toggleSidebar: (value:boolean) => void;
  selectedMainNavItem: string | null;
  businessId: string;
  userAB: string;
  businessApproved: boolean;
}

const MainSide: FC<PropType> = ({
  getSelectedMainSideBarMenu,
  toggleSidebar,
  selectedMainNavItem,
  businessId,
  userAB,
  businessApproved
}) => {
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  // const [path, setPath] = useState<string>("");
  const router = useLocation();

  useEffect(() => {
    // setPath(router.pathname)
    if(selectedMainNavItem){
      setSelectedItem(selectedMainNavItem)
    }

    if(router.pathname === '/'){
      if(businessId && businessApproved){
        handleItemClick("item1")
      }else{
        handleItemClick("onboarding")
      }
      return
    }

    if(router.pathname.includes("live-chat")){
      handleItemClick("item3")
      return
    }

    if(router.pathname.includes("conversations/")){
      handleItemClick("conversations")
      return
    }

    if(router.pathname.includes("email")){
      handleItemClick("item4")
      return
    }

    if(router.pathname.includes("training-center")){
      handleItemClick("item5")
      return
    }

    if(router.pathname.includes("customers")){
      handleItemClick("item7")
      return
    }

    if(router.pathname.includes("tickets")){
      handleItemClick("item8")
      return
    }

    if(router.pathname.includes("shop")){
      handleItemClick("item9")
      return
    }

    if(router.pathname.includes("settings")){
      handleItemClick("item13")
      return
    }

    if(router.pathname.includes("account")){
      handleItemClick("item15")
      return
    }

    if(router.pathname.includes("socials")){
      handleItemClick("item2")
      return
    }

    if(router.pathname.includes("connection")){
      handleItemClick("item6")
      return
    }

    if(router.pathname.includes("customer_engagement")){
      handleItemClick("item10")
      return
    }

    if(router.pathname.includes("analysis")){
      handleItemClick("analysis")
      return
    }

    if(router.pathname.includes("notification")){
      handleItemClick("item11")
      return
    }

    if(router.pathname.includes("search")){
      handleItemClick("item12")
      return
    }
    
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMainNavItem, router.pathname])

  const handleItemClick = (itemId: string) => {
    setSelectedItem(itemId);
    getSelectedMainSideBarMenu(itemId);
    toggleSidebar(true);
  };

  const logout = () => {
    localStorage.removeItem("business")
    localStorage.removeItem("mainpage")
    localStorage.removeItem("user")
    document.cookie = "Authorization" + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.assign('/login')
  }

  return (
    <section className={styles["main"]}>
      <header>
        <img src={Logo} alt="enif" />
      </header>
      <nav>
        <ul className={styles["main-ul"]}>
          <Link to={"/"}>
            <li
              title={businessId ? "Overview" : "Onboarding"}
              id="item1"
              className={`${selectedItem === (businessId ? "item1" : "onboarding") ? styles.selected : ""}`}
              onClick={() => handleItemClick("onboarding")}
            >
              <OverviewIcon style={styles["icon-stroke"]} />
            </li>
          </Link>
          {/* <Link to={`${businessApproved ? "/socials" : "/"}`}>
            <li
              title="Socials"
              id="item2"
              className={`${selectedItem === "item2" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item2")}}
            >
              <SocialIcon style={styles["icon-stroke"]} />
            </li>
          </Link> */}
          <Link to={`${businessApproved ? "/live-chat/all" : "/"}`}>
            <li
              title="Live Chat"
              id="item3"
              className={`${selectedItem === "item3" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item3")}}
            >
              <ChatBotIcon style={styles["icon-stroke"]} />
            </li>
          </Link>
          {/* <Link to={`${businessApproved ? "/conversations/all" : "/"}`}>
            <li
              title="Conversations"
              id="item3"
              className={`${selectedItem === "conversations" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("conversations")}}
            >
              <SocialIcon style={styles["icon-stroke"]} />
            </li>
          </Link> */}
          <Link to={`${businessApproved ? "/email/all" : "/"}`}>
            <li
              title="Email"
              id="item4"
              className={`${selectedItem === "item4" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item4")}}
            >
              <EmailIcon style={styles["icon-stroke"]} />
            </li>
          </Link>
          <Link to={`${businessApproved ? "/training-center/faq" : "/"}`}>
            <li
              title="Training Center"
              id="item5"
              className={`${selectedItem === "item5" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item5")}}
            >
              <TrainingCenterIcon style={styles["icon-stroke"]} />
            </li>
          </Link>
          {/* <Link to={`${businessApproved ? "/connection" : "/"}`}>
            <li
              title="Connection"
              id="item6"
              className={`${selectedItem === "item6" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item6")}}
            >
              <ConnectionIcon style={styles["icon-stroke"]} />
            </li>
          </Link> */}
          <Link to={`${businessApproved ? "/customers/all" : "/"}`}>
            <li
              title="Customers"
              id="item7"
              className={`${selectedItem === "item7" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item7")}}
            >
              <CustomerIcon style={styles["icon-stroke"]} />
          </li>
          </Link>
          <Link to={`${businessApproved ? "/tickets/all" : "/"}`}>
          <li
            title="Tickets"
            id="item8"
            className={`${selectedItem === "item8" && styles["selected"]}`}
            onClick={() => {businessApproved && handleItemClick("item8")}}
          >
            <TicketStrokeIcon style={styles["icon-stroke"]} />
          </li>
          </Link>
          {/* <Link to={`${businessApproved ? "/shop/inventory" : "/"}`}>
            <li
              title="Inventory"
              id="item9"
              className={`${selectedItem === "item9" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item9")}}
            >
              <ShopIcon style={styles["icon-stroke"]} />
            </li>
          </Link> */}
          {/* <Link to={`${businessApproved ? "/customer_engagement" : "/"}`}>
            <li
              title="Customer Engagement"
              id="item10"
              className={`${selectedItem === "item10" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item10")}}
            >
              <CustomerEngagementIcon style={styles["icon-stroke"]} />
            </li>
          </Link> */}
          {/* <Link to={`${businessApproved ? "/analytics/analysis/overview" : "/"}`}>
            <li
              title="Analytics"
              id="analytics"
              className={`${selectedItem === "analysis" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("analytics")}}
            >
              <AnalysisIcon style={styles["icon2"]} />
            </li>
          </Link> */}
        </ul>
        {/* </nav>
      <nav> */}
        <ul className={styles["main-ul"]}>
          <Link to={`${businessApproved ? "/notification" : "/"}`}>
            <li
              title="Notification"
              id="item11"
              className={`${selectedItem === "item11" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item11")}}
            >
              <NotificationsIcon style={styles["icon-stroke"]} />
            </li>
          </Link>
          {/* <Link to={`${businessApproved ? "/search" : "/"}`}>
          <li
            title="Search"
            id="item12"
            className={`${selectedItem === "item12" && styles["selected"]}`}
            onClick={() => {businessApproved && handleItemClick("item12")}}
          >
            <SearchIcon style={styles["icon-stroke"]} />
          </li>
          </Link> */}
          <Link to={`${businessApproved ? "/settings/admins" : "/"}`}>
            <li
              title="Settings"
              id="item13"
              className={`${selectedItem === "item13" && styles["selected"]}`}
              onClick={() => {businessApproved && handleItemClick("item13")}}
            >
              <SettingsIcon style={styles["icon-stroke"]} />
            </li>
          </Link>
          <li
            title="Logout"
            id="item14"
            className={`${selectedItem === "item14" && styles["selected"]}`}
            onClick={() => logout()}
          >
            <LogoutIcon style={styles["icon-stroke"]} />
          </li>
          {userAB && 
          <Link to={`${businessApproved ? "/account/personal" : "/"}`}>
          <li
            title="Account"
            id="item15"
            className={`${selectedItem === "item15" && styles["selected"]}   ${styles["user_logo"]}`}
            onClick={() => {businessApproved && handleItemClick("item15")}}
          >
            <div>
              <p>{userAB}</p>
            </div>
          </li>
          </Link>}
        </ul>
      </nav>
    </section>
  );
};

export default MainSide;
