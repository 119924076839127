// import { useEffect, useState } from "react";
import styles from "./agent.module.css";
import { checkuser, getCookie, showToast } from "../../../../utilities/helpers";
import General from "../general";
import { InviteIcon, PlusCircleIcon } from "../../../../assets/icons";
import { useEffect, useState } from "react";
import Modal from "../../../Modal/Modal";
import instance from "../../../../api";
import { AxiosError } from "axios";
import AgentRow from "./AgentRow/AgentRow";

export default function Agents() {
  const [ openModal, setOpenModal ] = useState(false);
  const [agentName, setAgentName] = useState('')
  const [agents, setAgents] = useState<Array<any>>()
  const [ isLoading, setIsLoading ] = useState(true);
  const [editId, setEditId] = useState<number | null>()
  checkuser()

  useEffect(() => {
    getAgents();
  }, [])

  const getAgents = () =>{
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance.get(
        `api/agent/${userData.businessId}/get-agents`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res.data.data)
          setAgents(res.data.data)
          setIsLoading(false)
      }).catch(e => {
        console.log(e.response.data)
        setIsLoading(false)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  const addAgent = async () => {
    if(agentName.length <= 0){
      return
    }
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let data = {
        "agentName": agentName
      }
      let token = getCookie("Authorization")
      const response = await instance.post(`api/agent/${userData.businessId}/create-agent`, { ...data },
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      getAgents()
      showToast("primary", "Success!", "Agent added successfully")
      setAgentName("")
      setOpenModal(false)

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
      console.log(error);
    }
  }

  const handleEdit = (id: number, agentName: string) => {
    setEditId(id)
    setAgentName(agentName)
    setOpenModal(true)
  }

  const editAgent = async () => {
    if(agentName.length <= 0){
      return
    }
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let data = {
        "agentName": agentName
      }
      let token = getCookie("Authorization")
      const response = await instance.patch(`api/agent/${userData.businessId}/update-agent/${editId}`, { ...data }, 
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      showToast("primary", "Success!", "Agent updated successfully")
      setAgentName("")
      getAgents()
      setEditId(null)
      setOpenModal(false)

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
      console.log(error);
    }
  }

  const deleteAgent = async (id: number) => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let token = getCookie("Authorization")
      const response = await instance.delete(`/api/agent/${userData.businessId}/delete-agent/${id}`, 
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data)
      getAgents()
      setAgentName('')
      showToast("secondary", "Success!", "Agent deleted successfully")
      setOpenModal(false)

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
      console.log(error);
    }
  }

  return (
    <General actionText="Create Agent" setOpenModal={setOpenModal}>
      <div className={styles.agents_container}>
        {!isLoading && 
         <>
         {agents && agents.length > 0 ?
          <div className={styles.usersList}>
            {agents.map((agent, index) => (
              <AgentRow
                key={index}
                index={index}
                agent={agent}
                avatar={agent}
                email={agent}
                deleteAgent={deleteAgent}
                handleEdit={handleEdit}
              />
            ))}
          </div>
          :
          <div className={styles.no_agents}>
            <p>You have no active agent</p>
            <div onClick={() => setOpenModal(true)} className={styles.create_agent}>
              <h4>Create One</h4>
              <PlusCircleIcon style={styles.plusicon} />
            </div>
          </div>
          }
          </>
        }
        <Modal isOpen={openModal} onClose={() => {setOpenModal(false); setEditId(null); setAgentName("")}}>
            <div className={styles.agent_form}>
              <h2>Create a new agent</h2>
              <div className={styles.form_group}>
                <label htmlFor="agentName">Agent Name</label>
                <input
                  id="agentName"
                  type="text"
                  value={agentName}
                  onChange={(e) => setAgentName(e.target.value)}
                />
              </div>
              <div onClick={() => {editId ? editAgent(): addAgent()}} className={styles.send_agent}>
                <h4>{editId ? "Update": "Create"} agent</h4>
                <InviteIcon style={styles.sendicon} />
              </div>
            </div>
        </Modal>
      </div>
    </General>
  );
}
