import React, { FC } from "react";
import styles from "./OnboardingSocialCard.module.css";
import {
  FacebookIcon,
  InstagramIcon,
  MailIcon,
  WhatsappIcon,
} from "../../assets/icons";

interface PropType {
  social: "facebook" | "instagram" | "mail" | "whatsapp";
}

const OnbardingSocialCard: FC<PropType> = ({ social }) => {
  let selectedSocial = null;

  if (social === "facebook") {
    selectedSocial = <FacebookIcon style={styles["icon"]} />;
  } else if (social === "instagram") {
    selectedSocial = <InstagramIcon style={styles["icon"]} />;
  } else if (social === "mail") {
    selectedSocial = <MailIcon style={styles["icon"]} />;
  } else if (social === "whatsapp") {
    selectedSocial = <WhatsappIcon style={styles["icon"]} />;
  }
  return (
    <div className={styles["card"]}>
      <header className={styles["header"]}>{selectedSocial}</header>
      <main
        className={`${styles[social]} ${styles["main"]}`}
      >{`Connect business ${social[0].toUpperCase()}${social.slice(1)}`}</main>
    </div>
  );
};
export default OnbardingSocialCard;
