import React from "react";
import styles from "./SettingsModal.module.css";

interface PropType {
  closeModal: () => void;
  handleRemoveUser: (email: string) => void;
  header: string;
  email: string;
  body: string;
}

const SettingsModal: React.FC<PropType> = ({
  header,
  email,
  closeModal,
  handleRemoveUser,
  body,
}) => {
  return (
    <div className={styles["modal-overlay"]} onClick={closeModal}>
      <div className={styles["modal"]}>
        <div className={styles["modal-header"]}>
          <div className={styles["header"]}>{header}</div>
          <div className={styles["email"]}>{email}</div>
        </div>
        <div className={styles["modal-content"]}>{body}</div>
        <div className={styles["modal-controls"]}>
          <input type="button" value="No" onClick={closeModal} />
          <input
            type="button"
            value="Remove"
            onClick={() => handleRemoveUser(email)}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
