import React, { useEffect, useRef, useState } from "react";
import styles from "./Gmail.module.css";
import CheckIcon from "../../assets/images/conversation_checkbox.svg";
import CommentIcon from "../../assets/images/conversation_uncomment.svg";
import StarIcon from "../../assets/images/conversation_star.svg";
import { AxiosError } from "axios";
import instance from "../../api/index";
// import { io } from "socket.io-client";
import { checkuser, getCookie, showToast } from "../../utilities/helpers";
import constants from "../../utilities/constants";
import GmailConversation from "../../components/Gmail/GmailConversation";
import {
  ArrowLeftIcon,
  ArrowRightIcon2,
  CircuitIcon,
  CollapseIcon,
  EyeStrokeIcon,
  MarkStrokeIcon,
  PlusCircleIcon,
  UrgencyIcon,

} from "../../assets/icons";
import { Link, useLocation} from "react-router-dom";

export default function 
Gmail() {
  checkuser();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [emails, setEmails] = useState<Array<any>>([]);
  const [error, setError] = useState<string | null>(null);
  const [totalMails, setTotalMails] = useState<number>(0);
  const router = useLocation(); 
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [mode, setMode] = useState("");
  const [selectMode, setSelectMode] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState<any | null>(
    null
  );
  const businessId: string | null = JSON.parse(localStorage.getItem("user")!)[
    "businessId"
  ];
  const [ isFreshlyVisited,setIsFreshlyVisited] = useState(true);
  const chatBoxRef = useRef<HTMLUListElement>(null);

  console.log(error, totalMails, currentPage);

 
  useEffect(() => {
    console.log("I am hereeee")
    if(!router.pathname.includes('thread')){
      localStorage.setItem('currentPage',JSON.stringify(1));
    const temp = localStorage.getItem('currentPage')
    console.log("here for temp",temp);
   setCurrentPage(1)
    }
    
  }, [router.pathname]); 


  useEffect(() => {
    // set up socket
    // const newSocket = io(`${process.env.REACT_APP_BACKEND_END_BASE_URL}`, {
    //   extraHeaders: {
    //     Authorization: `${businessId}`,
    //   },
    // });
    
    // fetch conversations on page load
    setCurrentPage(JSON.parse(localStorage.getItem('currentPage') || `1`));
    let token = getCookie("Authorization");
    let path = router.pathname;
    let action = getAction(path);
  
    let url = "";
    if (action === "all" || action === "") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${currentPage}`;
    } else if (action === "in active") { 
      url = `/api/email-forwarder/ticket/${businessId}?page=${currentPage}`;
    } else if (action === "escalated") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${currentPage}&escalated=true`;
    } else if (action === "unread") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${currentPage}&read=false`;
    } else if (action === "read") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${currentPage}&read=true`;
    } else if (action === "resolved") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${currentPage}&escalated=false`;
    } else if (action === "closed") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${currentPage}&escalated=false&closed=true`;
    } else {
      url = `/api/email-forwarder/ticket/${businessId}?page=${currentPage}`;
    }

    instance
      .get(url, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.data) {
          setTotalMails(response.data.pages);
          setEmails(response.data.data);
      
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });

    getMode();

    // newSocket.on("connect", () => console.log("Socket connection established"));
    // if (selectedConversation && chatBoxRef.current) {
    //   chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight!;
    // }

    // return () => {
    //   newSocket.disconnect();
    // };
  }, [businessId, selectedConversation, router]);

  const getMode = () => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance
        .get(`api/business/get/${userData.businessId}`, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {

          setMode(res.data.aiMode);
        })
        .catch((e) => {
       
        });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const handleConversationClicked = async (emailId: string) => {
    const selected =
      emails.find((email: any) => email?._id === emailId) ?? null;
    setSelectedConversation(selected);
  };

  const conversations = emails.map((email: any) => (
    <GmailConversation
      selected={selectedConversation?._id === email?._id}
      key={email?._id}
      gmailId={email?.gmail?.gmailId}
      email={email}
      ticket={email}
      read={email.read}
      customer={email.customer}
      date={new Date(email.createdAt)}
      handleConversationClicked={handleConversationClicked}
    />
  ));

  let moodClass = "";

  if (
    selectedConversation &&
    selectedConversation?.sentiment === constants.mood.angry
  ) {
    moodClass = styles["high-color"];
  } else if (
    selectedConversation &&
    selectedConversation?.sentiment === constants.mood.neutral
  ) {
    moodClass = styles["mid-color"];
  } else if (
    selectedConversation &&
    selectedConversation?.sentiment === constants.mood.happy
  ) {
    moodClass = styles["chill-color"];
  } else {
    moodClass = styles["high-color"];

  }

  const updateMode = async (mode: string) => {
    let newMode = "";
    if (mode === "Automatic Mode") {
      newMode = "auto";
    } else if (mode === "Supervised Mode") {
      newMode = "supervised";
    } else if (mode === "Hybrid Mode") {
      newMode = "hybrid";
    }

    let token = getCookie("Authorization");
    const userData = JSON.parse(localStorage.getItem("user") as string);

    setMode(newMode);
    try {
      let data = {
        userId: userData.userId,
        businessId: userData.businessId,
        aiMode: newMode,
      };
      const response = await instance.post(
        "api/business/update",
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showToast("primary", "Success!", "Mode updated successfully");
      setSelectMode(false);
      const business: { businessId: string }[] | [] = response.data?.business;

    } catch (error) {
      if (error instanceof AxiosError) {
      }
  
    }
  };

  useEffect(() => {
    localStorage.removeItem("selectedChat");
    const handelClick = (evt: any) => {
 
      let targetEl = evt.target; // clicked element
      let gmailsBox = document.getElementById("gmails");

      let moreOptionCon = document.getElementById("more_option_con");
      if (targetEl === moreOptionCon) {
   
        return;
      } else if (targetEl === gmailsBox) {

        setSelectMode((prev) => true);
        return;
      } else {
        if (selectMode && targetEl !== moreOptionCon) {
 
          setSelectMode((prev) => false);
        }
      }
    };

    document
      .getElementById("gmail_con")!
      .addEventListener("click", handelClick, false);

    return () => {
      document
        ?.getElementById("gmail_con")
        ?.removeEventListener("click", handelClick);
    };
  }, [selectMode]);

  const nextEmail = (num: number) => {
    let token = getCookie("Authorization")
    let path = router.pathname
    let action = getAction(path)

    let url = "";
    if (action === "all" || action === "") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${num}`;
    } else if (action === "in active") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${num}`;
    } else if (action === "escalated") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${num}&escalated=true`;
    } else if (action === "unread") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${num}&read=false`;
    } else if (action === "read") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${num}&read=true`;
    } else if (action === "resolved") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${num}&escalated=false`;
    } else if (action === "closed") {
      url = `/api/email-forwarder/ticket/${businessId}?page=${num}&closed=true`;
    } else {
      url = `/api/email-forwarder/ticket/${businessId}?page=${num}`;
    }

    instance
      .get(`${url}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
    
        if (response.data.data) {
          setTotalMails(response.data.pages);
          setEmails(response.data.data);
          setCurrentPage(response.data.currentPage);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });
  };

  const getAction = (path: string) => {
    let action = path.includes("all")
      ? "all"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("unread")
      ? "unread"
      : path.includes("resolved")
      ? "resolved"
      : path.includes("closed")
      ? "closed"
      : path.includes("read")
      ? "read"
      : "";

    return action;
  };

  return (
    <div id="gmail_con" className={styles.wrapper}>
      <div id="gmails" className={styles["conversation-column"]}>
        <header>
          <ul>
            <li>
              <img src={CheckIcon} alt="star" />
            </li>
            <li>Conversation</li>
            <li>
              <img src={CommentIcon} alt="star" />
            </li>
            <li>
              <img src={StarIcon} alt="star" />
            </li>
          </ul>
          <div className={styles.form_group2}>
            <div
              onClick={() => {
                setSelectMode(true);
              }}
            >
              {mode === "auto" && (
                <CircuitIcon
                  style={`${styles.mode_icon} ${styles.auto_icon}`}
                />
              )}
              {mode === "supervised" && (
                <EyeStrokeIcon
                  style={`${styles.mode_icon} ${styles.supervised_icon}`}
                />
              )}
              <p
                onClick={() => {
                  setSelectMode(true);
                }}
              >
                {mode === "auto" && "Automatic"}
                {mode === "supervised" && "Supervised"}
              </p>
              <span
                onClick={() => {
                  setSelectMode(true);
                }}
              >
                <CollapseIcon style={styles["collapse_icon"]} />
              </span>
              {selectMode && (
                <div id="more_option_con" className={styles["more_option_con"]}>
                  <div className={`${mode === "auto" && styles.selectedMode}`}>
                    <div
                      className={styles["more_option"]}
                      onClick={() => {
                        updateMode("Automatic Mode");
                        setSelectMode(false);
                      }}
                    >
                      <CircuitIcon
                        style={`${styles.mode_icon} ${styles.auto_icon}`}
                      />
                      <h5>Automatic</h5>
                      {mode === "auto" && (
                        <MarkStrokeIcon style={`${styles.selected_icon}`} />
                      )}
                    </div>
                  </div>
                  <div
                    className={`${
                      mode === "supervised" && styles.selectedMode
                    }`}
                  >
                    <div
                      className={styles["more_option"]}
                      onClick={() => {
                        updateMode("Supervised Mode");
                        setSelectMode(false);
                      }}
                    >
                      <EyeStrokeIcon
                        style={`${styles.mode_icon} ${styles.supervised_icon}`}
                      />
                      <h5>Supervised</h5>
                      {mode === "supervised" && (
                        <MarkStrokeIcon style={`${styles.selected_icon}`} />
                      )}
                    </div>
                  </div>
                  <div>
                    <Link to="/settings/mode">
                      <div className={styles["learn_more"]}>
                        <h5>Learn more</h5>
                        <UrgencyIcon style={styles["icons"]} />
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>
        <section
          style={
            conversations.length > 0
              ? { alignItems: "start" }
              : { alignItems: "center" }
          }
        >
          <div className={styles.messages}>
            <ul>
              {!isLoading && conversations.length > 0 && conversations}
              {!isLoading && conversations && conversations.length <= 0 && (
                // <p className={styles["text-align"]}>No conversations yet!</p>
                <div className={styles.no_emails}>
                  <p>You have no mails</p>
                  <div className={styles.create_email}>
                    <h4>Create One</h4>
                    <PlusCircleIcon style={styles.plusicon} />
                  </div>
                </div>
              )}
              {isLoading && <p className={styles["text-align"]}>Loading...</p>}
            </ul>
          </div>
          {!isLoading && conversations.length > 0 && (
            <div className={styles.pagination}>
              <div
                onClick={() => {
                  localStorage.setItem('currentPage',JSON.stringify(currentPage-1));
                  setCurrentPage(currentPage <= 1 ? 1 : currentPage - 1);
                  nextEmail(currentPage <= 1 ? 1 : currentPage - 1);
                }}
                className={styles.pagination_btn_left}
              >
                <ArrowLeftIcon style={styles.pagination_icon} />
              </div>
              <div>
                <h5
                  className={styles.pagination_num}
                >{`${currentPage} of ${totalMails}`}</h5>
              </div>
              <div
                onClick={() => {
                  localStorage.setItem('currentPage',JSON.stringify(currentPage+1))
                  setCurrentPage(
                    currentPage >= totalMails ? totalMails : currentPage + 1
                  );
                  nextEmail(
                    currentPage >= totalMails ? totalMails : currentPage + 1
                  );
                }}
                className={styles.pagination_btn_right}
              >
                <ArrowRightIcon2 style={styles.pagination_icon} />
              </div>
            </div>
          )}
        </section>
      </div>
    </div>
  );
}
