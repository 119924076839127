import React, { FC, useEffect, useState } from "react";
import styles from "./FAQ.module.css";

interface PropTypes {
  question: string;
  response: string;
  index: string;
  onClick: (index: any) => void;
  selected: boolean;
  selectFaqs: Function;
  selectedFaqIds: Array<string>
}

const FAQ: FC<PropTypes> = ({
  question,
  response,
  index,
  onClick,
  selected,
  selectFaqs,
  selectedFaqIds,
}) => {
  // const responseText1 = response.split(":")[0];
  // const responseText2 = response.split(":")[1];

  const [isSelected, setisSelected] = useState(false)

  useEffect(() => {
          
      // To calculate the time difference of two dates 
      // let id = setInterval(() => {
      //     var date1 = new Date(ticket.message?.createdAt); 
      //     var date2 = new Date(); 
      //     console.log(date2.getHours(), date1.getHours())
      //     console.log(date2.getDay(), date1.getDay())
      //     console.log(date2.getHours() - date1.getHours())
      //     console.log(date1.getSeconds() - date2.getSeconds())
      //     const total = Date.parse(ticket.message?.createdAt) + Date.parse(new Date().toDateString());
      //     const seconds = Math.floor((total / 1000) % 60);
      //     const minutes = Math.floor((total / 1000 / 60) % 60);
      //     const hours = Math.floor((total / 1000 / 60 / 60) % 24);

      //     setTimer(
      //         (hours > 9 ? hours : '0' + hours) + ':' +
      //         (minutes > 9 ? minutes : '0' + minutes) + ':'
      //         + (seconds > 9 ? seconds : '0' + seconds)
      //     )
      // }, 1000)
      // return clearInterval(id)

      if(selectedFaqIds){
          const found = selectedFaqIds.find((id) => id === index)
          if(found){
              setisSelected(true)
          }else{
              setisSelected(false)
          }
      }
      
  }, [selectedFaqIds, index])

  return (
    <section
      className={`${isSelected && styles["selected"]} ${selected && styles["selected"]}  ${styles["faq"]}`}
      onClick={() => {(isSelected || selected)  ? onClick(null) : onClick(index)}}
    >
      <div onClick={() => selectFaqs(index)}>
      <input className={`livechat`} onChange={() => {}} checked={isSelected} type="checkbox" />
      </div>
      <div className={styles["ques_res_con"]}>
        <div className={styles["question-div"]}>
          <p>
            <span className={styles["big"]}>Question: </span>
            {question}
          </p>
        </div>
        <div className={styles["response-div"]}>
          <p className={styles["responseText1"]}>
            <span className={styles["big"]}>Response:</span>
            <p dangerouslySetInnerHTML={{__html: response.replaceAll("\n", "<br>")}}></p>
          </p>
          {/* <p className={styles["responseText2"]}>{responseText2 ?? response}</p> */}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
