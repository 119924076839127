import React, { useEffect, useState } from "react";
import styles from "./Inventory.module.css";
import AddProductCard from "../../components/AddProductCard/AddProductCard";
// import InventoryBusinessModal from "../../components/InventoryBusinessModal/InventoryBusinessModal";
import CSVModal from "../../components/CSVModal/CSVModal";
import NewProductModal from "../../components/NewProductModal/NewProductModal";
import { checkuser, getCookie, showToast } from "../../utilities/helpers";
import axios, { AxiosError } from "axios";
import InventoryProductRow from "../../components/Inventory/ProductRow";
import instance from "../../api";
import { ArrowLeftIcon, ArrowRightIcon2 } from "../../assets/icons";

const Inventory = () => {
  checkuser()
  const [addProduct, setAddPoduct] = useState(false);
  // const [addCSV, SetAddCSV] = useState();
  const [businessId, setBusinessId] = useState('');
  const [hasInventory, setHasInventory] = useState(false)
  const [hasKnowledgeBase, setHasKnowledgeBase] = useState(false)
  const [uploadFile, setUploadFile] = useState(false)
  const [inventory, setInventory] = useState<Array<any>>([])
  const [file, setFile] = useState<string | null>(null);
  const [productsToAdd, setProductsToAdd] = useState<Array<any>>()
  const [addProductType, setAddProductType] = useState('');
  const [editProduct, setEditProduct] = useState<any | null>();
  const [updateProduct, setUpdateProduct] = useState<any | null>();
  const [editProductIndex, setEditProductIndex] = useState<number | null>();
  const [editProductId, setEditProductId] = useState<string | null>();
  const [isNew, setIsNew] = useState(true)
  const [loading, setLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState<number>(1)
  // const [limit, setLimit] = useState<number>(100)
  // const limit = 100
  const [totalPage, setTotalPage] = useState<number>(1)

  useEffect(() => {
    getInventory()
  }, [businessId, hasKnowledgeBase])
  
  const uploadInventory = () => {
    const input = document.createElement("input");
    input.accept = ".csv, .xlsx";
    input.id = "inventory";
    input.name = "inventory";
    input.type = "file";
    input.onchange = (ev) => handleUploadInventory(ev);
    input.hidden = true;
    input.click()
  }

  const handleUploadInventory = (ev: any) => {
    if(ev.target.files[0] !== undefined){
      let file = ev.target.files[0]
      setFile(file)
    }
  }

  const send = () => {
    submitInventoryFile(file)
  }

  const removeFile = () => {
    setFile(null);
  };

  const submitInventoryFile = async (file: string | null) => {
    setLoading(true)
    try {
      let token = getCookie("Authorization")

      let request = 'upload'
      console.log(request, hasKnowledgeBase)
      var formData = new FormData();
      if(file)
      formData.append('file', file)
      const res = await instance.post(
        `api/inventory/${businessId}/${request}`,
        formData,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }
      );
      console.log(res.data)
      if(res.data){
        showToast("primary", "Success!", "Inventory file uploaded successfully")
        setUploadFile(false)
        setInventory(res.data)
        setHasInventory(true)
        setHasKnowledgeBase(true)
      }
      setLoading(false)
      // setIsLoading(false);
      // navigate(`/training-center/saved-faq/${businessId}`);
    } catch (err: any) {
      setLoading(false)
      if (err instanceof AxiosError) {
        console.log(err.response?.data.message);
        showToast("secondary", "Error!", err.response?.data.message)
        // setError(err.response?.data.message);
      } else {
        console.log(err);
        // setError(err);
        // setIsLoading(false);
      }
    }
  }

  const submitInventory = async (products: any, isNew = true) => {
    setLoading(true)
    try {
      let request = hasKnowledgeBase ? 'update': 'create'
      const userData = JSON.parse(localStorage.getItem("user") as string);
      let inven = isNew ? [...inventory, ...products] : [...products]
      let formData = {
        userId: userData.userId,
        businessId: businessId,
        knowledgeBase: inven
      }

      let message = ""
      if(inven.length < inventory.length){
        message = "Inventory deleted successfully"
      }else if(inven.length > inventory.length){
        message = "Inventory added successfully"
      }else{
        message = "Inventory updated successfully"
      }

      const res = await axios.post(
        `${process.env.REACT_APP_BOT_BASE_URL}/ai/knowledge-base/${request}`,
        formData
      );

      showToast("primary", "Success!", message)
      setUploadFile(false)
      setInventory(res.data.knowledgeBase)
      setHasInventory(true)
      setLoading(false)
      setIsNew(true)
      setEditProductIndex(null)

      return res.data.knowledgeBase

      // setIsLoading(false);
      // navigate(`/training-center/saved-faq/${businessId}`);
    } catch (err: any) {
      showToast("secondary", "Success!", "Error adding inventory")
      if (err instanceof AxiosError) {
        console.log(err.response?.data.message);
        // setError(err.response?.data.message);
      } else {
        console.log(err);
        // setError(err);
        // setIsLoading(false);
      }
      setLoading(false)
    }
  }

  const updateInventory = async (product: any) => {
    setLoading(true)
    try {
      if(product.productImage){
        var formData = new FormData();
        formData.append('images', product.productImage)
        let res = await imageUpload(formData)
        product["image"] = res.data[0];
      }else{
      }

      let data = {
        inventory: {
          "name": product.productTitle,
          "quantity": product.productQty,
          "category": product.productCategory,
          "price": product.productPrice,
          "status": "In-stock",
          "currency": product.productCurrency
        }
      }
      let token = getCookie("Authorization")

      const res = await instance.patch(
        `api/inventory/${businessId}/${editProductId}/update`,
        data,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      showToast("primary", "Success!", "Product updated successfully")
      setUploadFile(false)
      console.log(res.data)
      // setInventory(res.data)
      getInventory()
      setHasInventory(true)
      setLoading(false)
      setIsNew(true)
      setEditProductIndex(null)

      return res.data

      // setIsLoading(false);
      // navigate(`/training-center/saved-faq/${businessId}`);
    } catch (err: any) {
      showToast("secondary", "Success!", "Error adding product")
      if (err instanceof AxiosError) {
        console.log(err.response?.data.message);
        // setError(err.response?.data.message);
      } else {
        console.log(err);
        // setError(err);
        // setIsLoading(false);
      }
      setLoading(false)
    }
  }

  const saveProduct = async (product: any) => {
    console.log(product, addProductType)
    if(addProductType==="single"){
      console.log(product)
      var formData = new FormData();
      console.log(!product.productImage)
      if(product.productImage){
        console.log(!product.productImage)
        formData.append('images', product.productImage)
      }

      let image = await imageUpload(formData)
      if(image.message && image.message === "Inventory images uploaded successfully"){
        let inventoryProduct = {
          name: product.productTitle,
          price: product.productPrice,
          status: "In-stock",
          category: product.productCategory,
          image: image.data[0],
          quantity: product.productQty,
          description: product.productDescription,
          currency: product.productCurrency,
        }
        await submitInventory([inventoryProduct])
        return true;
      }
      return false
    }else{
      // let pre = productsToAdd
      // if(!pre){
      //   pre = []
      // }
      // pre.push(product)
      // console.log(pre)
      setProductsToAdd((prev) => (
        prev ? [...prev, product] : [product]
      ))
      return true
    }
    
  }

  const editSavedProduct = async (product: any) => {
    if(editProductIndex != null){
      if(isNew){
        let pr : Array<any> | undefined = productsToAdd;
        pr![editProductIndex] = product
        setProductsToAdd(pr)
      }else{
        let newInventory = inventory;
        let image = product.productImageURL
        let inventoryProductImg: any = {
          name: product.productTitle,
          price: product.productPrice,
          status: "In-stock",
          image: image,
          category: product.productCategory,
          quantity: product.productQty,
          description: product.productDescription,
          currency: product.productCurrency,
        }

        if(product.productImage){
          var formData = new FormData();
          formData.append('images', product.productImage)
          let res = await imageUpload(formData)
          inventoryProductImg["image"] = res.data[0];
          newInventory[editProductIndex] = inventoryProductImg
          await submitInventory(newInventory, false)
        }else{
          newInventory[editProductIndex] = inventoryProductImg
          await submitInventory(newInventory, false)
        }

      }
    }
  }

  const imageUpload = async (formData: FormData) => {
    let token = getCookie("Authorization")
    // var formData = new FormData();
    // console.log(image)
    if(formData){
      // formData.append('images', image)
      try{
        const res = await instance.post(
          `api/admin/inventory-image-upload/${businessId}/business`,
          formData,
          {
            headers: {
              'Access-Control-Allow-Headers': 'X-Requested-With',
              'Authorization': `Bearer ${token}`
            }
          }
        )
        console.log(res.data)
        return res.data
      }catch(e){
        console.log(e)
        return e
      }
    }
  }

  const processSavedProducts =  async () => {
    setLoading(true)
    if(productsToAdd && productsToAdd.length > 0){
      var formData = new FormData();
      for(let i=0; i<productsToAdd.length; i++){
        let product = productsToAdd[i]
        if(product.productImage)
        formData.append('images', product.productImage)
      }
      let image = await imageUpload(formData)
      if(image.message && image.message === "Inventory images uploaded successfully"){
        let inventoryProduct: Array<any> = []
        for(let i=0; i<productsToAdd.length; i++){
          let product = productsToAdd[i]
          let inventoryProductImg = {
            name: product.productTitle,
            price: product.productPrice,
            status: "In-stock",
            category: product.productCategory,
            image: image.data[i],
            quantity: product.productQty,
            description: product.productDescription,
          }
          inventoryProduct.push(inventoryProductImg)
        }
        console.log(inventoryProduct)
        await submitInventory(inventoryProduct)
      }
      console.log(productsToAdd)
    }
  }

  const handleEditProduct = (product: any, index:number) => {
    console.log("dc")
    setEditProduct(product)
    setEditProductIndex(index)
  }

  const handleUpdateProduct = (product: any, id:string) => {
    setUpdateProduct(product)
    setEditProductId(id)
  }

  const handleDeleteProduct = async (product: any) => {
    setLoading(true)
    console.log(product)
    try {
      let token = getCookie("Authorization")

      const res = await instance.delete(
        `api/inventory/${businessId}/${product.id}/delete`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }
      );

      showToast("primary", "Success!", "Product deleted successfully")
      // setInventory(res.data)
      getInventory()
      setLoading(false)

      return res.data

      // setIsLoading(false);
      // navigate(`/training-center/saved-faq/${businessId}`);
    } catch (err: any) {
      showToast("secondary", "Success!", "Error deleting product")
      if (err instanceof AxiosError) {
        console.log(err.response?.data.message);
        // setError(err.response?.data.message);
      } else {
        console.log(err);
        // setError(err);
        // setIsLoading(false);
      }
      setLoading(false)
    }
  }
  
  const getInventory = async () => {
    if(localStorage.getItem("user")){
      let token = getCookie("Authorization")
      const userData = JSON.parse(localStorage.getItem("user") as string);
      setBusinessId(userData.businessId)
      instance.get(
        `api/inventory/${userData.businessId}/get`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }
      ).then(res => {
          if(res.data.data && res.data.data.length > 0){
            setHasInventory(true)
            setInventory(res.data.data)
          }
          setHasKnowledgeBase(true)
          setTotalPage(res.data.pages)
          setCurrentPage(res.data.currentPage)
      }).catch(e => {
        console.log(e)
        if(e.response.data === "This business dosen't have a knowledge base"){
          setHasInventory(false)
        }
        setHasInventory(false)
        setHasKnowledgeBase(false)
      })
    }
  }

  const nextPageInventory = (num:number) => {
    if(localStorage.getItem("user")){
      let token = getCookie("Authorization")
      const userData = JSON.parse(localStorage.getItem("user") as string);
      setBusinessId(userData.businessId)
      instance.get(
        `api/inventory/${userData.businessId}/get?page=${num}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }
      ).then(res => {
          if(res.data.data && res.data.data.length > 0){
            setHasInventory(true)
            setInventory(res.data.data)
          }
          setHasKnowledgeBase(true)
          setTotalPage(res.data.pages)
          setCurrentPage(res.data.currentPage)
      }).catch(e => {
        console.log(e)
        if(e.response.data === "This business dosen't have a knowledge base"){
          setHasInventory(false)
        }
        setHasInventory(false)
        setHasKnowledgeBase(false)
      })
    }
  }

  return (
    <section className={styles.wrapper}>
      {/* <InventoryBusinessModal isOpen={true} onClose={()=>{}}/> */}
      <CSVModal loading={loading} hasKnowledgeBase={hasKnowledgeBase} removeFile={removeFile} send={send} file={file} isOpen={uploadFile} uploadInventory={uploadInventory} onClose={() => {setUploadFile(false) }} />
      <NewProductModal updateInventory={updateInventory} isNew={isNew} updateProduct={updateProduct} editSavedProduct={editSavedProduct} editProduct={editProduct} saveProduct={saveProduct} isOpen={addProduct} onClose={() => {setAddPoduct(false); setEditProduct(null); setEditProductIndex(null)}} />
      <header>
        <h1>Inventory</h1>
      </header>
      <section className={styles.content}>
        <section className={styles["content-header"]}>
          <div className={styles.left}>
            <p>Add Product</p>
            <p>Add a minimum of 5 product to automate your sales.</p>
          </div>
          <div className={styles.right}>
            <input type="button" onClick={hasInventory ? () => {setAddPoduct(true); setAddProductType("single")} : ( productsToAdd && productsToAdd?.length >= 2 ? () => { processSavedProducts()} : () => {console.log('sssf')})} className={styles.add_product} value={ hasInventory ? "Add Products": productsToAdd && productsToAdd?.length >= 2 ? loading ? "Loading..." : "Continue" : "Add all 6 products"} />
            <input type="button" onClick={() => setUploadFile(true)} value="Upload CSV" />
          </div>
        </section>
        {!hasInventory ?
        <section className={styles["content-body"]}>
          <div className={styles["grid"]}>
            {Array.from({ length: (productsToAdd ? productsToAdd.length: 0) }, (value, index) => (
              <AddProductCard setEditProduct={() => handleEditProduct(productsToAdd![index], index)} product={productsToAdd![index]} setAddPoduct={setAddPoduct} setAddProductType={setAddProductType} key={index} />
            ))}
            {Array.from({ length: (6 - (productsToAdd ? productsToAdd.length: 0)) }, (value, index) => (
              <AddProductCard setAddPoduct={setAddPoduct} setAddProductType={setAddProductType} key={index} />
            ))}
          </div>
        </section>
        :
        <section className={styles["inventory_table_container"]}>
          <table className={styles["inventory_table"]}>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Category</th>
                <th>Selling Price</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {inventory.map((product: any, index) => {
                return (
                  <InventoryProductRow setIsNew={setIsNew} setAddPoduct={setAddPoduct} setEditProduct={() => handleUpdateProduct(product, product.id)} handleDeleteProduct={() => handleDeleteProduct(product)} product={product} key={index} />
                )
              })}
              
            </tbody>
            <div className={styles.pagination}>
              <div onClick={() => {setCurrentPage(currentPage <=1 ? 1 : currentPage-1); nextPageInventory(currentPage <=1 ? 1 : currentPage-1) } } className={styles.pagination_btn_left}>
                <ArrowLeftIcon style={styles.pagination_icon} />
              </div>
              <div>
                <h5 className={styles.pagination_num}>{`${currentPage} of ${totalPage}`}</h5>
              </div>
              <div onClick={() => {setCurrentPage(currentPage >= totalPage ? totalPage : currentPage+1); nextPageInventory(currentPage >= totalPage ? totalPage : currentPage+1) }} className={styles.pagination_btn_right}>
                <ArrowRightIcon2 style={styles.pagination_icon} />
              </div>
            </div>
          </table>
        </section>
        }
      </section>
    </section>
  );
};

export default Inventory;
