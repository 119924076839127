import React, { useEffect, useState } from "react";
import styles from "./Admin.module.css";
import { checkuser, getCookie, showToast } from "../../../../utilities/helpers";
import instance from "../../../../api";
import AdminRow from "./AdminRow/AdminRow";
import { AxiosError } from "axios";

export default function Admins() {
  checkuser()
  const [admins, setAdmins] = useState<Array<any>>()
  const [ isLoading, setIsLoading ] = useState(true);
  const [ user, setUser ] = useState<any>();

  useEffect(() => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      setUser(userData)
      // setBusinessId(userData.businessId)
      instance.get(
        `api/user/list-admins/${userData.businessId}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res.data)
          setAdmins(res.data.administrators)
          // setAgents(res.data.data)
          setIsLoading(false)
      }).catch(e => {
        console.log(e.response.data)
        setIsLoading(false)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }, [])

  const removeTeam = async (id:any) => {
    if(id <= 0){
      return
    }

    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let token = getCookie("Authorization")
      const response = await instance.delete(`api/team/${userData.businessId}/${id}`, 
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data);
      showToast("secondary", "Success!", "Team member deleted successfully")

    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response) {
          showToast("secondary", "Error!", error?.response.data.message)
        }
      }
    }
  }

  return (
    <>
      <section className={styles.wrapper}>
        <header>
          <h1>Admin</h1>
          {/* <input type="button" value="Add Admin" /> */}
        </header>
        <section className={styles.content}>
          <div className={styles.usersList}>
            {!isLoading && 
            <>
            {admins?.map((admin, index) => (
              <AdminRow
                key={index}
                id={admin.id}
                team={admin.team}
                role={admin.role}
                fullName={`${admin.userId?.firstName} ${admin.userId?.lastName}`}
                email={admin.userId?.email}
                removeTeam={removeTeam}
                you={admin.userId?._id === user.id}
              />
            ))}
            </>
            }
          </div>
        </section>
      </section>
    </>
  );
}
