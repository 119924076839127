import React, { FC, } from "react";
import styles from "./customtoast.module.css";
interface CustomToastModalProps {
  type: string,
  message: string,
  color: string
}

const CustomToast: FC<CustomToastModalProps> = ({ type, message, color }) => {

  return (
    <div className={styles["custom_toast"]}>
        <h5 className={color}>{type}</h5>
        <p>{message}</p>
    </div>
  );
};
export default CustomToast;
