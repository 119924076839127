import React, {
  ChangeEvent,
  FC,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./LiveChat.module.css";
// import CheckIcon from "../../assets/images/conversation_checkbox.svg";
import MessageIcon from "../../assets/images/conversation_message.svg";
import CommentIcon from "../../assets/images/conversation_uncomment.svg";
// import StarIcon from "../../assets/images/conversation_star.svg";
import Conversation from "../../components/Conversation/Conversation";
import Message from "../../components/Message/Message";
import { AxiosError } from "axios";
import instance from "../../api/index";
// import { io } from "socket.io-client";
import {
  checkuser,
  formatDate,
  formatDateToOrdinal,
  getCookie,
  showToast,
} from "../../utilities/helpers";
import constants from "../../utilities/constants";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import messageStyles from "../../components/Message/Message.module.css";
import {
  StatusIcon,
  CalenderIcon,
  TicketIcon,
  UrgencyIcon,
  EscalatedIcon,
  SendIcon,
  SearchIcon,
  CollapseIcon,
  CircuitIcon,
  MarkStrokeIcon,
  HybridIcon,
  ArrowRightIcon2,
  ArrowLeftIcon,
  CancelIcon,
  EditIconBox,
  RefreshIconStroke,
  SatisfactionIcon,
} from "../../assets/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "../../components/Modal/Modal";

interface MessageType {
  id: string;
  role: "assistance" | "user";
  content: string;
  createdAt: Date;
  status: string;
}
export interface interactingAdmin {
  _id: string;
  firstName: string;
  lastName: string;
}
interface ChatType {
  id: string;
  _id: string;
  ticketId: string;
  customer: string;
  email: string;
  phoneNo: string;
  createdAt: Date;
  updatedAt: Date;
  messages: Array<MessageType>;
  category: string;
  escalated: boolean;
  css: string;
  closed: boolean;
  escalation_department: string;
  department: string;
  sentiment: string;
  channel: string;
  message: any;
  title: string;
  urgency: string;
  type: string;
  read: true;
  aiMode: string;
  created_at: Date;
  latestMessageDate: Date;
  interactingAdmin: interactingAdmin;
  interactingStatus: boolean;
}

interface LiveChatProps {
  socket: WebSocket;
}

const LiveChat: FC<LiveChatProps> = ({ socket }) => {
  checkuser();
  const ref = useRef(null);
  const router = useLocation();
  const navigate = useNavigate();
  const [chats, setChats] = useState<Array<ChatType>>([]);
  const [error, setError] = useState<string | null>(null);
  const [editMessage, setEditMessage] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [message, setMessage] = useState("");
  const [selectedConversation, setSelectedConversation] =
    useState<ChatType | null>(null);
  const [selectedTicketId, setSelectedTicketId] = useState<string | null>(null);
  const [selectedMessages, setSelectedMessages] =
    useState<Array<MessageType> | null>(null);
  const businessId: string = JSON.parse(localStorage.getItem("user")!)[
    "businessId"
  ];
  const [comments, setComments] = useState<Array<any> | null>(null);
  const [selectedComment, setSelectedComment] = useState<any | null>();
  const lastItemOfSelectedConversation =
    selectedConversation &&
    selectedConversation.messages?.length &&
    selectedConversation.messages[selectedConversation?.messages?.length - 1];
  const chatBoxRef = useRef<HTMLUListElement>(null);
  const [selectMode, setSelectMode] = useState(false);
  const [mode, setMode] = useState("");
  const [confirmResolve, setConfirmResolve] = useState<boolean>(false);
  const [confirmClose, setConfirmClose] = useState<boolean>(false);
  const [resolveId, setResolveId] = useState<string | null>();
  const [commentId, setCommentId] = useState<string | null>();
  const [confirmEscalate, setConfirmEscalate] = useState<boolean>(false);
  const [escalateId, setEscalateId] = useState<string | null>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const user = JSON.parse(localStorage.getItem("user") || "");
  // const [currentPage, setCurrentPage] = useState<number>(JSON.parse(localStorage.getItem('pageState')||"1"));
  const [totalPage, setTotalPage] = useState<number>(1);
  const [filter, setFilter] = useState<string>("");
  const [departments, setDepartments] = useState<Array<any>>();
  const [selectedDepartment, setSelectedDepartment] = useState<any | null>();
  const limit = 10;
  const [searching, setSearching] = useState<boolean>(false);
  const [resolveMessage, setResolveMessage] = useState<string>();
  const [closeMessage, setCloseMessage] = useState<string>();
  const [escalateMessage, setEscalateMessage] = useState<string>();
  const [draftMessage, setDraftMessage] = useState<any>();
  const [draftBoxHeight, setDraftBoxHeight] = useState("0px");
  const [messageBoxHeight, setMessageBoxHeight] = useState("100px");
  const [action, setAction] = useState("");
  const [satisfactionScore, setSatisfactionScore] = useState<string>(`-`);
  const [restructuring, setRestructuring] = useState<boolean>(false);
  const [team, setTeam] = useState<any>();
  // const [socket, setSocket] = useState<any>()
  const [userTypingId, setUserTypingId] = useState<string>();
  // const [isMyTicket,setIsMyTicket] = useState<
  const [selectedTicketIds, setSelectedTicketIds] = useState<Array<string>>();
  // const userData = JSON.parse(localStorage.getItem("user") as string);
  // setBusinessId(userData.businessId)

  const blurTimeout = useRef<NodeJS.Timeout | null>(null);
  const focusInterval = useRef<NodeJS.Timeout | null>(null);
  const lastKeyPressTime = useRef<number>(Date.now());

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35.71,
    height: 20,
    padding: 0,
    borderRadius: 17,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 16,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 3,
      "&.Mui-checked": {
        transform: "translateX(15px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: "#3BDD99",
        },
        "& > .MuiSwitch-thumb": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#FFFFFF" : "#FFFFFF",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 14,
      height: 14,
      borderRadius: "50%",
      backgroundColor: theme.palette.mode === "dark" ? "#FFFFFF" : "#FFFFFF",
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      width: 35.71,
      height: 20,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor: "#D1D1E0",
      boxSizing: "border-box",
    },
  }));

  useEffect(() => {
    console.log("I have Mounted", setSelectedConversation);
    // Cleanup on unmount
    return () => {
      console.log("I have unmounted", selectedConversation);
      if (blurTimeout.current) {
        clearTimeout(blurTimeout.current);
      }
      if (focusInterval.current) {
        clearInterval(focusInterval.current);
      }
      if (selectedConversation) {
        if (selectedConversation?.aiMode == "auto") {
          updateChatAiMode("auto", selectedConversation?._id);

          console.log("I have unmounted");
        } // Call updateChatAiMode on component unmount
      }
    };
  }, []);
  useEffect(() => {
    getTeam();
  }, []);

  useEffect(() => {
    console.log(escalateId);
    if (team) {
      getDepartments();
      if (router?.search?.length > 0) {
        if (router.search.includes("&")) {
          let searchArray = router.search.split("&");
          if (searchArray[1] && searchArray[1].split("=")[0].includes("page")) {
            console.log("sddw");
            setCurrentPage(parseInt(searchArray[1].split("=")[1]));
            getChats(parseInt(searchArray[1].split("=")[1]));
            // getChatss(parseInt(searchArray[1].split("=")[1]))
          } else {
            getChats();
            // getChatss()
          }
        } else {
          getChats();
          // getChatss()
        }
      } else {
        getChats();
        // getChatss()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  useEffect(() => {
    console.log(selectedConversation + "here here");
    console.log(router);
    if (router.search.includes("&")) {
      let searchArray = router.search.split("&");
      if (searchArray[2] && searchArray[2].split("=")[0].includes("comment")) {
        console.log("sddw", searchArray[2].split("=")[1]);
      }
    } else {
      let searchArray = router.search.split("=");
      if (searchArray[0].includes("comment")) {
        let commentId = searchArray[1];
        setCommentId(commentId);
        const comment = comments?.filter((chat) => chat.id === commentId);
        console.log(comment, comments);
        if (comment && comment.length > 0) {
          setSelectedComment(comment[0]);
        }
        console.log(commentId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, router.search]);

  useEffect(() => {
    // set up socket
    // const newSocket = io(`${process.env.REACT_APP_BACKEND_END_BASE_URL}`, {
    //   extraHeaders: {
    //     Authorization: `${businessId}`,
    //   },
    // });

    // fetch conversations on page load

    const handleNewMessageEvent = (data: any) => {
      console.log(data, selectedConversation);
      const enifRes = data?.reply;
      const userRes = data?.request;
      if (selectedConversation && selectedConversation?.id === data.ticketId) {
        if (userRes) {
          setSelectedMessages((prevState: any) => [...prevState, userRes]);
        }
        if (enifRes) {
          setSelectedMessages((prevState: any) => [...prevState, enifRes]);
        } else {
          if (data.replyMode === "supervised") {
            setDraftMessage(data.message[1]);
          }
        }

        setSelectedTicketId((prev) => data.id);
        if (selectedConversation && chatBoxRef.current) {
          scrollToBottom();
        }
        // console.log(selectedConversation);
      } else {
        if (team) refreshPage(currentPage);
      }
    };

    const handleTicketInteracted = (data: any) => {
      setChats((prevChats) =>
        prevChats.map((chat) => {
          if (chat.id === data.ticketId) {
            const updatedMessages = [...chat.messages];
            if (data.request) {
              updatedMessages.push(data.request);
            }
            if (data.reply) {
              updatedMessages.push(data.reply);
            }

            const updatedChat = {
              ...chat,
              messages: updatedMessages,
            } as ChatType & { draftMessage?: string };

            if (data.replyMode === "supervised" && data.message?.[1]) {
              updatedChat.draftMessage = data.message[1];
            }

            return updatedChat;
          }
          return chat;
        })
      );

      if (
        selectedConversation &&
        selectedConversation.id === data.ticketId &&
        chatBoxRef.current
      ) {
        scrollToBottom();
      } else if (
        !selectedConversation ||
        selectedConversation.id !== data.ticketId
      ) {
        if (team) refreshPage(currentPage);
      }
    };

    const scrollToBottom = () => {
      if (chatBoxRef.current) {
        chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
      }
    };
    // const handleNewChatEvent = (data: any) => {
    //   console.log(data);
    //   setChats((prevChat) => [data, ...prevChat]);
    // };
    // newSocket.on("connect", () => console.log("Socket connection established"));
    // newSocket.on("newmessage", handleNewMessageEvent);
    // // newSocket.on("newchat", handleNewChatEvent);
    // // drag the ul element to the base so that new messages are seen first
    // if (selectedConversation && chatBoxRef.current) {
    //   scrollToBottom();
    // }

    // return () => {
    //   newSocket.disconnect();
    // };

    // const socket = new WebSocket(`wss://${process.env.REACT_APP_BACKEND_END_BASE_URL?.split("//")[1]}`, businessId);
    // setSocket(socket)
    socket.addEventListener("open", (event) => {
      // WebSocket connection is open
      console.log(event);
      console.log("WebSocket connection is open");
    });

    socket.addEventListener("message", (event) => {
      // Handle incoming WebSocket messages
      console.log(event, "this is the event");
      if (event.data) {
        let parseData = JSON.parse(event.data);

        if (parseData.event === "newmessage") {
          console.log("there has been a new chat", parseData.data);

          console.log("there has been a new chat", parseData.data);
        }
        if (parseData.event === "ticketInteracted") {
          handleTicketInteracted(parseData.data);
          console.log("ticket has been interacted with", parseData.data);
        }
      }
    });

    socket.addEventListener("close", (event) => {
      // WebSocket connection is closed
      console.log("WebSocket connection is closed");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessId, selectedConversation, team, router.pathname]);

  const scrollToBottom = () => {
    setTimeout(() => {
      const element: any = document.querySelector(".chat-list");
      if (element) {
        element.behavior = "smooth";
        element.scrollTop = element.scrollHeight;
        console.log(element.scrollHeight);
      }
    }, 10);
  };

  useEffect(() => {
    getChats();
  }, [router.pathname]);
  useEffect(() => {
    if (router?.search?.length > 0) {
      if (router.search.includes("&")) {
        let searchArray = router.search.split("&");
        if (
          searchArray[0] &&
          searchArray[0].split("=")[0].includes("ticketId")
        ) {
          let ticketId = searchArray[0].split("=")[1];
          const selected = chats.filter((chat) => chat.id === ticketId);
          // console.log(selected, ticketId)
          if (selected.length >= 1) {
            setSelectedConversation((prev) => selected[0]);
            getMessages(ticketId);
            // getMessagess(ticketId, selected[0].email)
          }
        }
        if (searchArray[1] && searchArray[1].split("=")[0].includes("page")) {
          console.log("sddw");
          setCurrentPage(parseInt(searchArray[1].split("=")[1]));
          // nextPage2(parseInt(searchArray[1].split("=")[1]))
          getChats(parseInt(searchArray[1].split("=")[1]));
        }
      } else {
        let searchArray = router.search.split("=");
        if (searchArray[0].includes("ticketId")) {
          let ticketId = searchArray[1];
          const selected = chats.filter((chat) => chat.id === ticketId);
          setSelectedConversation((prev) => selected[0]);
          console.log("here with conversation", selectedConversation);
          getMessages(ticketId);
          // getMessagess(ticketId, selected[0].email)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.search]);

  useEffect(() => {
    console.log(error);
    let token = getCookie("Authorization");
    if (!team) {
      if (localStorage.getItem("user") && token) {
        const userData = JSON.parse(localStorage.getItem("user") as string);
        // setBusinessId(userData.businessId)
        instance
          .get(
            `api/business/get/${userData.businessId}?admin=${userData.userId}`,
            {
              headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            setMode(res.data.aiMode);
          })
          .catch((e) => {
            console.log(e.message);
          });
      } else {
        localStorage.removeItem("user");
        window.location.assign("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.removeItem("selectedChat");
    const handelClick = (evt: any) => {
      console.log(selectMode);
      let targetEl = evt.target; // clicked element
      let conversationsBox = document.getElementById("conversations");

      let moreOptionCon = document.getElementById("more_option_con");
      let select_mode = document.getElementsByClassName("select_mode");
      if (targetEl === moreOptionCon) {
        console.log("sasfqfdvsd");
        return;
      } else if (targetEl === conversationsBox) {
        console.log("sdvsd");
        setSelectMode((prev) => true);
        return;
      } else {
        for (let i = 0; i < select_mode.length; i++) {
          if (targetEl === select_mode[i]) {
            console.log(select_mode[i]);
            setSelectMode((prev) => true);
            return;
          }
        }

        if (selectMode && targetEl !== moreOptionCon) {
          console.log("afwf");
          setSelectMode((prev) => false);
        }
      }
    };

    document
      .getElementById("livechat_con")!
      .addEventListener("click", handelClick, false);

    return () => {
      document
        ?.getElementById("livechat_con")
        ?.removeEventListener("click", handelClick);
    };
  }, [selectMode]);

  useLayoutEffect(() => {
    const element = document.getElementById("draft_message");
    if (element) {
      let height = element.getBoundingClientRect().height;
      console.log(height);
      setDraftBoxHeight(`${height}px`);
    } else {
      setDraftBoxHeight(`0px`);
    }
  }, [draftMessage]);

  const getChats = (num: number = 1) => {
    let token = getCookie("Authorization");
    let ticketId: string = "";
    if (router?.search?.length > 0) {
      if (router.search.includes("&")) {
        let searchArray = router.search.split("&");
        if (searchArray[0].split("=")[0].includes("ticketId")) {
          ticketId = searchArray[0].split("=")[1];
        }
      } else {
        let searchArray = router.search.split("=");
        if (searchArray[0].includes("ticketId")) {
          ticketId = searchArray[1];
        }
      }
    }

    if (router?.search?.length > 0) {
      if (router.search.includes("&")) {
        let searchArray = router.search.split("&");
        // if(searchArray[0].includes("ticketId")){
        //   let ticketId = searchArray[1]
        //   const selected = chats.filter((chat) => chat.id === ticketId);
        //   setSelectedConversation((prev) => selected[0]);
        //   getMessages(ticketId)
        // }
        if (searchArray[1].split("=")[0].includes("page")) {
          setCurrentPage(parseInt(searchArray[1].split("=")[1]));
        }
      }
    }
    console.log(ticketId, "sdvds");
    setIsLoading(true);
    setSelectedConversation(null);
    let path = router.pathname;
    let action = path.includes("all")
      ? "all"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("unread")
      ? "unread"
      : path.includes("resolved")
      ? "resolved"
      : path.includes("closed")
      ? "closed"
      : path.includes("read")
      ? "read"
      : "";
    setAction(action);

    let url = "";
    if (action === "all" || action === "") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&limit=${limit}`;
    } else if (action === "in active") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&limit=${limit}`;
    } else if (action === "escalated") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&limit=${limit}&escalated=true&closed=false`;
    } else if (action === "unread") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&limit=${limit}&read=false&closed=false`;
    } else if (action === "read") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&limit=${limit}&read=true&closed=false`;
    } else if (action === "resolved") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&limit=${limit}&escalated=false&closed=false`;
    } else if (action === "closed") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&limit=${limit}&escalated=false&closed=true`;
    }

    if (ticketId) {
      url = url + `&ticketId=${ticketId}`;
    }

    if (team) {
      if (team.role === "member") {
        url = url + "&department=" + team?.departmentId?.department;
      }
    } else {
      url = url + "&screen=ticket";
    }

    setCurrentPage(1);

    setFilter(action);

    instance
      .get(url, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        setChats(response.data.data);
        setTotalPage(response.data.pages);
        setCurrentPage(response.data.currentPage);
        console.log(ticketId);
        if (ticketId) {
          const selected = response.data.data.filter(
            (chat: any) => chat.id === ticketId
          );
          setSelectedConversation((prev) => selected[0]);
          getMessages(ticketId);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });
  };

  // const getChatss = (num:number = 1) => {
  //   let token = getCookie("Authorization")
  //   let ticketId: string = ""
  //   if(router?.search?.length > 0){
  //     if(router.search.includes("&")){
  //       let searchArray = router.search.split("&")
  //       if(searchArray[0].split("=")[0].includes("ticketId")){
  //         ticketId = searchArray[0].split("=")[1]
  //       }
  //     }else{
  //       let searchArray = router.search.split("=")
  //       if(searchArray[0].includes("ticketId")){
  //         ticketId = searchArray[1]
  //       }
  //     }
  //   }

  //   if(router?.search?.length > 0){
  //     if(router.search.includes("&")){
  //       let searchArray = router.search.split("&")
  //       // if(searchArray[0].includes("ticketId")){
  //       //   let ticketId = searchArray[1]
  //       //   const selected = chats.filter((chat) => chat.id === ticketId);
  //       //   setSelectedConversation((prev) => selected[0]);
  //       //   getMessages(ticketId)
  //       // }
  //       if(searchArray[1].split("=")[0].includes("page")){
  //         setCurrentPage(parseInt(searchArray[1].split("=")[1]))
  //       }
  //     }
  //   }
  //   console.log(ticketId, "sdvds")
  //   setIsLoading(true)
  //   setSelectedConversation(null)
  //   let path = router.pathname
  //   let action = path.includes("all")
  //   ? "all" : path.includes("escalated")
  //   ? "escalated": path.includes("unread")
  //   ? "unread": path.includes("resolved")
  //   ? "resolved": path.includes("closed")
  //   ? "closed": path.includes("read")
  //   ? "read": ""
  //   setAction(action);

  //   let url = "";
  //   if(action === "all" || action === ""){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "in active"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "escalated"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&escalated=true&closed=false`
  //   }else if(action === "unread"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&read=false&closed=false`
  //   }else if(action === "read"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&read=true&closed=false`
  //   }else if(action === "resolved"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&escalated=false&closed=false`
  //   }else if(action === "closed"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&escalated=false&closed=true`
  //   }

  //   if(ticketId){
  //     url = url + `&ticketId=${ticketId}`
  //   }

  //   if(team){
  //     if(team.role === "member"){
  //       url = url+"&department="+team?.departmentId?.department
  //     }
  //   }else{
  //     url = url+"&screen=ticket"
  //   }

  //   setCurrentPage(1)

  //   setFilter(action)

  //   instance
  //     .post(url,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //     .then((response) => {
  //       console.log(response)
  //       setChats(response.data.data);
  //       if(router?.search?.length > 0){
  //         if(router.search.includes("&")){
  //           let searchArray = router.search.split("&")
  //           if(searchArray[0] && searchArray[0].split("=")[0].includes("ticketId")){
  //             let ticketId = searchArray[0].split("=")[1]
  //             const selected = response.data.data.filter((chat: any) => chat.id === ticketId);
  //             // console.log(selected, ticketId)
  //             if(selected.length >= 1){
  //               setSelectedConversation((prev) => selected[0]);
  //               getMessagess(ticketId, selected[0].email)
  //             }
  //           }
  //           if(searchArray[1].split("=")[0].includes("page")){
  //             setCurrentPage(parseInt(searchArray[1].split("=")[1]))
  //           }
  //         }
  //       }
  //       setTotalPage(response.data.pages)
  //       setCurrentPage(response.data.currentPage)
  //       // console.log(ticketId)
  //       // if(ticketId){
  //       //   const selected = response.data.data.filter((chat:any) => chat.id === ticketId);
  //       //   setSelectedConversation((prev) => selected[0]);
  //       //   getMessages(ticketId)
  //       // }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       if (error instanceof AxiosError) {
  //         const errMessage = error.response?.data?.message;
  //         setError(errMessage);
  //       }
  //       setIsLoading(false);
  //     });
  // }

  const getTeam = async () => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance
        .get(`/api/team/${userData.businessId}/${userData.id}`, {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setTeam(res.data.data);
        })
        .catch((e) => {
          console.log(e.response.data);
        });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const nextPage = (num: number) => {
    let token = getCookie("Authorization");
    setIsLoading(true);
    setSelectedConversation(null);
    let path = router.pathname;
    let action = path.includes("all")
      ? "all"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("unread")
      ? "unread"
      : path.includes("resolved")
      ? "resolved"
      : path.includes("read")
      ? "read"
      : "";

    let url = "";
    if (action === "all" || action === "") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true`;
    } else if (action === "in active") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true`;
    } else if (action === "escalated") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&escalated=true`;
    } else if (action === "unread") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&read=false`;
    } else if (action === "read") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&read=true`;
    } else if (action === "resolved") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&escalated=false`;
    }

    if (action !== filter) {
      console.log();
      setCurrentPage(1);
    }

    if (team) {
      if (team.role === "member") {
        url = url + "&department=" + team?.departmentId?.department;
      }
    } else {
      url = url + "&screen=ticket";
    }

    setFilter(action);

    instance
      .get(url, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setChats(response.data.data);
        console.log("test-chats", response.data.data);
        setTotalPage(response.data.pages);
        setCurrentPage(response.data.currentPage);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });
  };

  // const nextPage2 = (num:number) => {
  //   let token = getCookie("Authorization")
  //   setIsLoading(true)
  //   setSelectedConversation(null)
  //   let path = router.pathname
  //   let action = path.includes("all")
  //   ? "all" : path.includes("escalated")
  //   ? "escalated": path.includes("unread")
  //   ? "unread": path.includes("resolved")
  //   ? "resolved": path.includes("read")
  //   ? "read": ""

  //   let url = "";
  //   if(action === "all" || action === ""){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "in active"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "escalated"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&escalated=true&closed=false`
  //   }else if(action === "unread"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&read=false&closed=false`
  //   }else if(action === "read"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&read=true&closed=false`
  //   }else if(action === "resolved"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&escalated=false&closed=false`
  //   }else if(action === "closed"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&escalated=false&closed=true`
  //   }

  //   if(action !== filter){
  //     console.log()
  //     setCurrentPage(1)
  //   }

  //   if(team){
  //     if(team.role === "member"){
  //       url = url+"&department="+team?.departmentId?.department
  //     }
  //   }else{
  //     url = url+"&screen=ticket"
  //   }

  //   setFilter(action)

  //   instance
  //     .post(url,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //     .then((response) => {
  //       console.log(response)
  //       setChats(response.data.data);
  //       setTotalPage(response.data.pages)
  //       setCurrentPage(response.data.currentPage)
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       if (error instanceof AxiosError) {
  //         const errMessage = error.response?.data?.message;
  //         setError(errMessage);
  //       }
  //       setIsLoading(false);
  //     });
  // }

  const refreshPage = (num: number) => {
    let token = getCookie("Authorization");
    let path = router.pathname;
    let action = path.includes("all")
      ? "all"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("unread")
      ? "unread"
      : path.includes("resolved")
      ? "resolved"
      : path.includes("read")
      ? "read"
      : "";

    let url = "";
    if (action === "all" || action === "") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true`;
    } else if (action === "in active") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true`;
    } else if (action === "escalated") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&escalated=true`;
    } else if (action === "unread") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&read=false`;
    } else if (action === "read") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&read=true`;
    } else if (action === "resolved") {
      url = `/api/chat/all-chat/${businessId}?page=${num}&active=true&escalated=false`;
    }

    if (action !== filter) {
      console.log();
      setCurrentPage(1);
    }

    if (team) {
      if (team.role === "member") {
        url = url + "&department=" + team?.departmentId?.department;
      }
    } else {
      url = url + "&screen=ticket";
    }

    setFilter(action);

    instance
      .get(url, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("test-chats", response.data.data);
        setChats(response.data.data);
        setTotalPage(response.data.pages);
        setCurrentPage(response.data.currentPage);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });
  };

  // const refreshPage2 = (num:number) => {
  //   let token = getCookie("Authorization")
  //   let path = router.pathname
  //   let action = path.includes("all")
  //   ? "all" : path.includes("escalated")
  //   ? "escalated": path.includes("unread")
  //   ? "unread": path.includes("resolved")
  //   ? "resolved": path.includes("read")
  //   ? "read": ""

  //   let url = "";
  //   if(action === "all" || action === ""){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "in active"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
  //   }else if(action === "escalated"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&escalated=true`
  //   }else if(action === "unread"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&read=false`
  //   }else if(action === "read"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&read=true`
  //   }else if(action === "resolved"){
  //     url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}&escalated=false`
  //   }

  //   if(action !== filter){
  //     console.log()
  //     setCurrentPage(1)
  //   }

  //   if(team){
  //     if(team.role === "member"){
  //       url = url+"&department="+team?.departmentId?.department
  //     }
  //   }else{
  //     url = url+"&screen=ticket"
  //   }

  //   setFilter(action)

  //   instance
  //     .post(url,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //     .then((response) => {
  //       console.log(response)
  //       setChats(response.data.data);
  //       setTotalPage(response.data.pages)
  //       setCurrentPage(response.data.currentPage)
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       if (error instanceof AxiosError) {
  //         const errMessage = error.response?.data?.message;
  //         setError(errMessage);
  //       }
  //       setIsLoading(false);
  //     });
  // }

  const handleConversationClicked = async (ticketId: string) => {
    setComments(null);
    setSelectedComment(null);
    setSelectedConversation(null);
    setSelectedMessages(null);

    if (router.search.length > 0) {
      let searchArray = router.search.split("=");
      if (searchArray[0].includes("ticketId")) {
        window.history.replaceState(null, "", "/live-chat");
      }
    }
    console.log(selectedConversation);
    if (selectedConversation && selectedConversation.aiMode === "hybrid") {
      updateChatAiMode("auto", selectedConversation.id);
    }
    const selected = chats.find((chat) => chat?.id === ticketId) ?? null;
    console.log("here showing selected", selected);
    localStorage.setItem(
      "selectedChat",
      JSON.stringify({ email: selected?.email, phoneNo: selected?.phoneNo })
    );

    setSelectedConversation(selected);
    console.log(selectedConversation, "new test for truthy indicator");
    console.log(
      selectedConversation?.interactingAdmin?._id,
      user.id,
      selectedConversation?.interactingAdmin?._id === user.id,
      "interactedWith"
    );
    console.log(selectedConversation, "this is selected conversation");
    if (selected) {
      setSelectedTicketId(selected.id);
      getComments(selected.id);
      getMessages(selected.id);
      // getMessagess(selected.id, selected.email)
    }
  };

  const getComments = async (ticketId: string) => {
    let token = getCookie("Authorization");
    instance
      .get(`/api/ticket/comment-logs/${businessId}/${ticketId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        localStorage.setItem("comments", JSON.stringify(response.data?.data));
        setComments(response.data?.data);
        // setIsLoading(false);
        // setSelectedMessages(response.data?.data);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });

    instance
      .get(`/api/chat/draft/${ticketId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setDraftMessage(response.data?.data);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });
    console.log(draftMessage);
  };

  const getMessages = async (ticketId: string) => {
    const userData: any = null;
    let token = getCookie("Authorization");
    instance
      .get(
        `/api/chat/messages/${ticketId}?read=True?admin=${
          userData?.userId ? userData?.userId : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log("here for selected responses", response.data);
        setIsLoading(false);
        setSelectedMessages(response.data?.data);
        scrollToBottom();
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });

    instance
      .get(`/api/chat/draft/${ticketId}`, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setDraftMessage(response.data?.data);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });
    console.log(draftMessage);
  };

  const getMessagess = async (ticketId: string, email: string) => {
    let token = getCookie("Authorization");
    const userData = JSON.parse(localStorage.getItem("user") as string);
    instance
      .get(
        `/api/chat/all/user/${email}/${businessId}?ticketId=${ticketId}?admin=${
          userData?.userId ? userData?.userId : ""
        }`,
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setSelectedMessages(response.data?.data);
        scrollToBottom();
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });

    console.log(draftMessage);
  };

  function editDraftMessage(message: MessageType) {
    setDraftMessage(null);
    setMessage(message.content);
    setEditMessage(message);
  }

  function removeDraft(message: MessageType) {
    // setMessage(message.content)
    setDraftMessage(null);
    setEditMessage(message);
  }

  const selectTickets = (ticketId: any) => {
    if (selectedTicketIds) {
      const index = selectedTicketIds.indexOf(ticketId);
      console.log(index);
      if (index >= 0) {
        let ids = selectedTicketIds;
        ids.splice(index, 1);
        console.log(ids);
        setSelectedTicketIds((prev: any) => [...ids]);
      } else {
        setSelectedTicketIds((prev: any) => [...prev, ticketId]);
      }
    } else {
      setSelectedTicketIds((prev: any) => [ticketId]);
    }
  };

  const selectAll = (ask: boolean) => {
    let ids = [];
    if (ask)
      for (let i = 0; i < chats.length; i++) {
        let ti: any = chats[i];
        ids.push(ti.id);
      }

    setSelectedTicketIds(ids);
  };

  const conversations = chats.map((chat) => (
    <Conversation
      selected={selectedConversation?.id === chat.id}
      key={chat.id}
      ticketId={chat.id}
      customer={chat.customer}
      date={new Date(chat.latestMessageDate ?? chat.updatedAt)}
      handleConversationClicked={handleConversationClicked}
      read={chat.read}
      interactingStatus={chat.interactingStatus}
      escalated={chat.escalated}
      selectTickets={selectTickets}
      selectedTicketIds={selectedTicketIds}
      action={action}
      interactingAdminId={chat?.interactingAdmin?._id}
      userId={user.id}
    />
  ));

  const messages = selectedMessages?.map((message: any) => (
    <Message
      key={message.id}
      id={message.id}
      role={message.role}
      created_date={message.createdAt}
      content={message.content}
      status={message.status}
      name={selectedConversation?.customer}
    />
  ));
  let moodClass = "";

  if (
    selectedConversation &&
    selectedConversation?.sentiment === constants.mood.angry
  ) {
    moodClass = styles["high-color"];
  } else if (
    selectedConversation &&
    selectedConversation?.sentiment === constants.mood.neutral
  ) {
    moodClass = styles["mid-color"];
  } else if (
    selectedConversation &&
    selectedConversation?.sentiment === constants.mood.happy
  ) {
    moodClass = styles["chill-color"];
  } else {
    moodClass = styles["high-color"];
  }

  async function sendMessage() {
    businessTyping(false);
    if (message.length < 1) {
      return;
    }
    console.log(editMessage, selectedConversation);
    let token = getCookie("Authorization");
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let data = {
        ticketId: selectedConversation?.id,
        businessId: userData.businessId,
        reply: message,
        messageId: editMessage?.id,
      };
      const response = await instance.post(
        "api/chat/reply",
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      // let conv: any = selectedConversation?.messages.map((msg: any) => {
      //   if(msg.id === editMessage.id){
      //     return {
      //       id: editMessage.id,
      //       role: editMessage.role,
      //       content: message,
      //       created_date: editMessage.createdAt,
      //       status: "sent"
      //     }
      //   }
      //   return msg
      // })
      getMessages(selectedConversation ? selectedConversation?.id : "");
      // getMessagess(selectedConversation ? selectedConversation?.id : '', selectedConversation?.email ?? "")
      // setSelectedConversation((prevState: any) => {
      //   if (prevState) {
      //     return {
      //       ...prevState,
      //       messages: [...conv],
      //     };
      //   }
      // });
      setMessage("");
      setEditMessage(null);
      resetTextarea();
    } catch (error) {
      if (error instanceof AxiosError) {
      }
      console.log(error);
    }
  }

  async function restructure(message: string) {
    if (message.length <= 0) {
      return;
    }
    setRestructuring(true);
    let token = getCookie("Authorization");
    try {
      let data = {
        message: message,
      };
      const response = await instance.post(
        "api/chat/restructure",
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showToast("primary", "Success!", "Corrected");
      // setSelectMode(false)
      console.log(response.data.data);
      setMessage(response.data.data);
      setRestructuring(false);
      const business: { businessId: string }[] | [] = response.data?.business;
      console.log(response.data, business);
    } catch (error) {
      if (error instanceof AxiosError) {
      }
      console.log(error);
      setRestructuring(false);
    }
  }

  const resetTextarea = () => {
    let textareaMsg = document.getElementById("message");
    if (textareaMsg) {
      console.log(textareaMsg?.scrollHeight);
      textareaMsg.style.height = "100px";
      setMessageBoxHeight("100px");
      scrollToBottom();
    }
    scrollToBottom();
  };

  const updateMode = async (mode: string) => {
    setSelectMode((prev) => false);
    let newMode = "";
    if (mode === "Automatic Mode") {
      newMode = "auto";
    } else if (mode === "Supervised Mode") {
      newMode = "supervised";
    } else if (mode === "Hybrid Mode") {
      newMode = "supervised";
    }

    let token = getCookie("Authorization");
    const userData = JSON.parse(localStorage.getItem("user") as string);

    setMode(newMode);
    try {
      let data = {
        userId: userData.userId,
        businessId: userData.businessId,
        aiMode: newMode,
      };
      const response = await instance.post(
        "api/business/update",
        { ...data },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showToast("primary", "Success!", "Mode updated successfully");
      setSelectMode(false);
      scrollToBottom();
      const business: { businessId: string }[] | [] = response.data?.business;
      console.log(response.data, business);
    } catch (error) {
      if (error instanceof AxiosError) {
      }
      console.log(error);
    }
  };

  const resolve = (ticketId: string) => {
    let token = getCookie("Authorization");
    instance
      .put(
        `/api/admin/resolve-ticket/business/${businessId}/ticket/${ticketId}`,
        {
          comment: resolveMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        nextPage(currentPage);
        setResolveMessage("");
        if (selectedConversation) {
          setSelectedConversation(res.data.data);
          console.log("here with conversation", selectedConversation);
        }
        showToast("primary", "Success!", "Ticket resolved successfully");
        setConfirmResolve(false);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const message = err.response.data?.message;
            showToast("secondary", "Error!", message);
          }
        }
      });
  };

  const confirmResolveAction = () => {
    setConfirmResolve(false);
    if (resolveId) resolve(resolveId);
  };

  const getDepartments = () => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      // const userData = JSON.parse(localStorage.getItem("user") as string);
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(userData.businessId)
      if (business)
        instance
          .get(`api/department/${business.id}`, {
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            console.log(res);
            setDepartments(res.data.data);
          })
          .catch((e) => {
            console.log(e.response.data);
          });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const reAssignTicket = () => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/ticket/reassign-escalated/${businessId}`,
        {
          departmentId: selectedDepartment?.id,
          ticketId: selectedConversation?.id,
          comment: escalateMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        // nextPage(currentPage)
        if (selectedConversation) {
          selectedConversation.id = res.data.data.id;
          setSelectedConversation((prev) => res.data.data);
        }
        // setSelectedConversation(res.data.data)
        showToast(
          "primary",
          "Success!",
          "Conversation reassigned successfully"
        );
        setConfirmEscalate(false);
        setSelectedDepartment(null);
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const data = err.response.data;
            showToast("secondary", "Error!", data?.data[0].message);
          } else {
            showToast("secondary", "Error!", "Error reassigning");
          }
        } else {
          showToast("secondary", "Error!", "Error reassigning");
        }
      });
    setEscalateMessage("");
  };

  const searchDepartment = (e: any) => {
    let token = getCookie("Authorization");
    if (e.target.value !== "") {
      if (localStorage.getItem("user") && token) {
        const business = JSON.parse(localStorage.getItem("business") as string);
        // setBusinessId(business.businessId)
        if (business)
          instance
            .get(`api/department/${business.id}?name=${e.target.value}`, {
              headers: {
                "Access-Control-Allow-Headers": "X-Requested-With",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              console.log(res);
              setDepartments(res.data.data);
            })
            .catch((e) => {
              console.log(e.response.data);
            });
      } else {
        localStorage.removeItem("user");
        window.location.assign("/login");
      }
    } else {
      getDepartments();
    }
  };

  const handleFocus = () => {
    businessTyping(true);
    // Clear any existing interval
    if (focusInterval.current) {
      clearInterval(focusInterval.current);
    }
    // Start a new interval to check for key press activity
    focusInterval.current = setInterval(() => {
      if (Date.now() - lastKeyPressTime.current >= 60000) {
        // 60 seconds
        console.log(
          Date.now,
          lastKeyPressTime,
          "This is last key pressed time"
        );
        clearInterval(focusInterval.current!);
        updateChatAiMode("auto", selectedConversation?._id);
      }
    }, 30000); // Check every 30 seconds
  };

  const updateChatAiMode = (mode: any, id?: string) => {
    let token = getCookie("Authorization");
    if (localStorage.getItem("user") && token) {
      // console.log((String(selectedConversation?.interactingAdmin._id)===user.id),"to test indicator");
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance
        .post(
          `api/chat/mode/business/${userData.businessId}/ticket/${
            id ? id : selectedConversation?.id
          }`,
          {
            aiMode: mode,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "X-Requested-With",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          showToast("primary", "Success!", "Mode changed successfully");
          if (!id) {
            let prevCon = res.data.data;
            console.log(prevCon, "this is prevCon");
            prevCon["_id"] = res.data.data["id"];
            setSelectedConversation(prevCon);
            console.log(
              String(selectedConversation?.interactingAdmin._id),
              user.id,
              "to test indicator"
            );
            console.log(
              String(selectedConversation?.interactingAdmin._id) === user.id,
              "to test indicator"
            );
          }
        })
        .catch((e) => {
          showToast("secondary", "Error", e.response.data.message);

          console.log(e.response.data);
        });
    } else {
      localStorage.removeItem("user");
      window.location.assign("/login");
    }
  };

  const handleMessage = (e: any) => {
    lastKeyPressTime.current = Date.now();
    setMessage(e.target.value);
    // businessTyping(e)
    let textareaMsg = document.getElementById("message");
    if (textareaMsg) {
      console.log(textareaMsg?.scrollHeight);
      let textareaRows = e.target.value.split("\n");
      console.log(textareaRows);

      if (
        textareaMsg?.scrollHeight >= 100 &&
        textareaMsg?.scrollHeight <= 280
      ) {
        textareaMsg.style.height = "auto";
        textareaMsg.style.height =
          (textareaMsg?.scrollHeight).toString() + "px";
        setMessageBoxHeight((textareaMsg?.scrollHeight).toString() + "px");
        // textareaMsg.setAttribute("rows", (textareaRows.length))
      } else {
        textareaMsg.style.height = "auto";
        textareaMsg.style.height = "280px";
        setMessageBoxHeight("280px");
      }
      scrollToBottom();
    }
  };

  const businessTyping = (isTyping: boolean) => {
    console.log(isTyping);
    const typingEvent = {
      event: "businessTyping",
      data: {
        businessId: businessId,
        ticketId: selectedConversation?.id,
        typing: isTyping,
      },
    };
    socket.send(JSON.stringify(typingEvent));
  };

  const closeTicket = (ticketIds = selectedConversation?.id) => {
    let token = getCookie("Authorization");
    instance
      .post(
        `/api/ticket/close-escalated-ticket/${businessId}`,
        {
          ticketId: ticketIds,
          comment: closeMessage,
        },
        {
          headers: {
            "Access-Control-Allow-Headers": "X-Requested-With",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        nextPage(currentPage);
        if (selectedConversation) {
          getComments(selectedConversation.id);
          let con = selectedConversation;
          con.closed = true;
          setSelectedConversation(con);
        }
        setCloseMessage("");
        showToast("primary", "Success!", "Chat closed successfully");
      })
      .catch((err) => {
        if (err instanceof AxiosError) {
          if (err.response?.status === 400) {
            const data = err.response.data;
            showToast("secondary", "Error!", data?.data[0].message);
          }
        }
      });
    setCloseMessage("");
    setConfirmClose(false);
  };

  const searchChat = (e: ChangeEvent<HTMLInputElement>) => {
    let token = getCookie("Authorization");
    setIsLoading(true);
    setSelectedConversation(null);
    let path = router.pathname;
    let action = path.includes("all")
      ? "all"
      : path.includes("escalated")
      ? "escalated"
      : path.includes("unread")
      ? "unread"
      : path.includes("resolved")
      ? "resolved"
      : path.includes("read")
      ? "read"
      : "";

    let url = "";
    if (action === "all" || action === "") {
      url = `/api/chat/all-chat/${businessId}?page=${1}&active=true`;
    } else if (action === "in active") {
      url = `/api/chat/all-chat/${businessId}?page=${1}&active=true`;
    } else if (action === "escalated") {
      url = `/api/chat/all-chat/${businessId}?page=${1}&active=true&escalated=true`;
    } else if (action === "unread") {
      url = `/api/chat/all-chat/${businessId}?page=${1}&active=true&read=false`;
    } else if (action === "read") {
      url = `/api/chat/all-chat/${businessId}?page=${1}&active=true&read=true`;
    } else if (action === "resolved") {
      url = `/api/chat/all-chat/${businessId}?page=${1}&active=true&escalated=false`;
    }

    if (action !== filter) {
      console.log();
      setCurrentPage(1);
    }

    if (team) {
      if (team.role === "member") {
        url = url + "&department=" + team?.departmentId?.department;
      }
    } else {
      url = url + "&screen=ticket";
    }

    setFilter(action);

    if (e.target.value) {
      url = `${url}&search=${e.target.value}`;
    }

    instance
      .get(url, {
        headers: {
          "Access-Control-Allow-Headers": "X-Requested-With",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response);
        setChats(response.data.data);
        console.log("here to check chat ", response.data.data);
        setTotalPage(response.data.pages);
        setCurrentPage(response.data.currentPage);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error instanceof AxiosError) {
          const errMessage = error.response?.data?.message;
          setError(errMessage);
        }
        setIsLoading(false);
      });
  };

  // const searchChats = (e: ChangeEvent<HTMLInputElement>) => {
  //   let token = getCookie("Authorization")
  //   let ticketId: string = ""
  //   if(router?.search?.length > 0){
  //     if(router.search.includes("&")){
  //       let searchArray = router.search.split("&")
  //       if(searchArray[0].split("=")[0].includes("ticketId")){
  //         ticketId = searchArray[0].split("=")[1]
  //       }
  //     }else{
  //       let searchArray = router.search.split("=")
  //       if(searchArray[0].includes("ticketId")){
  //         ticketId = searchArray[1]
  //       }
  //     }
  //   }

  //   if(router?.search?.length > 0){
  //     if(router.search.includes("&")){
  //       let searchArray = router.search.split("&")
  //       // if(searchArray[0].includes("ticketId")){
  //       //   let ticketId = searchArray[1]
  //       //   const selected = chats.filter((chat) => chat.id === ticketId);
  //       //   setSelectedConversation((prev) => selected[0]);
  //       //   getMessages(ticketId)
  //       // }
  //       if(searchArray[1].split("=")[0].includes("page")){
  //         setCurrentPage(parseInt(searchArray[1].split("=")[1]))
  //       }
  //     }
  //   }
  //   console.log(ticketId, "sdvds")
  //   setIsLoading(true)
  //   setSelectedConversation(null)
  //   let path = router.pathname
  //   let action = path.includes("all")
  //   ? "all" : path.includes("escalated")
  //   ? "escalated": path.includes("unread")
  //   ? "unread": path.includes("resolved")
  //   ? "resolved": path.includes("closed")
  //   ? "closed": path.includes("read")
  //   ? "read": ""
  //   setAction(action);

  //   let url = "";
  //   if(action === "all" || action === ""){
  //     url = `/api/chat/distinct-ticket-email?page=${1}&active=true&limit=${limit}`
  //   }else if(action === "in active"){
  //     url = `/api/chat/distinct-ticket-email?page=${1}&active=true&limit=${limit}`
  //   }else if(action === "escalated"){
  //     url = `/api/chat/distinct-ticket-email?page=${1}&active=true&limit=${limit}&escalated=true&closed=false`
  //   }else if(action === "unread"){
  //     url = `/api/chat/distinct-ticket-email?page=${1}&active=true&limit=${limit}&read=false&closed=false`
  //   }else if(action === "read"){
  //     url = `/api/chat/distinct-ticket-email?page=${1}&active=true&limit=${limit}&read=true&closed=false`
  //   }else if(action === "resolved"){
  //     url = `/api/chat/distinct-ticket-email?page=${1}&active=true&limit=${limit}&escalated=false&closed=false`
  //   }else if(action === "closed"){
  //     url = `/api/chat/distinct-ticket-email?page=${1}&active=true&limit=${limit}&escalated=false&closed=true`
  //   }

  //   if(ticketId){
  //     url = url + `&ticketId=${ticketId}`
  //   }

  //   if(team){
  //     if(team.role === "member"){
  //       url = url+"&department="+team?.departmentId?.department
  //     }
  //   }else{
  //     url = url+"&screen=ticket"
  //   }

  //   if(e.target.value){
  //     url=`${url}&search=${e.target.value}`
  //   }

  //   setFilter(action)

  //   instance
  //     .post(url,
  //       {
  //         headers: {
  //           'Access-Control-Allow-Headers': 'X-Requested-With',
  //           'Authorization': `Bearer ${token}`
  //         }
  //       })
  //     .then((response) => {
  //       console.log(response)
  //       setChats(response.data.data);
  //       setTotalPage(response.data.pages)
  //       setCurrentPage(response.data.currentPage)
  //       // console.log(ticketId)
  //       // if(ticketId){
  //       //   const selected = response.data.data.filter((chat:any) => chat.id === ticketId);
  //       //   setSelectedConversation((prev) => selected[0]);
  //       //   getMessages(ticketId)
  //       // }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       if (error instanceof AxiosError) {
  //         const errMessage = error.response?.data?.message;
  //         setError(errMessage);
  //       }
  //       setIsLoading(false);
  //     });
  // }

  console.log({ selectedConversation, chats });

  return (
    <div id="livechat_con" className={styles.wrapper}>
      <div id="conversation_col" className={styles["conversation-column"]}>
        <header>Conversation</header>
        <section>
          <div className={styles["search"]}>
            <SearchIcon style={styles["search_icon"]} />
            <input
              onChange={searchChat}
              placeholder="Search for customer name,"
            />
          </div>
          <div className={styles["messages-top-controls"]}>
            <ul>
              <li
                onClick={() => {
                  selectedTicketIds?.length === chats.length
                    ? selectAll(false)
                    : selectAll(true);
                }}
              >
                <input
                  onChange={() => {}}
                  checked={selectedTicketIds?.length === chats.length}
                  type="checkbox"
                />
              </li>
              <li>
                <img src={MessageIcon} alt="star" />
              </li>
              <li>
                <img src={CommentIcon} alt="star" />
              </li>
              {/* <li>
                <img src={StarIcon} alt="star" />
              </li> */}
            </ul>
          </div>
          <div className={styles.messages}>
            <ul>
              {!isLoading && conversations.length > 0 && conversations}
              {!isLoading && conversations.length <= 0 && (
                <p className={styles["text-align"]}>No conversations yet!</p>
              )}
              {isLoading && <p className={styles["text-align"]}>Loading...</p>}
            </ul>
          </div>
          <div className={styles.pagination}>
            <div
              onClick={() => {
                // localStorage.setItem('pageState',JSON.stringify(currentPage <= 1 ? 1 : currentPage - 1))
                setCurrentPage(currentPage <= 1 ? 1 : currentPage - 1);
                nextPage(currentPage <= 1 ? 1 : currentPage - 1);
              }}
              className={styles.pagination_btn_left}
            >
              <ArrowLeftIcon style={styles.pagination_icon} />
            </div>
            <div>
              <h5
                className={styles.pagination_num}
              >{`${currentPage} of ${totalPage}`}</h5>
            </div>
            <div
              onClick={() => {
                // localStorage.setItem('pageState',JSON.stringify(totalPage ? totalPage : currentPage + 1))
                setCurrentPage(
                  currentPage >= totalPage ? totalPage : currentPage + 1
                );
                nextPage(
                  currentPage >= totalPage ? totalPage : currentPage + 1
                );
              }}
              className={styles.pagination_btn_right}
            >
              <ArrowRightIcon2 style={styles.pagination_icon} />
            </div>
          </div>
        </section>
        <Modal isOpen={confirmResolve}>
          <div className={styles["confirmation_modal"]}>
            <div className={styles["modal-header"]}>
              <div className={styles["header"]}>
                Confirm Conversation Resolution
              </div>
            </div>
            <div className={styles["modal-content"]}>
              Are you sure you want to resolve this conversation?
            </div>
            {/* <div className={styles.form_group}>
            <label htmlFor="resolveMessage">Message</label>
            <textarea
              id="resolveMessage"
              value={resolveMessage}
              onChange={(e) => setResolveMessage(e.target.value)}
            />
          </div> */}
            <div className={styles["modal-controls"]}>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setResolveId(null);
                  setConfirmResolve(false);
                }}
              />
              <input
                type="button"
                value="Yes"
                onClick={() => confirmResolveAction()}
              />
            </div>
          </div>
        </Modal>
        <Modal isOpen={confirmClose}>
          <div className={styles["confirmation_modal"]}>
            <div className={styles["modal-header"]}>
              <div className={styles["header"]}>Confirm Conversation Close</div>
            </div>
            <div className={styles["modal-content"]}>
              Are you sure you want to close this conversation?
            </div>
            <div className={styles.form_group}>
              <label htmlFor="closeMessage">Comment</label>
              <textarea
                id="closeMessage"
                value={closeMessage}
                onChange={(e) => setCloseMessage(e.target.value)}
              />
            </div>
            <div className={styles["modal-controls"]}>
              <input
                type="button"
                value="No"
                onClick={() => {
                  setConfirmClose(false);
                }}
              />
              <input type="button" value="Yes" onClick={() => closeTicket()} />
            </div>
          </div>
        </Modal>
        <Modal isOpen={confirmEscalate}>
          <div className={styles["confirmation_modal"]}>
            <div className={styles["modal-header"]}>
              <div
                className={styles["header"]}
                style={{ marginBottom: ".5rem" }}
              >
                Confirm Ticket Reassignment
              </div>
            </div>
            <div
              className={styles["modal-content"]}
              style={{ margin: "0vh 0 1rem 0" }}
            >
              Are you sure you want to reassign this ticket to another
              department?
            </div>
            {selectedDepartment ? (
              <div className={styles["selected_department"]}>
                <h4>{selectedDepartment.department}</h4>
                <div
                  onClick={() => setSelectedDepartment(null)}
                  className={styles["remove_department"]}
                >
                  <CancelIcon style={styles["cancel_icon"]} />
                </div>
              </div>
            ) : (
              <div style={{ width: "144px", height: "45px" }}>
                <div className={styles["escalate_department_con"]}>
                  <div className={styles["search_input"]}>
                    <div id="search_con" className={styles["search2"]}>
                      <SearchIcon style={styles["search_icon"]} />
                      <input
                        onFocus={() => setSearching(true)}
                        onChange={(e) => {
                          searchDepartment(e);
                          setSearching(true);
                        }}
                        id="search"
                        placeholder="Search for department"
                      />
                    </div>
                  </div>
                  {searching && (
                    <div className={styles["department_con"]}>
                      {departments?.map((department) => {
                        return (
                          <div
                            onClick={() => {
                              setSelectedDepartment(department);
                            }}
                            className={styles["department"]}
                          >
                            <h4>{department.department}</h4>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
            <div className={styles.form_group}>
              <label htmlFor="escalateMessage">Comment</label>
              <textarea
                id="escalateMessage"
                value={escalateMessage}
                onChange={(e) => setEscalateMessage(e.target.value)}
              />
            </div>
            {/* <div className={styles.form_group}>
            <label htmlFor="resolveMessage">Message</label>
            <textarea
              id="resolveMessage"
              value={resolveMessage}
              onChange={(e) => setResolveMessage(e.target.value)}
            />
          </div> */}
            <div className={styles["modal-controls"]}>
              <input
                type="button"
                value="Cancel"
                onClick={() => {
                  setEscalateId(null);
                  setConfirmEscalate(false);
                  setSelectedDepartment(null);
                }}
              />
              <input
                type="button"
                value="Confirm"
                onClick={() => reAssignTicket()}
              />
            </div>
          </div>
        </Modal>
        {selectedComment && (
          <Modal
            isOpen={commentId && selectedComment ? true : false}
            onClose={() => {
              setSelectedComment(null);
              navigate(router.pathname);
            }}
          >
            <div className={styles["comment_modal"]}>
              <div className={styles["modal-header"]}>
                <div className={styles["header"]}>Comment Log</div>
                <div className={styles["header_department"]}>
                  {selectedComment.ticketId.department}
                </div>
              </div>
              <div className={styles["comment"]}>{selectedComment.comment}</div>
            </div>
          </Modal>
        )}
      </div>
      <div className={styles["conversation-body-column"]}>
        <header>
          <div className={styles["conversation-body-column_col"]}>
            <Link to="/live-chat/all">
              <div
                className={`${styles.chat} ${
                  filter === "all" && styles.unread
                }`}
              >
                All chats
              </div>
            </Link>
            <Link to="/live-chat/unread">
              <div
                className={`${styles.chat} ${
                  filter === "unread" && styles.unread
                }`}
              >
                Unread
              </div>
            </Link>
            <Link to="/live-chat/read">
              <div
                className={`${styles.chat} ${
                  filter === "read" && styles.unread
                }`}
              >
                Read
              </div>
            </Link>
            <Link to="/live-chat/escalated">
              <div
                className={`${styles.chat} ${
                  filter === "escalated" && styles.unread
                }`}
              >
                Escalated
              </div>
            </Link>
            <Link to="/live-chat/resolved">
              <div
                className={`${styles.chat} ${
                  filter === "resolved" && styles.unread
                }`}
              >
                Resolved
              </div>
            </Link>
            {selectedConversation?.interactingStatus &&
              (String(selectedConversation.interactingAdmin?._id) !==
                user?.id ||
                selectedConversation.interactingAdmin?._id === undefined) && (
                <div className={styles["indicator-div"]}>
                  <div className={styles["indicator"]}>
                    <span>
                      {`${selectedConversation?.interactingAdmin?.firstName.charAt(
                        0
                      )}${selectedConversation?.interactingAdmin?.lastName.charAt(
                        0
                      )}`}
                    </span>
                  </div>
                  <div className={styles["interacting-admin"]}>
                    {`${selectedConversation?.interactingAdmin?.firstName}  ${selectedConversation?.interactingAdmin?.lastName}`}
                    <div />
                  </div>
                </div>
              )}
          </div>
          <div className={styles.form_group2}>
            <div
              onClick={() => {
                setSelectMode(true);
              }}
            >
              {mode === "auto" && (
                <CircuitIcon
                  style={`${styles.mode_icon} ${styles.auto_icon}`}
                />
              )}
              {mode === "hybrid" && (
                <HybridIcon
                  style={`${styles.mode_icon} ${styles.hybrid_icon}`}
                />
              )}
              {mode === "supervised" && (
                <HybridIcon
                  style={`${styles.mode_icon} ${styles.hybrid_icon}`}
                />
              )}
              <p
                onClick={() => {
                  setSelectMode(true);
                }}
              >
                {mode === "auto" && "Automatic"}
                {mode === "hybrid" && "Manual"}

                {mode === "supervised" && "Manual"}
              </p>
              <span
                onClick={() => {
                  setSelectMode(true);
                }}
              >
                <CollapseIcon style={styles["collapse_icon"]} />
              </span>
              {selectMode && (
                <div
                  id="more_option_con"
                  className={`${styles["more_option_con"]} select_mode`}
                >
                  <div
                    className={`${
                      mode === "auto" && styles.selectedMode
                    } select_mode`}
                  >
                    <div
                      className={`${styles["more_option"]} select_mode`}
                      onClick={() => {
                        updateMode("Automatic Mode");
                        setSelectMode(false);
                      }}
                    >
                      <CircuitIcon
                        style={`${styles.mode_icon} ${styles.auto_icon} select_mode`}
                      />
                      <h5 className={`select_mode`}>Automatic</h5>
                      {mode === "auto" && (
                        <MarkStrokeIcon
                          style={`${styles.selected_icon} select_mode`}
                        />
                      )}
                    </div>
                  </div>
                  <div
                    className={`${
                      mode === "hybrid" && styles.selectedMode
                    } select_mode`}
                  >
                    <div
                      className={`${styles["more_option"]} select_mode`}
                      onClick={() => {
                        updateMode("Hybrid Mode");
                        setSelectMode(false);
                      }}
                    >
                      <HybridIcon
                        style={`${styles.mode_icon} ${styles.hybrid_icon}`}
                      />
                      <h5 className={`select_mode`}>Manual</h5>
                      {mode === "hybrid" && (
                        <MarkStrokeIcon
                          style={`${styles.selected_icon} select_mode`}
                        />
                      )}
                    </div>
                  </div>
                  <div>
                    <Link to="/settings/mode">
                      <div className={styles["learn_more"]}>
                        <h5>Learn more</h5>
                        <UrgencyIcon style={styles["icons"]} />
                      </div>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>
        {conversations.length > 0 && (
          <section id="conversations">
            <section className={styles.left}>
              <div
                style={
                  selectedConversation &&
                  selectedConversation.aiMode === "hybrid"
                    ? {
                        height: `calc(100vh - 20px - 1vh - 1vh - 95px - ${messageBoxHeight} - ${draftBoxHeight})`,
                      }
                    : { height: `calc(100vh - 95px - ${draftBoxHeight})` }
                }
                className={`${
                  selectedConversation &&
                  selectedTicketId &&
                  selectedMessages &&
                  selectedMessages.length
                    ? styles["flex-end"]
                    : styles["flex-center"]
                } ${styles["conversation-message"]}`}
              >
                {!selectedConversation && chats && chats.length > 0 && (
                  <p className={styles["select-message"]}>
                    Select a message to display
                  </p>
                )}
                {selectedConversation &&
                  selectedMessages &&
                  selectedMessages.length > 0 && (
                    <ul
                      ref={chatBoxRef}
                      className={`${styles["chat-list"]} chat-list`}
                    >
                      {messages}
                      {selectedConversation.id === userTypingId && (
                        <li className={messageStyles["message"]}>
                          <div
                            className={messageStyles["message-image-wrapper"]}
                          >
                            <div className={messageStyles["logo-name"]}>
                              {selectedConversation.customer![0]?.toUpperCase() ??
                                "N"}
                            </div>
                            <div className={messageStyles["message-wrapper"]}>
                              <div className={messageStyles["message-top"]}>
                                <p className={messageStyles["name"]}>
                                  {selectedConversation.customer
                                    ? selectedConversation.customer
                                    : "No Name"}
                                </p>
                                {/* <p className={messageStyles["date"]}>{formatDate(date)}</p> */}
                              </div>
                              <div className={messageStyles["content"]}>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: "Typing...",
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </ul>
                  )}

                {selectedConversation &&
                  selectedMessages &&
                  selectedMessages.length <= 0 && (
                    <p className={styles["select-message"]}>
                      No messages here yet
                    </p>
                  )}
              </div>
              {draftMessage && selectedConversation && (
                <div
                  id="draft_message"
                  ref={ref}
                  className={styles["draft_message"]}
                >
                  <div
                    onClick={() => {
                      editDraftMessage(draftMessage);
                    }}
                    className={styles["draft_message_header"]}
                  >
                    <h5>Enif Suggestion</h5>
                  </div>
                  <p
                    onClick={() => {
                      editDraftMessage(draftMessage);
                    }}
                    className={styles["draft_message_content"]}
                  >
                    {draftMessage.content}
                  </p>
                  <div
                    onClick={() => {
                      removeDraft(draftMessage);
                    }}
                    className={styles["close_draft_message"]}
                  >
                    <CancelIcon style={styles["close_draft_message_icon"]} />
                  </div>
                  <div
                    onClick={() => {
                      editDraftMessage(draftMessage);
                    }}
                  >
                    <EditIconBox style={styles["draft_icon"]} />
                  </div>
                </div>
              )}
              {selectedConversation &&
                selectedConversation.aiMode === "hybrid" &&
                (selectedConversation?.interactingAdmin._id === user?.id ||
                  !selectedConversation?.interactingAdmin) && (
                  <div className={styles["text-area"]}>
                    <div className={styles["text-area-wrapper"]}>
                      <textarea
                        id="message"
                        name="message"
                        placeholder="Type a message..."
                        cols={80}
                        rows={1}
                        value={message}
                        onChange={(e) => handleMessage(e)}
                        onBlur={(e) => businessTyping(false)}
                        onFocus={(e) => handleFocus()}
                      />
                      <div
                        className={styles["restructure"]}
                        onClick={() => restructure(message)}
                      >
                        <RefreshIconStroke
                          style={`${styles["refresh_icon"]} ${
                            restructuring && styles["restructuring"]
                          }`}
                        />
                      </div>
                      <button
                        onClick={sendMessage}
                        className={styles["send-message"]}
                      >
                        {<SendIcon style={styles["icons"]} />}
                      </button>
                    </div>
                  </div>
                )}
            </section>
            <section className={styles.right}>
              {selectedConversation && (
                <>
                  <div className={styles.right_row1}>
                    <div className={styles["calendar"]}>
                      <CalenderIcon style={styles["icons"]} />
                      <p>
                        {lastItemOfSelectedConversation
                          ? formatDate(
                              new Date(lastItemOfSelectedConversation.createdAt)
                            )
                          : formatDate(new Date())}
                      </p>
                    </div>
                    <div className={styles["others"]}>
                      <StatusIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Status</p>
                        <p className={styles["chill-color"]}>Online</p>
                      </div>
                    </div>
                    <div className={styles["others"]}>
                      <TicketIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Ticket Class</p>
                        <p className={styles["mid-color"]}>
                          {selectedConversation?.category}
                        </p>
                      </div>
                    </div>
                    <div className={styles["others"]}>
                      <TicketIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Ticket Type</p>
                        <p className={styles["mid-color"]}>
                          {selectedConversation.type}
                        </p>
                      </div>
                    </div>
                    <div className={styles["others"]}>
                      <TicketIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Ticket Desc.</p>
                        <p className={styles["mid-color"]}>
                          {selectedConversation.title}
                        </p>
                      </div>
                    </div>
                    <div className={styles["others"]}>
                      <TicketIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Ticket Dept.</p>
                        <p className={styles["mid-color"]}>
                          {selectedConversation.department}
                        </p>
                      </div>
                    </div>
                    <div className={styles["others"]}>
                      <TicketIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Ticket Sentiment</p>
                        <p className={moodClass}>
                          {selectedConversation.sentiment}
                        </p>
                      </div>
                    </div>
                    <div className={styles["others"]}>
                      <UrgencyIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Urgency</p>
                        <p>{selectedConversation.urgency}</p>
                      </div>
                    </div>
                    <div className={styles["others"]}>
                      <EscalatedIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Escalated</p>
                        <p
                          className={`${
                            selectedConversation.escalated
                              ? styles["high-color"]
                              : styles["chill-color"]
                          }`}
                        >
                          {selectedConversation.escalated ? "YES" : "No"}
                        </p>
                      </div>
                    </div>
                    <div className={styles["others"]}>
                      <SatisfactionIcon style={styles["icons"]} />
                      <div className={styles["others-child"]}>
                        <p>Satisfaction Score</p>
                        <p className={styles["chill-color"]}>
                          {selectedConversation.css || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={styles.right_row2}>
                <div className={styles.right_row2_col1}>
                  {selectedConversation && (
                    <div
                      onClick={() => {
                        setConfirmEscalate(true);
                        setEscalateId(selectedConversation.id);
                      }}
                      className={`${styles["resolved_button"]} ${styles["mark_escalate_button"]}`}
                    >
                      <h4>Escalate</h4>
                      {/* <EsclamationIcon style={styles["mark_escalate_icon"]} /> */}
                    </div>
                  )}
                  {selectedConversation && !selectedConversation.closed && (
                    <div
                      onClick={() => {
                        setConfirmClose(true);
                      }}
                      className={`${styles["resolved_button"]} ${styles["mark_resolved_button"]}`}
                    >
                      <h4>Close</h4>
                      {/* <CheckedCircleStrokeIcon style={styles["mark_resolved_icon"]} /> */}
                    </div>
                  )}
                </div>

                {/* {selectedConversation && selectedConversation.escalated && (
                  <div onClick={() => {setConfirmResolve(true); setResolveId(selectedConversation.id)}} className={`${styles["resolved_button"]} ${styles["mark_resolved_button"]}`}>
                    <h4>Escalate Ticket</h4>
                    <CheckedCircleStrokeIcon style={styles["mark_resolved_icon"]} />
                  </div>
                )} */}
                {selectedConversation && (
                  <div
                    onClick={() => {
                      updateChatAiMode(
                        selectedConversation.aiMode === "hybrid"
                          ? "auto"
                          : "hybrid"
                      );
                    }}
                    className={`${styles["switch_mode_con"]}`}
                  >
                    <h4>Hybrid Switch</h4>
                    <AntSwitch
                      checked={selectedConversation.aiMode === "hybrid"}
                      inputProps={{ "aria-label": "ant design" }}
                    />
                  </div>
                )}
              </div>
            </section>
          </section>
        )}
      </div>
    </div>
  );
};

export default LiveChat;
