import React, { useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import styles from "./MainLayout.module.css";
import { EnifContext } from "../utilities/context";
import instance from "../api";
import { getCookie } from "../utilities/helpers";

export default function MainLayout() {
  const [sideBarOpen, setSideBarOpen] = useState<null | boolean>(null);
  const [team, setTeam] = useState<any>()
  const handleSidebarCollapse = (sideBarOpen: boolean) => {
    setSideBarOpen(sideBarOpen);
  };
  let { threadId } = useParams();

  useEffect(() => {
    if(!team)
    getTeam()
  }, [team])

  const getTeam = async () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance.get(
        `/api/team/${userData.businessId}/${userData.id}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res.data)
          setTeam(res.data.data)
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }
  
  return (
    <EnifContext.Provider value={{team, setTeam}} >
    <div
      className={`${styles["layout"]}`}
      id={sideBarOpen === false 
        ? threadId ? "layout-grid-0" : "layout-grid-3"
        : threadId ? "layout-grid-15" : "layout-grid-18"
      }
    >
      <div></div>
      <SideBar handleSidebarCollapse={handleSidebarCollapse} />
      <Outlet />
    </div>
    </EnifContext.Provider>
  );
}