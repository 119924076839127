interface Type {
  mood: {
    happy: string;
    neutral: string;
    angry: string;
  };
  nigerianBanks: { key: string; label: string }[];
  categories: { key: string; label: string }[];
  industries: { value: string; label: string }[];
}
const constants: Type = {
  mood: {
    happy: "Happy",
    neutral: "Neutral",
    angry: "Angry",
  },
  nigerianBanks: [
    { key: "access", label: "Access Bank" },
    { key: "uba", label: "United Bank for Africa (UBA)" },
    { key: "gtb", label: "Guaranty Trust Bank (GTB)" },
    { key: "firstbank", label: "First Bank of Nigeria" },
    { key: "zenith", label: "Zenith Bank" },
    { key: "ecobank", label: "Ecobank Nigeria" },
    { key: "sterling", label: "Sterling Bank" },
    { key: "fcmb", label: "First City Monument Bank (FCMB)" },
    { key: "union", label: "Union Bank of Nigeria" },
    { key: "keystone", label: "Keystone Bank" },
    { key: "fidelity", label: "Fidelity Bank" },
    { key: "wema", label: "Wema Bank" },
    { key: "heritage", label: "Heritage Bank" },
    { key: "stanbic", label: "Stanbic IBTC Bank" },
    { key: "standard", label: "Standard Chartered Bank" },
    { key: "jaiz", label: "Jaiz Bank" },
    { key: "citibank", label: "Citibank Nigeria" },
    { key: "unity", label: "Unity Bank" },
    { key: "polaris", label: "Polaris Bank" },
  ],
  categories: [
    { key: "electronics", label: "Electronics" },
    { key: "fashion", label: "Fashion" },
    { key: "bonds", label: "Bonds" },
    { key: "loans", label: "Loans" },
  ],
  industries: [
    { value: "technology", label: "Technology" },
    { value: "finance", label: "Finance" },
    { value: "healthcare", label: "Healthcare" },
    { value: "manufacturing", label: "Manufacturing" },
    { value: "energy", label: "Energy" },
    { value: "retail", label: "Retail" },
    { value: "hospitality", label: "Hospitality and Tourism" },
    { value: "entertainment", label: "Entertainment and Media" },
    { value: "automotive", label: "Automotive" },
    { value: "education", label: "Education" },
    { value: "realEstate", label: "Real Estate" },
    { value: "agriculture", label: "Agriculture" },
    { value: "transportation", label: "Transportation and Logistics" },
    { value: "construction", label: "Construction" },
    { value: "telecommunications", label: "Telecommunications" },
    { value: "pharmaceuticals", label: "Pharmaceuticals" },
    { value: "environmental", label: "Environmental" },
    { value: "government", label: "Government and Public Services" },
    { value: "nonprofit", label: "Nonprofit and Social Organizations" },
    { value: "mining", label: "Mining and Resources" },
    { value: "e-commerce", label: "E-commerce" },
  ],
};
export default constants;
