
import { useEffect, useState } from "react";
import { BigCommerceIcon, CalenderIcon2, CollapseIcon, CopyStrokeIcon, DownloadIconStroke, EsclamationIcon, FireBaseIcon, JSIcon, LinkIcon, LiveChatIcon, MagentoIcon, SettingStrokeIcon, ShopifyIcon, WordPressIcon } from "../../../../assets/icons";
import { checkuser, getCookie, showToast } from "../../../../utilities/helpers";
import styles from "./integrations.module.css";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import instance from "../../../../api";

const Integrations = () => {
  checkuser()
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35.71,
    height: 20,
    padding: 0,
    borderRadius: 17,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 16,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked': {
        transform: 'translateX(15px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#3BDD99',
        },
        '& > .MuiSwitch-thumb': {
            backgroundColor:
        theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
          },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor:
        theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
        width: 35.71,
        height: 20,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor:
        '#D1D1E0',
      boxSizing: 'border-box',
    },
  }));
  const [color, setColor] = useState("#5100FF")
  const [sidebar, setSidebar] = useState(false)
  const [isWordPress, setIsWordPress] = useState(false)
  const [isFireBase, setIsFireBase] = useState(false)
  const [businessId, setBusinessId] = useState<string>("")
  const [serverKey, setServerKey] = useState<string | null>(null)
  const [newServerKey, setNewServerKey] = useState<string>("")
  const [howToConnectWordpress, setHowToConnectWordpress] = useState(false)
  const [howToIndex, setHowToIndex] = useState<number | null>(1)
  const [adding, setAdding] = useState<boolean>(false);

  useEffect(() => {
    if(localStorage.getItem("user")){
      getFCM()
      const userData = JSON.parse(localStorage.getItem("user") as string);
      if(userData.businessId){
        setBusinessId(userData.businessId)
      }
    }
    const handelClick = (evt: any) => {
      console.log(sidebar)
      let targetEl = evt.target; // clicked element  
  
      let sidebarCon = document.getElementById('sidebar');
      let integrationCon = document.getElementsByClassName('integration');
      // console.log(integrationCon)
      // console.log(sidebarCon)
      if(targetEl === sidebarCon) {
        console.log("sdfs")
        return;
      }else{

        for(let i=0; i<integrationCon.length; i++){
          if(targetEl === integrationCon[i]) {
            console.log(integrationCon[i])
            setSidebar((prev) => (true))
            return;
          }
        }
        
        if(sidebar && targetEl !== sidebarCon){
          console.log("afwf")
          setSidebar((prev) => (false));
          setHowToConnectWordpress((prev) => (false))
          setIsWordPress((prev) => (false));
        }
      }
    }

    document.getElementById("integration_con")!.addEventListener("click", handelClick, false);

    return () => {
      document?.getElementById("integration_con")?.removeEventListener('click', handelClick);
    };
  }, [sidebar])

  const colorChange = (e: any) => {
    setColor(e.target.value)
  }

  const getFCM = () => {
    setAdding(true)
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(userData.businessId)
      if(business)
      instance.get(
        `api/business/${business.businessId}/fcm`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res)
          setAdding(false)
          // getFCM()
          setServerKey(res.data.data.firebaseServerKey)
      }).catch(e => {
        console.log(e.response.data)
        setAdding(false)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  const createFCM = () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(userData.businessId)
      if(business)
      instance.post(
        `api/business/${business.businessId}/fcm`,
        {
          serverKey: newServerKey
        },
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res)
          // getFCM()
          setServerKey(res.data.data)
          showToast("primary", "Success!", res.data.message)
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  const handleHowToIndex = (index: number) => {

    if(index === howToIndex){
      setHowToIndex(Infinity)
    }else{
      setHowToIndex(index)
    }
  }

  const copyToClipBoard = (data: string) => {
    // const userData = JSON.parse(localStorage.getItem("user") as string);
    // let link = `https://chat.enif.ai/${userData.businessId}`
    if ('clipboard' in navigator) {
      navigator.clipboard.writeText(data).then(() => {
        showToast("primary", "Success!", "Copied")
      });
    } else {
        showToast("primary", "Success!", "Copied")
        return document.execCommand('copy', true, data);
    }
  }

  const handleDownload = () => {
    // Replace 'folder.zip' with the actual name of your pre-existing zip file
    const url = process.env.REACT_APP_BACKEND_END_BASE_URL === "http://localhost:8000" ? "http://localhost:3000" : 
              process.env.REACT_APP_BACKEND_END_BASE_URL === "https://test.enif.ai" ? "https://test-dev.enif.ai" : "https://app.enif.ai"
    const zipFilePath = url + '/assets/enif-wp-plugin.zip';
    
    // Create an anchor element and trigger a click to download the file
    const link = document.createElement('a');
    link.href = zipFilePath;
    link.download = 'enif-wp-plugin.zip';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <section id="integration_con" className={styles.wrapper}>
        <header>
          {/* <h1>Choose your Premium</h1> */}
        </header>
        <section className={styles.content}>
          <h3>Integrations</h3>
          <div className={styles["integrations_con"]}>
            <p><EsclamationIcon style={styles["esclamation_icon"]} />Enable or disable Integrations across various channels</p>
            <div className={styles["integrations_col"]}>
              <div className={styles["integrations"]}>
                <div style={isWordPress && sidebar ? {borderColor: "#5100FF"}: {}} onClick={() => {setIsWordPress(true); setIsFireBase(false)}} className={`${styles["integration"]} integration`}>
                  <div className={`${styles["channel"]} integration`}>
                    <div className={`${styles["channel_icon_con"]} integration`}>
                      <WordPressIcon style={`${styles["channel_icon2"]} ${styles["calendar_icon"]} integration`} />
                    </div>
                    <div>
                      <h4 className={`integration`}>Integrations for WordPress</h4>
                    </div>
                  </div>
                  <div className={styles["controls"]}>
                    <AntSwitch onClick={() => setSidebar(true)} inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div style={isFireBase && sidebar ? {borderColor: "#5100FF"}: {}} onClick={() => {setIsFireBase(true); setIsWordPress(false)}} className={`${styles["integration"]} integration`}>
                  <div className={`${styles["channel"]} integration`}>
                    <div style={{backgroundColor: "#f7f7f7"}} className={`${styles["channel_icon_con"]} integration`}>
                      <FireBaseIcon style={`${styles["channel_icon2"]} ${styles["calendar_icon"]} integration`} />
                    </div>
                    <div>
                      <h4 className={`integration`}>Integrations for FireBase</h4>
                    </div>
                  </div>
                  <div className={styles["controls"]}>
                    <AntSwitch onClick={() => setSidebar(true)} inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div className={`${styles["integration"]} integration`}>
                  <div className={`${styles["channel"]} integration`}>
                    <div style={{backgroundColor: "#FDF5F1"}} className={`${styles["channel_icon_con"]} integration`}>
                      <MagentoIcon style={`${styles["channel_icon2"]} ${styles["file_icon"]} integration`} />
                    </div>
                    <div>
                      <h4 className={`integration`}>Integrations for Magento</h4>
                    </div>
                  </div>
                  <div className={styles["controls"]}>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div className={`${styles["integration"]} integration`}>
                  <div className={`${styles["channel"]} integration`}>
                    <div style={{backgroundColor: "#EFF5E3"}} className={`${styles["channel_icon_con"]} integration`}>
                      <ShopifyIcon style={`${styles["channel_icon2"]} ${styles["file_icon"]} integration`} />
                    </div>
                    <div>
                      <h4 className={`integration`}>Integrations for Shopify</h4>
                    </div>
                  </div>
                  <div className={styles["controls"]}>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div className={`${styles["integration"]} integration`}>
                  <div className={`${styles["channel"]} integration`}>
                    <div style={{backgroundColor: "#EFF3F5"}} className={`${styles["channel_icon_con"]} integration`}>
                      <BigCommerceIcon style={`${styles["channel_icon2"]} ${styles["file_icon"]} integration`} />
                    </div>
                    <div>
                      <h4 className={`integration`}>Integrations for Big Commerce</h4>
                    </div>
                  </div>
                  <div className={styles["controls"]}>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div className={`${styles["integration"]} integration`}>
                  <div className={`${styles["channel"]} integration`}>
                    <div style={{backgroundColor: "#FCFAF3"}} className={`${styles["channel_icon_con"]} integration`}>
                      <JSIcon style={`${styles["channel_icon2"]} ${styles["file_icon"]} integration`} />
                    </div>
                    <div>
                      <h4 className={`integration`}>Integrations for JavaScript</h4>
                    </div>
                  </div>
                  <div className={styles["controls"]}>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {sidebar && isWordPress && !howToConnectWordpress && 
        <div id="sidebar" className={`${styles.wordpress_sidebar} integration`}>
          <div className={`${styles.wordpress_sidebar_header} integration`}>
            <div className={`integration`}>
              <WordPressIcon style={`${styles["wordpress_sidebar_header_icon"]} integration`} />
              WordPress Integration
            </div>
            <button onClick={() => {setHowToConnectWordpress(true)}} className={`integration`}>
              <EsclamationIcon style={styles["button_escalation"]} />
              How to integrate WordPress
            </button>
          </div>
          <div className={`${styles.wordpress_sidebar_body} integration`}>
            <div className={`${styles.wordpress_sidebar_section} ${styles.wordpress_sidebar_section3} integration`}>
              <header className={`integration`}>
                <LinkIcon style={`${styles.wordpress_sidebar_icon} integration`} />
                <p className={`integration`}>Business API Key</p>
              </header>
              <div className={`integration`}>
                <p className={`integration`}>
                  {businessId}
                </p>
                <div onClick={() => {copyToClipBoard(businessId)}} className={`${styles.copy_icon2} integration`}>
                  <p className={`integration`}>Copy</p>
                  <CopyStrokeIcon style={`${styles.copy_icon} integration`} />
                </div>
              </div>
            </div>
            <div className={`${styles.wordpress_sidebar_section} ${styles.wordpress_sidebar_section1} integration`}>
              <header className={`integration`}>
                <LinkIcon style={`${styles.wordpress_sidebar_icon} integration`} />
                <p className={`integration`}>Plugin Script</p>
              </header>
              <div className={`integration`}>
                {`<script>document.body.onload=function(){const e=document.createElement("iframe");
                var t=document.createElement("div"),n=document.createElement("button");
                document.createElement("img");const l=document.createElement("span");
                l.innerHTML="&#x1F4AC;",
                e.src="https://chat.enif.ai/7e5615051ae14d4b9caf071a8db1c92c00c790320c45443dadeb63cbd615adc4c
                c79ff883f8d4ace8a72cbba7cb86c2a",e.width="0",e.height="800",
                e.style.scale="0",e.style.transition="all 0.5s ease-in-out",
                e.style.transformOrigin="bottom right",n.style.width="4.6rem",
                n.style.height="4.6rem",n.style.background="#ddd",n.style.zIndex="9998"
                ,n.style.borderRadius="50%",n.style.position="fixed",n.style.right="3%",
                n.style.bottom="3%",l.style.zIndex="10000",l.style.width="2rem",
                l.style.minWidth="2rem",l.style.color="white",n.appendChild(l),
                t.appendChild(e),t.appendChild(n),t.style.position="fixed",
                t.style.zIndex="60",t.style.right="5%",t.style.bottom="0%",
                document.body.appendChild(t),n.addEventListener("click",()=>{"0"==e.width?(e.width="400",
                e.style.scale="1",l.innerHTML="&#x2715"):(e.width="0",e.style.sc
                ale="0",l.innerHTML="&#x1F4AC;")})}()<script>`
                }
                <section className={`integration`} onClick={() => {copyToClipBoard("data")}}>
                  <div className={`integration`}>
                    <p className={`integration`}>Download</p>
                    <CopyStrokeIcon style={`${styles.copy_icon} integration`} />
                  </div>
                </section>
              </div>
            </div> 
            <button onClick={handleDownload} className={`${styles.download_btn} integration`}>
              <p className={`integration`}>Download plug in</p>
              <DownloadIconStroke style={`${styles.download_icon_stroke} integration`} />
            </button>
            <div className={`${styles.wordpress_sidebar_section} ${styles.wordpress_sidebar_section2}`}>
              <header className={`integration`}>
                <SettingStrokeIcon style={`${styles.wordpress_sidebar_icon_stroke} integration`} />
                <p className={`integration`}>Plugin Configuration </p>
              </header>
              <div className={`integration`}>
                183dcb2f-73f6-47c7-91c0-720b38bca297fb69b188-f1b4-47bc-abd4-bc5e47ea83fe
                <section onClick={() => {copyToClipBoard("data")}}>
                  <div className={`integration`}>
                    <p className={`integration`}>Copy</p>
                    <CopyStrokeIcon style={`${styles.copy_icon} integration`} />
                  </div>
                </section>
              </div>
            </div> 
            <div className={`${styles.wordpress_sidebar_section} ${styles.wordpress_sidebar_section2}`}>
              <header className={`integration`}>
                <LinkIcon style={`${styles.wordpress_sidebar_icon} integration`} />
                <p className={`integration`}>Data & Privacy</p>
              </header>
              <div className={`integration`}>
                Data & Privacy
              </div>
            </div> 
            <div className={styles.wordpress_sidebar_bottom}>
              <div className={`integration`}>
                <h5>Change Colour</h5>
                <div className={`integration`}>
                  <p className={`integration`}>{color.replace("#", "")}</p>
                  <input type="color" defaultValue={"#5100FF"} onChange={colorChange} className={styles.color_box} id="style2" />
                  {/* <div className={styles.color_box}>
                    
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        {sidebar && isFireBase && !howToConnectWordpress && 
        <div id="sidebar" className={`${styles.wordpress_sidebar} integration`}>
          <div className={`${styles.wordpress_sidebar_header} integration`}>
            <div className={`integration`}>
              <FireBaseIcon style={`${styles["wordpress_sidebar_header_icon"]} integration`} />
              FireBase Integration
            </div>
          </div>
          {!serverKey && 
          <div className={`${styles.wordpress_sidebar_body} integration`}>
            <div className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2} integration`}>
              <header className={`integration`}>
                <LinkIcon style={styles.live_chat_sidebar_icon} />
                <p className={`integration`}>FireBase Server Key</p>
              </header>
              <input
                className={`integration`}
                value={newServerKey}
                onChange={(e) => setNewServerKey(e.target.value)}
              />
            </div>
            <button onClick={() => {!adding && createFCM()}} className={`${styles["add_department_button"]} integration`}>
              {adding ? "Loading..." : "Configure"}
            </button>
          </div>
          }
          <div className={`${styles.connected_departments_con} integration`}>
            <header className={`integration`}>
              <SettingStrokeIcon style={styles.live_chat_sidebar_icon_stroke} />
              <p> Connected Server key</p>
            </header>
            {!serverKey ?
            <div className={`${styles.no_connected_department_con} integration`}>
              <div className={`${styles.no_connected_department_icon} integration`}>
                <EsclamationIcon style={styles[""]} />
              </div>
              <p className={`integration`}>You have not configured any server key</p>
            </div>
            :
            <div className={`${styles.connected_departments} integration`}>
              <div className={`${styles.connected_department} integration`}>
                {serverKey}
              </div>
              
            </div>
            }
          </div>
        </div>
        }
        {sidebar && isWordPress && howToConnectWordpress && 
        <div id="sidebar" className={`${styles.wordpress_sidebar} integration`}>
          <div className={`${styles.wordpress_sidebar_header} integration`}>
            <div className={`integration`}>
              <WordPressIcon style={styles["wordpress_sidebar_header_icon"]} />
              How to integrate WordPress
            </div>
          </div>
          <div className={`${styles.wordpress_sidebar_body_how} integration`}>
            <div className={`${styles.wordpress_sidebar_section_how} integration`}>
              <header onClick={() => handleHowToIndex(1)} className={`integration`}>
                {/* <SettingStrokeIcon style={`${styles.wordpress_sidebar_how_icon_stroke} integration`} /> */}
                <p></p>
                <p className={`integration`}>Download the Plugin Zip File</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 1 && styles["rotate_icon"])} integration`} />
              </header>
              {howToIndex === 1 &&
              <div className={`${styles.how_process_con} integration`}>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Log in to your Enif business dashboard.</p>
                </div>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Navigate to the developer tab in the settings.</p>
                </div>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Find the option to download the Enif live chat WordPress plugin. <span>Download</span> the zip file to your computer.</p>
                </div>
              </div>
              }
            </div>
            <div className={`${styles.wordpress_sidebar_section_how} integration`}>
              <header onClick={() => handleHowToIndex(2)} className={`integration`}>
                {/* <SettingStrokeIcon style={`${styles.wordpress_sidebar_how_icon_stroke} integration`} /> */}
                <p></p>
                <p className={`integration`}>Access Your WordPress Dashboard</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 2 && styles["rotate_icon"])} integration`} />
              </header>
              {howToIndex === 2 &&
              <div className={`${styles.how_process_con} integration`}>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Log in to your <span>WordPress admin dashboard.</span></p>
                </div>
              </div>
              }
            </div>
            <div className={`${styles.wordpress_sidebar_section_how} integration`}>
              <header onClick={() => handleHowToIndex(3)} className={`integration`}>
                {/* <SettingStrokeIcon style={`${styles.wordpress_sidebar_how_icon_stroke} integration`} /> */}
                <p></p>
                <p className={`integration`}>Navigate to Plugins</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 3 && styles["rotate_icon"])} integration`} />
              </header>
              {howToIndex === 3 &&
              <div className={`${styles.how_process_con} integration`}>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>In the WordPress dashboard, go to the <span>“Plugins”</span> section.</p>
                </div>
              </div>
              }
            </div>
            <div className={`${styles.wordpress_sidebar_section_how} integration`}>
              <header onClick={() => handleHowToIndex(4)} className={`integration`}>
                {/* <SettingStrokeIcon style={`${styles.wordpress_sidebar_how_icon_stroke} integration`} /> */}
                <p></p>
                <p className={`integration`}>Add New Plugin</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 4 && styles["rotate_icon"])} integration`} />
              </header>
              {howToIndex === 4 &&
              <div className={`${styles.how_process_con} integration`}>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Click on the <span>“Add New”</span> button at the top of the Plugins page.</p>
                </div>
              </div>
              }
            </div>
            <div className={`${styles.wordpress_sidebar_section_how} integration`}>
              <header onClick={() => handleHowToIndex(5)} className={`integration`}>
                {/* <SettingStrokeIcon style={`${styles.wordpress_sidebar_how_icon_stroke} integration`} /> */}
                <p></p>
                <p className={`integration`}>Upload the Plugin</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 5 && styles["rotate_icon"])} integration`} />
              </header>
              {howToIndex === 5 &&
              <div className={`${styles.how_process_con} integration`}>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Click on the <span>“Upload Plugin”</span> button.</p>
                </div>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Choose the Enif live chat zip file you downloaded from your computer.</p>
                </div>
              </div>
              }
            </div>
            <div className={`${styles.wordpress_sidebar_section_how} integration`}>
              <header onClick={() => handleHowToIndex(6)} className={`integration`}>
                {/* <SettingStrokeIcon style={`${styles.wordpress_sidebar_how_icon_stroke} integration`} /> */}
                <p></p>
                <p className={`integration`}>Install the Plugin</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 6 && styles["rotate_icon"])} integration`} />
              </header>
              {howToIndex === 6 &&
              <div className={`${styles.how_process_con} integration`}>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Click on the <span>“Install Now”</span> button.</p>
                </div>
              </div>
              }
            </div>
            <div className={`${styles.wordpress_sidebar_section_how} integration`}>
              <header onClick={() => handleHowToIndex(7)} className={`integration`}>
                {/* <SettingStrokeIcon style={`${styles.wordpress_sidebar_how_icon_stroke} integration`} /> */}
                <p></p>
                <p className={`integration`}>Activate the Plugin</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 7 && styles["rotate_icon"])} integration`} />
              </header>
              {howToIndex === 7 &&
              <div className={`${styles.how_process_con} integration`}>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>After installation, click on the <span>“Activate Plugin”</span> link.</p>
                </div>
              </div>
              }
            </div>
            <div className={`${styles.wordpress_sidebar_section_how} integration`}>
              <header onClick={() => handleHowToIndex(8)} className={`integration`}>
                {/* <SettingStrokeIcon style={`${styles.wordpress_sidebar_how_icon_stroke} integration`} /> */}
                <p></p>
                <p className={`integration`}>Configure the Plugin</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 8 && styles["rotate_icon"])} integration`} />
              </header>
              {howToIndex === 8 &&
              <div className={`${styles.how_process_con} integration`}>
                <div className={`${styles.how_process} integration`}>
                  <div className={`integration`}></div>
                  <p className={`integration`}>Look for the Enif live chat plugin in the list of installed plugins.</p>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
        }
      </section>
    </>
  );
};

export default Integrations;
