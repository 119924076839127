// import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styles from "./general.module.css";
import { FC } from "react";
import { AgentIcon, DepartmentsIcon, InviteIcon } from "../../../assets/icons";

interface ModalProps {
    children: React.ReactNode;
    actionText: string;
    setOpenModal?: Function
}

const General: FC<ModalProps> = ({ children, actionText, setOpenModal }) =>  {
    const route = useLocation()

  return (
    <>
      <section className={styles.wrapper}>
        <header>
          <h1>Settings</h1>
          <button onClick={() => setOpenModal && setOpenModal(true)}>
            {actionText === "Invite" &&
            <InviteIcon style={styles.send_icon_stroke} />
            }
            {actionText === "Create Department" &&
            <DepartmentsIcon style={styles.send_icon} />
            }
            {actionText === "Create Agent" &&
            <AgentIcon style={styles.send_icon} />
            }
            <p>
            {actionText}
            </p>
          </button>
        </header>
        <section className={styles.content}>
          <div className={styles.content_header}>
            <h2>General</h2>
          </div>
          <div className={styles.content_body}>
            <div className={styles.tabs}>
                <Link to={"/settings/teams"}>
                <div className={styles.tab + " " + (route.pathname.includes('teams') && styles.tab_hover) }>
                    Team
                </div>
                </Link>
                <Link to={"/settings/departments"}>
                <div className={styles.tab + " " + (route.pathname.includes('departments') && styles.tab_hover) }>
                    Department
                </div>
                </Link>
                <Link to={"/settings/agents"}>
                <div className={styles.tab + " " + (route.pathname.includes('agents') && styles.tab_hover) }>
                    Agent
                </div>
                </Link>
            </div>
            {children}
          </div>
        </section>
      </section>
    </>
  );
}

export default General