import React, { FC, useEffect, useState } from "react";
import styles from "../../pages/Tickets/tickets.module.css";
import { calculateDuration } from "../../utilities/helpers";
import { ClockIcon, GreenClockIcon, RedClockIcon } from "../../assets/icons";

interface TicketRowProps {
  action: string;
  ticket: any;
  selectTicket: Function;
  setConfirmClose: Function;
  setTicketId: Function;
  selectedTicket: any;
  setEscalate: Function;
  selectedTicketIds: Array<string> | undefined;
  selectTickets: Function;
  handleTicketClicked: Function;
  // handleAdminRemove?: (email: string) => void;
}

const TicketRow: FC<TicketRowProps> = ({
  action,
  selectTicket,
  ticket,
  setConfirmClose,
  setTicketId,
  selectedTicket,
  setEscalate,
  selectedTicketIds,
  selectTickets,
  handleTicketClicked,
}) => {
  // const [timer, setTimer] = useState('00:00:00');
  const [selected, setSelected] = useState(false);
  const [slaExceeded, setSlaExceeded] = useState<Boolean>();
  const { sla } = JSON.parse(localStorage.getItem("business") || "");
  const [resolutionTime, setResolutionTime] = useState<string>();
  useEffect(() => {
    const numberAndUnit = extractNumberAndUnit(sla);

    let duration: number;
    switch (numberAndUnit?.unit) {
      case "hr":
        duration = numberAndUnit?.number * 60 * 60 * 1000; // 48 hours in milliseconds
        break;
      case "min":
        duration = numberAndUnit?.number * 60 * 1000; // 2 minutes in milliseconds
        break;
      case "sec":
        duration = numberAndUnit?.number * 1000; // 20 seconds in milliseconds
        break;
      default:
        duration = 24 * 60 * 60 * 1000;
    }

    // To calculate the time difference of two dates
    let id = setInterval(async () => {
      // console.log(date1,date2,interval,ticket.customer,"this is to solve NaN bug")

      if (!ticket.escalated && !ticket.closed) {
        setResolutionTime("00D 00H 00M");
      } else {
        const date1 = new Date(ticket.escalatedDate);
        // const date2 = ticket.closedDate!=null?  new Date(ticket.closedDate) : new Date();
        // const date2 = new Date(ticket.closedDate);
        const date2 = ticket.closed ? new Date(ticket.closedDate) : new Date();
        const interval: number = Math.abs(date2.getTime() - date1.getTime());
        // const interval: number = Math.abs(
        //   new Date(ticket.SLADeliveryDate).getTime()
        // );

        const days = Math.floor(interval / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (interval % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((interval % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((interval % (1000 * 60)) / 1000);

        // setResolutionTime(
        //   days > 0
        //     ? `${days}D ${hours}H ${minutes}M`
        //     : `${hours}D ${minutes}H ${seconds}S`
        // );

        setResolutionTime(
          days > 0
            ? `${days}D ${hours}H ${minutes}M`
            : `${hours}H ${minutes}M ${seconds}S`
        );
        if (interval > duration) {
          setSlaExceeded(true);
          ticket["slaExceeded"] = true;
        } else {
          setSlaExceeded(false);
          ticket["slaExceeded"] = false;
        }
      }

      // console.log(date2.getHours(), date1.getHours())
      // console.log(date2.getDay(), date1.getDay())
      // console.log(date2.getHours() - date1.getHours())
      // console.log(date1.getSeconds() - date2.getSeconds())
      // const total = Date.parse(ticket.message?.createdAt) + Date.parse(new Date().toDateString());
      // const seconds = Math.floor((total / 1000) % 60);
      // const minutes = Math.floor((total / 1000 / 60) % 60);
      // const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    });

    if (selectedTicketIds) {
      const found = selectedTicketIds.find((id) => id === ticket.id);
      if (found) {
        setSelected(true);
      } else {
        setSelected(false);
      }
    }
  }, [selectedTicketIds, ticket]);

  function extractNumberAndUnit(str: string) {
    const regex = /(\d+)([a-zA-Z]+)/;
    const match = str?.match(regex);
    if (match) {
      return {
        number: parseInt(match[1]),
        unit: match[2],
      };
    }
    return null;
  }
  // console.log(selectedTicket , ticket.channel)

  return (
    <tr
      style={
        action === "closed"
          ? { gridTemplateColumns: "4% 17% 14% 12% 12% 12% 12% 12%" }
          : { gridTemplateColumns: "4% 17% 14% 12% 12% 12% 12% 12%" }
      }
      className={
        selected
          ? styles["selected"]
          : selectedTicket?.id === ticket.id
          ? styles["selected"]
          : styles[""]
      }
    >
      <td onClick={() => selectTickets(ticket)}>
        <input onChange={() => {}} checked={selected} type="checkbox" />
      </td>
      <td
        className={`${"livechat"}`}
        onClick={() => {
          handleTicketClicked(ticket, ticket.channel);
        }}
      >
        <h5 className={`${"livechat"}`}>{ticket.customer}</h5>
      </td>
      <td
        className={`${"livechat"}`}
        onClick={() => {
          handleTicketClicked(ticket, ticket.channel);
        }}
      >
        <h5 className={`${"livechat"}`}>
          {ticket.title ? ticket.title : ticket.message?.content}
        </h5>
      </td>
      <td
        className={`${"livechat"}`}
        onClick={() => {
          handleTicketClicked(ticket, ticket.channel);
        }}
      >
        <h5 className={`${"livechat"}`}>{ticket.department}</h5>
      </td>
      <td
        className={`${"livechat"}`}
        onClick={() => {
          handleTicketClicked(ticket, ticket.channel);
        }}
      >
        <h5
          className={
            ticket.closed ? "" : ticket.escalated ? styles["angry"] : ""
          }
        >
          {ticket.closed
            ? "Closed"
            : ticket.escalated
            ? "Escalated"
            : "AI Closed"}
        </h5>
      </td>
      <td
        className={`${"livechat"}`}
        onClick={() => {
          handleTicketClicked(ticket, ticket.channel);
        }}
      >
        <h5 className={`${"livechat"}`}>{ticket.sentiment}</h5>
      </td>
      {/* <td className={`${ "livechat"}`} onClick={() => {handleTicketClicked(ticket, ticket.channel)}}> 
            <h5 className={`${ "livechat"}`}>{ticket.category}</h5>
            </td> */}
      {/* <td className={`${ "livechat"}`} onClick={() => {handleTicketClicked(ticket, ticket.channel)}}> 
            <h5 className={`${ "livechat"}`}>{ticket.type}</h5>
            </td> */}
      <td
        className={`${"livechat"}`}
        onClick={() => {
          handleTicketClicked(ticket, ticket.channel);
        }}
      >
        <div className={styles.resolution_time_td}>
          {/* {ticket.escalated || (ticket.closed && slaExceeded) ? (
            <RedClockIcon style={styles.happy} />
          ) : ticket.escalated && !slaExceeded ? (
            <GreenClockIcon style={styles.happy} />
          ) : (
            <GreenClockIcon style={styles.happy} />
          )} */}
          {slaExceeded ? (
            <RedClockIcon style={styles.happy} />
          ) : (
            <GreenClockIcon style={styles.happy} />
          )}
          <h5 className={styles["livechat"]}>{resolutionTime}</h5>
          {/*  keep this ?styles["livechat"]:(!slaExceeded)?styles['happy']:styles["angry"]} */}
        </div>
      </td>
      {/* <td className={`${ "livechat"}`} onClick={() => {handleTicketClicked(ticket, ticket.channel)}}> 
            <h5 className={slaExceeded? styles["angry"]:!slaExceeded? styles["happy"]:`${ "livechat"}`}>{slaExceeded?"Exceeded SLA":"Within SLA"}</h5>
            </td> */}
      {action !== "closed" && (
        <td className={`${"livechat"}`}>
          {ticket.escalated && (
            <div
              style={{ gridTemplateColumns: "max-content" }}
              onClick={() => {
                setConfirmClose(true);
                setTicketId(ticket.id);
                selectTicket(ticket);
              }}
              className={`${styles["ticket_details_modal_button2"]} ${styles["mark_resolved_button"]} `}
            >
              <h4>Close</h4>
            </div>
          )}
          {/* :
            <div style={{gridTemplateColumns: "max-content"}} onClick={() => {selectTicket(ticket); setEscalate(true)}} className={`${styles["ticket_details_modal_button2"]} ${styles["escalate_button"]}`}>
                <h4>Escalate</h4>
            </div>
            } */}
        </td>
      )}
    </tr>
  );
};

export default TicketRow;
