import React, { useEffect, useState } from "react";
import styles from "./customer.module.css";
import instance from "../../api";
import { useLocation } from "react-router-dom";
import { checkuser, formatStringToDate } from "../../utilities/helpers";
import { ArrowLeftIcon, ArrowRightIcon2 } from "../../assets/icons";

const Customers = () => {
  checkuser()
  const router = useLocation()
  const [businessId, setBusinessId] = useState('');
  const [action, setAction] = useState('');
  const [search, setSearch] = useState('');
  const [hasCustomer, setHasCustomer] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [selectedCustomer, setSelectedCustomer] = useState<any>()
  const [customer, setCustomers] = useState([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [totalPage, setTotalPage] = useState<number>(1)
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const limit = 100

  useEffect(() => {
    setAction("all customers");
    let channel : string | null;
    if(router.search.length > 0){
      let searchArray = router.search.split("=")
      if(searchArray[0].includes("channel")){
        channel = searchArray[1]
        setSearch(channel)
      }
    }else{
      setSearch("")
    }

    if(localStorage.getItem("user")){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      setBusinessId(userData.businessId)
      instance.post(
        `/api/chat/distinct-ticket-email?page=${1}&active=true&limit=${limit}`
      ).then(res => {
          console.log(res)
          if(res.data.data.length > 0){
            if(!channel){
              setHasCustomer(true)
              setCustomers(res.data.data)
              setTotalPage(res.data.pages)
            }else{
              let filter = res.data.data.filter((customer: any) => {
                return customer.channel === channel
              })

              setHasCustomer(true)
              setCustomers(filter)
            }
          }
      }).catch(e => {
        console.log(e.response.data)
        if(e.response.data === "This business dosen't have a knowledge base"){
          setHasCustomer(false)
        }
        setHasCustomer(false)
      })
    }
  }, [openModal, router, businessId])

  const selectCustomer = (customer: any) => {
    setOpenModal(true)
    setSelectedCustomer(customer)
  }

  const nextPageCustomer = (num:number) => {
    let path = router.pathname
    let action = path.includes("all") ? "all": path.includes("inactive") ? "in active": path.includes("escalated") ? "escalated": "resolved";
    console.log(action)
    setAction(action);
    let department : string | null;
    if(router.search.length > 0){
      let searchArray = router.search.split("=")
      if(searchArray[0].includes("department")){
        department = searchArray[1].replaceAll("%20", " ")
        setSearch(department)
      }
    }else{
      setSearch("")
    }
    if(localStorage.getItem("user")){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      setBusinessId(userData.businessId)
      let url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`;
      if(action === "all"){
        url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
      }else if(action === "in active"){
        url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
      }
      else if(action === "escalated"){
        url = `/api/chat/distinct-ticket-email?page=${num}&active=true&limit=${limit}`
      }

      if(action === "escalated"){
        url = url+"&escalated=true"
      }else if(action === "resolved"){
        url = url+"&escalated=false"
      }

      instance.post(
        url
      ).then(res => {
          if(res.data.data.length > 0){
            setHasCustomer(true)
            setCustomers(res.data.data)
          }
          setTotalPage(res.data.pages)
          setCurrentPage(res.data.currentPage)
          setIsLoading(false)
      }).catch(e => {
        setIsLoading(false)
        console.log(e.response.data)
        if(e.response.data === "This business dosen't have a knowledge base"){
          setHasCustomer(false)
        }
        setHasCustomer(false)
      })
    }else{
      setIsLoading(false)
    }
  }

  return (
    <section className={styles.wrapper}>
      {/* {selectedCustomer &&
      <Modal isOpen={openModal} onClose={() => {setOpenModal(false)}}>
        <div className={styles["customer_details_modal"]}>
          <div className={styles["customer_details_modal_row_1"]}>
            <div className={styles["customer_details_modal_info"]}>
              <h1>{selectedCustomer.customer}</h1>
              <p>{selectedCustomer.email}</p>
            </div>
            <div className={styles["customer_details_modal_buttons"]}>
              <Link to={`/live-chat?customerId=${selectedCustomer.ticketId}`}>
              <div className={`${styles["customer_details_modal_button"]} ${styles["view_conversation_button"]}`}>
                <h4>View conversation</h4>
                <ArrowRightIcon style={styles["arrow_right_icon"]} />
              </div>
              </Link>
              {selectedCustomer.escalated &&
              <div onClick={() => resolve(selectedCustomer.ticketId)} className={`${styles["customer_details_modal_button"]} ${styles["mark_resolved_button"]}`}>
                <h4>Mark as resolved</h4>
                <CheckedCircleStrokeIcon style={styles["mark_resolved_icon"]} />
              </div>
              }
            </div>
          </div>
          <div className={styles["customer_details_modal_row_2"]}>
            <h2>Account Details</h2>
            <div className={styles["customer_details_modal_more_info"]}>
              <div className={styles["customer_details_modal_category"]}>
                <div>
                  <CategoryStrokeIcon style={styles["category_icon_stroke"]} />
                  <h3>Categorisation</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedCustomer.category}</h4>
                </div>
              </div>
              <div className={styles["customer_details_modal_category"]}>
                <div>
                  <LovelyIcon style={styles["category_icon"]} />
                  <h3>Sentiment</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedCustomer.sentiment}</h4>
                </div>
              </div>
              <div className={styles["customer_details_modal_category"]}>
                <div>
                  <UsersStrokeIcon style={styles["category_icon_stroke"]} />
                  <h3>Department</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedCustomer.department}</h4>
                </div>
              </div>
              <div className={styles["customer_details_modal_category"]}>
                <div>
                  <TypeIcon style={styles["category_icon"]} />
                  <h3>Type</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedCustomer.type}</h4>
                </div>
              </div>
              <div className={styles["customer_details_modal_category"]}>
                <div>
                  <UrgencyIcon2 style={styles["category_icon"]} />
                  <h3>Escalation</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedCustomer.escalated ? "Yes": "No"}</h4>
                </div>
              </div>
              <div className={styles["customer_details_modal_category"]}>
                <div>
                  <UserStrokeIcon2 style={styles["category_icon_stroke"]} />
                  <h3>Agent Name</h3>
                </div>
                <div>
                  <div></div>
                  <h4>{selectedCustomer.category}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      } */}
      <header>
        <h1>{search ? search : action}</h1>
      </header>
      <section className={styles.content}>
        {!hasCustomer ?
        <section className={styles["content-body"]}>
          
        </section>
        :
        <section className={styles["customer_table_container"]}>
          <table className={styles["customer_table"]}>
            <thead>
              <tr>
                <th>User Id</th>
                <th>Customer Name</th>
                <th>Main Contact</th>
                <th>Channel</th>
                <th>Date</th>
                <th>Sentiment</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {customer.map((customer: any, index) => {
                return (
                  <tr key={index} onClick={() => selectCustomer(customer.ticket)} className={selectedCustomer?._id === customer.ticket._id ? styles["selected"]: styles[""]}>
                    <td>
                      <h5>{customer.ticket._id}</h5>
                    </td>
                    <td> 
                      <h5>{customer.ticket.customer}</h5>
                    </td>
                    <td> 
                      <h5>{customer.ticket.phoneNo}</h5>
                    </td>
                    <td> 
                      <h5>{customer.ticket.channel}</h5>
                    </td>
                    <td> 
                      <h5>{formatStringToDate(customer.ticket.createdAt)}</h5>
                    </td>
                    <td> 
                      <h5 className={customer.ticket.sentiment === "Angry" ? styles["angry"]: customer.ticket.sentiment === "Happy" ? styles["happy"] : styles["neutral"]}>{customer.ticket.sentiment}</h5>
                    </td>
                    <td> 
                      <h5>Action</h5>
                    </td>
                  </tr>
                )
              })}
              
            </tbody>
            <div className={styles.pagination}>
              <div onClick={() => {setCurrentPage(currentPage <=1 ? 1 : currentPage-1); nextPageCustomer(currentPage <=1 ? 1 : currentPage-1) } } className={styles.pagination_btn_left}>
                <ArrowLeftIcon style={styles.pagination_icon} />
              </div>
              <div>
                <h5 className={styles.pagination_num}>{`${currentPage} of ${totalPage}`}</h5>
              </div>
              <div onClick={() => {setCurrentPage(currentPage >= totalPage ? totalPage : currentPage+1); nextPageCustomer(currentPage >= totalPage ? totalPage : currentPage+1) }} className={styles.pagination_btn_right}>
                <ArrowRightIcon2 style={styles.pagination_icon} />
              </div>
            </div>
          </table>
        </section>
        }
      </section>
    </section>
  );
};

export default Customers;
