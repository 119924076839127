
import { useEffect, useState } from "react";
import { CollapseIcon, EsclamationIcon, GoogleChatIcon, JiraIcon, LinkIcon, SettingStrokeIcon, SlackIcon, UserStrokeIcon, WhatsappIcon2 } from "../../../../assets/icons";
import styles from "./notification.module.css";
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { checkuser, getCookie, showToast } from "../../../../utilities/helpers";
import instance from "../../../../api";
import { AxiosError } from "axios";

const Notifications = () => {
  checkuser()
  const [isSlack, setIsSlack] = useState(false)
  const [isSMSCampaign, setIsSMSCampaign] = useState(false)
  const [howToConnectSlack, setHowToConnectSlack] = useState(false)
  const [howToIndex, setHowToIndex] = useState<number | null>(1)
  const [isGoogleChat, setIsGoogleChat] = useState(false)
  const [department, setDepartment] = useState<any>()
  const [selectedDepartment, setSelectedDepartment] = useState<any>()
  const [webhook, setWebhook] = useState<any>()
  const [adding, setAdding] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Array<any>>()
  const [slackDepartments, setSlackDepartments] = useState<Array<string>>()
  const [campaignName, setCampaignName] = useState<any>("")
  const [businessDetails, setBusinessDetails] = useState<any>()
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35.71,
    height: 20,
    padding: 0,
    borderRadius: 17,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 16,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(9px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 3,
      '&.Mui-checked': {
        transform: 'translateX(15px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: '#3BDD99',
        },
        '& > .MuiSwitch-thumb': {
            backgroundColor:
        theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
          },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 14,
      height: 14,
      borderRadius: '50%',
      backgroundColor:
        theme.palette.mode === 'dark' ? '#FFFFFF' : '#FFFFFF',
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
        width: 35.71,
        height: 20,
      borderRadius: 18 / 2,
      opacity: 1,
      backgroundColor:
        '#D1D1E0',
      boxSizing: 'border-box',
    },
  }));

  const [sidebar, setSidebar] = useState(false)

  useEffect(() => {
    getDepartments()
    getBusiness()
    const handelClick = (evt: any) => {
      console.log(sidebar)
      let targetEl = evt.target; // clicked element  
  
      let sidebarCon = document.getElementById('sidebar');
      let notifyCon = document.getElementsByClassName('notify');
      // console.log(notifyCon)
      // console.log(sidebarCon)
      if(targetEl === sidebarCon) {
        console.log("sdfs")
        return;
      }else{

        for(let i=0; i<notifyCon.length; i++){
          if(targetEl === notifyCon[i]) {
            console.log(notifyCon[i])
            setSidebar((prev) => (true))
            return;
          }
        }
        
        if(sidebar && targetEl !== sidebarCon){
          console.log("afwf")
          setSidebar((prev) => (false))
          setHowToConnectSlack((prev) => (false))
          setIsSlack((prev) => (false))
          setIsGoogleChat((prev) => (false))
        }
      }
    }

    document.getElementById("notification_con")!.addEventListener("click", handelClick, false);

    return () => {
      document?.getElementById("notification_con")?.removeEventListener('click', handelClick);
    };
  }, [sidebar])

  const configureSlack = async () => {
    setAdding(true)
    let token = getCookie("Authorization")
    const userData = JSON.parse(localStorage.getItem("user") as string);
    try {
      let data = {
        "webhook": webhook, 
        "departmentId": department.id
      }
      const response = await instance.post(`api/integration/slack/configure/${userData.businessId}`, { ...data },
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data)
      showToast("primary", "Success!", "Department connected")
      setDepartment(null)
      getDepartments()
      setWebhook("")
      setSelectedDepartment("")
      setAdding(false)
    } catch (error) {
      if (error instanceof AxiosError) {
        
      }
      setAdding(false)
      console.log(error);
    }
  }

  const getDepartments = () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(userData.businessId)
      if(business)
      instance.get(
        `api/department/${business.id}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res)
          let slackDept = []
          if(res.data.data){
            for(let i=0; i<res.data.data.length; i++){
              if(res.data.data[i].slackWebhook && res.data.data[i].slackWebhook.length > 0){
                slackDept.push(res.data.data[i].department)
              }
            }
            setSlackDepartments(slackDept);
          }
          setDepartments(res.data.data)
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  const searchDepartment = (e: any) => {
    let token = getCookie("Authorization")
    if(e.target.value !== ""){
      if(localStorage.getItem("user") && token){
        const business = JSON.parse(localStorage.getItem("business") as string);
        // setBusinessId(business.businessId)
        if(business)
        instance.get(
          `api/department/${business.id}?name=${e.target.value}`,
          {
            headers: {
              'Access-Control-Allow-Headers': 'X-Requested-With',
              'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            console.log(res)
            setDepartments(res.data.data)
        }).catch(e => {
          console.log(e.response.data)
        })
      }else{
        localStorage.removeItem("user")
        window.location.assign('/login')
      }
    }else{
      getDepartments()
    }
  }

  const handleHowToIndex = (index: number) => {

    if(index === howToIndex){
      setHowToIndex(Infinity)
    }else{
      setHowToIndex(index)
    }
  }

  const createCampaignName = async () => {
    setAdding(true)
    let token = getCookie("Authorization")
    try {
      let data = {
        "senderName": campaignName,
      }
      const response = await instance.post(`api/sales-pilot/register-sender-name`, { ...data },
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      });
      console.log(response.data)
      getBusiness()
      showToast("primary", "Success!", "Campaign name created")
      setIsSMSCampaign(false)
      setCampaignName("")
      setAdding(false)
    } catch (error) {
      if (error instanceof AxiosError) {
        
      }
      setAdding(false)
      console.log(error);
    }
  }

  const getBusiness = async () => {
    const userData = JSON.parse(localStorage.getItem("user") as string);
    let token = getCookie("Authorization")
    instance.get(
      `api/business/get/${userData.businessId}`,
      {
        headers: {
          'Access-Control-Allow-Headers': 'X-Requested-With',
          'Authorization': `Bearer ${token}`
        }
      }).then(res => {
        console.log(res.data)
        setBusinessDetails(res.data)
    }).catch(e => {
      console.log(e.response.data)
    })
  }

  return (
    <>
      <section id="notification_con" className={styles.wrapper}>
        <header>
          {/* <h1>Choose your Premium</h1> */}
        </header>
        <section id="notification" className={styles.content}>
          <h3>Notifications</h3>
          <div className={styles["notifications_con"]}>
            <p><EsclamationIcon style={styles["esclamation_icon"]} /> Enable or disable escalated notification sent through the various channels</p>
            <div className={styles["notifications_col"]}>
              <div className={styles["notifications"]}>
                <div className={`${styles["notification"]} notify`}>
                  <div className={`${styles["channel"]} notify`}>
                    <div className={`${styles["channel_icon_con"]} notify`}>
                      <WhatsappIcon2 style={`${styles["channel_icon2"]} notify`} />
                    </div>
                    <div>
                      <h4 className={`notify`}>Escalation to Whatsapp</h4>
                    </div>
                  </div>
                  <div className={`${styles["controls"]} notify`}>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div style={isSlack && sidebar ? {borderColor: "#5100FF"}: {}} onClick={() => {setIsSMSCampaign(false); setIsSlack(true); setIsGoogleChat(false)}} className={`${styles["notification"]} notify`}>
                  <div className={`${styles["channel"]} notify`}>
                    <div className={`${styles["channel_icon_con"]} notify`}>
                      <SlackIcon style={`${styles["channel_icon2"]} notify`} />
                    </div>
                    <div>
                      <h4 className={`notify`}>Escalation to Slack</h4>
                    </div>
                  </div>
                  <div className={`${styles["controls"]} notify`}>
                    <AntSwitch onClick={() => setSidebar(true)} className="notify" checked={(slackDepartments && slackDepartments?.length>0)} inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div className={`${styles["notification"]} notify`}>
                  <div className={`${styles["channel"]} notify`}>
                    <div className={`${styles["channel_icon_con"]} notify`}>
                      <JiraIcon style={`${styles["channel_icon2"]} notify`} />
                    </div>
                    <div>
                      <h4 className={`notify`}>Escalation to Jira</h4>
                    </div>
                  </div>
                  <div className={`${styles["controls"]} notify`}>
                    <AntSwitch inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div style={isGoogleChat && sidebar ? {borderColor: "#5100FF"}: {}} onClick={() => {setIsSMSCampaign(false); setIsGoogleChat(true); setIsSlack(false)}} className={`${styles["notification"]} notify`}>
                  <div className={`${styles["channel"]} notify`}>
                    <div className={`${styles["channel_icon_con"]} notify`}>
                      <GoogleChatIcon style={`${styles["channel_icon2"]} notify`} />
                    </div>
                    <div>
                      <h4 className={`notify`}>Escalation to Google Chat</h4>
                    </div>
                  </div>
                  <div className={`${styles["controls"]} notify`}>
                    <AntSwitch onClick={() => setSidebar(true)} className="notify" checked={(slackDepartments && slackDepartments?.length>0)} inputProps={{ 'aria-label': 'ant design' }} />
                  </div>
                </div>
                <div style={isGoogleChat && sidebar ? {borderColor: "#5100FF"}: {}} onClick={() => {setIsSMSCampaign(true); setIsGoogleChat(false); setIsSlack(false)}} className={`${styles["notification"]} notify`}>
                  <div className={`${styles["channel"]} notify`}>
                    <div className={`${styles["channel_icon_con"]} notify`}>
                      <GoogleChatIcon style={`${styles["channel_icon2"]} notify`} />
                    </div>
                    <div>
                      <h4 className={`notify`}>SMS Campaign</h4>
                    </div>
                  </div>
                  {/* <div className={`${styles["controls"]} notify`}>
                    <AntSwitch onClick={() => setSidebar(true)} className="notify" checked={(slackDepartments && slackDepartments?.length>0)} inputProps={{ 'aria-label': 'ant design' }} />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {sidebar && isSlack && !howToConnectSlack &&
        <div id="sidebar" className={`${styles.live_chat_sidebar} notify`}>
          <div className={`${styles.live_chat_sidebar_header} notify`}>
            <div className={`notify`}>
              <SlackIcon style={styles["live_chat_sidebar_header_icon"]} />
              Slack Integration
            </div>
            <button onClick={() => {setHowToConnectSlack(true)}} className={`notify`}>
              How to connect slack
              <EsclamationIcon style={styles["button_escalation"]} />
            </button>
          </div>
          <div className={`${styles.live_chat_sidebar_body} notify`}>
            <div className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2} notify`}>
              <header className={`notify`}>
                <UserStrokeIcon style={styles.live_chat_sidebar_icon_stroke} />
                <p>Department Name</p>
              </header>
              <input
                value={selectedDepartment}
                className={`notify`}
                onChange={(e) => {searchDepartment(e); setSelectedDepartment(e.target.value); setSearching(true)}}
              />
              {searching && 
              <div className={`${styles["department_con"]} notify`}>
                {departments?.map((department) => {
                  return (
                    <div onClick={() => {setDepartment(department); setSelectedDepartment(department.department); setSearching(false)}} className={`${styles["department"]} notify`}>
                      <h4 className={`notify`}>{department.department}</h4>
                    </div>
                  )
                })}
              </div>
              }
            </div>

            <div className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2} notify`}>
              <header className={`notify`}>
                <LinkIcon style={styles.live_chat_sidebar_icon} />
                <p className={`notify`}>Web Hook Link</p>
              </header>
              <textarea
                className={`notify`}
                value={webhook}
                onChange={(e) => setWebhook(e.target.value)}
              />
            </div>
            <button onClick={() => {!adding && configureSlack()}} className={`${styles["add_department_button"]} notify`}>
              {adding ? "Loading..." : "Connect"}
            </button>
          </div>
          <div className={`${styles.connected_departments_con} notify`}>
            <header className={`notify`}>
              <SettingStrokeIcon style={styles.live_chat_sidebar_icon_stroke} />
              <p> Connected Departments</p>
            </header>
            {/* {slackDepartments && slackDepartments.length <= 0 ? */}
            <div className={`${styles.no_connected_department_con} notify`}>
              <div className={`${styles.no_connected_department_icon} notify`}>
                <EsclamationIcon style={styles[""]} />
              </div>
              <p className={`notify`}>You have not connected any department</p>
            </div>
            {/* :
            <div className={`${styles.connected_departments} notify`}>
              {
                slackDepartments?.map((slackDepartment) => {
                  return(
                    <div className={`${styles.connected_department} notify`}>
                      {slackDepartment}
                    </div>
                  )
                })
              }
              
            </div>
            } */}
          </div>
        </div>
        }
        {sidebar && isSMSCampaign &&
        <div id="sidebar" className={`${styles.live_chat_sidebar} notify`}>
          <div className={`${styles.live_chat_sidebar_header} notify`}>
            <div className={`notify`}>
              <SlackIcon style={styles["live_chat_sidebar_header_icon"]} />
              SMS Campaign
            </div>
          </div>
          <div className={`${styles.live_chat_sidebar_body} notify`}>
            <div className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2} notify`}>
              <header className={`notify`}>
                <UserStrokeIcon style={styles.live_chat_sidebar_icon_stroke} />
                <p>Campaign Name</p>
              </header>
              <input
                contentEditable={businessDetails.businessSenderName ? false : true}
                value={businessDetails.businessSenderName ? businessDetails.businessSenderName : campaignName}
                className={`notify`}
                onChange={(e) => {setCampaignName(e.target.value)}}
              />
            </div>
            {!businessDetails.businessSenderName &&
            <button onClick={() => {!adding && createCampaignName()}} className={`${styles["add_department_button"]} notify`}>
              {adding ? "Loading..." : "Create"}
            </button>
            }
          </div>
          
        </div>
        }
        {sidebar && isSlack && howToConnectSlack && 
        <div id="sidebar" className={`${styles.live_chat_sidebar} notify`}>
          <div className={`${styles.live_chat_sidebar_header} notify`}>
            <div className={`notify`}>
              <SlackIcon style={styles["live_chat_sidebar_header_icon"]} />
              How to connect slack
            </div>
          </div>
          <div className={`${styles.live_chat_sidebar_body_how} notify`}>
            <div className={`${styles.live_chat_sidebar_section_how} notify`}>
              <header onClick={() => handleHowToIndex(1)} className={`notify`}>
                <SettingStrokeIcon style={`${styles.live_chat_sidebar_how_icon_stroke} notify`} />
                <p className={`notify`}>Access Slack Configuration</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 1 && styles["rotate_icon"])} notify`} />
              </header>
              {howToIndex === 1 &&
              <div className={`${styles.how_process_con} notify`}>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Navigate to the web application.</p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Click on the settings button <span>(gear icon)</span> on the left sidebar.</p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Choose <span>“Channels”</span> and locate the Slack option.</p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Click on <span>“Connect”</span> to open the configuration screen.</p>
                </div>
              </div>
              }
            </div>
            <div className={`${styles.live_chat_sidebar_section_how} notify`}>
              <header onClick={() => handleHowToIndex(2)} className={`notify`}>
                <SettingStrokeIcon style={`${styles.live_chat_sidebar_how_icon_stroke} notify`} />
                <p className={`notify`}>Obtain Slack Webhook URL</p>
                <CollapseIcon style={`${styles["icon_colapse"]} ${(howToIndex === 2 && styles["rotate_icon"])} notify`} />
              </header>
              {howToIndex === 2 &&
              <div className={`${styles.how_process_con} notify`}>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>To get the Slack webhook URL: <span>Go to your Slack workspace.</span></p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Click on your workspace name in the top left.</p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Select <span>“Settings & administration”</span> and choose <span>“Manage apps.”</span></p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Find <span>“Incoming Webhooks”</span> and activate it.</p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Click <span>“Add to Workspace”</span> and select the channel where you want notifications.</p>
                </div>
              </div>
              }
            </div>

            <div className={`${styles.live_chat_sidebar_section_how} notify`}>
              <div className={`${styles.how_process_con} notify`}>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Once added, you’ll see the Webhook URL. Copy this URL (e.g.)</p>
                </div>
                <div className={`${styles.code_div} notify`}>
                  https://hooks.slack.com/services/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
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>On the configuration screen, find a text-box.</p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Enter the copied Slack webhook URL.</p>
                </div>
                <div className={`${styles.how_process} notify`}>
                  <div className={`notify`}></div>
                  <p className={`notify`}>Click on the <span>“Connect”</span> button located at the top of the text-box.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        {sidebar && isGoogleChat && 
        <div id="sidebar" className={`${styles.live_chat_sidebar} notify`}>
          <div className={`${styles.live_chat_sidebar_header} notify`}>
            <div className={`notify`}>
              <GoogleChatIcon style={styles["live_chat_sidebar_header_icon"]} />
              Gmail Integration
            </div>
            <button className={`notify`}>
              How to connect gmail
              <EsclamationIcon style={styles["button_escalation"]} />
            </button>
          </div>
          <div className={`${styles.live_chat_sidebar_body} notify`}>
            <div className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2} notify`}>
              <header className={`notify`}>
                <UserStrokeIcon style={styles.live_chat_sidebar_icon_stroke} />
                <p>Department Name</p>
              </header>
              <input
                value={selectedDepartment}
                className={`notify`}
                onChange={(e) => {searchDepartment(e); setSelectedDepartment(e.target.value); setSearching(true)}}
              />
              {searching && 
              <div className={`${styles["department_con"]} notify`}>
                {departments?.map((department) => {
                  return (
                    <div onClick={() => {setDepartment(department); setSelectedDepartment(department.department); setSearching(false)}} className={`${styles["department"]} notify`}>
                      <h4 className={`notify`}>{department.department}</h4>
                    </div>
                  )
                })}
              </div>
              }
            </div>

            <div className={`${styles.live_chat_sidebar_section} ${styles.live_chat_sidebar_section2} notify`}>
              <header className={`notify`}>
                <LinkIcon style={styles.live_chat_sidebar_icon} />
                <p className={`notify`}>Web Hook Link</p>
              </header>
              <textarea
                className={`notify`}
                value={webhook}
                onChange={(e) => setWebhook(e.target.value)}
              />
            </div>
            <button onClick={() => {!adding && configureSlack()}} className={`${styles["add_department_button"]} notify`}>
              {adding ? "Loading..." : "Connect"}
            </button>
          </div>
          <div className={`${styles.connected_departments_con} notify`}>
            <header className={`notify`}>
              <SettingStrokeIcon style={styles.live_chat_sidebar_icon_stroke} />
              <p> Connected Departments</p>
            </header>
            {slackDepartments && slackDepartments.length <= 0 ?
            <div className={`${styles.no_connected_department_con} notify`}>
              <div className={`${styles.no_connected_department_icon} notify`}>
                <EsclamationIcon style={styles[""]} />
              </div>
              <p className={`notify`}>You have not connected any department</p>
            </div>
            :
            <div className={`${styles.connected_departments} notify`}>
              {
                slackDepartments?.map((slackDepartment) => {
                  return(
                    <div className={`${styles.connected_department} notify`}>
                      {slackDepartment}
                    </div>
                  )
                })
              }
              
            </div>
            }
          </div>
        </div>
        }
      </section>
    </>
  );
};

export default Notifications;
