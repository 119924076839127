import React, { useEffect, useState } from "react";
import styles from "./Conversation.module.css";
// import CheckIcon from "../../assets/images/conversation_checkbox.svg";
// import StarIcon from "../../assets/images/conversation_star.svg";
import { EscalatedChatIcon, StarIconStroke } from "../../assets/icons";
import { interactingAdmin } from "../../pages/LiveChat/LiveChat";

interface Props {
  customer: string;
  date: Date;
  ticketId: string;
  handleConversationClicked: (ticketId: string) => void;
  selected: boolean;
  read:boolean;
  selectedTicketIds: Array<string> | undefined
  selectTickets: Function;
  escalated: boolean;
  action: string;
  interactingStatus:boolean;
  userId:string;
  interactingAdminId:string
}

const Conversation: React.FC<Props> = (props) => {
  const [isRead, setIsRead] = useState(false);
  const [selected, setSelected] = useState(false)
  
  const handleClick = () => {
    setIsRead(true)
    props.handleConversationClicked(props.ticketId);
  };

  useEffect(() => {
    setIsRead(props.read)
  }, [props.read])

  useEffect(() => {
    if(props.selectedTicketIds){
      const found = props.selectedTicketIds.find((id) => id === props.ticketId)
      if(found){
          setSelected(true)
      }else{
          setSelected(false)
      }
    }
  }, [props.selectedTicketIds, props.ticketId])

  return (
    <li
      className={`${styles["list-item"]} ${
        props.selected && styles["selected"]
      }`}
      onClick={handleClick}
    >
      <div className={styles.checkIcons}>
        <div onClick={() => props.selectTickets(props.ticketId)}>
          <input onChange={() => {}} checked={selected} type="checkbox" />
        </div>
        {/* <img src={StarIcon} alt="star" /> */}
        {(props.escalated && (props.action === "all" || props.action === "unread" || props.action === "read")) ? <EscalatedChatIcon style={styles.starIcons} /> : <p></p>}
        {/* <StarIconStroke style={props.escalated ? styles.starIcons_checked: styles.starIcons} /> */}
        {/* <p className={styles["message-text"]}>{props.customer}</p> */}
      </div>
      
      <div className={styles["message-text"]}>
        <p style={{fontFamily: isRead ? "Axiforma Light": "Axiforma"}}>{props.customer ? props.customer : "No Name"}</p>
       {props.interactingStatus && (props.interactingAdminId !== props.userId) &&
       <div className={styles['indicator-circle']}>
          <div className = {styles["inner-circle"]}>
            </div>
       </div>
      }
      </div>

      <span className={styles["message-date"]}>
        {props.date.toLocaleString("default", {
          month: "short",
          day: "2-digit",
        })}
      </span>
      {/* <span className={styles["message-date"]}>Aug 04</span> */}
    </li>
  );
};

export default Conversation;
