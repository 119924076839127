import React, { useEffect, useState } from "react";
import styles from "./orders.module.css";
import instance from "../../api";
import { Link, useLocation } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import { ArrowLeftIcon, ArrowRightIcon2, CollapseIcon, FilterIconStroke, MailStrokeIcon, PhoneIcon, SearchIcon } from "../../assets/icons";
import { checkuser, getCookie, } from "../../utilities/helpers";
import OrderRow from "../../components/Orders/OrderRow";

const Orders = () => {
  checkuser()
  const router = useLocation()
  const [businessId, setBusinessId] = useState('');
  const [action, setAction] = useState('');
  const [hasOrder, setHasOrder] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState<any>()
  const [selectedOrderIds, setSelectedOrderIds] = useState<Array<string>>()
  const [orders, setOrders] = useState([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1)
  // const [limit, setLimit] = useState<number>(100)
  const limit = 100
  const [totalPage, setTotalPage] = useState<number>(1)
  // const [totalOrder, setTotalOrder] = useState<number>(1)
  const [escalate, setEscalate] = useState<boolean>(false);
  const [departments, setDepartments] = useState<Array<any>>()
  const [selectedDepartment, setSelectedDepartment] = useState<any | null>()
  const [team, setTeam] = useState<any>()
  const [tab, setTab] = useState<string>("pending")
  console.log(hasOrder)

  useEffect(() => {
    setIsLoading(true)
    if(team){
      getOrders()
      getDepartments()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, businessId, team])

  useEffect(() => {
    getTeam()
  }, [])

  useEffect(() => {
    const handelClick = (evt: any) => {
      console.log(escalate)
      let targetEl = evt.target; // clicked element  
      let ticketsBox = document.getElementById('tickets');
  
      let escalateBtn = document.getElementById('escalate_btn');
      let searchCon = document.getElementById('search_con');
      let search = document.getElementById('search');
      let departmentCon = document.getElementsByClassName('department_con_c');
      console.log(departmentCon)
      let departmenth4 = document.getElementsByClassName('departmenth4');
      console.log(search)
      console.log(escalateBtn)
      if(targetEl === escalateBtn) {
        return;
      }else if(targetEl === ticketsBox) {
        setEscalate((prev) => (true))
        return;
      }else if(targetEl === search) {
        setEscalate((prev) => (true))
        return;
      }else{
        console.log("sfe")
        for(let i=0; i<departmentCon.length; i++){
          if(targetEl === departmentCon[i]) {
            console.log(departmentCon[i])
            setEscalate((prev) => (true))
            return;
          }
        }
        for(let i=0; i<departmenth4.length; i++){
          if(targetEl === departmenth4[i]) {
            console.log(departmenth4[i])
            setEscalate((prev) => (true))
            return;
          }
        }
        if(!escalate && targetEl !== search && targetEl !== searchCon){
          console.log("afwf")
          setEscalate((prev) => (false))
        }
      }
    }

    document.getElementById("order_con")!.addEventListener("click", handelClick, false);

    return () => {
      document?.getElementById("order_con")?.removeEventListener('click', handelClick);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const selectOrder = (order: any) => {
    console.log(selectedOrderIds)
    // if(selectedOrderIds){
    //   if(selectedOrderIds?.length < 1){
    //     setOpenModal(true)
    //     setSelectedOrder(order)
    //     setSelectedOrderIds((prev: any) => [order.id])
    //   }else{
    //     setSelectedOrder(order)
    //     setSelectedOrderIds((prev: any) => [...prev, order.id])
    //   }
    // }else{
      setOpenModal(true)
      setSelectedOrder(order)
      setSelectedOrderIds((prev: any) => [order.id])
    // }
  }

  const getDepartments = () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const business = JSON.parse(localStorage.getItem("business") as string);
      // setBusinessId(business.businessId)
      if(business)
      instance.get(
        `api/department/${business.id}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res)
          setDepartments(res.data.data)
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  const getOrders = () => {
    let path = router.pathname
    let action = path.includes("all") ? "all": path.includes("inactive") ? "in active": path.includes("escalated") ? "escalated": path.includes("resolved") ? "resolved": "closed";
    console.log(action)
    setAction(action);
    // if(router.search.length > 0){
    //   let searchArray = router.search.split("=")
    //   if(searchArray[0].includes("department")){
    //     department = searchArray[1].replaceAll("%20", " ")
    //     setSearch(department)
    //   }else{
    //     department = null
    //   }
    // }else{
    //   department = null
    //   setSearch("")
    // }
    setCurrentPage(1)
    if(localStorage.getItem("user")){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      const businessData = JSON.parse(localStorage.getItem("business") as string);
      setBusinessId(userData.businessId)
      let url = `/api/chat/all-chat/${userData.businessId}?page=${1}&active=true&limit=${limit}&closed=false`;
      if(action === "all"){
        url = `/api/chat/all-chat/${userData.businessId}?page=${1}&active=true&limit=${limit}`
      }else if(action === "in active"){
        url = `/api/chat/all-chat/${userData.businessId}?page=${1}&active=true&limit=${limit}&closed=false`
      }
      else if(action === "escalated"){
        url = `/api/chat/all-chat/${userData.businessId}?page=${1}&active=true&limit=${limit}&closed=false`
      }else if(action === "closed"){
        url = `/api/chat/all-chat/${userData.businessId}?page=${1}&active=true&closed=true`
      }

      if(action === "escalated"){
        url = url+"&escalated=true"
      }else if(action === "resolved"){
        url = url+"&escalated=false"
      }

      console.log(team)

      if(team){
        if(team.role !== "owner"){
          url = url+"&screen=ticket"
        }else{
          // if(department){
          //   url = url+"&department="+department
          // }
        }
      }else{
        url = url+"&screen=ticket"
      }

      console.log(url)

      instance.get(
        `/api/order/get-all/${businessData.id}`
      ).then(res => {
        console.log(res.data)
          if(res.data.length >= 0){
            // if(department){
            //   const tickets = res.data.filter((chat:any) => chat.department === department);
            //   setOrders(tickets)
            // }else{
              setOrders(res.data)
            // }
            setHasOrder(true)
            
          }
          // setTotalPage(res.data.pages)
          // setCurrentPage(res.data.currentPage)
          setIsLoading(false)
      }).catch(e => {
        setIsLoading(false)
        console.log(e.response.data)
        if(e.response.data === "This business dosen't have a knowledge base"){
          setHasOrder(false)
        }
        setHasOrder(false)
      })
    }else{
      setIsLoading(false)
    }
  }

  const nextPageTicket = (num:number) => {
    // setSelectedFilterDepartment(null)
    // setSelectedFilterDate(null)
    setIsLoading(true)
    let path = router.pathname
    let action = path.includes("all") ? "all": path.includes("inactive") ? "in active": path.includes("escalated") ? "escalated": path.includes("resolved") ? "resolved": "closed";
    console.log(action)
    setAction(action);
    // if(router.search.length > 0){
    //   let searchArray = router.search.split("=")
    //   if(searchArray[0].includes("department")){
    //     department = searchArray[1].replaceAll("%20", " ")
    //     setSearch(department)
    //   }else{
    //     department = null
    //   }
    // }else{
    //   department = null
    //   setSearch("")
    // }
    if(localStorage.getItem("user")){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      setBusinessId(userData.businessId)
      let url = `/api/chat/all-chat/${userData.businessId}?page=${num}&active=true&limit=${limit}&closed=false`;
      if(action === "all"){
        url = `/api/chat/all-chat/${userData.businessId}?page=${num}&active=true&limit=${limit}`
      }else if(action === "in active"){
        url = `/api/chat/all-chat/${userData.businessId}?page=${num}&active=true&limit=${limit}&closed=false`
      }
      else if(action === "escalated"){
        url = `/api/chat/all-chat/${userData.businessId}?page=${num}&active=true&limit=${limit}&closed=false`
      }else if(action === "closed"){
        url = `/api/chat/all-chat/${userData.businessId}?page=${num}&active=true&limit=${limit}&closed=true`
      }

      if(action === "escalated"){
        url = url+"&escalated=true"
      }else if(action === "resolved"){
        url = url+"&escalated=false"
      }

      if(team){
        if(team.role !== "owner"){
          url = url+"&screen=ticket"
        }else{
          // if(department){
          //   url = url+"&department="+department
          // }else if(selectedFilterDepartment){
          //   url = url+"&department="+selectedFilterDepartment.department
          // }
        }
      }else{
        url = url+"&screen=ticket"
      }

      instance.get(
        url
      ).then(res => {
          if(res.data.data.length >= 0){
            // if(department){
            //   const tickets = res.data.data.filter((chat:any) => chat.department === department);
            //   setOrders(tickets)
            // }else{
              setOrders(res.data.data)
            // }
            setHasOrder(true)
          }
          setTotalPage(res.data.pages)
          setCurrentPage(res.data.currentPage)
          setIsLoading(false)
      }).catch(e => {
        setIsLoading(false)
        console.log(e.response.data)
        if(e.response.data === "This business dosen't have a knowledge base"){
          setHasOrder(false)
        }
        setHasOrder(false)
        setIsLoading(false)
      })
    }else{
      setIsLoading(false)
    }
  }

  const getTeam = async () => {
    let token = getCookie("Authorization")
    if(localStorage.getItem("user") && token){
      const userData = JSON.parse(localStorage.getItem("user") as string);
      // setBusinessId(userData.businessId)
      instance.get(
        `/api/team/${userData.businessId}/${userData.id}`,
        {
          headers: {
            'Access-Control-Allow-Headers': 'X-Requested-With',
            'Authorization': `Bearer ${token}`
          }
        }).then(res => {
          console.log(res.data)
          setTeam(res.data.data)
      }).catch(e => {
        console.log(e.response.data)
      })
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }


  return (
    <section id="order_con" className={styles.wrapper}>
      {selectedOrder &&
      <Modal isOpen={openModal} onClose={() => {setOpenModal(false); setSelectedDepartment(null); setEscalate(false)}}>
        <div className={styles["order_details_modal"]}>
          <div className={styles["order_details_modal_row_1"]}>
            <div className={styles["order_details_modal_row_1_row_1"]}>
              <div className={styles["order_details_modal_row_1_row_1_col_1"]}>
                <h2>Order Details</h2>
                <div className={styles["order_details_modal_more_info"]}>
                  <div className={styles["order_details_modal_category"]}>
                    <div>
                      <h3>Order ID</h3>
                    </div>
                    <div>
                      <div></div>
                      <p>{selectedOrder.id}</p>
                    </div>
                  </div>
                  <div className={styles["order_details_modal_category"]}>
                    <div>
                      <h3>Item Name</h3>
                    </div>
                    <div>
                      <p>{selectedOrder.productName}</p>
                    </div>
                  </div>
                  <div className={styles["order_details_modal_category"]}>
                    <div>
                      <h3>Order Status</h3>
                    </div>
                    <div>
                      <p 
                      className={
                        selectedOrder.status === "pending" ? styles["neutral"] : 
                        selectedOrder.status === "cancelled" ? styles["angry"]: 
                        styles["happy"]
                      }
                      style={{textTransform: "capitalize"}}
                      >{selectedOrder.status}</p>
                    </div>
                  </div>
                  <div className={styles["order_details_modal_category"]}>
                    <div>
                      <h3>Order Amount</h3>
                    </div>
                    <div>
                      <p>{selectedOrder.price.$numberDecimal}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles["order_details_modal_row_1_row_1_col_2"]}>
                <div className={styles["order_product_image_con"]}>
                  <img className={styles["order_product_image"]} src={selectedOrder.productImage} alt="product" />
                </div>
              </div>
            </div>
            <div className={styles["order_details_modal_row_1_row_2"]}>
              <div className={styles["order_action_buttons"]}>
                <div className={`${styles["order_action_button"]} ${styles["order_action_button_active"]}`}>
                  Complete order
                </div>
                <div className={`${styles["order_action_button"]}`}>
                  Cancel order
                </div>
                <Link to={`/live-chat/${action}?ticketId=${selectedOrder?.ticketId}&page=${currentPage}`}>
                  <div className={`${styles["order_action_button"]}`}>
                    View Conversation
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div className={styles["order_details_modal_row_2"]}>
            <div className={styles["order_details_modal_info"]}>
              <h1>{selectedOrder?.customer}</h1>
            </div>
            <div className={styles["order_details_mores"]}>
              <div className={`${styles["order_details_more"]}`}>
                <MailStrokeIcon style={styles["order_details_more_icon_stroke"]} />
                <h4>{selectedOrder?.email}</h4>
              </div>
              <div className={`${styles["order_details_more"]}`}>
                <PhoneIcon style={styles["order_details_more_icon"]} />
                <h4>{selectedOrder?.phoneNo}</h4>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      }
      <header style={{gridTemplateColumns: "max-content 1fr"}}>
        <ul>
        <Link to={"/gmail"}>
        <li className={styles["go_back"]}>
            <CollapseIcon style={styles["icon"]} />
        </li>
        </Link>
        <h1>All Orders</h1>
        <FilterIconStroke style={styles["filter_icon"]} />
        </ul>

        <div className={styles.header_col2}>
          <div className={styles["search2"]}>
            <SearchIcon style={styles["search_icon"]} />
            <input placeholder="Search for customer name," />
          </div>
        </div>
      </header>
      <div className={styles.header2}>
        <div className={styles.tabs}>
          {/* <Link to={"/settings/teams"}> */}
          <div onClick={() => setTab("pending")} className={styles.tab + " " + (tab === "pending" && styles.tab_hover) }>
              Pending
          </div>
          {/* </Link> */}
          {/* <Link to={"/settings/departments"}> */}
          <div onClick={() => setTab("completed")} className={styles.tab + " " + (tab === "completed" && styles.tab_hover) }>
              Completed
          </div>
          {/* </Link> */}
          {/* <Link to={"/settings/agents"}> */}
          <div onClick={() => setTab("cancelled")} className={styles.tab + " " + (tab === "cancelled" && styles.tab_hover) }>
              Cancelled
          </div>
          {/* </Link> */}
        </div>
      </div>
      <section id="tickets" className={styles.content}>
        
        <section className={styles["order_table_container"]}>
          <table className={styles["order_table"]}>
            <thead>
              <tr style={action === "closed" ? {gridTemplateColumns: "13% 15% 16% 11% 11% 11% 11%"} : {gridTemplateColumns: "13% 15% 16% 11% 11% 11% 11%"}}>
                <th>Order ID</th>
                <th>Customer Name</th>
                <th>Item</th>
                <th>Amount</th>
                <th>Date</th>
                <th>Payment Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ?
              <tr style={{gridTemplateColumns: "1fr"}}>
                <td>
                  <h5 style={{textAlign: "center"}}>Loading...</h5>
                </td>
              </tr>
              :
              <>
                {orders.map((order: any, index) => {
                  return (
                    <OrderRow 
                    order={order}
                    setEscalate={setEscalate}
                    selectedOrder={selectedOrder}
                    action= {action}
                    selectOrder= {selectOrder}
                    />
                  )
                })}
              </>
              }
              
            </tbody>
            <div className={styles.pagination}>
              <div onClick={() => {setCurrentPage(currentPage <=1 ? 1 : currentPage-1); nextPageTicket(currentPage <=1 ? 1 : currentPage-1) } } className={styles.pagination_btn_left}>
                <ArrowLeftIcon style={styles.pagination_icon} />
              </div>
              <div>
                <h5 className={styles.pagination_num}>{`${currentPage} of ${totalPage}`}</h5>
              </div>
              <div onClick={() => {setCurrentPage(currentPage >= totalPage ? totalPage : currentPage+1); nextPageTicket(currentPage >= totalPage ? totalPage : currentPage+1) }} className={styles.pagination_btn_right}>
                <ArrowRightIcon2 style={styles.pagination_icon} />
              </div>
            </div>
          </table>
        </section>
        
      </section>
    </section>
  );
};

export default Orders;
