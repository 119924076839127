import { isToday, isYesterday, format } from "date-fns";
import PizZip from "pizzip";
import { DOMParser } from "@xmldom/xmldom";
import { toast } from "react-toastify";
import CustomToast from "../components/CustomToast/customToast";
import Papa from 'papaparse';

export const formatDate = (date: Date) => {
  if (isToday(date)) {
    return `Today at ${format(date, "hh:mm a")}`;
  } else if (isYesterday(date)) {
    return `Yesterday at ${format(date, "hh:mm a")}`;
  } else {
    return format(date, "MMMM d, yyyy, hh:mm a");
  }
};

export const formatDateToOrdinal = (date: Date) => {
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const dayOfWeek = daysOfWeek[date.getDay()];

  let ordinalIndicator = "th";
  if (day === 1 || day === 21 || day === 31) {
    ordinalIndicator = "st";
  } else if (day === 2 || day === 22) {
    ordinalIndicator = "nd";
  } else if (day === 3 || day === 23) {
    ordinalIndicator = "rd";
  }

  return `${dayOfWeek} ${day}${ordinalIndicator} ${months[monthIndex]} ${year}`;
};

export const formatDateToOrdinal2 = (dateString: string) => {
  var date = new Date(dateString)
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = date.getDate();
  const monthIndex = date.getMonth();
  // console.log(date.getHours(), dateString)
  const hours = date.getHours()

  let ordinalIndicator = "th";
  if (day === 1 || day === 21 || day === 31) {
    ordinalIndicator = "st";
  } else if (day === 2 || day === 22) {
    ordinalIndicator = "nd";
  } else if (day === 3 || day === 23) {
    ordinalIndicator = "rd";
  }

  return `${months[monthIndex]} ${day}${ordinalIndicator} ${hours > 12 ? "PM": "AM"}`;
};

export const calculateDuration = (dateString: string) => {
  if(!dateString){
    return
  }
  var date1 = new Date(dateString); 
  var date2 = new Date(); 
    
  // To calculate the time difference of two dates 
  var difference_In_Time = date2.getTime() - date1.getTime(); 
  var difference_In_Hours = date2.getHours() - date1.getHours(); 
  var difference_In_Minutes = date2.getMinutes() - date1.getMinutes(); 
  var difference_In_Seconds = date2.getSeconds() - date1.getSeconds(); 
    
  // To calculate the no. of days between two dates 
  var difference_In_Days = Math.floor(difference_In_Time / (1000 * 3600 * 24)); 

  // console.log(difference_In_Days, difference_In_Hours, difference_In_Minutes, difference_In_Seconds)
  if(difference_In_Days <= 0){
    if(difference_In_Hours <=0 ){
      if(difference_In_Minutes <=0 ){
        return `${difference_In_Seconds} sec`
      }else{
        return `${difference_In_Minutes} min`
      }
    }else{
      return `${difference_In_Hours} ${difference_In_Hours > 1 ? "hours": "hour"}`
    }
  }
    
  return `${difference_In_Days} ${difference_In_Days > 1 ? "days": "day"}`

};

export const formatStringToDate = (date: string) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2) 
      month = '0' + month;
  if (day.length < 2) 
      day = '0' + day;

  return [day, month, year].join('-');
}

export const str2xml = (str: any) => {
  if (str.charCodeAt(0) === 65279) {
    // BOM sequence
    str = str.substr(1);
  }
  return new DOMParser().parseFromString(str, "text/xml");
};

export const getParagraphs = (content: any) => {
  const zip = new PizZip(content);
  const xml = str2xml(zip.files["word/document.xml"].asText());
  const paragraphsXml = xml.getElementsByTagName("w:p");
  const paragraphs = [];

  for (let i = 0, len = paragraphsXml.length; i < len; i++) {
    let fullText = "";
    const textsXml = paragraphsXml[i].getElementsByTagName("w:t");
    for (let j = 0, len2 = textsXml.length; j < len2; j++) {
      const textXml = textsXml[j];
      if (textXml.childNodes) {
        fullText += textXml.childNodes[0].nodeValue;
      }
    }
    if (fullText) {
      paragraphs.push(fullText);
    }
  }
  return paragraphs;
};
export const ConvertToCSVAndSave = (tickets: Array<any>) => {
  // Convert JSON to CSV format
  let ticketArray = [];

  for(let ticket of tickets){
      ticketArray.push({
          channel: ticket.ticket.channel,
          customer: ticket.ticket.customer,
          email: ticket.ticket.email,
          phoneNo: ticket.ticket.phoneNo,
          category: ticket.ticket.category,
          sentiment: ticket.ticket.sentiment,
          department: ticket.ticket.department,
          escalated: ticket.ticket.escalated,
      })
  }
  const csvData = Papa.unparse(ticketArray);

  // Create a Blob with the CSV data
  const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });

  // Create a download link
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  // Set the link's attributes
  link.setAttribute('href', url);
  link.setAttribute('download', `tickets-${new Date().toLocaleString()}.csv`);

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger a click on the link to initiate download
  link.click();

  // Remove the link from the body
  document.body.removeChild(link);
};
// export const formatText = (text: string) => {
//   const splitText = text.split("Question:");
//   // Remove the first empty entry if present
//   if (splitText[0].trim() === "") splitText.shift();

//   return splitText.map((item) => {
//     const parts = item.split("Response:");
//     return {
//       question: parts[0].trim(),
//       response: parts[1].trim(),
//     };
//   });
// };

export const formatText = (text: string) => {
  const splitText = text.split("Question:");
  if (splitText[0].trim() === "") splitText.shift();

  const formattedData: any = [];

  splitText.forEach((item) => {
    const parts = item.split("Response:");

    if (parts.length !== 2 || !parts[0].trim() || !parts[1].trim()) {
      // console.warn("Invalid format detected:", item);
      return []; // Skip this iteration if format is invalid
    }

    formattedData.push({
      question: parts[0].trim(),
      response: parts[1].trim(),
    });
  });

  return formattedData;
};

export function validateArrayOfObjects(
  arr: { question: string; response: string }[]
) {
  if (!Array.isArray(arr)) {
    return false;
  }

  for (const obj of arr) {
    if (
      !obj ||
      typeof obj !== "object" ||
      !("question" in obj) ||
      !("response" in obj) ||
      typeof obj.question !== "string" ||
      typeof obj.response !== "string"
    ) {
      return false;
    }
  }

  return true;
}

export function checkuser(){
  if(!localStorage.getItem("user")){
    window.location.assign('/login')
  }else{
    const user: any = JSON.parse(localStorage.getItem("user") as string)
    const business: any = JSON.parse(localStorage.getItem("business") as string)
    // console.log(user)
      // console.log(business)
    if(!user.businessId){
      if(window.location.pathname.length !== 0)
        window.location.assign('/')
    }else{
      // console.log(business)
      if(window.location.pathname.length !== 0){
        if(!business?.approved || !business?.completionStatus){
          window.location.assign('/')
        }
      }
    }
  }
}

export function getCookie(cname:string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function setCookie (cname: String, cvalue: String, exdays: number){
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";";
}

export function showToast (className: string, type: string, message: string, autoClose: number | boolean = 5000){
  const options: any = {
    autoClose: autoClose,
    pauseOnHover: true,
    className: className,
  };
  toast(<CustomToast color={className} type={type} message={message} />, options)
}

export const getDateWithInterval = (inputDate:string, interval: string) => {
  const currentDate = new Date();

  switch (interval) {
      case 'Today':
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          currentDate;
          break;
      case 'Yesterday':
          currentDate.setDate(currentDate.getDate() - 1);
          break;
      case '7 Days':
          currentDate.setDate(currentDate.getDate() - 7);
          break;
      case '30 Days':
          currentDate.setDate(currentDate.getDate() - 30);
          break;
      case '3M':
          currentDate.setMonth(currentDate.getMonth() - 3);
          break;
      case '6M':
          currentDate.setMonth(currentDate.getMonth() - 6);
          break;
      default:
          throw new Error('Invalid interval');
  }

  // const formattedDate = formatDate(currentDate);
  return currentDate;
}