import { FC, useState } from "react";
import { CollapseIcon, EnifIcon, SentIcon } from "../../../assets/icons";
import styles from "../../../pages/Gmail/Gmail.module.css";
import { formatDate } from "../../../utilities/helpers";
import Parser from "html-react-parser";

interface PropType {
    selectedEmail: any;
    thread: MailType
}

interface MailType {
    id: string;
    ticketId: string;
    mailId: string;
    emailId: string;
    from: string;
    to: string;
    customerName: string;
    threadId: string;
    subject: string;
    content: string;
    status: string;
    businessId: string;
    originalMailDate: string;
    read: boolean;
    updatedAt: string;
    assistantResponse: string;
    assistantResponseDate: Date;
    role: "assistance" | "user";
    createdAt: Date;
}

const MailCard: FC<PropType> = ({thread, selectedEmail}) => {
    const [selectFilter, setSelectFilter] = useState<string>("") 

    return(
        <>
            {thread.content && 
            <li className={styles["message"]} >
                <div className={styles["message-image-wrapper"]}>
                {thread.role === "assistance" ? (
                    <div className={styles["logo-enif"]}>
                    <EnifIcon style={styles["logo-enifs"]} />
                    </div>
                ) : (
                    <div className={styles["logo-name"]}>
                    {selectedEmail.customer[0]?.toUpperCase()}
                    </div>
                )}
                <div className={styles["message-wrapper"]}>
                    <div className={styles["message-top"]}>
                    <div style={{
                        display: "grid",
                        gap: "10px",
                        gridAutoFlow: "column"
                    }}>
                    <p className={styles["name"]}>
                        {thread.role === "assistance" ? "ENIF" : selectedEmail.customer}
                    </p>
                    <div onMouseEnter={() => setSelectFilter(thread.id)} onMouseLeave={() => setSelectFilter("")} className={`${styles.dropdown} `}>
                        {/* <span className={``} onClick={() => {setSelectFilter(thread.id)}}><FilterIconStroke style={`${styles["filter_icon"]} `} /></span> */}
                        {/* <p className={``} onClick={() => {setSelectFilter(thread.id)}}>{filterEmail}</p> */}
                        <span className={``} onClick={() => {setSelectFilter(thread.id)}}><CollapseIcon style={`${styles["collapse_icon"]} `} /></span>
                        {selectFilter === thread.id &&
                        <div className={`${styles["more_option_con2"]} `}>
                            <div>
                            <h5 className={``}>from:</h5>
                            <h6 className={``}>{thread.from}</h6>
                            </div>
                            <div>
                            <h5 className={``}>to:</h5>
                            <h6 className={``}>{thread.to}</h6>
                            </div>
                            <div>
                            <h5 className={``}>subject:</h5>
                            <h6 className={``}>{thread.subject}</h6>
                            </div>
                        </div>
                        }
                    </div>
                    </div>
                    <p className={styles["date"]}>{thread.originalMailDate && formatDate(new Date(thread.originalMailDate))}</p>
                    </div>
                </div>
                </div>
                <div className={styles["content"]}>
                <p
                dangerouslySetInnerHTML={{__html: thread.content?.replaceAll("\r\n\r\n", "<br><br>").replace(/\r\n$/, "").replaceAll("\n\n", "<br>").replace(/\n/g, '')}}
                >
                    {/* {Parser(thread.content?.replaceAll("\r\n\r\n", "<br><br>").replace(/\r\n$/, "").replace(/\n/g, '<br>'))} */}
                </p>
                {thread.role === "assistance" &&
                <>
                    {thread.status === "sent" &&
                    <SentIcon style={styles["sent_icon"]} />
                    }
                    {thread.status === "draft" &&
                    <SentIcon style={styles["draft_icon"]} />
                    }
                </>
                }
                </div>
            </li>
            }
            <li style={{
                height: "1px",
                backgroundColor: "#F0F2F7",
                position: "relative",
            }}>
                <div style={{
                position: "absolute",
                top: "-18px",
                right: "calc(50% - 17px)",
                backgroundColor: "white",
                padding: "7px 1rem",
                fontFamily: "Axiforma",
                fontSize: "10px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#949699",

                }}>
                {new Date(thread.originalMailDate).toLocaleString("default", {
                month: "short",
                day: "2-digit",
                })}
                </div>
            </li>
            {thread.assistantResponse &&
            <li className={styles["message"]} >
                <div className={styles["message-image-wrapper"]}>
                <div className={styles["logo-enif"]}>
                    <EnifIcon style={styles["logo-enifs"]} />
                </div>
                
                <div className={styles["message-wrapper"]}>
                    <div className={styles["message-top"]}>
                    <div style={{
                        display: "grid",
                        gap: "10px",
                        gridAutoFlow: "column"
                    }}>
                    <p className={styles["name"]}>
                        ENIF
                    </p>
                    <div onMouseEnter={() => setSelectFilter("ENIF")} onMouseLeave={() => setSelectFilter("")} className={`${styles.dropdown} `}>
                        {/* <span className={``} onClick={() => {setSelectFilter(thread.id)}}><FilterIconStroke style={`${styles["filter_icon"]} `} /></span> */}
                        {/* <p className={``} onClick={() => {setSelectFilter(thread.id)}}>{filterEmail}</p> */}
                        <span className={``} onClick={() => {setSelectFilter("ENIF")}}><CollapseIcon style={`${styles["collapse_icon"]} `} /></span>
                        {selectFilter === "ENIF" &&
                        <div className={`${styles["more_option_con2"]} `}>
                            <div>
                            <h5 className={``}>from:</h5>
                            <h6 className={``}>{thread.to}</h6>
                            </div>
                            <div>
                            <h5 className={``}>to:</h5>
                            <h6 className={``}>{thread.from}</h6>
                            </div>
                            <div>
                            <h5 className={``}>subject:</h5>
                            <h6 className={``}>{thread.subject}</h6>
                            </div>
                        </div>
                        }
                    </div>
                    </div>
                    <p className={styles["date"]}>{thread.assistantResponseDate && formatDate(new Date(thread.assistantResponseDate))}</p>
                    </div>
                </div>
                </div>
                <div className={styles["content"]}>
                <p>
                    {thread.assistantResponse && Parser(thread.assistantResponse?.replace("\n", "<br>").replaceAll("\n", "").replaceAll("<br />\n\n", "").replaceAll("\n\n<br/>", "").replaceAll("\n\n<br>", "").replaceAll("\n\n", "<br>").replaceAll("\n  \n<br>", "").replaceAll("\n  \n<br/>", "").replaceAll("\n  \n<br />", "").replaceAll("\n  \n", "<br>").replaceAll("\n<br>", "").replaceAll("\n<br/>", "").replaceAll("\n<br />", "").replaceAll("\n \n<br>", "").replace(/\n/g, ''))}
                </p>
                {thread.role === "assistance" &&
                <>
                    {thread.status === "sent" &&
                    <SentIcon style={styles["sent_icon"]} />
                    }
                    {thread.status === "draft" &&
                    <SentIcon style={styles["draft_icon"]} />
                    }
                </>
                }
                </div>
            </li>
            }
        </>
    )
}

export default MailCard