import React, { FC, useContext, useEffect, useState } from "react";
import styles from "./SideBar.module.css";
import MainSide from "./Main/MainSide";
import ChildSide from "./Child/ChildSide";
import instance from "../../api";
import { useLocation, useParams } from "react-router-dom";
import { getCookie } from "../../utilities/helpers";
import { EnifContext } from "../../utilities/context";

interface PropType {
  handleSidebarCollapse: (value: boolean) => void;
}

const SideBar: FC<PropType> = ({ handleSidebarCollapse }) => {
  const [selectedMainItem, setSelectedMainItem] = useState<string | null>(null);
  const [sideBarIsOpen, setSideBarIsOpen] = useState<boolean>(true);
  const [analysis, setAnalysis] = useState<any | null>();
  const [businessId, setBusinessId] = useState("")
  const [userAB, setUserAB] = useState("")
  const router = useLocation()
  let { threadId } = useParams();
  const [businessApproved, setBusinessApproved] = useState(false)

  const { team } = useContext(EnifContext);

  useEffect(() => {
    if(localStorage.getItem('mainpage')){
      setSelectedMainItem(localStorage.getItem('mainpage'))
    }
    if(router.pathname.includes("training-center")){
      setSideBarIsOpen(false)
      handleSidebarCollapse(false)
    }

    if(router.pathname.includes("conversations")){
      setSideBarIsOpen(false)
      handleSidebarCollapse(false)
    }

    if(localStorage.getItem("user")){
      let token = getCookie("Authorization")
      const userData = JSON.parse(localStorage.getItem("user") as string);
      const businessData = JSON.parse(localStorage.getItem("business") as string);
      setUserAB(userData.firstName[0]+userData.lastName[0])
      setBusinessId(userData.businessId)
      if(userData.businessId && router.pathname === '/'){
        instance.get(
          `api/analysis/${userData.businessId}`,
          {
            headers: {
              'Access-Control-Allow-Headers': 'X-Requested-With',
              'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            console.log(res.data)
            setAnalysis(res.data)
        }).catch(e => {
          console.log(e.response.data)
        })
      }

      if(businessData?.approved && businessData?.completionStatus){
        setBusinessApproved(true)
      }
    }else{
      localStorage.removeItem("user")
      window.location.assign('/login')
    }

  }, [handleSidebarCollapse, router.pathname, selectedMainItem])
  const getSelectedMainSideBarMenu = (item: string) => {
    setSelectedMainItem(item);
    localStorage.setItem('mainpage', item)
  }
  console.log(team)
  const toggleSidebar = (value?: boolean) => {
    if (value) {
      setSideBarIsOpen(true);
      handleSidebarCollapse(true);
    } else {
      setSideBarIsOpen(!sideBarIsOpen);
      handleSidebarCollapse(!sideBarIsOpen);
    }
  }
  return (
    <aside className={styles["side-bar"]}>
      {!threadId &&
      <MainSide
        getSelectedMainSideBarMenu={getSelectedMainSideBarMenu}
        toggleSidebar={toggleSidebar}
        selectedMainNavItem={selectedMainItem}
        businessId={businessId}
        userAB={userAB}
        businessApproved={businessApproved}
      />
      }
      <ChildSide
        selectedMainNavItem={selectedMainItem}
        getSelectedMainSideBarMenu={getSelectedMainSideBarMenu}
        showSideBar={sideBarIsOpen}
        toggleSidebar={toggleSidebar}
        analysis={analysis}
        businessId={businessId}
        businessApproved={businessApproved}
      />
    </aside>
  );
};

export default SideBar;