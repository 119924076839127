import { FormEvent, useEffect, useState } from "react";
import styles from "./ForgotPassword.module.css";
import logo from "../../assets/images/enif_logo_full.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import instance from "../../api";
import { AxiosError } from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons";
import { setCookie, showToast } from "../../utilities/helpers";
interface InputType {
  email: { value: string; isValid: null | boolean; isTouched: boolean };
  password: { value: string; isValid: null | boolean; isTouched: boolean };
  error: "";
  success: boolean;
}
export default function ForgotPassword() {
  const navigate = useNavigate();
  const router = useLocation()
  const [formInput, setFormInputs] = useState<InputType>({
    email: { value: "", isValid: null, isTouched: false },
    password: { value: "", isValid: null, isTouched: false },
    error: "",
    success: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [password, setPassword] = useState({
    showPassword: false,
    showConfirmPassword: false,
  });

  useEffect(() => {
    if(router?.search?.length > 0){
      if(router.search.includes("&")){
        let email = ""
        let searchArray = router.search.split("&")
        if(searchArray[0]?.split("=")[0].includes("email")){
          email = searchArray[0].split("=")[1]
        }
        if(searchArray[1]?.split("=")[0].includes("email")){
          email = searchArray[1].split("=")[1]
        }
        if(searchArray[2]?.split("=")[0].includes("email")){
          email = searchArray[2].split("=")[1]
        }
        console.log(email)
        setFormInputs((prevState) => ({
          ...prevState,
          email: {
            value: email,
            isValid: validateEmail(email),
            isTouched: true,
          },
        }))
      }
    }
  }, [router.search])

  const disableSubmitButton = Object.values(formInput).some(
    (value) => value.isValid === false || (value.isTouched && !value.isValid)
  );

  const handleForgotPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);
    const data = {
      email: formInput.email.value,
    };
    try {
      const response = await instance.post("api/user/forgot-password", { ...data });
      console.log(response)
      setFormInputs((prev) => ({
        ...prev,
        email: { value: "", isValid: null, isTouched: false },
      }));
      setIsLoading(false);
      showToast("primary", "Success!", response.data.message)


    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status) {
          const message = error.response.data?.message;

          showToast("secondary", "Error!", message)
          setFormInputs((prev) => ({
            ...prev,
            error: message,
          }));
        }else{
          showToast("secondary", "Error!", error.message)
        }
      }
      setIsLoading(false);
      console.log(error);
    }
  };

  const validateEmail = (value: string) =>
    value.length > 0 && value.includes("@") && value.includes(".");

  const validatePassword = (value: string) => value.length > 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <img src={logo} alt="enif" />
      </div>
      <div className={styles.body}>
        <p>Welcome back</p>
        <p>Log into your enif account</p>
        {formInput.error && (
          <div className={styles["error-message"]}>{formInput.error}</div>
        )}
        <div className={styles.formWrapper}>
          <form className={styles["signin-form"]} onSubmit={handleForgotPassword}>
            <div>
              <input
                type="email"
                placeholder="Email Address"
                value={formInput.email.value}
                className={`${
                  !formInput.email.isValid &&
                  formInput.email.isTouched &&
                  styles["error"]
                }`}
                onFocus={() =>
                  setFormInputs((prevState) => {
                    return {
                      ...prevState,
                      email: { ...prevState.email, isTouched: true },
                    };
                  })
                }
                onChange={(e) =>
                  setFormInputs((prevState) => ({
                    ...prevState,
                    email: {
                      value: e.target.value,
                      isValid: validateEmail(e.target.value),
                      isTouched: true,
                    },
                  }))
                }
              />
            </div>
            <div>
              <input
                type="submit"
                value={isLoading ? "Loading..." : "Find My Account"}
                disabled={disableSubmitButton}
              />
            </div>
            
          </form>
        </div>
      </div>
    </div>
  );
}
