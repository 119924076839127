import React, { ChangeEvent, FC, useRef } from "react";
import styles from "./CSVModal.module.css";
import Modal from "../Modal/Modal";
import { FileIcon2, UploadIcon } from "../../assets/icons";
import ReactLoading from 'react-loading';
interface CSVModalModalProps {
  loading: boolean;
  isOpen: boolean;
  onClose: () => void;
  uploadInventory: () => void;
  file: string | null;
  send: () => void;
  removeFile: () => void;
  hasKnowledgeBase: boolean
}

const CSVModal: FC<CSVModalModalProps> = ({ loading, isOpen, onClose, uploadInventory, send, removeFile, file, hasKnowledgeBase }) => {
  const CSVRef = useRef<HTMLInputElement | null>(null);
  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData();
    if (e.target.files && e.target.files.length > 0) {
      formData.append("csv", e.target.files[0]);
    }
  };
  console.log(hasKnowledgeBase)
  // const triggerFileInput = () => {
  //   console.log("triggering file input");
  //   if (CSVRef.current) {
  //     CSVRef.current.click();
  //   }
  // };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <section className={styles["wrapper"]}>
        <section className={styles["header"]}>
          <p>Update Inventory</p>
        </section>
        <section className={styles["main"]}>
          <p>
            Please ensure that the CSV/Excel file is arranged in the following
            format before uploading.
          </p>
          <div className={styles["csv-image"]}></div>
          {file && (
            <p className={styles["file-name"]}>
              <FileIcon2 style={styles["file-icon"]} />
              {(file as unknown as File)?.name}
              <button onClick={removeFile}>X</button>
            </p>
          )}
          <section className={styles["main-section"]}>
            <button
              onClick={file ? send : uploadInventory}
              className={styles["upload-button"]}
            >
              {loading ?
              <div>
                <ReactLoading type={"spin"} color={"rgb(255 255 255 / 60%)"} height={'16px'} width={'16px'} />
              </div>
              : 
              <>
                {file ? 
                "Send":
                <>
                <UploadIcon style={styles["upload-icon"]} />
                Upload file
                </>
                }
              </>
              }
            </button>
            <input
              id={styles["hidden-input"]}
              type="file"
              onChange={handleFileUpload}
              ref={CSVRef}
            />
          </section>
        </section>
      </section>
    </Modal>
  );
};
export default CSVModal;
