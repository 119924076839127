import React, { FC, ChangeEvent } from "react";
import styles from "./SettingsUser.module.css";
import Avatar from "../../assets/images/setting_uer_photo.svg";

interface user {
  name: string;
  role: string;
  avatar: string;
  email: string;
  status?: string;
  handleAdminRemove?: (email: string) => void;
}

const SettingsUser: FC<user> = (props) => {
  const admin = "admin";
  const handleRoleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    console.log(e.target.value);
  };
  const userRoles =
    props.role.toLowerCase() !== admin ? (
      <div className={`${styles.dropdown} ${styles["div-dropdown"]}`}>
        {props.role}
      </div>
    ) : (
      <select
        className={styles.dropdown}
        name="roles"
        onChange={handleRoleChange}
      >
        <option value="">{props.role}</option>
        <option value="option1">Super-Admin</option>
      </select>
    );
  return (
    <section className={styles.wrapper}>
      <div className={styles["user-profile"]}>
        <img src={Avatar} alt={props.name} />
        <div>
          <h4>{props.name}</h4>
          <p>{props.email}</p>
        </div>
      </div>
      <div className={styles["controls"]}>
        {userRoles}
        <input
          type="button"
          onClick={() =>
            props.handleAdminRemove && props?.handleAdminRemove(props.email)!
          }
          value={
            props.role.toLowerCase() === "super-admin" ? "Leave" : "Remove"
          }
        />
      </div>
    </section>
  );
};

export default SettingsUser;
