import React, { FC } from "react";
import styles from "./Message.module.css";
import { formatDate } from "../../utilities/helpers";
import { EnifIcon, SentIcon } from "../../assets/icons";
import { Link } from "react-router-dom";

interface PropType {
  id: string;
  role: string;
  content: string;
  created_date: Date;
  name: string | undefined;
  status: string;
}

const Message: FC<PropType> = (props) => {
  const date: Date = new Date(props.created_date);

  // const formatMessage = (message: string) => {
  //   let msg = message;
  //   if(msg){
  //     let name = message.match(/\[(.*?)\]/)
  //     let image = message.match(/\((.*?)\)/)

  //     if(name || image){
  //     msg = msg.replace(/\[(.*?)\]/g, '<br>')
  //     let images = msg.match(/\((.*?)\)/g)
  //     if(images){
  //         for (let i = 0; i < images.length; i++) {
  //         const image = images[i];
  //         let exImage = image.match(/\((.*?)\)/);
  //         if(exImage)
  //         if(exImage[1].lastIndexOf('.jpg') > -1 || exImage[1].lastIndexOf('.png') > -1 || exImage[1].lastIndexOf('.jpeg') > -1 || exImage[1].lastIndexOf('.gif') > -1 || exImage[1].lastIndexOf('.webp') > -1){
  //             msg = msg.replace(exImage[0], `<br><img className="" src="${exImage[1]}" alt="product image" />`)
  //             msg = msg.replace('!', '')
  //             // msg = msg.replace(' - ', '<>&emsp</>')
  //         }else{
  //             if(exImage[1].indexOf('http') > -1){
  //             msg = msg.replace(exImage[0], `<a className="" href="${exImage[1]}" target='_blank' >Link</a> <br>`)
  //             msg = msg.replace('!', '')
  //             }
  //         }
  //         }
  //     }
  //     }
  //     msg = msg.replace(/\n/g, '<br>')
  //     return msg;
  //   }else{
  //     return "";
  //   }
  // }
  // const formatMessage = (message: string) => {
  //   let formattedMessage = message;

  //   // Handle links within parentheses and outside parentheses
  //   const linkRegex = /((http|https):\/\/[^\s]+)(?![\.,:;])|(\[([^\]]+)\])/g;

  //   formattedMessage = formattedMessage.replace(linkRegex, (match) => {
  //     const url = match.startsWith('http') ? match : match.slice(1, -1); // Extract URL
  //     const linkText = match.startsWith('http') ? match : match.slice(1, -1); // Extract link text

  //     return `<a className="" href="${url}" target="_blank">${linkText}</a><br>`;
  //   });

  //   // Handle line breaks
  //   formattedMessage = formattedMessage.replace(/\n/g, '<br>');

  //   return formattedMessage;
  // };

  const formatMessage = (message: string) => {
    let formattedMessage = message;
    console.log("formattedMessage1", { formattedMessage });
    if (!formattedMessage || typeof formattedMessage !== "string") {
      return formattedMessage;
    }

    // Regular expression to match URLs with or without parentheses
    const linkRegex = /((http|https):\/\/[^\s()]+)/g;

    formattedMessage = formattedMessage.replace(linkRegex, (match) => {
      let url = match; // URL extracted from the match

      // Remove parentheses from the URL if present
      url = url.replace(/[()]/g, "");

      // Check if the URL is an image URL
      if (/\.(jpg|jpeg|png|gif|webp)$/i.test(url)) {
        return `<br><img className="" src="${url}" alt="product image" /><br>`;
      } else {
        return `<a className="" href="${url}" target="_blank">${url}</a><br>`;
      }
    });

    // Remove parentheses from formattedMessage
    formattedMessage = formattedMessage.replace(/[()]/g, "");

    // Handle line breaks
    formattedMessage = formattedMessage.replace(/\n/g, "<br>");

    // console.log("formattedMessage2", { formattedMessage });
    return formattedMessage;
  };

  return (
    <>
      {/* {(props.status === "draft" && (props.editMessage && props.editMessage.id) !== props.id) &&
      <li className={styles["draft_message"]}>
        <div onClick={() => {props.setEditMessage(props)}} className={styles["draft_message_header"]}>
          <h5>Enif Suggestion</h5>
        </div>
        <p onClick={() => {props.setEditMessage(props)}} className={styles["draft_message_content"]}>{props.content}</p>
        <div onClick={() => {props.removeDraft(props)}} className={styles["close_draft_message"]}>
          <CancelIcon style={styles["close_draft_message_icon"]} />
        </div>
        <div onClick={() => {props.setEditMessage(props)}}>
        <EditIconBox style={styles["draft_icon"]} />
        </div>
      </li>
    } */}
      {props.status === "sent" && (
        <li className={styles["message"]}>
          <div className={styles["message-image-wrapper"]}>
            {props.role === "assistance" ? (
              <div className={styles["logo-enif"]}>
                <EnifIcon style={styles["logo-enifs"]} />
              </div>
            ) : (
              <div className={styles["logo-name"]}>
                {props.name![0]?.toUpperCase() ?? "N"}
              </div>
            )}
            <div className={styles["message-wrapper"]}>
              <div className={styles["message-top"]}>
                <p className={styles["name"]}>
                  {props.role === "assistance"
                    ? "ENIF"
                    : props.name
                    ? props.name
                    : "No Name"}
                </p>
                <p className={styles["date"]}>{formatDate(date)}</p>
              </div>
              <div className={styles["content"]}>
                <p
                  dangerouslySetInnerHTML={{
                    __html: formatMessage(props.content),
                  }}
                ></p>
                {props.role === "assistance" && (
                  <>
                    {props.status === "sent" && (
                      <div className={styles["message_status"]}>
                        <SentIcon style={styles["sent_icon"]} />
                        <p style={{ color: "#5100FF" }}>Sent</p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  );
};
export default Message;
