import instance from "../../api";
import { getCookie } from "../../utilities/helpers";
import Onboarding from "../Onboarding/Onboarding";
import { useEffect, useState } from "react";

export default function Dashboard() {
    const [hasBusiness, setHasBusiness] = useState(false)
    const [isCompleted, setIsCompleted] = useState(false)
    const [businessApproved, setBusinessApproved] = useState(true)

  useEffect(() => {
    // const user: any = JSON.parse(localStorage.getItem("user") as string)

    // if(!user.businessId){
    // //   if(!window.location.pathname.includes("onboarding"))
    //     setHasBusiness(false)
    //     localStorage.setItem("mainpage", "onboarding")
    // }else{
    //   setHasBusiness(true)
    // }
    const business: any = JSON.parse(localStorage.getItem("business") as string)
    if(business){
      setHasBusiness(true)
      console.log(business)
      if(business.completionStatus){
        setBusinessApproved(business.approved);
        setIsCompleted(true)
      }else{
        setBusinessApproved(false)
        setIsCompleted(false)
      }

    }
    
    getBusiness()

  }, [hasBusiness])
  
  const getBusiness = () => {
    if(localStorage.getItem("user")){
      let token = getCookie("Authorization")
      const business: any = JSON.parse(localStorage.getItem("business") as string)
      // const userData = JSON.parse(localStorage.getItem("user") as string);
      if(business && business.businessId){
        console.log("fwefef")
        instance.get(
          `api/business/get/${business.businessId}`,
          {
            headers: {
              'Access-Control-Allow-Headers': 'X-Requested-With',
              'Authorization': `Bearer ${token}`
            }
          }).then(res => {
            if(res.data){
              setHasBusiness(true)
              localStorage.setItem("business", JSON.stringify(res.data))
              if(res.data.completionStatus){
                setBusinessApproved(res.data.approved)
              }else{
                setBusinessApproved(false)
              }
            }
            console.log(res.data)
        }).catch(e => {
          setHasBusiness(false)
          localStorage.removeItem("business")
          window.location.reload()
          console.log(e.response.data)
        })
      }

    }else{
      setHasBusiness(false)
      localStorage.removeItem("user")
      window.location.assign('/login')
    }
  }

  return (
    <>
        {hasBusiness ? 
        // <section className={styles.wrapper}>
        //     <header>
        //     <h1>Your Monthly Growth</h1>
        //     </header>
        // </section> 
        <Onboarding isCompleted={isCompleted} hasBusiness={hasBusiness} businessApproved={businessApproved} />
        :
        <Onboarding isCompleted={isCompleted} hasBusiness={hasBusiness} businessApproved={businessApproved} />
        }
    </>
  );
}
